import {
   Alert,
   Box,
   Button,
   Chip,
   FormControl,
   FormControlLabel,
   FormLabel,
   InputLabel,
   MenuItem,
   OutlinedInput,
   Radio,
   RadioGroup,
   Select,
   TextField,
   Typography,
   useTheme,
} from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import myStyles from './LegisConstis.module.css'
import MyLoaderCircularGradient from '../../../Utils/Loaders/MyLoaderCircularGradient'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../../../FirebaseConfig'
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
   PaperProps: {
      style: {
         maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
         width: 250,
      },
   },
}

function getStyles(name, personName, theme) {
   return {
      fontWeight:
         personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
   }
}

function AddLegisConsti({
   legis,
   statesList,
   districtsList,
   lastConsti,
   uploading,
   uploaded,
   uploadingFailed,
   handleAddConsti,
   stateFull,
   parentConstisList,
   showStatesInModal,
   showConstisListFromParentInModal,
}) {
   const [stateFullNew, setStateFullNew] = useState('')
   const [districtsListNew, setDistrictsListNew] = useState([])

   // const [selectedDivisionIDs, setSelectedDivisionIDs] = useState([])
   // const [selectedDivisionNames, setSelectedDivisionNames] = useState([])

   // const [selectedDistrictIDs, setSelectedDistrictIDs] = useState([])
   const [selectedDistrictNames, setSelectedDistrictNames] = useState([])

   const theme = useTheme()

   const [constiDetails, setConstiDetails] = useState({
      Name: '',
      NameOld: [],
      NameHindi: '',
      NameHindiOld: [],
      NameLocal: '',
      NameLocalOld: [],
      NameAlt: '',
      NameAltOld: [],
      NameAltHindi: '',
      NameAltLocal: '',
      ConstituencyNumber: '',
      Legislature_id: legis.id,
      LegislatureName: legis.Name,
      LegislatureNameHindi: legis.NameHindi,
      LegislatureNameLocal: legis.NameLocal,
      ParentConsti_id: '',
      ParentConstiName: '',
      IsNominated: '',
      Country_id: legis.Country_id,
      CountryName: legis.CountryName,
      Strata: legis.Strata,
      State_id: legis.State_id,
      StateName: legis.StateName,
      Division_id: [legis.Division_id],
      DivisionName: [legis.DivisionName],
      District_id: [legis.District_id],
      DistrictName: [legis.DistrictName],
      SubDistrict_id: '',
      SubDistrictName: '',
      Block_id: '',
      BlockName: '',
      Village_id: '',
      VillageName: '',
      NetasCount: 0,
      NetasCountOld: 0,
      AttachedUsersCount: 0,
      AttachedUsersCanceledCount: 0,
   })

   useEffect(() => {
      console.log('Consti details on change: ', constiDetails)
   }, [constiDetails])

   // related to states
   const handleChangeState = (event) => {
      setSelectedDistrictNames([])
      setConstiDetails({
         ...constiDetails,
         State_id: event.target.value,
         District_id: [],
         DistrictName: [],
         DivisionName: [],
         Division_id: [],
      })

      fetchStateFullNew(event.target.value)
   }

   const fetchStateFullNew = async (stateId) => {
      try {
         const docRef = doc(db, 'States', stateId)
         const docSnap = await getDoc(docRef)
         if (docSnap.exists()) {
            setStateFullNew({ ...docSnap.data(), id: docSnap.id })
         }
      } catch (err) {
         console.log('error: ', err)
      }
   }

   useEffect(() => {
      console.log('reached the use effect of stateFullNew outside if condition')
      if (stateFullNew !== '') {
         fetchDistrictsListByStateNew()
         setConstiDetails({ ...constiDetails, StateName: stateFullNew.Name })
      }
   }, [stateFullNew])

   const fetchDistrictsListByStateNew = async () => {
      try {
         const sortedList = [...stateFullNew.Districts].sort((a, b) => {
            if (b.Name > a.Name) {
               return -1
            } else if (b.Name < a.Name) {
               return 1
            } else {
               return 0
            }
         })

         setDistrictsListNew(sortedList)

         console.log('districts list fetched by state ', sortedList)
      } catch (err) {
         console.log('error fetching districts list: ', err)
      }
   }

   // related to parent consti

   const handleChangeParentConsti = (event) => {
      const parentConstiComplete = parentConstisList.find((item) => item.Constituency_id === event.target.value)
      if (parentConstiComplete) {
         setConstiDetails({
            ...constiDetails,
            ParentConsti_id: event.target.value,
            ParentConstiName: parentConstiComplete.Name,
         })
      } else {
         setConstiDetails({
            ...constiDetails,
            ParentConsti_id: '',
            ParentConstiName: '',
         })
      }
   }

   const handleChangeDistricts = (event) => {
      const {
         target: { value },
      } = event
      // setselectedDistrictIDs(
      //    // On autofill we get a stringified value.
      //    typeof value === 'string' ? value.split(',') : value,
      // )
      setSelectedDistrictNames(
         // On autofill we get a stringified value.
         typeof value === 'string' ? value.split(',') : value,
      )
   }

   useEffect(() => {
      if (selectedDistrictNames && selectedDistrictNames.length > 0) {
         let selectedDistrictsList = []
         if (districtsListNew.length > 0) {
            selectedDistrictsList = districtsListNew.filter((f) =>
               selectedDistrictNames.some((item) => item === f.Name),
            )
         } else {
            selectedDistrictsList = districtsList.filter((f) => selectedDistrictNames.some((item) => item === f.Name))
         }

         const selectedDistrictIDsFetched = []
         const selectedDistrictIDNamePairFetched = []

         const selectedDivisionIDsFetched = []
         const selectedDivisionNamesFetched = []
         const selectedDivisionIDNamePairFetched = []

         selectedDistrictsList.forEach((obj) => {
            selectedDistrictIDsFetched.push(obj.District_id)
            selectedDistrictIDNamePairFetched.push({ District_id: obj.District_id, Name: obj.Name })
            if (obj.Division_id) {
               const divisionId = selectedDivisionIDsFetched.find((item) => item === obj.Division_id)
               if (!divisionId) {
                  selectedDivisionIDsFetched.push(obj.Division_id)
                  selectedDivisionNamesFetched.push(obj.DivisionName)
                  selectedDivisionIDNamePairFetched.push({ Division_id: obj.Division_id, Name: obj.DivisionName })
               }
            }
         })

         setConstiDetails({
            ...constiDetails,
            District_id: selectedDistrictIDsFetched,
            DistrictName: selectedDistrictIDNamePairFetched,
            DivisionName: selectedDivisionIDNamePairFetched,
            Division_id: selectedDivisionIDsFetched,
         })
      }
   }, [selectedDistrictNames])

   // useEffect(() => {
   //    if (selectedDistrictIDs && selectedDistrictIDs.length > 0) {
   //       setConstiDetails({ ...constiDetails, District_id: selectedDistrictIDs })
   //       if (selectedDistrictNames && selectedDistrictNames.length) {
   //          // update selected division IDs
   //          const selectedDistrictsList = districtsList.filter((f) =>
   //             selectedDistrictNames.some((item) => item === f.Name),
   //          )
   //          const selectedDivisionIDsFetched = []
   //          selectedDistrictsList.forEach((obj) => {
   //             if (obj.Division_id) {
   //                const divisionId = selectedDivisionIDsFetched.find((item) => item === obj.Division_id)
   //                if (!divisionId) {
   //                   selectedDivisionIDsFetched.push(obj.Division_id)
   //                }
   //             }
   //          })
   //          setSelectedDivisionIDs(selectedDivisionIDsFetched)
   //       } else {
   //          setSelectedDivisionIDs([])
   //       }
   //    }
   // }, [selectedDistrictIDs])

   // related to divisions

   // useEffect(() => {
   //    if (selectedDivisionIDs && selectedDivisionIDs.length > 0) {
   //       setConstiDetails({ ...constiDetails, Division_id: selectedDivisionIDs })
   //       if (selectedDivisionIDs && selectedDivisionIDs.length) {
   //          const selectedDistrictsList = districtsList.filter((f) =>
   //             selectedDistrictNames.some((item) => item === f.Name),
   //          )
   //          const selectedDivisionNamesFetched = []
   //          selectedDistrictsList.forEach((obj) => {
   //             // if (obj.DivisionName) {
   //             const divisionName = selectedDivisionNamesFetched.find((item) => item === obj.DivisionName)
   //             if (!divisionName) {
   //                selectedDivisionNamesFetched.push(obj.DivisionName)
   //             }
   //             // }
   //          })
   //          setSelectedDivisionNames(selectedDivisionNamesFetched)
   //       } else {
   //          setSelectedDivisionNames([])
   //       }
   //    }
   // }, [selectedDivisionIDs])

   // useEffect(() => {
   //    if (selectedDistrictNames && selectedDistrictNames.length > 0) {
   //       setConstiDetails({ ...constiDetails, DivisionName: selectedDivisionNames })
   //    } else {
   //       setConstiDetails({ ...constiDetails, DivisionName: [] })
   //    }
   // }, [selectedDivisionNames])

   // related to form filling and submission
   function handleConstiDetails(e) {
      setConstiDetails({ ...constiDetails, [e.target.name]: e.target.value })
   }

   const handleSubmitConsti = async (e) => {
      e.preventDefault()
      if (legis.Strata !== 'Central' && constiDetails.ParentConsti_id === '') {
         alert('Please select a parent constituency!')
         return
      }
      if (
         constiDetails.Name.length >= 3 &&
         constiDetails.IsNominated !== '' &&
         constiDetails.ConstituencyNumber !== ''
      ) {
         console.log('constiDetails before submit: ', constiDetails)
         handleAddConsti(constiDetails)
         setConstiDetails({
            Name: '',
            NameOld: [],
            NameHindi: '',
            NameHindiOld: [],
            NameLocal: '',
            NameLocalOld: [],
            NameAlt: '',
            NameAltOld: [],
            NameAltHindi: '',
            NameAltLocal: '',
            ConstituencyNumber: '',
            Legislature_id: legis.id,
            LegislatureName: legis.Name,
            LegislatureNameHindi: legis.NameHindi,
            LegislatureNameLocal: legis.NameLocal,
            ParentConsti_id: constiDetails.ParentConsti_id,
            ParentConstiName: constiDetails.ParentConstiName,
            IsNominated: constiDetails.IsNominated,
            Country_id: constiDetails.Country_id,
            CountryName: constiDetails.CountryName,
            Strata: legis.Strata,
            State_id: constiDetails.State_id,
            StateName: constiDetails.StateName,
            Division_id: constiDetails.Division_id,
            DivisionName: constiDetails.DivisionName,
            District_id: constiDetails.District_id,
            DistrictName: constiDetails.DistrictName,
            SubDistrict_id: constiDetails.SubDistrict_id,
            SubDistrictName: constiDetails.SubDistrictName,
            Block_id: constiDetails.Block_id,
            BlockName: constiDetails.BlockName,
            Village_id: constiDetails.Village_id,
            VillageName: constiDetails.VillageName,
            NetasCount: 0,
            NetasCountOld: 0,
            AttachedUsersCount: 0,
            AttachedUsersCanceledCount: 0,
         })

         switch (legis.Strata) {
            case 'Central':
               setSelectedDistrictNames([])
               break
            case 'State':
               setSelectedDistrictNames(constiDetails.DistrictName)
               break
            case 'Division':
               //
               break
            case 'District':
               //
               break
            default:
         }
      }
   }

   return (
      <Box
         sx={{
            mb: 1,
            mx: 1,
            pb: 1,
            // borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            '& .MuiButton-root': { width: '15ch' },
            '& .MuiTextField-root': { width: '40ch' },
            '& .MuiFormControl-root': { width: '40ch' },
            justifyContent: 'center',
            alignItems: 'center',
            // background: 'linear-gradient(to top, #c1dfc4 0%, #deecdd 100%)',
         }}
      >
         <Typography
            variant="subtitle1"
            sx={{
               textAlign: 'center',
               fontSize: 14,
               display: 'inline',
            }}
         >
            <strong>List a constituency</strong> (for {legis.Name})
         </Typography>
         <div>
            {showStatesInModal && (
               <FormControl sx={{ m: 1 }} size="small">
                  <InputLabel>State</InputLabel>
                  <Select
                     value={constiDetails.State_id}
                     label="State"
                     onChange={handleChangeState}
                     sx={{ backgroundColor: '#ffffff' }}
                  >
                     <MenuItem value="">
                        <em>None</em>
                     </MenuItem>
                     {statesList &&
                        statesList.map((item) => (
                           <MenuItem key={item.State_id} value={item.State_id}>
                              {item.Name}
                           </MenuItem>
                        ))}
                  </Select>
               </FormControl>
            )}
         </div>
         <div>
            {showConstisListFromParentInModal && (
               <FormControl sx={{ m: 1 }} size="small">
                  <InputLabel>Parent Constituency</InputLabel>
                  <Select
                     value={constiDetails.ParentConsti_id}
                     label="Parent Constituency"
                     onChange={handleChangeParentConsti}
                     sx={{ backgroundColor: '#ffffff' }}
                  >
                     <MenuItem value="">
                        <em>None</em>
                     </MenuItem>
                     {parentConstisList &&
                        parentConstisList.map((item) => (
                           <MenuItem key={item.Constituency_id} value={item.Constituency_id}>
                              {item.Name}
                           </MenuItem>
                        ))}
                  </Select>
               </FormControl>
            )}
         </div>
         <div>
            <FormControl sx={{ m: 1 }}>
               <InputLabel id="demo-multiple-chip-label">Connected Districts</InputLabel>
               <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  sx={{ backgroundColor: '#ffffff' }}
                  multiple
                  value={selectedDistrictNames}
                  onChange={handleChangeDistricts}
                  input={<OutlinedInput id="select-multiple-chip" label="Connected Districts" />}
                  renderValue={(selected) => (
                     <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                           <Chip key={value} label={value} />
                        ))}
                     </Box>
                  )}
                  MenuProps={MenuProps}
               >
                  {districtsListNew &&
                     districtsListNew.length > 0 &&
                     districtsListNew.map((item) => (
                        <MenuItem
                           key={item.District_id}
                           value={item.Name}
                           style={getStyles(item.District_id, selectedDistrictNames, theme)}
                        >
                           {item.Name}
                           {selectedDistrictNames.includes(item.Name) ? <CheckIcon color="info" /> : null}
                        </MenuItem>
                     ))}
                  {districtsList &&
                     districtsList.length > 0 &&
                     districtsList.map((item) => (
                        <MenuItem
                           key={item.District_id}
                           value={item.Name}
                           style={getStyles(item.District_id, selectedDistrictNames, theme)}
                        >
                           {item.Name}
                           {selectedDistrictNames.includes(item.Name) ? <CheckIcon color="info" /> : null}
                        </MenuItem>
                     ))}
               </Select>
            </FormControl>
         </div>
         {/* {showForm && ( */}
         <div>
            <div>
               <TextField
                  onBlur={(event) => event}
                  onChange={(e) => {
                     handleConstiDetails(e)
                  }}
                  value={constiDetails.ConstituencyNumber}
                  name="ConstituencyNumber"
                  id="constituencyNumber"
                  label="Constituency Number"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  required
                  fullWidth
                  sx={{ backgroundColor: '#ffffff', borderRadius: 1 }}
               ></TextField>
            </div>
            <div>
               <TextField
                  onBlur={(event) => event}
                  onChange={(e) => {
                     handleConstiDetails(e)
                  }}
                  value={constiDetails.Name}
                  name="Name"
                  id="name"
                  label="Name"
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  size="small"
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               ></TextField>
            </div>
            <div>
               <TextField
                  onBlur={(event) => event}
                  onChange={(e) => {
                     handleConstiDetails(e)
                  }}
                  value={constiDetails.NameHindi}
                  name="NameHindi"
                  id="nameHindi"
                  label="Name in Hindi"
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  size="small"
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               ></TextField>
            </div>
            <div>
               <TextField
                  onBlur={(event) => event}
                  onChange={(e) => {
                     handleConstiDetails(e)
                  }}
                  value={constiDetails.NameLocal}
                  name="NameLocal"
                  id="nameLocal"
                  label="Name in local language"
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  size="small"
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               ></TextField>
            </div>
            {/* <div>
               <TextField
                  onBlur={(event) => event}
                  onChange={(e) => {
                     handleConstiDetails(e)
                  }}
                  value={constiDetails.NameAlt}
                  name="NameAlt"
                  id="nameAlt"
                  label="Other name"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth
                  sx={{ backgroundColor: '#ffffff', borderRadius: 1 }}
               ></TextField>
            </div> */}

            <div>
               <FormControl>
                  <FormLabel id="demo-controlled-radio-buttons-group">Is nominated?</FormLabel>
                  <RadioGroup
                     row
                     aria-labelledby="demo-controlled-radio-buttons-group"
                     name="IsNominated"
                     value={constiDetails.IsNominated}
                     onChange={(e) => {
                        handleConstiDetails(e)
                     }}
                     sx={{ backgroundColor: '#ffffff', borderRadius: 1, px: 1, mb: 2 }}
                  >
                     <FormControlLabel value="true" control={<Radio size="small" />} label="Yes" />
                     <FormControlLabel value="false" control={<Radio size="small" />} label="No" />
                  </RadioGroup>
               </FormControl>
            </div>
            <div className={myStyles.alertDiv}>
               {uploading && (
                  <MyLoaderCircularGradient title={'Listing the constituency ...'}></MyLoaderCircularGradient>
               )}
               {uploaded && (
                  <Alert variant="outlined" severity="success">
                     <strong>{lastConsti}</strong> listed successfully.
                  </Alert>
               )}
               {uploadingFailed && (
                  <Alert variant="outlined" severity="error">
                     Error occured! <strong>{lastConsti}</strong> could not be listed.
                  </Alert>
               )}
            </div>
            <div>
               <Button
                  type="submit"
                  align="center"
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={(e) => {
                     handleSubmitConsti(e)
                  }}
                  disabled={
                     constiDetails.Name.length < 2 ||
                     constiDetails.ConstituencyNumber === '' ||
                     constiDetails.IsNominated === ''
                  }
               >
                  Submit
               </Button>
               {/* {error && <div className={myStyles.error}>{error}</div>} */}
            </div>
         </div>
         {/* )} */}
      </Box>
   )
}

AddLegisConsti.propTypes = {
   legis: PropTypes.object.isRequired,
   stateFull: PropTypes.object.isRequired,
   handleAddConsti: PropTypes.func.isRequired,
   lastConsti: PropTypes.string.isRequired,
   uploading: PropTypes.bool.isRequired,
   uploaded: PropTypes.bool.isRequired,
   uploadingFailed: PropTypes.bool.isRequired,
   districtsList: PropTypes.array.isRequired,
   statesList: PropTypes.array.isRequired,
   parentConstisList: PropTypes.array.isRequired,
   showStatesInModal: PropTypes.bool.isRequired,
   showConstisListFromParentInModal: PropTypes.bool.isRequired,
}

export default AddLegisConsti
