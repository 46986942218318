import { Box, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import {
   TwitterShareButton,
   WhatsappShareButton,
   FacebookShareButton,
   TelegramShareButton,
   EmailShareButton,
} from 'react-share'
import XIcon from '@mui/icons-material/X'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import FacebookIcon from '@mui/icons-material/Facebook'
import TelegramIcon from '@mui/icons-material/Telegram'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'

import myStyles from './GoDownStageWise.module.css'

const ReactShareButtons = ({ title, hashtags, hashtag, image }) => {
   //

   const shareUrl = window.location.href
   //    const title = document.head.innerHTML
   //    const hashtags = ['StarWars', 'News']

   return (
      <Box>
         <Stack spacing={0.25} className={`${myStyles.icon_position_right} ${myStyles.icon_style_2}`}>
            <TwitterShareButton url={shareUrl} title={title} hashtags={hashtags} image={image}>
               <XIcon
                  size={32}
                  sx={{
                     color: '#ffffff',
                     backgroundColor: '#000000',
                     '&:hover': { backgroundColor: '#ffffff', color: '#000000' },
                  }}
               />
            </TwitterShareButton>
            <FacebookShareButton url={shareUrl} hashtag={hashtag} image={image}>
               <FacebookIcon
                  size={32}
                  sx={{
                     color: '#03a9f4',
                     backgroundColor: '#ffffff',
                     '&:hover': { backgroundColor: '#03a9f4', color: '#ffffff' },
                  }}
               />
            </FacebookShareButton>
            <WhatsappShareButton url={shareUrl} title={title} media={image}>
               <WhatsAppIcon
                  size={32}
                  sx={{
                     color: '#ffffff',
                     backgroundColor: '#4caf50',
                     '&:hover': { backgroundColor: '#ffffff', color: '#4caf50' },
                  }}
               />
            </WhatsappShareButton>
            <TelegramShareButton url={shareUrl} title={title}>
               <TelegramIcon
                  size={32}
                  sx={{
                     color: '#ffffff',
                     backgroundColor: '#03a9f4',
                     '&:hover': { backgroundColor: '#ffffff', color: '#03a9f4' },
                  }}
               />
            </TelegramShareButton>
            <EmailShareButton url={shareUrl} subject={title} body={title}>
               <AlternateEmailIcon
                  size={32}
                  sx={{
                     color: '#ffffff',
                     backgroundColor: '#ba68c8',
                     '&:hover': { backgroundColor: '#ffffff', color: '#ba68c8' },
                  }}
               />
            </EmailShareButton>
         </Stack>
      </Box>
   )
}

ReactShareButtons.propTypes = {
   title: PropTypes.string.isRequired,
   hashtags: PropTypes.array.isRequired,
   hashtag: PropTypes.array.isRequired,
   image: PropTypes.string,
}

export default ReactShareButtons
