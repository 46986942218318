import { Box, Button, TextField } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import myStyles from './Edits.module.css'

function AddImageOnly({ handleAddImage }) {
   const [imageDetails, setImageDetails] = useState({
      image: '',
      Description: '',
      ValueRadio: 'Current image',
   })

   useEffect(() => {
      console.log('image Details on change: ', imageDetails)
   }, [imageDetails])

   const [selectedImage, setSelectedImage] = useState(null)
   const [imageUrl, setImageUrl] = useState(null)
   const refImageInput = useRef()

   function handleChangeImageInput(e) {
      if (e.target.files[0]) {
         setSelectedImage(e.target.files[0])
         // setImageUrl(URL.createObjectURL(e.target.files[0]))
      } else {
         setSelectedImage(null)
         setImageUrl(null)
      }
   }

   useEffect(() => {
      if (selectedImage) {
         setImageUrl(URL.createObjectURL(selectedImage))
         setImageDetails({
            ...imageDetails,
            image: selectedImage,
         })
      }
   }, [selectedImage])

   function RemoveImage() {
      setSelectedImage(null)
      setImageUrl(null)
      refImageInput.current.value = ''
      setImageDetails({
         ...imageDetails,
         image: '',
      })
   }

   function handleDescriptionDetails(event) {
      setImageDetails({ ...imageDetails, [event.target.name]: event.target.value })
   }

   function handleSubmitImage(e) {
      e.preventDefault()

      if (selectedImage !== null) {
         handleAddImage(imageDetails)
      }

      reset()
   }

   function reset() {
      setImageDetails({
         ...imageDetails,
         image: '',
         Description: '',
         ValueRadio: 'Current image',
      })
      setSelectedImage(null)
      setImageUrl(null)
      refImageInput.current.value = ''
   }

   return (
      <Box sx={{ mx: 2 }}>
         <div className={myStyles.flexRow}>
            <input
               type="file"
               id="select-image"
               onChange={handleChangeImageInput}
               accept="image/*"
               ref={refImageInput}
            />
            {imageUrl && selectedImage && (
               <Button variant="outlined" sx={{ textTransform: 'none', py: 0, minWidth: 0 }} onClick={RemoveImage}>
                  {' '}
                  Clear image
               </Button>
            )}
         </div>
         {imageUrl && selectedImage && <img src={imageUrl} className={myStyles.imgX} />}

         <div>
            <TextField
               onBlur={(event) => event}
               onChange={handleDescriptionDetails}
               value={imageDetails.Description}
               name="Description"
               id="description"
               label="Description"
               variant="outlined"
               margin="dense"
               // required
               fullWidth
               multiline
               rows={4}
               size="small"
               sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
            ></TextField>
         </div>
         <div className={myStyles.margin_top_one}>
            <Button
               type="submit"
               align="center"
               variant="contained"
               color="primary"
               size="small"
               margin="dense"
               sx={{ textTransform: 'none', py: 0, minWidth: 0, mt: 0.5 }}
               onClick={(e) => {
                  handleSubmitImage(e)
               }}
               disabled={selectedImage === null}
            >
               Upload image
            </Button>
         </div>
      </Box>
   )
}

AddImageOnly.propTypes = {
   handleAddImage: PropTypes.func.isRequired,
}

export default AddImageOnly
