import React, { useEffect, useState } from 'react'
import myStyles from './Edits.module.css'
import { Box, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material'
import PropTypes from 'prop-types'
import validator from 'validator'

import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

function AddNewsLinksCandidate({ handleNewsLinks }) {
   const [weblinkDetails, setWeblinkDetails] = useState({
      Title: '',
      Weblink: '',
      Date: '',
      Description: '',
      ThisConstituency: 'Yes',
      ThisCandidate: '',
   })
   useEffect(() => {
      console.log('Weblink details on change of its value: ', weblinkDetails)
   }, [weblinkDetails])

   const [errorMessage, setErrorMessage] = useState('')

   const validate = (value) => {
      if (validator.isURL(value)) {
         setErrorMessage('Is Valid URL')
      } else {
         setErrorMessage('Is Not Valid URL')
      }
   }

   function handleDetails(event) {
      setWeblinkDetails({ ...weblinkDetails, [event.target.name]: event.target.value })
      if (event.target.name === 'Weblink') {
         validate(event.target.value)
      }
   }

   // const [chkCandidate, setChkCandidate] = useState(false)

   // const handleChangeCandidate = (event) => {
   //    setChkCandidate(event.target.checked)
   // }

   function handleSubmitTextAndWeblink(e) {
      e.preventDefault()

      if (errorMessage === 'Is Valid URL' && weblinkDetails.ThisCandidate !== '') {
         handleNewsLinks(weblinkDetails)
      }

      reset()
   }

   function reset() {
      setWeblinkDetails({
         Title: '',
         Weblink: '',
         Date: '',
         Description: '',
         ThisConstituency: 'Yes',
         ThisCandidate: '',
      })
   }

   return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
         <Box sx={{ m: 2 }}>
            <Box>
               <DatePicker
                  label="Date"
                  name="Date"
                  value={weblinkDetails.Date != null ? dayjs(weblinkDetails.Date) : null}
                  format="DD-MMM-YYYY"
                  onChange={(newValue) =>
                     setWeblinkDetails({
                        ...weblinkDetails,
                        Date: new Date(newValue),
                     })
                  }
                  size="small"
                  views={['year', 'month', 'day']}
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               />
            </Box>
            <Box>
               <TextField
                  onBlur={(event) => event}
                  onChange={handleDetails}
                  value={weblinkDetails.Title}
                  name="Title"
                  id="title"
                  label="Title"
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  size="small"
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               ></TextField>
            </Box>
            <Box>
               <TextField
                  onBlur={(event) => event}
                  onChange={handleDetails}
                  value={weblinkDetails.Weblink}
                  name="Weblink"
                  id="weblink"
                  label="Website"
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  size="small"
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               ></TextField>
            </Box>

            <Box sx={{ mb: 1 }}>
               <TextField
                  onBlur={(event) => event}
                  onChange={handleDetails}
                  value={weblinkDetails.Description}
                  name="Description"
                  id="description"
                  label="Description"
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  size="small"
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               ></TextField>
            </Box>
            {/* <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
               <FormLabel component="legend">News relates to:</FormLabel>
               <FormGroup>
                  <FormControlLabel
                     disabled
                     control={<Checkbox defaultChecked name="Constituency" />}
                     label="Constituency"
                  />
                  <FormControlLabel
                     control={
                        <Checkbox checked={chkCandidate} onChange={handleChangeCandidate} name="This Candidate" />
                     }
                     label="This Candidate"
                  />
               </FormGroup>
               <FormHelperText>Be careful</FormHelperText>
            </FormControl> */}
            <FormControl
               sx={{
                  px: 2,
                  py: 1,
                  mr: 2,
                  backgroundColor: '#D1D1D1',
                  borderRadius: 1,
               }}
               disabled
            >
               <FormLabel id="demo-controlled-radio-buttons-group">This Constituency:</FormLabel>
               <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="ThisConstituency"
                  value={weblinkDetails.ThisConstituency}
                  // style={{ display: 'flex', gap: '2rem' }}
                  onChange={(e) => {
                     handleDetails(e)
                  }}
                  sx={{
                     backgroundColor: '#ffffff',
                     borderRadius: 1,
                     border: 0.5,
                     py: 0,
                     px: 0.5,
                     '& .MuiSvgIcon-root': {
                        fontSize: 15,
                     },
                     '& .MuiButtonBase-root': {
                        py: 0,
                     },
                     '& MuiFormControlLabel-label': {
                        fontSize: 13,
                        lineHeight: 16,
                        py: 0,
                     },
                  }}
               >
                  <FormControlLabel
                     value="Yes"
                     control={<Radio size="small" />}
                     label="Yes"
                     sx={{ py: 0, fontSize: 15 }}
                  />
                  <FormControlLabel
                     value="No"
                     control={<Radio size="small" />}
                     label="No"
                     sx={{ py: 0, fontSize: 15 }}
                  />
               </RadioGroup>
            </FormControl>
            <FormControl
               sx={{
                  px: 2,
                  py: 1,
                  backgroundColor: '#D1D1D1',
                  borderRadius: 1,
               }}
            >
               <FormLabel id="ons-group" sx={{ color: '#000000' }}>
                  This Candidate:
               </FormLabel>
               <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="ThisCandidate"
                  value={weblinkDetails.ThisCandidate}
                  // style={{ display: 'flex', gap: '2rem' }}
                  onChange={(e) => {
                     handleDetails(e)
                  }}
                  sx={{
                     backgroundColor: '#ffffff',
                     borderRadius: 1,
                     border: 0.5,
                     py: 0,
                     px: 0.5,
                     '& .MuiSvgIcon-root': {
                        fontSize: 15,
                     },
                     '& .MuiButtonBase-root': {
                        py: 0,
                     },
                     '& MuiFormControlLabel-label': {
                        fontSize: 13,
                        lineHeight: 16,
                        py: 0,
                     },
                  }}
               >
                  <FormControlLabel
                     value="Yes"
                     control={<Radio size="small" />}
                     label="Yes"
                     sx={{ py: 0, fontSize: 15 }}
                  />
                  <FormControlLabel
                     value="No"
                     control={<Radio size="small" />}
                     label="No"
                     sx={{ py: 0, fontSize: 15 }}
                  />
               </RadioGroup>
            </FormControl>
            <Box className={myStyles.text_align_center} sx={{ mt: 2 }}>
               <Button
                  type="submit"
                  align="center"
                  variant="contained"
                  color="primary"
                  size="small"
                  margin="dense"
                  onClick={(e) => {
                     handleSubmitTextAndWeblink(e)
                  }}
                  disabled={errorMessage !== 'Is Valid URL' || weblinkDetails.ThisCandidate === ''}
               >
                  Submit
               </Button>
            </Box>
         </Box>
      </LocalizationProvider>
   )
}

AddNewsLinksCandidate.propTypes = {
   handleNewsLinks: PropTypes.func.isRequired,
}

export default AddNewsLinksCandidate
