import {
   Alert,
   Box,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogContentText,
   DialogTitle,
   FormControl,
   IconButton,
   InputLabel,
   Link,
   MenuItem,
   Select,
   Slide,
   Stack,
   Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { useDispatch, useSelector } from 'react-redux'
import { selectUserProfile, selectUsers, setUserProfile } from '../../redux/reducers/users-slice'
import CloseIcon from '@mui/icons-material/Close'
import { selectCountries } from '../../redux/reducers/countries-slice'
import {
   arrayUnion,
   collection,
   doc,
   getDoc,
   getDocs,
   increment,
   query,
   setDoc,
   updateDoc,
   where,
} from 'firebase/firestore'
import { auth, db } from '../../FirebaseConfig'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'

const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction="up" ref={ref} {...props} />
})

function YourConstituencies() {
   const user = useSelector(selectUsers)
   const ip = user.ip
   const dispatch = useDispatch()
   const userProfile = useSelector(selectUserProfile)
   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')

   const [nameOfUser, setNameOfUser] = useState('')
   const [selectedCountry, setSelectedCountry] = useState('')
   const [statesList, setStatesList] = useState('')
   const [legisCentral, setLegisCentral] = useState('')
   const [constisCentral, setConstisCentral] = useState('')
   const [selectedConstiIdForCentral, setSelectedConstiIdForCentral] = useState('')
   const [selectedConstiObjForCentral, setSelectedConstiObjForCentral] = useState('')
   const [legisState, setLegisState] = useState('')
   const [constisState, setConstisState] = useState('')
   const [selectedConstiIdForState, setSelectedConstiIdForState] = useState('')
   const [selectedConstiObjForState, setSelectedConstiObjForState] = useState('')

   const thisCountry = useSelector(selectCountries)

   useEffect(() => {
      if (userProfile && userProfile !== '') {
         const name = userProfile.NameFirst + ' ' + userProfile.NameMiddle + ' ' + userProfile.NameLast
         setNameOfUser(name)
      }
   }, [userProfile])

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         //

         setFirstLoadStatus('success')
      }
   }, [])

   useEffect(() => {
      // fetch central legislature
      if (thisCountry) {
         setSelectedCountry(thisCountry.selectedCountryInformation)
         setStatesList(thisCountry.selectedCountryInformation.States)
      }
   }, [thisCountry])

   useEffect(() => {
      // fetch central legislature
      if (selectedCountry) {
         console.log('selectedCountry::', selectedCountry)
         fetchLegisCentral(selectedCountry.id)
      }
   }, [selectedCountry])

   useEffect(() => {
      console.log('statesList for attach::', statesList)
   }, [statesList])

   // for Central constis

   const fetchLegisCentral = async (id) => {
      try {
         const querryLegis = query(
            collection(db, 'Legislatures'),
            where('Country_id', '==', 'lsTDGRF8XHWnR3VjVuB4'),
            where('Strata', '==', 'Central'),
            where('Type', '==', 'Legislative'),
            where('HasConstituencies', '==', 'true'),
         )
         const querySnapshotLegis = await getDocs(querryLegis)
         const list = []
         querySnapshotLegis.forEach((docSnap) => {
            setLegisCentral({ ...docSnap.data(), id: docSnap.id })
            // extract the constituencies under the legisature
            setConstisCentral(docSnap.data().Constituencies)
            list.push({ Name: docSnap.data().Name, Type: docSnap.data().Type })
         })
         console.log('constis central: ', list)
      } catch (err) {
         console.log('error', err)
      }
   }

   useEffect(() => {
      // fetch central legislature
      console.log('legisCentral for attach:: ', legisCentral)
   }, [legisCentral])

   const handleSelectedConstiForCentral = async (e) => {
      e.preventDefault()
      setSelectedConstiIdForCentral(e.target.value)
   }

   useEffect(() => {
      if (selectedConstiIdForCentral) {
         const thisConsti = constisCentral.find((item) => item.Constituency_id === selectedConstiIdForCentral)
         console.log('thisConsti - Central', thisConsti)
         const constiObjForCentral = {
            Constituency_id: thisConsti.Constituency_id,
            ConstituencyNumber: thisConsti.ConstituencyNumber,
            ConstituencyName: thisConsti.Name,
            ConstituencyNameHindi: thisConsti.NameHindi,
            ConstituencyNameLocal: thisConsti.NameLocal,
            LegislatureName: thisConsti.LegislatureName,
            Legislature_id: thisConsti.Legislature_id,
            Level: 'Central',
            StateName: thisConsti.StateName,
            State_id: thisConsti.State_id,
            CrBy: auth.currentUser.uid,
            CrDt: new Date(),
            CrIP: ip.data.ip,
            ModDt: '',
         }
         setSelectedConstiObjForCentral(constiObjForCentral)
         console.log('constiObjForCentral', constiObjForCentral)
      }
   }, [selectedConstiIdForCentral])

   const handleAttachConstiCentral = async (e) => {
      e.preventDefault()
      attachUserToConsti('Central')
   }

   // state's constis

   const [selectedStateIdForState, setSelectedStateIdForState] = useState('')

   const handleChangeStateForState = async (e) => {
      e.preventDefault()
      setSelectedStateIdForState(e.target.value)
      try {
         // get full state details
         // const docRefState = doc(db, 'States', e.target.value)
         // const docSnapState = await getDoc(docRefState)

         // get full detais of legislature under that state
         const querryLegis = query(
            collection(db, 'Legislatures'),
            where('State_id', '==', e.target.value),
            where('Strata', '==', 'State', where('HasConstituencies', '==', true)),
         )
         const querySnapshotLegis = await getDocs(querryLegis)
         querySnapshotLegis.forEach((docSnap) => {
            setLegisState({ ...docSnap.data(), id: docSnap.id })
            // extract the constituencies under the legisature
            setConstisState(docSnap.data().Constituencies)
         })
      } catch (error) {
         console.log('error inside handleChangeStateForState: ', error)
      }
   }

   const handleSelectedConstiForState = async (e) => {
      e.preventDefault()
      setSelectedConstiIdForState(e.target.value)
      const thisConsti = constisState.find((item) => item.Constituency_id === e.target.value)
      console.log('thisConsti - State', thisConsti)
      const constiObjForState = {
         Constituency_id: thisConsti.Constituency_id,
         ConstituencyNumber: thisConsti.ConstituencyNumber,
         ConstituencyName: thisConsti.ConstituencyName,
         ConstituencyNameHindi: thisConsti.ConstituencyNameHindi,
         ConstituencyNameLocal: thisConsti.ConstituencyNameLocal,
         LegislatureName: thisConsti.LegislatureName,
         Legislature_id: thisConsti.Legislature_id,
         Level: 'State',
         StateName: thisConsti.StateName,
         State_id: thisConsti.State_id,
         CrBy: auth.currentUser.uid,
         CrDt: new Date(),
         CrIP: ip.data.ip,
         ModDt: '',
      }
      setSelectedConstiObjForState(constiObjForState)
   }

   const handleAttachConstiState = async (e) => {
      e.preventDefault()
      attachUserToConsti('State')
   }

   // attach

   async function attachUserToConsti(strata) {
      try {
         // 1. first get the count of attached users from the Constituency and finalize corelist id
         let constiId = ''
         switch (strata) {
            case 'Central':
               constiId = selectedConstiIdForCentral
               break
            case 'State':
               constiId = selectedConstiIdForState
               break
         }

         const docRefConsti = doc(db, 'LegisConstituencies', constiId)
         const docSnapConsti = await getDoc(docRefConsti)
         const slab = 5000
         const count = docSnapConsti.data().AttachedUsersCount
         const quotient = Math.floor(count / slab)
         const num = quotient + 1
         const numStr = String(num).padStart(6, '0')

         // console.log('attaching user stage 11: ')
         // The above is for now. Later when people start changing constis after 2 years, the code need to be revised

         // 2. Now attach the user inside Constituency-AttachedUsersLists-CoreListId

         const docRefConstiSubCollAttached = doc(db, 'LegisConstituencies', constiId, 'AttachedUsersLists', numStr)
         const docSnapConstiSubCollAttached = await getDoc(docRefConstiSubCollAttached)

         if (docSnapConstiSubCollAttached.exists()) {
            await updateDoc(docRefConstiSubCollAttached, {
               Users: arrayUnion({
                  Name: nameOfUser,
                  UserProfile_id: userProfile.id,
                  User_id: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
                  ModDt: '',
               }),
            })
         } else {
            // console.log('attaching user stage 13: ')
            setDoc(
               docRefConstiSubCollAttached,
               {
                  Users: [
                     {
                        Name: nameOfUser,
                        UserProfile_id: userProfile.id,
                        User_id: auth.currentUser.uid,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                        ModDt: '',
                     },
                  ],
               },
               { merge: true },
            )
            // console.log('attaching user stage 14: ', {
            //    Name: nameOfUser,
            //    UserProfile_id: userProfile.id,
            //    User_id: auth.currentUser.uid,
            //    CrDt: new Date(),
            //    CrIP: ip.data.ip,
            //    ModDt: '',
            // })
         }

         // 3. Now, Increment count inside Constituency
         await updateDoc(docRefConsti, {
            AttachedUsersCount: increment(1),
         })

         // 4. Append the coreList id to the object
         const constiObjForUserCentral = { ...selectedConstiObjForCentral, CoreListId: numStr }
         const constiObjForUserState = { ...selectedConstiObjForState, CoreListId: numStr }

         // 5. Add record to user profile
         const docRefUserProfile = doc(db, 'UserProfiles', userProfile.id)
         const docRefUserProfileSubCollAttachedConstis = doc(
            db,
            'UserProfiles',
            userProfile.id,
            'CoreLists',
            'AttachedConstituencies',
         )
         const docSnapUserProfileSubCollAttachedConstis = await getDoc(docRefUserProfileSubCollAttachedConstis)

         switch (strata) {
            case 'Central':
               console.log('attaching user stage 5.C.1: ')
               await updateDoc(docRefUserProfile, {
                  AtCentralConstituency: constiObjForUserCentral,
               })
               console.log('attaching user stage 5.C.2: ')
               if (docSnapUserProfileSubCollAttachedConstis.exists()) {
                  await updateDoc(docRefUserProfileSubCollAttachedConstis, {
                     AttachedConstituencies: arrayUnion(constiObjForUserCentral),
                  })
                  console.log('attaching user stage 5.C.3: ')
               } else {
                  setDoc(
                     docRefUserProfileSubCollAttachedConstis,
                     {
                        AttachedConstituencies: [constiObjForUserCentral],
                     },
                     { merge: true },
                  )
                  console.log('attaching user stage 5.C.4: ')
               }
               break
            case 'State':
               console.log('attaching user stage 5.S.1: ')
               await updateDoc(docRefUserProfile, {
                  AtStateConstituency: constiObjForUserState,
               })
               console.log('attaching user stage 5.S.2: ')
               if (docSnapUserProfileSubCollAttachedConstis.exists()) {
                  await updateDoc(docRefUserProfileSubCollAttachedConstis, {
                     AttachedConstituencies: arrayUnion(constiObjForUserState),
                  })
                  console.log('attaching user stage 5.S.3: ')
               } else {
                  setDoc(
                     docRefUserProfileSubCollAttachedConstis,
                     {
                        AttachedConstituencies: [constiObjForUserState],
                     },
                     { merge: true },
                  )
                  console.log('attaching user stage 5.S.4: ')
               }
               break
         }
         // console.log('attaching user stage 6: ')

         // 6. set the state of userProfile afresh
         const querryUser = query(collection(db, 'UserProfiles'), where('User_id', '==', auth.currentUser.uid))
         const querySnapshot = await getDocs(querryUser)
         let userFetched = ''
         querySnapshot.forEach((docSnap) => {
            userFetched = { id: docSnap.id, ...docSnap.data() }
         })
         dispatch(setUserProfile(userFetched))
      } catch (error) {
         console.log('error attaching user', error)
      }
   }

   // for dialog not logged

   const [openNotLogged, setOpenNotLogged] = useState(false)

   const handleCloseNotLogged = () => {
      setOpenNotLogged(false)
   }

   // for dialog not logged

   const [openAttachToConsti, setOpenAttachToConsti] = useState(false)

   const handleCloseAttachToConsti = () => {
      setOpenAttachToConsti(false)
   }

   // Attach
   const handleClickToAttach = () => {
      if (!userProfile) {
         setOpenNotLogged(true)
      } else {
         setOpenAttachToConsti(true)
      }
   }

   // const [selectedStrata, setSelectedStrata] = useState(false)

   // const handleSelectStrata = (e) => {
   //    setSelectedStrata(e.target.value)
   // }

   return (
      <Stack
         sx={{
            minHeight: 300,
            maxHeight: 300,
            mb: 0.5,
            backgroundImage: 'linear-gradient(120deg, #ebedee 0%, #fdfbfb 100%)',
            overflowY: 'auto',
            scrollbarWidth: 'thin',
            borderRadius: 1,
            '&::-webkit-scrollbar': {
               width: '0.4em',
            },
            '&::-webkit-scrollbar-track': {
               background: '#f1f1f1',
            },
            '&::-webkit-scrollbar-thumb': {
               backgroundColor: '#888',
            },
            '&::-webkit-scrollbar-thumb:hover': {
               background: '#555',
            },
         }}
      >
         <Box xs={12} sm={12} md={12}>
            <Typography
               sx={{
                  fontSize: 15,
                  px: 1,
                  textAlign: 'left',
                  backgroundColor: '#233458',
                  fontWeight: 500,
                  color: '#ffffff',
                  mt: 0.5,
               }}
            >
               Parliamentary (संसदीय):
            </Typography>
            <Box
               sx={{
                  p: 0.5,
                  textAlign: 'left',
                  color: '#1b5e20',
               }}
            >
               {userProfile && userProfile.AtCentralConstituency && userProfile.AtCentralConstituency !== '' ? (
                  <>
                     <Typography
                        sx={{ color: '#7b1fa2', fontWeight: 500, lineHeight: 1.2, textAlign: 'left', fontSize: 15 }}
                     >
                        {userProfile.AtCentralConstituency.LegislatureName}
                        <Link
                           href={`/legislature/${userProfile.AtCentralConstituency.LegislatureName}/${userProfile.AtCentralConstituency.Legislature_id}`}
                           sx={{ textDecoration: 'none !important' }}
                           rel="noreferrer"
                           target="_blank"
                        >
                           <IconButton edge="end" sx={{ ml: 'auto', height: '25px', width: '25px' }}>
                              <ArrowCircleRightIcon sx={{ color: '#1976d2' }} />
                           </IconButton>
                        </Link>
                     </Typography>
                     <Typography
                        sx={{ color: '#1b5e20', fontWeight: 500, lineHeight: 1.2, textAlign: 'left', fontSize: 15 }}
                     >
                        {userProfile.AtCentralConstituency.ConstituencyNumber} -{' '}
                        {userProfile.AtCentralConstituency.ConstituencyName}{' '}
                        {userProfile.AtCentralConstituency.ConstituencyNameLocal &&
                           userProfile.AtCentralConstituency.ConstituencyNameLocal}{' '}
                        {!userProfile.AtCentralConstituency.ConstituencyNameLocal &&
                           userProfile.AtCentralConstituency.ConstituencyNameHindi}
                        {userProfile.AtCentralConstituency.StateName &&
                           `(${userProfile.AtCentralConstituency.StateName})`}
                        <Link
                           href={`/constituency/${userProfile.AtCentralConstituency.ConstituencyName}/${userProfile.AtCentralConstituency.Constituency_id}`}
                           sx={{ textDecoration: 'none !important' }}
                           rel="noreferrer"
                           target="_blank"
                        >
                           <IconButton edge="end" sx={{ ml: 'auto', height: '25px', width: '25px' }}>
                              <ArrowCircleRightIcon sx={{ color: '#1976d2' }} />
                           </IconButton>
                        </Link>
                     </Typography>
                  </>
               ) : (
                  <IconButton
                     sx={{
                        height: '25px',
                        width: '25px',
                        backgroundColor: '#03a9f4',
                        color: '#FFFFFF',
                        '&:hover': { backgroundColor: '#01579b' },
                        ml: 'auto',
                        mt: 0.25,
                     }}
                     onClick={handleClickToAttach}
                  >
                     <AttachFileIcon sx={{ height: '20px', width: '20px' }} />
                  </IconButton>
               )}
            </Box>
         </Box>
         <Box xs={12} sm={12} md={12}>
            <Typography
               sx={{
                  fontSize: 15,
                  px: 1,
                  textAlign: 'left',
                  backgroundColor: '#233458',
                  fontWeight: 500,
                  color: '#ffffff',
               }}
            >
               State Assembly (राज्य विधायिका):
            </Typography>
            <Typography
               sx={{
                  fontSize: 12,
                  fontWeight: 600,
                  p: 0.5,
                  textAlign: 'left',
                  color: '#1b5e20',
               }}
            >
               {userProfile && userProfile.AtStateConstituency && userProfile.AtStateConstituency !== '' ? (
                  <>
                     <Typography
                        sx={{ color: '#7b1fa2', fontWeight: 500, lineHeight: 1.2, textAlign: 'left', fontSize: 15 }}
                     >
                        {userProfile.AtStateConstituency.LegislatureName}
                        <Link
                           href={`/legislature/${userProfile.AtStateConstituency.LegislatureName}/${userProfile.AtStateConstituency.Legislature_id}`}
                           sx={{ textDecoration: 'none !important' }}
                           rel="noreferrer"
                           target="_blank"
                        >
                           <IconButton edge="end" sx={{ ml: 'auto', height: '25px', width: '25px' }}>
                              <ArrowCircleRightIcon sx={{ color: '#1976d2' }} />
                           </IconButton>
                        </Link>
                     </Typography>
                     <Typography
                        sx={{ color: '#1b5e20', fontWeight: 500, lineHeight: 1.2, textAlign: 'left', fontSize: 15 }}
                     >
                        {userProfile.AtStateConstituency.ConstituencyNumber} -{' '}
                        {userProfile.AtStateConstituency.ConstituencyName}{' '}
                        {userProfile.AtStateConstituency.ConstituencyNameLocal &&
                           userProfile.AtStateConstituency.ConstituencyNameLocal}{' '}
                        {!userProfile.AtStateConstituency.ConstituencyNameLocal &&
                           userProfile.AtStateConstituency.ConstituencyNameHindi}
                        <Link
                           href={`/constituency/${userProfile.AtStateConstituency.ConstituencyName}/${userProfile.AtStateConstituency.Constituency_id}`}
                           sx={{ textDecoration: 'none !important' }}
                           rel="noreferrer"
                           target="_blank"
                        >
                           <IconButton edge="end" sx={{ ml: 'auto', height: '25px', width: '25px' }}>
                              <ArrowCircleRightIcon sx={{ color: '#1976d2' }} />
                           </IconButton>
                        </Link>
                     </Typography>
                  </>
               ) : (
                  <IconButton
                     sx={{
                        height: '25px',
                        width: '25px',
                        backgroundColor: '#03a9f4',
                        color: '#FFFFFF',
                        '&:hover': { backgroundColor: '#01579b' },
                        ml: 'auto',
                        mt: 0.25,
                     }}
                     onClick={handleClickToAttach}
                  >
                     <AttachFileIcon sx={{ height: '20px', width: '20px' }} />
                  </IconButton>
               )}
            </Typography>
         </Box>
         <Box xs={12} sm={12} md={12}>
            <Typography
               sx={{
                  fontSize: 15,
                  px: 1,
                  textAlign: 'left',
                  backgroundColor: '#233458',
                  fontWeight: 500,
                  color: '#ffffff',
               }}
            >
               District Level (जिला स्तर):
            </Typography>
            <Typography
               sx={{
                  fontSize: 12,
                  fontWeight: 600,
                  p: 0.5,
                  textAlign: 'left',
                  color: '#1b5e20',
               }}
            >
               {userProfile && userProfile.AtDistrictConstituency && userProfile.AtDistrictConstituency !== '' ? (
                  `${userProfile.AtDistrictConstituency.ConstituencyNumber} - ${userProfile.AtDistrictConstituency.ConstituencyNameLocal} ${userProfile.AtDistrictConstituency.ConstituencyName} ${userProfile.AtDistrictConstituency.ConstituencyNameHindi}`
               ) : (
                  <IconButton
                     sx={{
                        height: '25px',
                        width: '25px',
                        backgroundColor: '#03a9f4',
                        color: '#FFFFFF',
                        '&:hover': { backgroundColor: '#01579b' },
                        ml: 'auto',
                        mt: 0.25,
                     }}
                     onClick={handleClickToAttach}
                  >
                     <AttachFileIcon sx={{ height: '20px', width: '20px' }} />
                  </IconButton>
               )}
            </Typography>
         </Box>
         <Box xs={12} sm={12} md={12}>
            <Typography
               sx={{
                  fontSize: 15,
                  px: 1,
                  textAlign: 'left',
                  backgroundColor: '#233458',
                  fontWeight: 500,
                  color: '#ffffff',
               }}
            >
               Sub-District Level (उप-जिला स्तर):
            </Typography>
            <Typography
               sx={{
                  fontSize: 12,
                  fontWeight: 600,
                  p: 0.5,
                  textAlign: 'left',
                  color: '#1b5e20',
               }}
            >
               {userProfile && userProfile.AtSbDistrictConstituency && userProfile.AtSubDistrictConstituency !== '' ? (
                  `${userProfile.AtSubDistrictConstituency.ConstituencyNumber} - ${userProfile.AtSubDistrictConstituency.ConstituencyNameLocal} ${userProfile.AtSubDistrictConstituency.ConstituencyName} ${userProfile.AtSubDistrictConstituency.ConstituencyNameHindi}`
               ) : (
                  <IconButton
                     sx={{
                        height: '25px',
                        width: '25px',
                        backgroundColor: '#03a9f4',
                        color: '#FFFFFF',
                        '&:hover': { backgroundColor: '#01579b' },
                        ml: 'auto',
                        mt: 0.25,
                     }}
                     onClick={handleClickToAttach}
                  >
                     <AttachFileIcon sx={{ height: '20px', width: '20px' }} />
                  </IconButton>
               )}
            </Typography>
         </Box>
         <Box xs={12} sm={12} md={12}>
            <Typography
               sx={{
                  fontSize: 15,
                  px: 1,
                  textAlign: 'left',
                  backgroundColor: '#233458',
                  fontWeight: 500,
                  color: '#ffffff',
               }}
            >
               Block Level (ब्लॉक स्तर):
            </Typography>
            <Typography
               sx={{
                  fontSize: 12,
                  fontWeight: 600,
                  p: 0.5,
                  textAlign: 'left',
                  color: '#1b5e20',
               }}
            >
               {userProfile && userProfile.AtBlockConstituency && userProfile.AtBlockConstituency !== '' ? (
                  `${userProfile.AtBlockConstituency.ConstituencyNumber} - ${userProfile.AtBlockConstituency.ConstituencyNameLocal} ${userProfile.AtBlockConstituency.ConstituencyName} ${userProfile.AtBlockConstituency.ConstituencyNameHindi}`
               ) : (
                  <IconButton
                     sx={{
                        height: '25px',
                        width: '25px',
                        backgroundColor: '#03a9f4',
                        color: '#FFFFFF',
                        '&:hover': { backgroundColor: '#01579b' },
                        ml: 'auto',
                        mt: 0.25,
                     }}
                     onClick={handleClickToAttach}
                  >
                     <AttachFileIcon sx={{ height: '20px', width: '20px' }} />
                  </IconButton>
               )}
            </Typography>
         </Box>
         <Box xs={12} sm={12} md={12}>
            <Typography
               sx={{
                  fontSize: 15,
                  px: 1,
                  textAlign: 'left',
                  backgroundColor: '#233458',
                  fontWeight: 500,
                  color: '#ffffff',
               }}
            >
               Base Level (मूल स्तर):
            </Typography>
            <Typography
               sx={{
                  fontSize: 12,
                  fontWeight: 600,
                  p: 0.5,
                  textAlign: 'left',
                  color: '#1b5e20',
               }}
            >
               {userProfile && userProfile.AtBaseConstituency && userProfile.AtBaseConstituency !== '' ? (
                  `${userProfile.AtBaseConstituency.LevelConstituencyNumber} - ${userProfile.AtBaseConstituency.LevelConstituencyNameLocal} ${userProfile.AtBaseConstituency.ConstituencyName} ${userProfile.AtBaseConstituency.LevelConstituencyNameHindi}`
               ) : (
                  <IconButton
                     sx={{
                        height: '25px',
                        width: '25px',
                        backgroundColor: '#03a9f4',
                        color: '#FFFFFF',
                        '&:hover': { backgroundColor: '#01579b' },
                        ml: 'auto',
                        mt: 0.25,
                     }}
                     onClick={handleClickToAttach}
                  >
                     <AttachFileIcon sx={{ height: '20px', width: '20px' }} />
                  </IconButton>
               )}
            </Typography>
         </Box>
         <Box id="boxModals">
            <Dialog
               open={openNotLogged}
               TransitionComponent={Transition}
               keepMounted
               onClose={handleCloseNotLogged}
               aria-describedby="alert-dialog-slide-notLogged"
            >
               <DialogContent>
                  <DialogContentText id="alert-dialog-slide-su" sx={{ whiteSpace: 'pre-line', color: '#b69c0f' }}>
                     You are not logged in. {'\n'}
                     आपने लॉग - इन नहीं किया है|
                  </DialogContentText>
               </DialogContent>
               <DialogActions>
                  <Button onClick={handleCloseNotLogged}>Close</Button>
               </DialogActions>
            </Dialog>
            <Dialog
               open={openAttachToConsti}
               TransitionComponent={Transition}
               keepMounted
               onClose={handleCloseAttachToConsti}
               aria-describedby="alert-dialog-slide-attachToConsti"
            >
               <DialogTitle id="dialog-title" sx={{ whiteSpace: 'pre-line' }}>
                  Attach yourself to constituencies{'\n'}अपने निर्वाचन क्षेत्रों से जुड़ें
                  <IconButton sx={{ float: 'right' }} onClick={handleCloseAttachToConsti}>
                     <CloseIcon />
                  </IconButton>
               </DialogTitle>
               <DialogContent>
                  <DialogContentText
                     id="dialog-content"
                     sx={{ whiteSpace: 'pre-line', color: '#1b5e20', lineHeight: 1.1, fontSize: 15 }}
                  >
                     <Alert variant="outlined" severity="warning" sx={{ lineHeight: 1.1, fontSize: 15 }}>
                        <Typography sx={{ lineHeight: 1.1, fontSize: 15 }}>
                           Your user_id will be attached to your selected constituency for next three years.
                        </Typography>
                        <Typography sx={{ lineHeight: 1.1, fontSize: 15, mt: 0.5 }}>
                           आपकी user_id अगले तीन वर्षों के लिए चयनित निर्वाचन क्षेत्र से जुड़ जाएगी।
                        </Typography>
                     </Alert>
                  </DialogContentText>
                  <Stack spacing={2} margin={2}>
                     {/* <FormControl
                        sx={{
                           px: 2,
                           py: 1,
                           mr: 2,
                           backgroundColor: '#D1D1D1',
                           borderRadius: 1,
                        }}
                     >
                        <FormLabel id="demo-controlled-radio-buttons-group">Select strata:</FormLabel>
                        <RadioGroup
                           row
                           aria-labelledby="demo-controlled-radio-buttons-group"
                           name="ThisConstituency"
                           value={selectedStrata}
                           // style={{ display: 'flex', gap: '2rem' }}
                           onChange={(e) => {
                              handleSelectStrata(e)
                           }}
                           sx={{
                              backgroundColor: '#ffffff',
                              borderRadius: 1,
                              border: 0.5,
                              py: 0,
                              px: 0.5,
                              '& .MuiSvgIcon-root': {
                                 fontSize: 15,
                              },
                              '& .MuiButtonBase-root': {
                                 py: 0,
                              },
                              '& MuiFormControlLabel-label': {
                                 fontSize: 13,
                                 lineHeight: 16,
                                 py: 0,
                              },
                           }}
                        >
                           <FormControlLabel
                              value="Central"
                              control={<Radio size="small" />}
                              label="Central"
                              sx={{ py: 0, fontSize: 15 }}
                           />
                           <FormControlLabel
                              value="State"
                              control={<Radio size="small" />}
                              label="State"
                              sx={{ py: 0, fontSize: 15 }}
                           />
                           <FormControlLabel
                              value="District"
                              control={<Radio size="small" />}
                              label="District"
                              sx={{ py: 0, fontSize: 15 }}
                           />
                           <FormControlLabel
                              value="Block"
                              control={<Radio size="small" />}
                              label="Block"
                              sx={{ py: 0, fontSize: 15 }}
                           />
                           <FormControlLabel
                              value="Vilage"
                              control={<Radio size="small" />}
                              label="Village"
                              sx={{ py: 0, fontSize: 15 }}
                           />
                        </RadioGroup>
                     </FormControl> */}
                     <Box sx={{ color: '#FFFFFF', backgroundColor: '#1b5e20', fontWeight: 600, pl: 2 }}>
                        Centra Level:
                     </Box>

                     {userProfile && userProfile.AtCentralConstituency && userProfile.AtCentralConstituency !== '' && (
                        <Box>
                           <Box sx={{ textDecoration: 'underline', mb: 0.5 }}>You are attached to:</Box>
                           <Box sx={{ color: '#7b1fa2', fontWeight: 600 }}>
                              {userProfile.AtCentralConstituency.LegislatureName}
                           </Box>
                           <Box sx={{ color: '#1b5e20' }}>
                              <strong>
                                 {userProfile.AtCentralConstituency.ConstituencyNumber} -{' '}
                                 {userProfile.AtCentralConstituency.ConstituencyName}{' '}
                                 {userProfile.AtCentralConstituency.ConstituencyNameLocal}{' '}
                                 {userProfile.AtCentralConstituency.ConstituencyNameHindi}
                              </strong>
                           </Box>
                        </Box>
                     )}
                     {(!userProfile ||
                        !userProfile.AtCentralConstituency ||
                        userProfile.AtCentralConstituency === '') && (
                        <Box>
                           <Box sx={{ color: '#7b1fa2', fontWeight: 600, mb: 1 }}>{legisCentral.Name}</Box>
                           <FormControl
                              sx={{
                                 ml: 1,
                                 width: '35ch',
                              }}
                              size="small"
                           >
                              <InputLabel>Select Constituency / निर्वाचन क्षेत्र चयन करें</InputLabel>
                              <Select
                                 value={selectedConstiIdForCentral}
                                 label="Select Constituency / निर्वाचन क्षेत्र चयन करें"
                                 onChange={(e) => {
                                    handleSelectedConstiForCentral(e)
                                 }}
                                 MenuProps={{
                                    sx: {
                                       '&& .Mui-selected': {
                                          color: '#FFFFFF',
                                          background: '#1565c0',
                                       },
                                    },
                                 }}
                              >
                                 <MenuItem value="">
                                    <em>None</em>
                                 </MenuItem>
                                 {constisCentral &&
                                    constisCentral.length > 0 &&
                                    constisCentral.map((item) => (
                                       <MenuItem
                                          key={item.Constituency_id}
                                          value={item.Constituency_id}
                                          sx={{ fontSize: 13 }}
                                       >
                                          <strong style={{ mr: 1 }}>
                                             {item.Name} ({item.ConstituencyNumber})
                                             {item.NameLocal !== ''
                                                ? ` ${item.NameLocal}`
                                                : item.NameHindi !== ''
                                                  ? ` ${item.NameHindi}`
                                                  : null}
                                          </strong>
                                          {` - ${item.StateName}`}
                                       </MenuItem>
                                    ))}
                              </Select>
                           </FormControl>
                           <br />
                           <Alert variant="outlined" severity="warning" sx={{ lineHeight: 1.1, fontSize: 15, my: 1 }}>
                              <Typography sx={{ lineHeight: 1.1, fontSize: 15 }}>
                                 Please attach yourself to <strong>your constituency strictly</strong>.
                              </Typography>
                              <Typography sx={{ lineHeight: 1.1, fontSize: 15, mt: 0.5 }}>
                                 कृपया <strong>अपने निर्वाचन क्षेत्र</strong> से ही जुड़ें।
                              </Typography>
                           </Alert>
                           <Button
                              type="submit"
                              align="center"
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={(e) => {
                                 handleAttachConstiCentral(e)
                              }}
                              disabled={selectedConstiIdForCentral === ''}
                           >
                              Attach
                           </Button>
                        </Box>
                     )}

                     <Box sx={{ color: '#FFFFFF', backgroundColor: '#1b5e20', fontWeight: 600, pl: 2 }}>
                        State Level:
                     </Box>

                     {legisState && <Box sx={{ color: '#7b1fa2', fontWeight: 600 }}>{legisState.Name}</Box>}

                     {userProfile && userProfile.AtStateConstituency && userProfile.AtStateConstituency !== '' && (
                        <Box>
                           <Box sx={{ textDecoration: 'underline', mb: 0.5 }}>You are attached to:</Box>
                           <Box sx={{ color: '#7b1fa2', fontWeight: 600 }}>
                              {userProfile.AtStateConstituency.LegislatureName}
                           </Box>
                           <Box sx={{ color: '#1b5e20' }}>
                              <strong>
                                 {userProfile.AtStateConstituency.ConstituencyNumber} -{' '}
                                 {userProfile.AtStateConstituency.ConstituencyName}{' '}
                                 {userProfile.AtStateConstituency.ConstituencyNameLocal}{' '}
                                 {userProfile.AtStateConstituency.ConstituencyNameHindi}
                              </strong>
                           </Box>
                        </Box>
                     )}
                     {(!userProfile || !userProfile.AtStateConstituency || userProfile.AtStateConstituency === '') && (
                        <Box sx={{ backgroundColor: '#D1D1D1', borderRadius: 1 }}>
                           <FormControl
                              sx={{
                                 ml: 1,
                              }}
                              size="small"
                           >
                              <InputLabel>Select State / राज्य चयन करें</InputLabel>
                              <Select
                                 value={selectedStateIdForState}
                                 label="State / राज्य"
                                 onChange={(e) => {
                                    handleChangeStateForState(e)
                                 }}
                                 MenuProps={{
                                    sx: {
                                       '&& .Mui-selected': {
                                          color: '#FFFFFF',
                                          background: '#1565c0',
                                       },
                                    },
                                 }}
                              >
                                 <MenuItem value="">
                                    <em>None</em>
                                 </MenuItem>
                                 {statesList &&
                                    statesList.length > 0 &&
                                    statesList.map((item) => (
                                       <MenuItem key={item.State_id} value={item.State_id} sx={{ fontSize: 13 }}>
                                          <strong style={{ mr: 1 }}>{item.Name}</strong>
                                       </MenuItem>
                                    ))}
                              </Select>
                           </FormControl>
                           {selectedStateIdForState && (
                              <FormControl
                                 sx={{
                                    ml: 1,
                                 }}
                                 size="small"
                              >
                                 <InputLabel>Select Constituency / निर्वाचन क्षेत्र चयन करें</InputLabel>
                                 <Select
                                    value={selectedConstiIdForState}
                                    label="Select Constituency / निर्वाचन क्षेत्र चयन करें"
                                    onChange={(e) => {
                                       handleSelectedConstiForState(e)
                                    }}
                                    MenuProps={{
                                       sx: {
                                          '&& .Mui-selected': {
                                             color: '#FFFFFF',
                                             background: '#1565c0',
                                          },
                                       },
                                    }}
                                 >
                                    <MenuItem value="">
                                       <em>None</em>
                                    </MenuItem>
                                    {constisState &&
                                       constisState.length > 0 &&
                                       constisState.map((item) => (
                                          <MenuItem
                                             key={item.Constituency_id}
                                             value={item.Constituency_id}
                                             sx={{ fontSize: 13 }}
                                          >
                                             <strong style={{ mr: 1 }}>
                                                {item.Name} ({item.ConstituencyNumber})
                                                {item.NameLocal !== ''
                                                   ? ` ${item.NameLocal}`
                                                   : item.NameHindi !== ''
                                                     ? ` ${item.NameHindi}`
                                                     : null}
                                             </strong>
                                             {/* {legis.Strata === 'Central' ? ` - ${item.StateName}` : null} */}
                                          </MenuItem>
                                       ))}
                                 </Select>
                              </FormControl>
                           )}
                           <Alert variant="outlined" severity="warning" sx={{ lineHeight: 1.1, fontSize: 15, my: 1 }}>
                              <Typography sx={{ lineHeight: 1.1, fontSize: 15 }}>
                                 Please attach yourself to <strong>your constituency strictly</strong>.
                              </Typography>
                              <Typography sx={{ lineHeight: 1.1, fontSize: 15, mt: 0.5 }}>
                                 कृपया <strong>अपने निर्वाचन क्षेत्र</strong> से ही जुड़ें।
                              </Typography>
                           </Alert>
                           <Button
                              type="submit"
                              align="center"
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={(e) => {
                                 handleAttachConstiState(e)
                              }}
                              disabled={selectedConstiIdForState === ''}
                           >
                              Attach
                           </Button>
                        </Box>
                     )}
                     <Box sx={{ color: '#FFFFFF', backgroundColor: '#1b5e20', fontWeight: 600, pl: 2 }}>
                        District Level:
                     </Box>
                     <Box>
                        <Alert variant="outlined" severity="warning">
                           To be available soon.
                           <br />
                           जल्द ही उपलब्ध होगा।
                        </Alert>
                     </Box>
                     <Box sx={{ color: '#FFFFFF', backgroundColor: '#1b5e20', fontWeight: 600, pl: 2 }}>
                        Sub-District Level:
                     </Box>
                     <Box>
                        <Alert variant="outlined" severity="warning">
                           To be available soon.
                           <br />
                           जल्द ही उपलब्ध होगा।
                        </Alert>
                     </Box>
                     <Box sx={{ color: '#FFFFFF', backgroundColor: '#1b5e20', fontWeight: 600, pl: 2 }}>
                        Block Level:
                     </Box>
                     <Box>
                        <Alert variant="outlined" severity="warning">
                           To be available soon.
                           <br />
                           जल्द ही उपलब्ध होगा।
                        </Alert>
                     </Box>
                     <Box sx={{ color: '#FFFFFF', backgroundColor: '#1b5e20', fontWeight: 600, pl: 2 }}>
                        Village Level:
                     </Box>
                     <Box>
                        <Alert variant="outlined" severity="warning">
                           To be available soon.
                           <br />
                           जल्द ही उपलब्ध होगा।
                        </Alert>
                     </Box>
                  </Stack>
               </DialogContent>
               <DialogActions>
                  <Button onClick={handleCloseAttachToConsti}>Close</Button>
               </DialogActions>
            </Dialog>
         </Box>
      </Stack>
   )
}

export default YourConstituencies
