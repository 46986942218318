import {
   Alert,
   AlertTitle,
   Box,
   Divider,
   FormControl,
   FormControlLabel,
   FormLabel,
   Grid,
   IconButton,
   InputLabel,
   List,
   MenuItem,
   Radio,
   RadioGroup,
   Select,
   TextField,
   Typography,
} from '@mui/material'

import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'

import CloudOffIcon from '@mui/icons-material/CloudOff'
import DeleteIcon from '@mui/icons-material/Delete'
import SearchIcon from '@mui/icons-material/Search'
import MyLoaderCircularGradient from '../../../Utils/Loaders/MyLoaderCircularGradient'

import { doc, getDoc } from 'firebase/firestore'
import { db } from '../../../FirebaseConfig'
import { useSelector } from 'react-redux'
import { selectCountries } from '../../../redux/reducers/countries-slice'
import ElecCandiCompSearch from '../../../Components/ElecCandiComp/ElecCandiCompSearch'
// import { isEmpty } from 'validator'

function ElecFilters({ legis, elec, candidatesListRoot }) {
   //
   const countries = useSelector(selectCountries).countriesList

   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')

   const [stateFull, setStateFull] = useState('')
   const [statesList, setStatesList] = useState([])
   const [selectedState, setSelectedState] = useState({ id: '', actionable: false })

   const [districtsList, setDistrictsList] = useState([])

   const [partiesList, setPartiesList] = useState('')
   const [selectedParty, setSelectedParty] = useState({ id: '', name: '', actionable: false })

   const [alliancesList, setAlliancesList] = useState('')
   const [selectedAlliance, setSelectedAlliance] = useState({ id: '', name: '', actionable: false })

   const [selectedGender, setSelectedGender] = useState({ name: '', actionable: false })

   const [candisListRootLocal, setCandisListRootLocal] = useState([])
   const [candidatesListCount, setCandidatesListCount] = useState('')
   const [candidatesListRejected, setCandidatesListRejected] = useState([])
   const [candidatesListRejectedCount, setCandidatesListRejectedCount] = useState('')
   const [candidatesListWithdrawn, setCandidatesListWithdrawn] = useState([])
   const [candidatesListWithdrawnCount, setCandidatesListWithdrawnCount] = useState('')
   const [candidatesListWaiting, setCandidatesListWaiting] = useState([])
   const [candidatesListWaitingCount, setCandidatesListWaitingCount] = useState('')
   const [candidatesListAccepted, setCandidatesListAccepted] = useState([])
   const [candidatesListAcceptedCount, setCandidatesListAcceptedCount] = useState('')
   const [uploading, setUploading] = useState(false)

   const [headingState, setHeadingState] = useState('')
   const [showHeadingState, setShowHeadingState] = useState(false)
   const [headingParty, setHeadingParty] = useState('')
   const [showHeadingParty, setShowHeadingParty] = useState(false)
   const [headingAlliance, setHeadingAlliance] = useState('')
   const [showHeadingAlliance, setShowHeadingAlliance] = useState(false)
   const [headingGender, setHeadingGender] = useState('')
   const [showHeadingGender, setShowHeadingGender] = useState(false)
   const [headingAge, setHeadingAge] = useState('')
   const [showHeadingAge, setShowHeadingAge] = useState(false)
   const [headingText, setHeadingText] = useState('')
   const [showHeadingText, setShowHeadingText] = useState(false)

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         //
         console.log('Elec filter - candidatesListRoot: ', candidatesListRoot)
         try {
            const listConsolidated = candidatesListRoot.filter(
               (item) => item && Object.keys(item).length > 0 && item.Name && item.Name.toLowerCase() !== 'zznota',
            )
            // the above filter is used to weed out entries with som undefined data

            const sortedCandidatesList = [...listConsolidated].sort((a, b) => {
               if (b.Name.trim() > a.Name.trim()) {
                  return -1
               } else if (b.Name.trim() < a.Name.trim()) {
                  return 1
               } else {
                  return 0
               }
            })
            setCandisListRootLocal(sortedCandidatesList)

            //
            switch (legis.Strata) {
               case 'Central':
                  // console.log('on load - case central')
                  fetchStatesList()
                  break
               case 'State':
                  // console.log('on load - case state')
                  setSelectedState({ id: legis.State_id, name: legis.StateName, actionable: false })
                  fetchStateFull(legis.State_id)
                  break
               case 'Division':
                  //
                  setSelectedState({ id: legis.State_id, name: legis.StateName, actionable: false })
                  break
               case 'District':
                  //
                  setSelectedState({ id: legis.State_id, name: legis.StateName, actionable: false })
                  break
               default:
            }

            // get the list of all parties that are associated with elected members
            const parties = []
            listConsolidated.forEach((candi) => {
               const partyObj = {
                  Party_id: candi.Party_id,
                  PartyName: candi.PartyName,
                  PartyNameHindi: candi.PartyNameHindi,
                  PartyNameLocal: candi.PartyNameLocal,
                  PartyAbbreviation: candi.PartyAbbreviation,
                  PartyFlag: candi.PartyFlag,
               }
               parties.push(partyObj)
            })
            // now create a list of unique items in parties list
            // in the following code, we can have multiple conditions
            const partiesUnique = parties.filter(
               (ele, ind) => ind === parties.findIndex((elem) => elem.Party_id === ele.Party_id),
            )
            const sortedParties = partiesUnique.sort((a, b) => {
               if (b.PartyName.trim() > a.PartyName.trim()) {
                  return -1
               } else if (b.PartyName.trim() < a.PartyName.trim()) {
                  return 1
               } else {
                  return 0
               }
            })

            setPartiesList(sortedParties)

            // get the list of all alliances that are associated with elected members
            const alliances = []
            listConsolidated.forEach((candi) => {
               alliances.push(...candi.Alliances)
            })

            // now create a list of unique items in alliances list
            // in the following code, we can have multiple conditions
            // console.log('alliances: ', alliances)
            const alliancesUnique = alliances.filter(
               (ele, ind) => ind === alliances.findIndex((elem) => elem.Alliance_id === ele.Alliance_id),
            )
            const sortedAlliances = alliancesUnique.sort((a, b) => {
               if (b.Name.trim() > a.Name.trim()) {
                  return -1
               } else if (b.Name.trim() < a.Name.trim()) {
                  return 1
               } else {
                  return 0
               }
            })

            setAlliancesList(sortedAlliances)
         } catch (error) {
            console.log(error)
         }

         setFirstLoadStatus('success')
      }
   }, [])

   useEffect(() => {
      // console.log('candisListRootLocal: ', candisListRootLocal)
   }, [candisListRootLocal])

   // for states

   const fetchStateFull = async (stateId) => {
      try {
         const docRef = doc(db, 'States', stateId)
         const docSnap = await getDoc(docRef)
         if (docSnap.exists()) {
            setStateFull({ ...docSnap.data(), id: docSnap.id })
         }
      } catch (err) {
         console.log('error: ', err)
      }
   }

   useEffect(() => {
      // console.log('reached the use effect of stateFull outside if condition')
      if (stateFull !== '') {
         fetchDistrictsListByState()
      }
   }, [stateFull])

   const fetchStatesList = async () => {
      try {
         const selectedCountryComplete = countries.find((item) => item.id === legis.Country_id)
         // console.log('selectedCountryComplete: ', selectedCountryComplete)

         const sortedList = [...selectedCountryComplete.States].sort((a, b) => {
            if (b.Name.trim() > a.Name.trim()) {
               return -1
            } else if (b.Name.trim() < a.Name.trim()) {
               return 1
            } else {
               return 0
            }
         })

         setStatesList(sortedList)
      } catch (err) {
         console.log('error fetching states list: ', err)
      }
   }

   function handleChangeState(e) {
      e.preventDefault()
      // setSelectedState({ id: e.target.value, actionable: true })
      if (e.target.value !== '') {
         const state = statesList.find((item) => item.State_id === e.target.value)
         setSelectedState({ id: state.State_id, name: state.Name, actionable: true })
      } else {
         setSelectedState({ id: '', name: '', actionable: true })
      }
   }

   const handleRemoveState = async (e) => {
      e.preventDefault()
      setSelectedState({ id: '', name: '', actionable: true })
   }

   useEffect(() => {
      if (firstLoadStatus !== 'idle') {
         try {
            if (selectedState.actionable === true) {
               setUploading(true)
               getResults()
            }
         } catch (error) {
            console.log(error)
         }
      }
   }, [selectedState])

   // related to Districts

   const fetchDistrictsListByState = async () => {
      try {
         const sortedList = [...stateFull.Districts].sort((a, b) => {
            if (b.Name.trim() > a.Name.trim()) {
               return -1
            } else if (b.Name.trim() < a.Name.trim()) {
               return 1
            } else {
               return 0
            }
         })

         setDistrictsList(sortedList)

         // console.log('sorted districts list fetched by state ', sortedList)
      } catch (err) {
         console.log('error fetching districts list: ', err)
      }
   }

   useEffect(() => {
      if (districtsList && districtsList.length > 0) {
         // console.log('districts list fetched by state ', districtsList)
      }
   }, [districtsList])

   // related to Party dropdown

   function handleChangeParty(e) {
      e.preventDefault()
      setSelectedAlliance({ id: '', name: '', actionable: false })
      if (e.target.value !== 'All') {
         if (e.target.value !== 'Independent') {
            const party = partiesList.find((item) => item.PartyName === e.target.value)
            setSelectedParty({ id: party.Party_id, name: party.PartyName, actionable: true })
         } else {
            setSelectedParty({ id: '', name: e.target.value, actionable: true })
         }
      } else {
         setSelectedParty({ id: '', name: '', actionable: true })
      }
   }

   const handleRemoveParty = async (e) => {
      e.preventDefault()
      setSelectedParty({ id: '', name: '', actionable: true })
   }

   useEffect(() => {
      if (firstLoadStatus !== 'idle') {
         try {
            if (selectedParty.actionable === true) {
               setUploading(true)
               getResults()
            }
         } catch (error) {
            console.log(error)
         }
      }
   }, [selectedParty])

   // related to Alliance dropdown

   function handleChangeAlliance(e) {
      e.preventDefault()

      setSelectedParty({ id: '', name: '', actionable: false })
      if (e.target.value !== '') {
         setUploading(true)
         const alliance = alliancesList.find((item) => item.Alliance_id === e.target.value)
         setSelectedAlliance({ id: e.target.value, name: alliance.Name, actionable: true })
      } else {
         setSelectedAlliance({ id: '', name: '', actionable: true })
      }

      // console.log('aliance stage 3')
   }

   const handleRemoveAlliance = async (e) => {
      e.preventDefault()
      setSelectedAlliance({ id: '', name: '', actionable: true })
   }

   useEffect(() => {
      if (firstLoadStatus !== 'idle') {
         try {
            if (selectedAlliance.actionable === true) {
               setUploading(true)
               getResults()
            }
         } catch (error) {
            console.log(error)
         }
      }
   }, [selectedAlliance])

   // related to Gender Radio List

   function handleGenderChange(e) {
      e.preventDefault()
      setSelectedGender({ name: e.target.value, actionable: true })
   }

   const handleRemoveGender = async (e) => {
      e.preventDefault()
      setSelectedGender({ name: '', actionable: true })
   }

   useEffect(() => {
      if (firstLoadStatus !== 'idle') {
         try {
            if (selectedGender.actionable === true) {
               setUploading(true)
               getResults()
            }
         } catch (error) {
            console.log(error)
         }
      }
   }, [selectedGender])

   // for search by name
   const [searchText, setSearchText] = useState('')
   const [searchTextCount, setSearchTextCount] = useState(0)

   const handleChangeSearchText = (e) => {
      setSearchTextCount(searchTextCount + 1)
      if (searchTextCount < 2) {
         setCandidatesListWaiting([])
         setCandidatesListAccepted([])
         setCandidatesListRejected([])
         setCandidatesListWithdrawn([])

         setCandidatesListCount('')
         setCandidatesListWaitingCount('')
         setCandidatesListAcceptedCount('')
         setCandidatesListRejectedCount('')
         setCandidatesListWithdrawnCount('')
      }
      setSearchText({ text: e.target.value, actionable: true })

      setSelectedState({ id: '', name: '', actionable: false })
      setSelectedParty({ id: '', name: '', actionable: false })
      setSelectedAlliance({ id: '', name: '', actionable: false })
      setSelectedGender({ name: '', actionable: false })
      setCandisListAge('')
      setMinAge('')
      setMaxAge('')
      setAgeIsValid(false)

      setShowHeadingState(false)
      setShowHeadingParty(false)
      setShowHeadingAlliance(false)
      setShowHeadingGender(false)
      setShowHeadingAge(false)
   }

   useEffect(() => {
      if (firstLoadStatus !== 'idle') {
         try {
            if (searchText.text !== '' && searchText.text.length > 1 && searchText.actionable === true) {
               // this condition is reached when a text is entered
               const newList = candisListRootLocal.filter((item) =>
                  item.Name.toLowerCase().includes(searchText.text.toLowerCase()),
               )

               const listAccepted = newList.filter((item) => item.Accepted === 'Accepted' && item.Withdrawn === '')
               const listRejected = newList.filter((item) => item.Rejected === 'Rejected' || item.Refused === 'Refused')
               const listWithdrawn = newList.filter((item) => item.Withdrawn === 'Withdrawn')
               const listWaiting = newList.filter(
                  (item) =>
                     item.Accepted !== 'Accepted' &&
                     item.Rejected !== 'Rejected' &&
                     item.Refused !== 'Refused' &&
                     item.Withdrawn !== 'Withdrawn',
               )

               setCandidatesListWaiting(listWaiting)
               setCandidatesListAccepted(listAccepted)
               setCandidatesListRejected(listRejected)
               setCandidatesListWithdrawn(listWithdrawn)

               setCandidatesListCount(newList.length)
               setCandidatesListWaitingCount(listWaiting.length)
               setCandidatesListAcceptedCount(listAccepted.length)
               setCandidatesListRejectedCount(listRejected.length)
               setCandidatesListWithdrawnCount(listWithdrawn.length)

               setHeadingText(searchText.text)
               setShowHeadingText(true)
            } else if ((searchText.text === '' || searchText.text.length < 2) && searchText.actionable === true) {
               // this condition is reached when the text is removed
               // here the lists need to be set according to memListFiltered

               // candisListFiltered was not altered above

               setCandidatesListWaiting([])
               setCandidatesListAccepted([])
               setCandidatesListRejected([])
               setCandidatesListWithdrawn([])

               setCandidatesListCount('')
               setCandidatesListWaitingCount('')
               setCandidatesListAcceptedCount('')
               setCandidatesListRejectedCount('')
               setCandidatesListWithdrawnCount('')

               setShowHeadingText(false)
            } else if (searchText.text === '' && searchText.actionable === false) {
               // this condition is reached when the text is altered by an outsider parameter selection
               // i.e. selectedHouseNumber or selectedState
               // what to do?
            }
         } catch (error) {
            console.log(error)
         }
      }
   }, [searchText])

   // age:

   const [minAge, setMinAge] = useState('')
   const [maxAge, setMaxAge] = useState('')
   const [candisListAge, setCandisListAge] = useState('')
   const [ageError, setAgeError] = useState('')
   const [ageIsValid, setAgeIsValid] = useState(false)

   const handleMinAge = (e) => {
      if (e.target.value !== '') {
         //
         const newValue = parseInt(e.target.value, 10)
         newValue && !isNaN(newValue) ? setMinAge(newValue) : setMinAge('')

         if (!isNaN(newValue) && maxAge !== '') {
            if (newValue > maxAge) {
               setAgeError('Minimum age cannot be greater than maximum age!')
               setAgeIsValid(false)
            } else if (newValue === maxAge) {
               setAgeError('Minimum age cannot be equal to maximum age!')
               setAgeIsValid(false)
            } else {
               setAgeError('')
               setAgeIsValid(true)
            }
         } else if (!isNaN(newValue) && maxAge === '') {
            setAgeError('')
            setAgeIsValid(true)
         }
      } else {
         setMinAge('')
         setAgeError('')
         if (maxAge !== '') {
            setAgeIsValid(true)
         } else {
            setAgeIsValid(false)
         }
      }
   }

   const handleMaxAge = (e) => {
      if (e.target.value !== '') {
         //
         const newValue = parseInt(e.target.value, 10)
         newValue && !isNaN(newValue) ? setMaxAge(newValue) : setMaxAge('')

         if (!isNaN(newValue) && minAge !== '') {
            if (newValue < minAge) {
               setAgeError('Maximum age cannot be less than minimum age!')
               setAgeIsValid(false)
            } else if (newValue === minAge) {
               setAgeError('Maximum age cannot be equal to minimum age!')
               setAgeIsValid(false)
            } else {
               setAgeError('')
               setAgeIsValid(true)
            }
         } else if (!isNaN(newValue) && minAge === '') {
            setAgeError('')
            setAgeIsValid(true)
         }
      } else {
         setMaxAge('')
         setAgeError('')
         if (minAge !== '') {
            setAgeIsValid(true)
         } else {
            setAgeIsValid(false)
         }
      }
   }

   const handleRemoveAge = async (e) => {
      e.preventDefault()
      setCandisListAge([])
      setAgeError('')
      setMinAge('')
      setMaxAge('')
      setAgeIsValid(false)
   }

   const handleSubmitAge = async (e) => {
      e.preventDefault()

      if (minAge === '' && maxAge === '') {
         // do nothing
         setAgeError('Please provide age.')
         setAgeIsValid(false)
         setShowHeadingAge(false)
      } else if (ageIsValid) {
         if (minAge !== '' && maxAge !== '') {
            // proceed with minAge and maxAge
            setUploading(true)
            const heading = minAge + ' - ' + maxAge
            setHeadingAge(heading)
            setShowHeadingAge(true)
            const list = candisListRootLocal.filter((item) => item.Age && item.Age >= minAge && item.Age <= maxAge)
            setCandisListAge(list)
            // getResults()
         } else if (minAge !== '' && maxAge === '') {
            // proceed with minAge
            setUploading(true)
            const heading = minAge + ' + '
            setHeadingAge(heading)
            setShowHeadingAge(true)
            const list = candisListRootLocal.filter((item) => item.Age && item.Age >= minAge)
            setCandisListAge(list)
            // getResults()
         } else if (minAge === '' && maxAge !== '') {
            // proceed with maxAge
            setUploading(true)
            const heading = ' < ' + maxAge
            setHeadingAge(heading)
            setShowHeadingAge(true)
            const list = candisListRootLocal.filter((item) => item.Age && item.Age <= maxAge)
            setCandisListAge(list)
         }
      } else {
         setShowHeadingAge(false)
      }
   }

   useEffect(() => {
      getResults()
   }, [candisListAge])

   // results

   function resetResults() {
      setCandidatesListWaiting([])
      setCandidatesListAccepted([])
      setCandidatesListRejected([])
      setCandidatesListWithdrawn([])

      setCandidatesListCount('')
      setCandidatesListWaitingCount('')
      setCandidatesListAcceptedCount('')
      setCandidatesListRejectedCount('')
      setCandidatesListWithdrawnCount('')

      setHeadingState('')
      setShowHeadingState(false)
      setHeadingParty('')
      setShowHeadingParty(false)
      setHeadingAlliance('')
      setShowHeadingAlliance(false)
      setHeadingGender('')
      setShowHeadingGender(false)
      setHeadingAge('')
      setShowHeadingAge(false)
   }

   const getResults = () => {
      setSearchText({ text: '', actionable: false })
      setHeadingText('')
      setShowHeadingText(false)

      if (
         selectedState.id === '' &&
         selectedParty.name === '' &&
         selectedAlliance.id === '' &&
         selectedGender.name === '' &&
         !ageIsValid
      ) {
         resetResults()
      } else {
         let newList = []
         if (selectedState.id !== '') {
            // SITUATION 1:
            // this will occurin case of central legis by choice and other legises as mandatory
            // Check the absence of all factors for search.
            // Then check every factor one by one.
            // tackling total of 5 factors: State_id, Party_id, Alliance_id, Gender, age
            if (selectedParty.name === '' && selectedAlliance.id === '' && selectedGender.name === '' && !ageIsValid) {
               // SITUATION 1.1: no selected parameters found
               // Factor-1. State_id
               // newList = candisListRootLocal.filter((item) => item.State_id === selectedState.id)
               resetResults()
            } else if (selectedParty.name !== '' && selectedAlliance.id === '') {
               // SITUATION 1.2: where presence of party and absence of alliance
               if (selectedGender.name !== '' && ageIsValid) {
                  // console.log('1.2 a')
                  // Factors: State_id, Party_id, Gender, age
                  newList = candisListAge.filter(
                     (item) =>
                        item.State_id === selectedState.id &&
                        item.PartyName === selectedParty.name &&
                        item.Gender === selectedGender.name,
                  )
               } else if (selectedGender.name !== '' && !ageIsValid) {
                  // console.log('1.2 b')
                  // Factors: State_id, Party_id, Gender
                  newList = candisListRootLocal.filter(
                     (item) =>
                        item.State_id === selectedState.id &&
                        item.PartyName === selectedParty.name &&
                        item.Gender === selectedGender.name,
                  )
               } else if (selectedGender.name === '' && ageIsValid) {
                  // console.log('1.2 c')
                  // console.log('1.2 c candisListAge: ', candisListAge)
                  // Factors: State_id, Party_id, age
                  newList = candisListAge.filter(
                     (item) => item.State_id === selectedState.id && item.PartyName === selectedParty.name,
                  )
               } else {
                  // console.log('1.2 d')
                  // Factors: State_id, Party_id
                  newList = candisListRootLocal.filter(
                     (item) => item.State_id === selectedState.id && item.PartyName === selectedParty.name,
                  )
               }
            } else if (selectedParty.name === '' && selectedAlliance.id !== '') {
               // SITUATION 1.3: where absence of party and presence of alliance
               if (selectedGender.name !== '' && ageIsValid) {
                  // console.log('1.3 a')
                  // Factors: State_id, Alliance_id, Gender, age
                  const list = candisListAge.filter(
                     (item) => item.State_id === selectedState.id && item.Gender === selectedGender.name,
                  )

                  list.forEach((item) => {
                     const listAlliances = []
                     item.Alliances.forEach((itemAlliances) => {
                        listAlliances.push(itemAlliances.Alliance_id)
                     })
                     if (listAlliances.includes(selectedAlliance.id)) {
                        newList.push(item)
                     }
                  })
               } else if (selectedGender.name !== '' && !ageIsValid) {
                  // console.log('1.3 b')
                  // Factors: State_id, Alliance_id, Gender
                  const list = candisListRootLocal.filter(
                     (item) => item.State_id === selectedState.id && item.Gender === selectedGender.name,
                  )

                  list.forEach((item) => {
                     const listAlliances = []
                     item.Alliances.forEach((itemAlliances) => {
                        listAlliances.push(itemAlliances.Alliance_id)
                     })
                     if (listAlliances.includes(selectedAlliance.id)) {
                        newList.push(item)
                     }
                  })
               } else if (selectedGender.name === '' && ageIsValid) {
                  // console.log('1.3 c')
                  // Factors: State_id, Alliance_id, age
                  const list = candisListAge.filter((item) => item.State_id === selectedState.id)

                  list.forEach((item) => {
                     const listAlliances = []
                     item.Alliances.forEach((itemAlliances) => {
                        listAlliances.push(itemAlliances.Alliance_id)
                     })
                     if (listAlliances.includes(selectedAlliance.id)) {
                        newList.push(item)
                     }
                  })
               } else {
                  // console.log('1.3 d')
                  // Factors: State_id, Alliance_id
                  const list = candisListRootLocal.filter((item) => item.State_id === selectedState.id)

                  list.forEach((item) => {
                     const listAlliances = []
                     item.Alliances.forEach((itemAlliances) => {
                        listAlliances.push(itemAlliances.Alliance_id)
                     })
                     if (listAlliances.includes(selectedAlliance.id)) {
                        newList.push(item)
                     }
                  })
               }
            } else if (selectedGender.name !== '') {
               // SITUATION 1.4: where presence of gender
               if (selectedParty.name !== '' && selectedAlliance.id === '' && ageIsValid) {
                  // console.log('1.4 a')
                  // Factors: State_id, Party_id, Gender, Age
                  // --this situation is already covered at 1.2
               } else if (selectedParty.name !== '' && selectedAlliance.id === '' && !ageIsValid) {
                  // console.log('1.4 b')
                  // Factors: State_id, Party_id, Gender
                  // --this situation is already covered at 1.2
               } else if (selectedParty.name === '' && selectedAlliance.id !== '' && ageIsValid) {
                  // console.log('1.4 c')
                  // Factors: State_id, Alliance_id, Gender, Age
                  // -- this situation is already covered under 1.3
               } else if (selectedParty.name === '' && selectedAlliance.id !== '' && !ageIsValid) {
                  // console.log('1.4 d')
                  // Factors: State_id, Alliance_id, Gender
                  // -- this situation is already covered under 1.3
               } else if (selectedParty.name === '' && selectedAlliance.id === '' && ageIsValid) {
                  // console.log('1.4 e')
                  // Factors: State_id, Gender, Age
                  newList = candisListAge.filter(
                     (item) => item.State_id === selectedState.id && item.Gender === selectedGender.name,
                  )
               } else if (selectedParty.name === '' && selectedAlliance.id === '' && !ageIsValid) {
                  // console.log('1.4 f')
                  // Factors: State_id, Gender
                  newList = candisListRootLocal.filter(
                     (item) => item.State_id === selectedState.id && item.Gender === selectedGender.name,
                  )
               }
            } else if (ageIsValid) {
               // SITUATION 1.5: where presence of age
               if (selectedParty.name !== '' && selectedAlliance.id === '' && selectedGender.name !== '') {
                  // console.log('1.5 a')
                  // Factors: State_id, Party_id, Gender, Age
                  // --this situation is already covered at 1.2
               } else if (selectedParty.name !== '' && selectedAlliance.id === '' && selectedGender.name === '') {
                  // console.log('1.5 b')
                  // Factors: State_id, Party_id, Age
                  // --this situation is already covered at 1.2
               } else if (selectedParty.name === '' && selectedAlliance.id !== '' && selectedGender.name !== '') {
                  // console.log('1.5 c')
                  // Factors: State_id, Alliance_id, Gender, Age
                  // -- this situation is already covered under 1.3
               } else if (selectedParty.name === '' && selectedAlliance.id !== '' && selectedGender.name === '') {
                  // console.log('1.5 d')
                  // Factors: State_id, Alliance_id, Age
                  // -- this situation is already covered under 1.3
               } else if (selectedParty.name === '' && selectedAlliance.id === '' && selectedGender.name !== '') {
                  // console.log('1.5 e')
                  // Factors: State_id, Gender, Age
                  // -- this situation is already covered under 1.4
               } else if (selectedParty.name === '' && selectedAlliance.id === '' && selectedGender.name === '') {
                  // console.log('1.5 f candisListRootLocal: ', candisListRootLocal, 'candisListAge: ', candisListAge)
                  // Factors: State_id, Age
                  newList = candisListAge.filter((item) => item.State_id === selectedState.id)
               }
            }
         } else {
            // SITUATION 2:
            // If legis = central, means No State_id is selected
            // compulsary situation in non-central
            // Check the absence of all factors for search.
            // Then check every factor one by one.
            if (selectedParty.id === '' && selectedAlliance.id === '' && selectedGender.name === '' && !ageIsValid) {
               // SITUATION 2.1: no selected parameters found
               // Factors: none.
               resetResults()
            } else if (selectedParty.name !== '' && selectedAlliance.id === '') {
               // SITUATION 2.2: where presence of party and absence of alliance
               if (selectedGender.name !== '' && ageIsValid) {
                  // console.log('2.2 a')
                  // Factors: Party_id, Gender, age
                  newList = candisListAge.filter(
                     (item) => item.PartyName === selectedParty.name && item.Gender === selectedGender.name,
                  )
               } else if (selectedGender.name !== '' && !ageIsValid) {
                  // console.log('2.2 b')
                  // Factors: Party_id, Gender
                  newList = candisListRootLocal.filter(
                     (item) => item.PartyName === selectedParty.name && item.Gender === selectedGender.name,
                  )
               } else if (selectedGender.name === '' && ageIsValid) {
                  // console.log('2.2 c')
                  // Factors: Party_id, age
                  newList = candisListAge.filter((item) => item.PartyName === selectedParty.name)
               } else {
                  // console.log('2.2 d')
                  // Factors: Party_id
                  newList = candisListRootLocal.filter((item) => item.PartyName === selectedParty.name)
               }
            } else if (selectedParty.name === '' && selectedAlliance.id !== '') {
               // SITUATION 2.3: where absence of party and presence of alliance
               if (selectedGender.name !== '' && ageIsValid) {
                  // console.log('2.3 a')
                  // Factors: Alliance_id, Gender, age
                  const list = candisListAge.filter((item) => item.Gender === selectedGender.name)

                  list.forEach((item) => {
                     const listAlliances = []
                     item.Alliances.forEach((itemAlliances) => {
                        listAlliances.push(itemAlliances.Alliance_id)
                     })
                     if (listAlliances.includes(selectedAlliance.id)) {
                        newList.push(item)
                     }
                  })
               } else if (selectedGender.name !== '' && !ageIsValid) {
                  // console.log('2.3 b')
                  // Factors: Alliance_id, Gender
                  const list = candisListRootLocal.filter((item) => item.Gender === selectedGender.name)

                  list.forEach((item) => {
                     const listAlliances = []
                     item.Alliances.forEach((itemAlliances) => {
                        listAlliances.push(itemAlliances.Alliance_id)
                     })
                     if (listAlliances.includes(selectedAlliance.id)) {
                        newList.push(item)
                     }
                  })
               } else if (selectedGender.name === '' && ageIsValid) {
                  // console.log('2.3 c')
                  // Factors: Alliance_id, age

                  candisListAge.forEach((item) => {
                     const listAlliances = []
                     item.Alliances.forEach((itemAlliances) => {
                        listAlliances.push(itemAlliances.Alliance_id)
                     })
                     if (listAlliances.includes(selectedAlliance.id)) {
                        newList.push(item)
                     }
                  })
               } else {
                  // console.log('2.3 d')
                  // Factors: Alliance_id

                  candisListRootLocal.forEach((item) => {
                     const listAlliances = []
                     item.Alliances.forEach((itemAlliances) => {
                        listAlliances.push(itemAlliances.Alliance_id)
                     })
                     if (listAlliances.includes(selectedAlliance.id)) {
                        newList.push(item)
                     }
                  })
               }
            } else if (selectedGender.name !== '') {
               /// SITUATION 2.4: where presence of gender
               if (selectedParty.name !== '' && selectedAlliance.id === '' && ageIsValid) {
                  // console.log('2.4 a')
                  // Factors: Party_id, Gender, Age
                  // --this situation is already covered at 2.2
               } else if (selectedParty.name !== '' && selectedAlliance.id === '' && !ageIsValid) {
                  // console.log('2.4 b')
                  // Factors: Party_id, Gender
                  // --this situation is already covered at 2.2
               } else if (selectedParty.name === '' && selectedAlliance.id !== '' && ageIsValid) {
                  // console.log('2.4 c')
                  // Factors: Alliance_id, Gender, Age
                  // -- this situation is already covered under 2.3
               } else if (selectedParty.name === '' && selectedAlliance.id !== '' && !ageIsValid) {
                  // console.log('2.4 d')
                  // Factors: Alliance_id, Gender
                  // -- this situation is already covered under 2.3
               } else if (selectedParty.name === '' && selectedAlliance.id === '' && ageIsValid) {
                  // console.log('2.4 e')
                  // Factors: Gender, Age
                  newList = candisListAge.filter((item) => item.Gender === selectedGender.name)
               } else if (selectedParty.name === '' && selectedAlliance.id === '' && !ageIsValid) {
                  // console.log('2.4 f')
                  // Factors: Gender
                  newList = candisListRootLocal.filter((item) => item.Gender === selectedGender.name)
               }
            } else if (ageIsValid) {
               // SITUATION 2.5: where presence of age
               if (selectedParty.name !== '' && selectedAlliance.id === '' && selectedGender.name !== '') {
                  // console.log('2.5 a')
                  // Factors: Party_id, Gender, Age
                  // --this situation is already covered at 2.2
               } else if (selectedParty.name !== '' && selectedAlliance.id === '' && selectedGender.name === '') {
                  // console.log('2.5 b')
                  // Factors: Party_id, Age
                  // --this situation is already covered at 2.2
               } else if (selectedParty.name === '' && selectedAlliance.id !== '' && selectedGender.name !== '') {
                  // console.log('2.5 c')
                  // Factors: Alliance_id, Gender, Age
                  // -- this situation is already covered under 2.3
               } else if (selectedParty.name === '' && selectedAlliance.id !== '' && selectedGender.name === '') {
                  // console.log('2.5 d')
                  // Factors: Alliance_id, Age
                  // -- this situation is already covered under 2.3
               } else if (selectedParty.name === '' && selectedAlliance.id === '' && selectedGender.name !== '') {
                  // console.log('2.5 e')
                  // Factors: Gender, Age
                  // -- this situation is already covered under 2.4
               } else if (selectedParty.name === '' && selectedAlliance.id === '' && selectedGender.name === '') {
                  // console.log('2.5 f')
                  // Factors: Age
                  newList = candisListAge
               }
            }
         }

         const listAccepted = newList.filter((item) => item.Accepted === 'Accepted' && item.Withdrawn === '')
         const listRejected = newList.filter((item) => item.Rejected === 'Rejected' || item.Refused === 'Refused')
         const listWithdrawn = newList.filter((item) => item.Withdrawn === 'Withdrawn')
         const listWaiting = newList.filter(
            (item) =>
               item.Accepted !== 'Accepted' &&
               item.Rejected !== 'Rejected' &&
               item.Refused !== 'Refused' &&
               item.Withdrawn !== 'Withdrawn',
         )

         setCandidatesListWaiting(listWaiting)
         setCandidatesListAccepted(listAccepted)
         setCandidatesListRejected(listRejected)
         setCandidatesListWithdrawn(listWithdrawn)

         setCandidatesListCount(newList.length)
         setCandidatesListWaitingCount(listWaiting.length)
         setCandidatesListAcceptedCount(listAccepted.length)
         setCandidatesListRejectedCount(listRejected.length)
         setCandidatesListWithdrawnCount(listWithdrawn.length)

         if (selectedState.id !== '') {
            setHeadingState(selectedState.name)
            setShowHeadingState(true)
         } else {
            setHeadingState('')
            setShowHeadingState(false)
         }

         if (selectedParty.name !== '') {
            setHeadingParty(selectedParty.name)
            setShowHeadingParty(true)
         } else {
            setHeadingParty('')
            setShowHeadingParty(false)
         }

         if (selectedAlliance.id !== '') {
            setHeadingAlliance(selectedAlliance.name)
            setShowHeadingAlliance(true)
         } else {
            setHeadingAlliance('')
            setShowHeadingAlliance(false)
         }

         if (selectedGender.name !== '') {
            setHeadingGender(selectedGender.name)
            setShowHeadingGender(true)
         } else {
            setHeadingGender('')
            setShowHeadingGender(false)
         }

         if (ageIsValid) {
            //
         } else {
            setHeadingAge('')
            setShowHeadingAge(false)
         }
      }

      setUploading(false)
   }

   const focusRefWaiting = useRef(null)
   const focusRefAccepted = useRef(null)
   const focusRefWithdrawn = useRef(null)
   const focusRefRejected = useRef(null)

   const handleWaitingCount = (e) => {
      e.preventDefault()

      focusRefWaiting.current?.scrollIntoView({ behavior: 'smooth' })
      // focusRef.current?.scrollIntoView({ block: 'start', inline: 'nearest' })
   }

   const handleAcceptedCount = (e) => {
      e.preventDefault()

      focusRefAccepted.current?.scrollIntoView({ behavior: 'smooth' })
      // focusRef.current?.scrollIntoView({ block: 'start', inline: 'nearest' })
   }

   const handleWithdrawnCount = (e) => {
      e.preventDefault()

      focusRefWithdrawn.current?.scrollIntoView({ behavior: 'smooth' })
      // focusRef.current?.scrollIntoView({ block: 'start', inline: 'nearest' })
   }

   const handleRejectedCount = (e) => {
      e.preventDefault()

      focusRefRejected.current?.scrollIntoView({ behavior: 'smooth' })
      // focusRef.current?.scrollIntoView({ block: 'start', inline: 'nearest' })
   }

   return (
      <Box>
         <div>
            <Helmet>
               <title>{`Track Democracy - Filtered results of ${elec.Name}`}</title>
            </Helmet>
         </div>
         {!navigator.onLine && (
            <div>
               <Alert
                  variant="outlined"
                  severity="error"
                  sx={{ margin: 5, justifyContent: 'center', alignItems: 'center' }}
               >
                  <AlertTitle>
                     You are offline !
                     <CloudOffIcon sx={{ ml: 5 }} />
                  </AlertTitle>
               </Alert>
            </div>
         )}
         <Box
            sx={{
               p: 1,
               mt: 0.5,
               backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
               color: '#ffffff',
            }}
         >
            <Box>Filtered data:</Box>
            <Box>निस्यंदित आंकड़े:</Box>
         </Box>
         <Grid id="home_grid_container_Filter" container spacing={0} sx={{ my: 0.25 }} disableEqualOverflow>
            {legis.Strata === 'Central' && (
               <Grid
                  item
                  id="home_grid_Container_Fiter_state"
                  xs={6}
                  sm={6}
                  md={4}
                  order={{ xs: 1, sm: 1, md: 1 }}
                  sx={{
                     backgroundColor: '#F1F1F1',
                     py: 1,
                     px: 1.5,
                     mt: 0.5,
                     display: 'flex',
                     flexDirection: 'row',
                     border: 2,
                     borderColor: '#ffffff',
                  }}
               >
                  <FormControl sx={{ width: '100%', backgroundColor: '#F1F1F1' }} size="small">
                     <InputLabel>State / राज्य</InputLabel>
                     <Select
                        value={selectedState.id}
                        label="State / राज्य"
                        onChange={handleChangeState}
                        MenuProps={{
                           sx: {
                              '&& .Mui-selected': {
                                 color: '#FFFFFF',
                                 background: '#1565c0',
                              },
                           },
                        }}
                        sx={{ backgroundColor: '#ffffff' }}
                     >
                        <MenuItem value="">
                           <em>All</em>
                        </MenuItem>
                        {statesList &&
                           statesList.map((item) => (
                              <MenuItem key={item.State_id} value={item.State_id}>
                                 {item.Name}
                              </MenuItem>
                           ))}
                     </Select>
                  </FormControl>
                  <IconButton
                     edge="end"
                     aria-label="delete"
                     onClick={(e) => {
                        handleRemoveState(e)
                     }}
                     sx={{ height: '25px', width: '25px' }}
                     disabled={selectedState.id === ''}
                  >
                     <DeleteIcon fontSize="small" />
                  </IconButton>
               </Grid>
            )}
            <Grid
               item
               id="home_grid_Container_Filter_party"
               xs={6}
               sm={6}
               md={4}
               order={{ xs: 2, sm: 2, md: 2 }}
               sx={{
                  backgroundColor: '#F1F1F1',
                  py: 1,
                  px: 1.5,
                  mt: 0.5,
                  display: 'flex',
                  flexDirection: 'row',
                  border: 2,
                  borderColor: '#ffffff',
               }}
            >
               <FormControl sx={{ width: '100%', backgroundColor: '#F1F1F1' }} size="small">
                  <InputLabel>Party / दल</InputLabel>
                  <Select
                     value={selectedParty.name}
                     label="Party / दल"
                     onChange={handleChangeParty}
                     MenuProps={{
                        sx: {
                           '&& .Mui-selected': {
                              color: '#FFFFFF',
                              background: '#1565c0',
                           },
                        },
                     }}
                     sx={{ backgroundColor: '#ffffff' }}
                  >
                     <MenuItem key="All" value="All">
                        All
                     </MenuItem>
                     {partiesList &&
                        partiesList.map((item) => (
                           <MenuItem key={item.Party_id} value={item.PartyName}>
                              {item.PartyName} -{' '}
                              <strong> {item.PartyAbbreviation && `(${item.PartyAbbreviation})`} </strong>
                           </MenuItem>
                        ))}
                  </Select>
               </FormControl>
               <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={(e) => {
                     handleRemoveParty(e)
                  }}
                  sx={{ height: '25px', width: '25px' }}
                  disabled={selectedParty.name === ''}
               >
                  <DeleteIcon fontSize="small" />
               </IconButton>
            </Grid>
            <Grid
               item
               id="home_grid_Container_filter_alliance"
               xs={6}
               sm={6}
               md={4}
               order={{ xs: 3, sm: 3, md: 3 }}
               sx={{
                  backgroundColor: '#F1F1F1',
                  py: 1,
                  px: 1.5,
                  mt: 0.5,
                  display: 'flex',
                  flexDirection: 'row',
                  border: 2,
                  borderColor: '#ffffff',
               }}
            >
               <FormControl sx={{ width: '100%', backgroundColor: '#F1F1F1' }} size="small">
                  <InputLabel>Alliance / गठबंधन</InputLabel>
                  <Select
                     value={selectedAlliance.id}
                     label="Alliance / गठबंधन"
                     onChange={handleChangeAlliance}
                     MenuProps={{
                        sx: {
                           '&& .Mui-selected': {
                              color: '#FFFFFF',
                              background: '#1565c0',
                           },
                        },
                     }}
                     sx={{ backgroundColor: '#ffffff' }}
                  >
                     <MenuItem value="">
                        <em>None</em>
                     </MenuItem>
                     {alliancesList &&
                        alliancesList.map((item) => (
                           <MenuItem key={item.Alliance_id} value={item.Alliance_id}>
                              {item.Name} - <strong> {item.Abbreviation && `(${item.Abbreviation})`} </strong>
                           </MenuItem>
                        ))}
                  </Select>
               </FormControl>
               <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={(e) => {
                     handleRemoveAlliance(e)
                  }}
                  sx={{ height: '25px', width: '25px' }}
                  disabled={selectedAlliance.name === ''}
               >
                  <DeleteIcon fontSize="small" />
               </IconButton>
            </Grid>
            <Grid
               item
               id="home_grid_Container_filter_searchText"
               xs={6}
               sm={6}
               md={4}
               order={{ xs: 6, sm: 6, md: 6 }}
               sx={{
                  backgroundColor: '#F1F1F1',
                  py: 1,
                  px: 1.5,
                  mt: 0.5,
                  display: 'flex',
                  flexDirection: 'row',
                  border: 2,
                  borderColor: '#ffffff',
                  alignItems: 'center',
                  justifyContent: 'center',
               }}
            >
               <TextField
                  id="SearchName"
                  label="Search Name"
                  value={searchText.text}
                  onChange={(e) => {
                     handleChangeSearchText(e)
                  }}
                  onBlur={(event) => event}
                  size="small"
                  sx={{ backgroundColor: '#FFFFFF', width: '100%' }}
               />
            </Grid>
            <Grid
               item
               id="home_grid_Container_filter_gender"
               xs={6}
               sm={6}
               md={4}
               order={{ xs: 6, sm: 6, md: 6 }}
               sx={{
                  backgroundColor: '#F1F1F1',
                  py: 1,
                  px: 1.5,
                  mt: 0.5,
                  display: 'flex',
                  flexDirection: 'row',
                  border: 2,
                  borderColor: '#ffffff',
               }}
            >
               <FormControl
                  size="small"
                  sx={{
                     py: 0,
                     px: 1,
                     border: 1,
                     borderRadius: 1,
                     backgroundColor: '#ffffff',
                  }}
               >
                  <FormLabel id="demo-controlled-radio-buttons-group">Gender / लिंग:</FormLabel>
                  <RadioGroup
                     row
                     aria-labelledby="demo-controlled-radio-buttons-group"
                     name="Gender"
                     value={selectedGender.name}
                     // style={{ display: 'flex', gap: '2rem' }}
                     onChange={(e) => {
                        handleGenderChange(e)
                     }}
                     sx={{
                        borderRadius: 1,
                        py: 0,
                        '& .MuiSvgIcon-root': {
                           fontSize: 15,
                        },
                        '& .MuiButtonBase-root': {
                           py: 0,
                        },
                        '& MuiFormControlLabel-label': {
                           fontSize: 10,
                           lineHeight: 16,
                           py: 0,
                        },
                     }}
                  >
                     {/* <FormControlLabel
                        value="Male"
                        control={<Radio size="small" />}
                        label={
                           <Typography variant="caption" sx={{ textDecoration: 'line-through' }} disabled>
                              Male / पुरुष
                           </Typography>
                        }
                        disabled
                     /> */}
                     <FormControlLabel
                        value=""
                        control={<Radio size="small" />}
                        label={<Typography variant="body1">All Genders / सभी लिंग</Typography>}
                        sx={{ py: 0, fontSize: 10 }}
                     />
                     <FormControlLabel
                        value="Female"
                        control={<Radio size="small" />}
                        label={<Typography variant="body1">Female / स्त्री</Typography>}
                     />
                     <FormControlLabel
                        value="Transgender"
                        control={<Radio size="small" />}
                        label={<Typography variant="body1">Third Gender / तृतीय लिंग</Typography>}
                        sx={{ py: 0, fontSize: 10 }}
                     />
                  </RadioGroup>
               </FormControl>
               <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={(e) => {
                     handleRemoveGender(e)
                  }}
                  sx={{ height: '25px', width: '25px' }}
                  disabled={selectedGender.name === ''}
               >
                  <DeleteIcon fontSize="small" />
               </IconButton>
            </Grid>
            <Grid
               item
               id="home_grid_Container_filter_age"
               xs={6}
               sm={6}
               md={4}
               order={{ xs: 6, sm: 6, md: 6 }}
               sx={{
                  backgroundColor: '#F1F1F1',
                  py: 1,
                  px: 1.5,
                  mt: 0.5,
                  display: 'flex',
                  flexDirection: 'row',
                  border: 2,
                  borderColor: '#ffffff',
               }}
            >
               <Box
                  sx={{
                     py: 0.5,
                     px: 1,
                     border: 1,
                     borderRadius: 1,
                     backgroundColor: '#ffffff',
                     width: '100%',
                  }}
               >
                  <Box
                     sx={{
                        display: 'flex',
                        flexdirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                     }}
                  >
                     <Box>Age / आयु:</Box>
                     <IconButton
                        edge="end"
                        aria-label="age"
                        onClick={(e) => {
                           handleSubmitAge(e)
                        }}
                        sx={{
                           height: '35px',
                           width: '35px',
                           backgroundColor: '#03a9f4',
                           color: '#FFFFFF',
                           '&:hover': { backgroundColor: '#01579b' },
                        }}
                        disabled={!ageIsValid}
                     >
                        <SearchIcon fontSize="small" />
                     </IconButton>
                  </Box>
                  <Box
                     sx={{
                        display: 'flex',
                        flexdirection: 'row',
                        mt: 0.25,
                     }}
                  >
                     <TextField
                        id="MinAge"
                        label="Min / न्यूनतम"
                        onBlur={(event) => event}
                        onChange={(e) => {
                           handleMinAge(e)
                        }}
                        value={minAge}
                        size="small"
                        sx={{ backgroundColor: '#FFFFFF', mr: 0.5 }}
                     />
                     <TextField
                        id="MaxAge"
                        label="Max / अधिकतम"
                        value={maxAge}
                        onChange={(e) => {
                           handleMaxAge(e)
                        }}
                        onBlur={(event) => event}
                        size="small"
                        sx={{ backgroundColor: '#FFFFFF' }}
                     />
                  </Box>
                  <Box sx={{ color: 'Crimson' }}>{ageError}</Box>
               </Box>
               <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={(e) => {
                     handleRemoveAge(e)
                  }}
                  sx={{ height: '25px', width: '25px' }}
                  disabled={!ageIsValid}
               >
                  <DeleteIcon fontSize="small" />
               </IconButton>
            </Grid>
         </Grid>
         <Box
            sx={{
               backgroundColor: '#d3d3d3',
               py: 0.5,
               whiteSpace: 'pre-wrap',
               lineHeight: 1.2,
               fontFamily: 'Poppins',
               display: 'flex',
               flexDirection: 'row',
            }}
         >
            <Box sx={{ backgroundColor: '#000000', color: '#CFFF04', borderRadius: 1 }}>{` >> `}</Box>
            {showHeadingState && (
               <>
                  <Box
                     sx={{ backgroundColor: '#000000', color: '#CFFF04', borderRadius: 1, mx: 1, p: 0.5 }}
                  >{`State / UT: `}</Box>
                  {headingState}
               </>
            )}
            {showHeadingParty && (
               <>
                  <Box
                     sx={{ backgroundColor: '#000000', color: '#CFFF04', borderRadius: 1, mx: 1, p: 0.5 }}
                  >{`Party: `}</Box>
                  {headingParty}
               </>
            )}
            {showHeadingAlliance && (
               <>
                  <Box
                     sx={{ backgroundColor: '#000000', color: '#CFFF04', borderRadius: 1, mx: 1, p: 0.5 }}
                  >{`Alliance: `}</Box>
                  {headingAlliance}
               </>
            )}
            {showHeadingText && (
               <>
                  <Box
                     sx={{ backgroundColor: '#000000', color: '#CFFF04', borderRadius: 1, mx: 1, p: 0.5 }}
                  >{`Text: `}</Box>
                  {headingText}
               </>
            )}
            {showHeadingGender && (
               <>
                  <Box
                     sx={{ backgroundColor: '#000000', color: '#CFFF04', borderRadius: 1, mx: 1, p: 0.5 }}
                  >{`Gender: `}</Box>
                  {headingGender}
               </>
            )}
            {showHeadingAge && (
               <>
                  <Box
                     sx={{ backgroundColor: '#000000', color: '#CFFF04', borderRadius: 1, mx: 1, p: 0.5 }}
                  >{`Age: `}</Box>
                  {headingAge}
               </>
            )}
         </Box>
         <Box
            sx={{
               backgroundColor: '#d3d3d3',
               px: 1,
               py: 0.5,
               whiteSpace: 'pre-wrap',
               lineHeight: 1.2,
               fontFamily: 'Poppins',
               display: 'flex',
               flexDirection: 'row',
               justifyContent: 'space-around',
            }}
         >
            Total: <Box sx={{ backgroundColor: '#FFFFFF', borderRadius: 1, px: 1, mr: 1 }}>{candidatesListCount}</Box>
            Waiting:{' '}
            <Box
               sx={{ backgroundColor: '#FFFFFF', borderRadius: 1, px: 1, mr: 1, color: '#ba68c8', cursor: 'pointer' }}
               onClick={(e) => {
                  handleWaitingCount(e)
               }}
            >
               {candidatesListWaitingCount}
            </Box>
            Contesting:{' '}
            <Box
               sx={{ backgroundColor: '#FFFFFF', borderRadius: 1, px: 1, mr: 1, color: '#4caf50', cursor: 'pointer' }}
               onClick={(e) => {
                  handleAcceptedCount(e)
               }}
            >
               {candidatesListAcceptedCount}
            </Box>
            Withdrawn:{' '}
            <Box
               sx={{ backgroundColor: '#FFFFFF', borderRadius: 1, px: 1, mr: 1, color: '#ff9800', cursor: 'pointer' }}
               onClick={(e) => {
                  handleWithdrawnCount(e)
               }}
            >
               {candidatesListWithdrawnCount}
            </Box>
            Rejected:{' '}
            <Box
               sx={{ backgroundColor: '#FFFFFF', borderRadius: 1, px: 1, mr: 1, color: '#c62828', cursor: 'pointer' }}
               onClick={(e) => {
                  handleRejectedCount(e)
               }}
            >
               {candidatesListRejectedCount}
            </Box>
         </Box>
         {uploading && (
            <Box>
               <MyLoaderCircularGradient title={'Fetching results...'}></MyLoaderCircularGradient>
            </Box>
         )}
         {navigator.onLine && (
            <Box>
               <Box
                  sx={{
                     mt: 2,
                     p: 1,
                     color: '#ffffff',
                     fontWeight: 700,
                     backgroundColor: '#03a9f4',
                  }}
                  ref={focusRefWaiting}
               >
                  Waiting:
               </Box>
               <Box>
                  <List dense>
                     {candidatesListWaiting &&
                        candidatesListWaiting.length > 0 &&
                        candidatesListWaiting.map((item, index) => (
                           <div key={item.Candidate_id}>
                              <ElecCandiCompSearch item={item} index={index}></ElecCandiCompSearch>

                              <Divider />
                           </div>
                        ))}
                  </List>
               </Box>
               <Box
                  sx={{
                     p: 1,
                     color: '#ffffff',
                     fontWeight: 700,
                     backgroundColor: '#4caf50',
                  }}
                  ref={focusRefAccepted}
               >
                  Accepted:
               </Box>
               <Box>
                  <List dense>
                     {candidatesListAccepted &&
                        candidatesListAccepted.length > 0 &&
                        candidatesListAccepted.map((item, index) => (
                           <div key={item.Candidate_id}>
                              <ElecCandiCompSearch item={item} index={index}></ElecCandiCompSearch>

                              <Divider />
                           </div>
                        ))}
                  </List>
               </Box>
               <Box
                  sx={{
                     p: 1,
                     mt: 0.5,
                     color: '#ffffff',
                     fontWeight: 700,
                     backgroundColor: '#ff9800',
                  }}
                  ref={focusRefWithdrawn}
               >
                  Withdrawn:
               </Box>
               <Box>
                  <List dense>
                     {candidatesListWithdrawn &&
                        candidatesListWithdrawn.length > 0 &&
                        candidatesListWithdrawn.map((item, index) => (
                           <div key={item.Candidate_id}>
                              <ElecCandiCompSearch item={item} index={index}></ElecCandiCompSearch>

                              <Divider />
                           </div>
                        ))}
                  </List>
               </Box>
               <Box
                  sx={{
                     p: 1,
                     mt: 0.5,
                     backgroundColor: '#ef5350',
                     color: '#ffffff',
                     fontWeight: 700,
                  }}
                  ref={focusRefRejected}
               >
                  Rejected:
               </Box>
               <Box>
                  <List dense>
                     {candidatesListRejected &&
                        candidatesListRejected.length > 0 &&
                        candidatesListRejected.map((item, index) => (
                           <div key={item.Candidate_id}>
                              <ElecCandiCompSearch item={item} index={index}></ElecCandiCompSearch>

                              <Divider />
                           </div>
                        ))}
                  </List>
               </Box>
            </Box>
         )}
      </Box>
   )
}

ElecFilters.propTypes = {
   elec: PropTypes.object.isRequired,
   legis: PropTypes.object.isRequired,
   candidatesListRoot: PropTypes.array.isRequired,
}

export default ElecFilters
