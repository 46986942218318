import React, { useEffect, useState } from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import CropSquareIcon from '@mui/icons-material/CropSquare'
import MenuBookIcon from '@mui/icons-material/MenuBook'

import myStyles from './GoDownStageWise.module.css'
import { Box, Stack } from '@mui/material'

const GoDownStageWise = () => {
   //
   const style = {
      height: 20,
      width: 50,
      color: '#FFFFFF',
      borderRadius: 0.5,
      display: 'flex',
      flexDirection: 'row',
      fontSize: 'small',
      alignItems: 'center',
      justifyContent: 'space-around',
      px: 0.5,
   }

   const [stage, setStage] = useState(0)
   const [showThisBtn, setShowThisBtn] = useState(false)

   useEffect(() => {
      //   if (document.body.scrollHeight > 1100) {
      //      setShowThisBtn(true)
      //   } else {
      //      setShowThisBtn(false)
      //   }
      window.addEventListener('scroll', () => {
         if (window.scrollY > 400) {
            setShowThisBtn(true)
         } else {
            setShowThisBtn(false)
         }

         if (window.scrollY < document.body.scrollHeight / 5) {
            setStage(0)
         } else if (window.scrollY < (document.body.scrollHeight / 5) * 2) {
            setStage(20)
         } else if (window.scrollY < (document.body.scrollHeight / 5) * 3) {
            setStage(40)
         } else if (window.scrollY < (document.body.scrollHeight / 5) * 4) {
            setStage(60)
         } else if (window.scrollY < (document.body.scrollHeight / 5) * 5) {
            setStage(80)
         }
      })
   }, [])

   const goTo00 = () => {
      //   setStage(0)
      window.scrollTo({
         top: 0,
         left: 0,
         behavior: 'smooth',
      })
   }

   const goTo20 = () => {
      //   setStage(20)
      window.scrollTo({
         top: document.body.scrollHeight / 5 + 1,
         left: 0,
         behavior: 'smooth',
      })
   }

   const goTo40 = () => {
      //   setStage(40)
      window.scrollTo({
         top: (document.body.scrollHeight / 5) * 2 + 1,
         left: 0,
         behavior: 'smooth',
      })
   }

   const goTo60 = () => {
      //   setStage(60)
      window.scrollTo({
         top: (document.body.scrollHeight / 5) * 3 + 1,
         left: 0,
         behavior: 'smooth',
      })
   }

   const goTo80 = () => {
      //   setStage(80)
      window.scrollTo({
         top: (document.body.scrollHeight / 5) * 4 + 1,
         left: 0,
         behavior: 'smooth',
      })
   }

   //    const goTo100 = () => {
   //       //   setStage(100)
   //       window.scrollTo({
   //          top: (document.body.scrollHeight / 5) * 5,
   //          left: 0,
   //          behavior: 'smooth',
   //       })
   //    }

   return (
      <div className={myStyles.top_to_btm}>
         {' '}
         {showThisBtn && (
            <Stack spacing={0.25} className={`${myStyles.icon_position} ${myStyles.icon_style}`} sx={{ p: 0.5 }}>
               <Box
                  sx={{
                     color: '#FFFFFF',
                     fontSize: 'small',
                     width: 48,
                     height: 20,
                     alignItems: 'center',
                     justifyContent: 'space-around',
                     display: 'flex',
                     borderRadius: 0.5,
                     px: 0.5,
                     // backgroundColor: 'rgba( 255, 255, 255, 0.25 ) !important',
                  }}
                  className={myStyles.button_style}
               >
                  {/* Page Parts */}
                  <MenuBookIcon sx={{ width: 20 }} />
               </Box>
               <Box sx={{ ...style, backgroundColor: '#42a5f5' }} onClick={goTo00}>
                  20%{' '}
                  {stage === 0 && (
                     <CropSquareIcon sx={{ color: '#FFFFFF', background: '#ed6c02', fontSize: 'small' }} />
                  )}
                  {stage > 0 && <ArrowDropUpIcon sx={{ p: 0, minWidth: 0 }} />}
               </Box>
               <Box sx={{ ...style, backgroundColor: '#4383cc' }} onClick={goTo20}>
                  40% {stage < 20 && <ArrowDropDownIcon />}
                  {stage === 20 && (
                     <CropSquareIcon sx={{ color: '#FFFFFF', background: '#ed6c02', fontSize: 'small' }} />
                  )}
                  {stage > 20 && <ArrowDropUpIcon />}
               </Box>
               <Box sx={{ ...style, backgroundColor: '#1976d2' }} onClick={goTo40}>
                  60% {stage < 40 && <ArrowDropDownIcon />}
                  {stage === 40 && (
                     <CropSquareIcon sx={{ color: '#FFFFFF', background: '#ed6c02', fontSize: 'small' }} />
                  )}
                  {stage > 40 && <ArrowDropUpIcon />}
               </Box>
               <Box sx={{ ...style, backgroundColor: '#1565c0' }} onClick={goTo60}>
                  80% {stage < 60 && <ArrowDropDownIcon sx={{ p: 0, minWidth: 0 }} />}
                  {stage === 60 && (
                     <CropSquareIcon sx={{ color: '#FFFFFF', background: '#ed6c02', fontSize: 'small' }} />
                  )}
                  {stage > 60 && <ArrowDropUpIcon sx={{ p: 0, minWidth: 0 }} />}
               </Box>
               <Box sx={{ ...style, backgroundColor: '#125aac' }} onClick={goTo80}>
                  100% {stage < 80 && <ArrowDropDownIcon />}
                  {stage === 80 && (
                     <CropSquareIcon sx={{ color: '#FFFFFF', background: '#ed6c02', fontSize: 'small' }} />
                  )}
                  {stage > 80 && <ArrowDropUpIcon />}
               </Box>
            </Stack>
         )}
      </div>
   )
}

export default GoDownStageWise
