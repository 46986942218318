import { Box, List, ListItem, ListItemText, Typography } from '@mui/material'
import React from 'react'

function Terms() {
   const terminology = [
      `This terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements. 
      यह शब्दावली इन नियमों एवं शर्तों, गोपनीयता कथन एवं अस्वीकरण नोटिस तथा सभी समझौतों पर लागू होती है।`,
      `"The Company", "Ourselves", "We", "Our", "Us", "Site" and "Website" refers to https://trackdemocracy.com. \n "कंपनी", "हम", "हमारा", "हमें", "साइट" और "वेबसाइट" का तात्पर्य https://trackdemocracy.com से है।`,
      '"Client", "User", "You" and "Your" refers to you, the person logging on this website. \n "ग्राहक", "उपयोगकर्ता", "आप" और "आपका" से तात्पर्य आप से है, अर्थात इस वेबसाइट पर लॉग इन करने वाले व्यक्ति से है।',
      '"Party", "Parties", or "Us", refers to both the Client and ourselves. \n "पार्टी", "पार्टियां" या "हम", का तात्पर्य ग्राहक और स्वयं दोनों से है।',
      `All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of provision of the Company’s stated services.
      सभी शर्तें कंपनी की बताई गई सेवाओं के प्रावधान के संबंध में ग्राहक की जरूरतों को पूरा करने के स्पष्ट उद्देश्य के लिए सबसे उपयुक्त तरीके से ग्राहक को हमारी सहायता की प्रक्रिया शुरू करने के लिए आवश्यक भुगतान की पेशकश, स्वीकृति और विचार को संदर्भित करती हैं।`,
      `Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.
      उपरोक्त शब्दावली या अन्य शब्दों का एकवचन, बहुवचन, कैपिटलाइज़ेशन और/या वह/वह या वे में कोई भी उपयोग, विनिमेय के रूप में लिया जाता है और इसलिए एक ही को संदर्भित करता है।`,
   ]

   const general = [
      `This is an agreement of use between the "Site" and the users (each, a "User") for the use of the "Site" by the "User".
      यह "साइट" और उपयोगकर्ताओं (प्रत्येक, एक "उपयोगकर्ता") के बीच "उपयोगकर्ता" द्वारा "साइट" के उपयोग के लिए एक समझौता है।`,
      `These terms and conditions outline the rules and regulations for the use of the "Site".
      ये नियम और शर्तें "साइट" के उपयोग के लिए नियम और विनियमों को रेखांकित करती हैं।`,
      `By accessing this website, we assume you accept these terms and conditions.
      इस वेबसाइट पर पहुंचकर, हम मानते हैं कि आप इन नियमों और शर्तों को स्वीकार करते हैं।`,
      `Do not continue to use this "Site" if you do not agree to take all of the terms and conditions stated on this page.
      यदि आप इस पृष्ठ पर वर्णित सभी नियमों और शर्तों से सहमत नहीं हैं तो इस "साइट" का उपयोग जारी न रखें।`,
   ]

   const registration = [
      `"User" shall provide only true and genuine information and details about himself / herself.
      "उपयोगकर्ता" को अपने बारे में केवल सच्ची एवं वास्तविक जानकारी एवं विवरण ही प्रदान करना होगा।`,
      `"User" shall provide only factually, officially and legally correct information and details about himself / herself.
      "उपयोगकर्ता" को अपने बारे में केवल तथ्यात्मक, आधिकारिक और कानूनी रूप से सही जानकारी और विवरण प्रदान करना होगा।`,
      `"User" shall create only one account for himself / herself.
      "उपयोगकर्ता" को अपने लिए केवल एक ही खाता बनाना होगा।`,
      `If found to violate any of these terms and conditions, legal action shall be taken against the "User" for the same and the legal cost involved shall be recovered from him / her.
      यदि इनमें से किसी भी नियम व शर्त का उल्लंघन पाया जाता है, तो इसके लिए "उपयोगकर्ता" के विरुद्ध कानूनी कार्रवाई की जाएगी तथा इसमें शामिल कानूनी लागत उससे वसूल की जाएगी।`,
      `If you do not agree to these terms and conditions, please do not use our "Site".
      यदि आप इन नियमों और शर्तों से सहमत नहीं हैं, तो कृपया हमारी "साइट" का उपयोग न करें।`,
   ]

   const cookies = [
      `We employ the use of cookies. By accessing the "Site", you agree to use cookies in agreement with the Site's Privacy Policy.
      हम कुकीज़ का उपयोग करते हैं। "साइट" तक पहुँचने से, आप साइट की गोपनीयता नीति के साथ समझौते में कुकीज़ का उपयोग करने के लिए सहमत होते हैं।`,
      `We use cookies to let us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website.
      हम प्रत्येक विज़िट के लिए उपयोगकर्ता के विवरण प्राप्त करने के लिए कुकीज़ का उपयोग करते हैं। हमारी वेबसाइट द्वारा कुकीज़ का उपयोग कुछ क्षेत्रों की कार्यक्षमता को सक्षम करने के लिए किया जाता है ताकि हमारी वेबसाइट पर आने वाले लोगों के लिए इसे आसान बनाया जा सके।`,
      `Some of our affiliate / advertising partners may also use cookies.
      हमारे कुछ सहयोगी/विज्ञापन साझेदार भी कुकीज़ का उपयोग कर सकते हैं।`,
   ]

   const licences = [
      `Unless otherwise stated, we and/or our licensors own the intellectual property rights for all material on the "Site". 
      All intellectual property rights are reserved. You may access this from the "Site" for your own personal use subject to restrictions set in these terms and conditions.
      जब तक अन्यथा न कहा जाए, हम और/या हमारे लाइसेंसकर्ता "साइट" पर सभी सामग्री के लिए बौद्धिक संपदा अधिकारों के स्वामी हैं।
      सभी बौद्धिक संपदा अधिकार सुरक्षित हैं। आप इन नियमों और शर्तों में निर्धारित प्रतिबंधों के अधीन अपने निजी उपयोग के लिए "साइट" से इस तक पहुँच सकते हैं।`,
      `You must not republish material from the "Site".
      आपको "साइट" से सामग्री पुनः प्रकाशित नहीं करनी चाहिए।`,
      `You must not sell, rent or sub-license material from the "Site".
      आपको "साइट" से सामग्री बेचना, किराए पर देना या उप-लाइसेंस नहीं देना चाहिए।`,
      `You must not reproduce, duplicate or copy material from the "Site".
      आपको "साइट" से सामग्री का पुनरुत्पादन, डुप्लिकेट या प्रतिलिपि नहीं बनानी चाहिए।`,
      `You must not redistribute content from the "Site".
      आपको "साइट" से सामग्री का पुनर्वितरण नहीं करना चाहिए।`,
   ]

   const usage = [
      `Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website.
      इस वेबसाइट के कुछ हिस्से उपयोगकर्ताओं को वेबसाइट के कुछ क्षेत्रों में राय और जानकारी पोस्ट करने और आदान-प्रदान करने का अवसर प्रदान करते हैं।`,
      `We do not filter, edit, publish or review Comments prior to their presence on the website.
      हम वेबसाइट पर टिप्पणियों की उपस्थिति से पहले उन्हें फ़िल्टर, संपादित, प्रकाशित या समीक्षा नहीं करते हैं।`,
      `"User" shall exclusively and entirely take the legal and moral responsibility of the contents posted by him / her.
      "उपयोगकर्ता" स्वयं द्वारा पोस्ट की गई सामग्री की कानूनी और नैतिक जिम्मेदारी पूरी तरह से लेगा।`,
      `In case of any descripancy / dispute, the concerned "User" shall be held responsible and legally liable for explanation, correction, remedy, legal redressal.
      किसी भी विसंगति/विवाद की स्थिति में, संबंधित "उपयोगकर्ता" को स्पष्टीकरण, सुधार, उपाय, कानूनी निवारण के लिए जिम्मेदार और कानूनी रूप से उत्तरदायी ठहराया जाएगा।`,
      `In case the "User" realizes that he / she has posted something wrong, he / she shall attach an appropriate apology for the same.
      यदि "उपयोगकर्ता" को पता चलता है कि उसने कुछ गलत पोस्ट किया है, तो उसे इसके लिए उचित क्षमा याचना संलग्न करनी होगी।`,
      `Comments do not reflect the views and opinions of the "Site", its agents and/or affiliates.
      टिप्पणियाँ "साइट", उसके एजेंटों और/या सहयोगियों के विचारों और राय को प्रतिबिंबित नहीं करती हैं।`,
      `Comments reflect the views and opinions of the user/s who post/s their content, information, views and opinions.
      टिप्पणियाँ उस उपयोगकर्ता/उपयोगकर्ताओं के विचारों और राय को प्रतिबिंबित करती हैं जो अपनी सामग्री, जानकारी, विचार और राय पोस्ट करते हैं।`,
      `To the extent permitted by applicable laws, we shall not be liable for any content / comment posted by you on this website.
      लागू कानूनों द्वारा अनुमत सीमा तक, हम इस वेबसाइट पर आपके द्वारा पोस्ट की गई किसी भी सामग्री / टिप्पणी के लिए उत्तरदायी नहीं होंगे।`,
      `To the extent permitted by applicable laws, we shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.
      लागू कानूनों द्वारा अनुमत सीमा तक, हम इस वेबसाइट पर टिप्पणियों के किसी भी उपयोग और/या पोस्टिंग और/या उपस्थिति के परिणामस्वरूप होने वाली किसी भी देयता, क्षति या व्यय के लिए टिप्पणियों के लिए उत्तरदायी नहीं होंगे।`,
      `We reserve the right to monitor all comments and to remove any comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions.
      हम सभी टिप्पणियों की निगरानी करने और किसी भी टिप्पणी को हटाने का अधिकार सुरक्षित रखते हैं जिसे अनुचित, आपत्तिजनक माना जाता है या जो इन नियमों और शर्तों का उल्लंघन करती है।`,
      `You warrant and represent that you are entitled to post the contents / comments on our website and have all necessary licenses and consents to do so.
      आप वारंटी देते हैं और प्रतिनिधित्व करते हैं कि आप हमारी वेबसाइट पर सामग्री/टिप्पणियाँ पोस्ट करने के हकदार हैं और ऐसा करने के लिए आपके पास सभी आवश्यक लाइसेंस और सहमति हैं।`,
      `You warrant and represent that the contents / comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party.
      आप वारंटी देते हैं और प्रतिनिधित्व करते हैं कि सामग्री / टिप्पणियाँ किसी भी बौद्धिक संपदा अधिकार पर आक्रमण नहीं करती हैं, जिसमें बिना किसी सीमा के किसी तीसरे पक्ष का कॉपीराइट, पेटेंट या ट्रेडमार्क शामिल है।`,
      `You warrant and represent that the contents / comments do not contain any defamatory, libellous, offensive, indecent or otherwise unlawful material which is an invasion of privacy.
      आप वारंटी देते हैं और प्रतिनिधित्व करते हैं कि सामग्री/टिप्पणियों में कोई अपमानजनक, अपमानजनक, आपत्तिजनक, अशिष्ट या अन्यथा गैरकानूनी सामग्री नहीं है जो गोपनीयता का उल्लंघन करती हो।`,
      `You warrant and represent that the contents / comments will not be used to solicit or promote any unlawful activity.
      आप वारंटी देते हैं और प्रतिनिधित्व करते हैं कि सामग्री/टिप्पणियों का उपयोग किसी भी गैरकानूनी गतिविधि को बढ़ावा देने या बढ़ावा देने के लिए नहीं किया जाएगा।`,
      `You hereby grant ourselves a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media.
      आप स्वयं को किसी भी और सभी रूपों, प्रारूपों या मीडिया में आपकी किसी भी टिप्पणी का उपयोग, पुनरुत्पादन, संपादन और दूसरों को उपयोग, पुनरुत्पादन और संपादन करने के लिए अधिकृत करने के लिए एक गैर-अनन्य लाइसेंस प्रदान करते हैं।`,
      `We shall not be held responsible for any content that appears on our Website but posted / uploaded by users other than Us.
      हम अपनी वेबसाइट पर प्रदर्शित किसी भी ऐसी सामग्री के लिए जिम्मेदार नहीं होंगे जो हमसे इतर किसी अन्य उपयोगकर्ता द्वारा पोस्ट/अपलोड की गई हो।`,
      `You commit not to post / upload any content / comment on our Website that may be interpreted as libellous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third-party rights.
      आप हमारी वेबसाइट पर ऐसी कोई भी सामग्री/टिप्पणी पोस्ट/अपलोड नहीं करने के लिए प्रतिबद्ध हैं, जिसे मानहानिकारक, अश्लील या आपराधिक माना जा सकता है, या जो किसी तीसरे पक्ष के अधिकारों का उल्लंघन करती है, अन्यथा उल्लंघन करती है, या उल्लंघन या अन्य उल्लंघन की वकालत करती है।`,
   ]

   const usageSharing = [
      `"User" shall post only factual statements and issues.
      "उपयोगकर्ता" केवल तथ्यात्मक बयान और मुद्दे ही पोस्ट करेगा।`,
      `"User" shall abide by the guidelines issued from time to time by the "Site" in connection of posting contents / feedback, etc.
      "उपयोगकर्ता" को सामग्री / फीडबैक आदि पोस्ट करने के संबंध में "साइट" द्वारा समय-समय पर जारी दिशानिर्देशों का पालन करना होगा।`,
      `"User" shall not post any caricature / cartoon / memes / or its like.
      उपयोगकर्ता" कोई भी कैरिकेचर / कार्टून / मीम्स / या इसके समान कोई सामग्री पोस्ट नहीं करेगा।`,
      `"User" shall not use any kind of abusive word / sentence / language.
      "उपयोगकर्ता" किसी भी प्रकार के अपमानजनक शब्द / वाक्य / भाषा का प्रयोग नहीं करेगा।`,
      `"User" shall not misrepresent facts by way of stating half truths.
      "उपयोगकर्ता" को आधे-अधूरे सच बताकर तथ्यों को गलत तरीके से प्रस्तुत नहीं करना चाहिए।`,
      `"User" shall not misrepresent facts by way of ill intendedly reproducing half statements of anybody else.
      "उपयोगकर्ता" किसी अन्य व्यक्ति के आधे-अधूरे कथनों को गलत इरादे से पुनः प्रस्तुत करके तथ्यों को गलत तरीके से प्रस्तुत नहीं करेगा।`,
      `"User" shall not misrepresent facts by way of accusing falsley (though the "User" can express his apprehension or suspicion).
      "उपयोगकर्ता" झूठे आरोप लगाकर तथ्यों को गलत तरीके से प्रस्तुत नहीं करेगा (हालांकि "उपयोगकर्ता" अपनी आशंका या संदेह व्यक्त कर सकता है)।`,
      `"User" shall not misrepresent facts by way of using morphed / altered photos or links to such items.
      "उपयोगकर्ता" को विकृत/परिवर्तित फोटो या ऐसी वस्तुओं के लिंक का उपयोग करके तथ्यों को गलत तरीके से प्रस्तुत नहीं करना चाहिए।`,
      `"User" shall not misrepresent facts by way of using altered or tampered videos or links to such items.
      "उपयोगकर्ता" को परिवर्तित या छेड़छाड़ किए गए वीडियो या ऐसी वस्तुओं के लिंक का उपयोग करके तथ्यों को गलत तरीके से प्रस्तुत नहीं करना चाहिए।`,
      `"User" shall not misrepresent facts by way of circulating any kind of slander against anybody.
      "उपयोगकर्ता" किसी के विरुद्ध किसी भी प्रकार की बदनामी प्रसारित करके तथ्यों को गलत तरीके से प्रस्तुत नहीं करेगा।`,
      `"User" shall not post / upload any content / comment on our Website that may be interpreted as libellous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third-party rights.
      "उपयोगकर्ता" हमारी वेबसाइट पर ऐसी कोई भी सामग्री / टिप्पणी पोस्ट / अपलोड नहीं करेगा जिसे मानहानिकारक, अश्लील या आपराधिक माना जा सकता है, या जो किसी तीसरे पक्ष के अधिकारों का उल्लंघन करता है, अन्यथा उल्लंघन करता है, या उल्लंघन या अन्य उल्लंघन का समर्थन करता है।`,
      `"User" shall not not post any poetry / story / "shayari" or its like.
      "उपयोगकर्ता" कोई भी कविता / कहानी / "शायरी" या इस तरह की कोई सामग्री पोस्ट नहीं करेगा।`,
      `In case any violation of any of the terms, the "Site" reserves the right to take appropriate administrative actions.
      किसी भी शर्त का उल्लंघन होने पर, "साइट" उचित प्रशासनिक कार्रवाई करने का अधिकार सुरक्षित रखती है।`,
      `In case of any violation of any of the terms, the person / institution being affected by the slander shall be free to take appropriate action aginst the said "User" having circulated the slander.
      किसी भी शर्त के उल्लंघन की स्थिति में, बदनामी से प्रभावित व्यक्ति/संस्था बदनामी फैलाने वाले उक्त "उपयोगकर्ता" के विरुद्ध उचित कार्रवाई करने के लिए स्वतंत्र होगी।`,
   ]

   const iFrames = [
      `Without prior approval and written permission granted by us, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.
      हमारी पूर्व स्वीकृति और लिखित अनुमति के बिना, आप हमारे वेबपेजों के चारों ओर ऐसे फ्रेम नहीं बना सकते हैं जो किसी भी तरह से हमारी वेबसाइट की दृश्य प्रस्तुति या उपस्थिति को बदल दें।`,
   ]

   const privacy = [
      `Please read Privacy Policy.
      कृपया गोपनीयता नीति पढ़ें.`,
   ]

   const rights = [
      `We reserve the right to request that you remove all links or any particular link to our Website.
      हम आपसे यह अनुरोध करने का अधिकार सुरक्षित रखते हैं कि आप हमारी वेबसाइट से सभी लिंक या कोई विशेष लिंक हटा दें।`,
      `You approve to immediately remove all links to our Website upon request.
      आप अनुरोध किए जाने पर हमारी वेबसाइट के सभी लिंक तुरंत हटाने की स्वीकृति देते हैं।`,
      `We also reserve the right to amend these terms and conditions and it’s linking policy at any time.
      हम किसी भी समय इन नियमों और शर्तों और इसकी लिंकिंग नीति को संशोधित करने का अधिकार सुरक्षित रखते हैं।`,
      `By using or linking to our Website, you agree to be bound to and follow these terms and conditions.
      हमारी वेबसाइट का उपयोग या उससे लिंक करके, आप इन नियमों और शर्तों से बंधे रहने और उनका पालन करने के लिए सहमत होते हैं।`,
   ]

   const removalOfLinks = [
      `If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment.
      We will consider requests to remove links but we are not obligated to or so or to respond to you directly.
      यदि आपको हमारी वेबसाइट पर कोई लिंक किसी भी कारण से आपत्तिजनक लगता है, तो आप किसी भी समय हमसे संपर्क कर सकते हैं और हमें सूचित कर सकते हैं।
      हम लिंक हटाने के अनुरोधों पर विचार करेंगे, लेकिन हम ऐसा करने या आपको सीधे जवाब देने के लिए बाध्य नहीं हैं।`,
      `We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.
      हम यह सुनिश्चित नहीं करते कि इस वेबसाइट पर दी गई जानकारी सही है, हम इसकी पूर्णता या सटीकता की गारंटी नहीं देते हैं; न ही हम यह सुनिश्चित करने का वादा करते हैं कि वेबसाइट उपलब्ध रहेगी या वेबसाइट पर सामग्री अद्यतन रखी जाएगी।`,
   ]

   const disclaimer = [
      `To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website.
      लागू कानून द्वारा अनुमत अधिकतम सीमा तक, हम अपनी वेबसाइट और इस वेबसाइट के उपयोग से संबंधित सभी अभ्यावेदन, वारंटी और शर्तों को बाहर करते हैं।`,
      `Nothing in this disclaimer will limit or exclude our or your liability for death or personal injury.
      इस अस्वीकरण में कुछ भी ऐसा नहीं है जो मृत्यु या व्यक्तिगत चोट के लिए हमारे या आपके दायित्व को सीमित या बहिष्कृत करेगा।`,
      `Nothing in this disclaimer will limit or exclude our or your liability for fraud or fraudulent misrepresentation.
      इस अस्वीकरण में कुछ भी धोखाधड़ी या धोखाधड़ीपूर्ण गलतबयानी के लिए हमारे या आपके दायित्व को सीमित या बहिष्कृत नहीं करेगा।`,
      `Nothing in this disclaimer will limit any of our or your liabilities in any way that is not permitted under applicable law.
      इस अस्वीकरण में कोई भी बात हमारी या आपकी देनदारियों को किसी भी तरह से सीमित नहीं करेगी, जिसकी लागू कानून के तहत अनुमति नहीं है।`,
      `Nothing in this disclaimer will exclude any of our or your liabilities that may not be excluded under applicable law.
      इस अस्वीकरण में ऐसा कुछ भी नहीं है जो हमारी या आपकी किसी भी देनदारी को बाहर रखे, जिसे लागू कानून के तहत बाहर नहीं रखा जा सकता है।`,
   ]

   const amendments = [
      `"Site" reserves the right to ammend the terms as deemed fit and required.
      "साइट" को उचित एवं आवश्यक समझे जाने पर शर्तों में संशोधन करने का अधिकार सुरक्षित है।`,
      `Any changes to the terms of use of the "Site" shall be notified on the home page of the "Site".
      "साइट" के उपयोग की शर्तों में कोई भी परिवर्तन "साइट" के होम पेज पर अधिसूचित किया जाएगा।`,
      `Continued use of the "Site" after notification of change in terms of use shall constitute acceptance of amended terms of use of "Site" by the "User".
      उपयोग की शर्तों में परिवर्तन की अधिसूचना के बाद "साइट" का निरंतर उपयोग "उपयोगकर्ता" द्वारा "साइट" के उपयोग की संशोधित शर्तों की स्वीकृति माना जाएगा।`,
   ]

   const disputes = [
      `Any dispute / claim shall be subject to the jurisdiction of the High Court of Kolkata, West Bengal, India.
      कोई भी विवाद/दावा कोलकाता, पश्चिम बंगाल, भारत के उच्च न्यायालय के अधिकार क्षेत्र के अधीन होगा।`,
   ]

   const commencement = [
      `This Agreement shall begin on October 31st, 2024.
      यह समझौता 31 अक्टूबर, 2024 से शुरू होगा।`,
      `Last updated on October 24th, 2024.
      अंतिम बार 24 अक्टूबर, 2024 को अपडेट किया गया।`,
   ]

   return (
      <Box sx={{ minHeight: 800, m: 4, pb: 20, backgroundColor: '#FFFFF0' }}>
         <Box
            sx={{
               p: 2,
               fontWeight: 800,
               fontSize: 18,
               textAlign: 'center',
            }}
         >
            TERMS OF USE
         </Box>
         <Box sx={{ p: 2 }}>
            <strong>Terminology:</strong>
            <br />
            <List sx={{ ml: 4 }}>
               {terminology.map((value, index) => (
                  <>
                     <ListItem key={value} sx={{ p: 0, ml: 4, mt: 1 }}>
                        <ListItemText
                           sx={{ my: 0 }}
                           primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                           secondaryTypographyProps={{
                              whiteSpace: 'pre-line',
                              color: 'text.primary',
                              display: 'inline',
                              textAlign: 'left',
                           }}
                           primary={
                              <>
                                 <Typography component="span" sx={{ fontWeight: 600, mr: 2 }}>
                                    {index + 1}.
                                 </Typography>
                                 <Typography component="span" sx={{ whiteSpace: 'pre-line' }}>
                                    {value}
                                 </Typography>
                              </>
                           }
                        />
                     </ListItem>
                  </>
               ))}
            </List>
         </Box>
         <Box sx={{ p: 2 }}>
            <strong>General:</strong>
            <br />
            <List sx={{ ml: 4 }}>
               {general.map((value, index) => (
                  <>
                     <ListItem key={value} sx={{ p: 0, ml: 4, mt: 1 }}>
                        <ListItemText
                           sx={{ my: 0 }}
                           primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                           secondaryTypographyProps={{
                              whiteSpace: 'pre-line',
                              color: 'text.primary',
                              display: 'inline',
                              textAlign: 'left',
                           }}
                           primary={
                              <>
                                 <Typography component="span" sx={{ fontWeight: 600, mr: 2 }}>
                                    {index + 1}.
                                 </Typography>
                                 <Typography component="span" sx={{ whiteSpace: 'pre-line' }}>
                                    {value}
                                 </Typography>
                              </>
                           }
                        />
                     </ListItem>
                  </>
               ))}
            </List>
         </Box>
         <Box sx={{ p: 2 }}>
            <strong>{`Registration of "User" on the "Site"`}:</strong>
            <br />
            <List sx={{ ml: 4 }}>
               {registration.map((value, index) => (
                  <>
                     <ListItem key={value} sx={{ p: 0, ml: 4, mt: 1 }}>
                        <ListItemText
                           sx={{ my: 0 }}
                           primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                           secondaryTypographyProps={{
                              whiteSpace: 'pre-line',
                              color: 'text.primary',
                              display: 'inline',
                              textAlign: 'left',
                           }}
                           primary={
                              <>
                                 <Typography component="span" sx={{ fontWeight: 600, mr: 2 }}>
                                    {index + 1}.
                                 </Typography>
                                 <Typography component="span" sx={{ whiteSpace: 'pre-line' }}>
                                    {value}
                                 </Typography>
                              </>
                           }
                        />
                     </ListItem>
                  </>
               ))}
            </List>
         </Box>
         <Box sx={{ p: 2 }}>
            <strong>Cookies:</strong>
            <br />
            <List sx={{ ml: 4 }}>
               {cookies.map((value, index) => (
                  <>
                     <ListItem key={value} sx={{ p: 0, ml: 4, mt: 1 }}>
                        <ListItemText
                           sx={{ my: 0 }}
                           primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                           secondaryTypographyProps={{
                              whiteSpace: 'pre-line',
                              color: 'text.primary',
                              display: 'inline',
                              textAlign: 'left',
                           }}
                           primary={
                              <>
                                 <Typography component="span" sx={{ fontWeight: 600, mr: 2 }}>
                                    {index + 1}.
                                 </Typography>
                                 <Typography component="span" sx={{ whiteSpace: 'pre-line' }}>
                                    {value}
                                 </Typography>
                              </>
                           }
                        />
                     </ListItem>
                  </>
               ))}
            </List>
         </Box>
         <Box sx={{ p: 2 }}>
            <strong>License:</strong>
            <br />
            <List sx={{ ml: 4 }}>
               {licences.map((value, index) => (
                  <>
                     <ListItem key={value} sx={{ p: 0, ml: 4, mt: 1 }}>
                        <ListItemText
                           sx={{ my: 0 }}
                           primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                           secondaryTypographyProps={{
                              whiteSpace: 'pre-line',
                              color: 'text.primary',
                              display: 'inline',
                              textAlign: 'left',
                           }}
                           primary={
                              <>
                                 <Typography component="span" sx={{ fontWeight: 600, mr: 2 }}>
                                    {index + 1}.
                                 </Typography>
                                 <Typography component="span" sx={{ whiteSpace: 'pre-line' }}>
                                    {value}
                                 </Typography>
                              </>
                           }
                        />
                     </ListItem>
                  </>
               ))}
            </List>
         </Box>
         <Box sx={{ p: 2 }}>
            <strong>Usage – responsibility, liability and rights:</strong>
            <br />
            <List sx={{ ml: 4 }}>
               {usage.map((value, index) => (
                  <>
                     <ListItem key={value} sx={{ p: 0, ml: 4, mt: 1 }}>
                        <ListItemText
                           sx={{ my: 0 }}
                           primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                           secondaryTypographyProps={{
                              whiteSpace: 'pre-line',
                              color: 'text.primary',
                              display: 'inline',
                              textAlign: 'left',
                           }}
                           primary={
                              <>
                                 <Typography component="span" sx={{ fontWeight: 600, mr: 2 }}>
                                    {index + 1}.
                                 </Typography>
                                 {index === 2 || index === 3 || index === 4 ? (
                                    <Typography
                                       component="span"
                                       sx={{ whiteSpace: 'pre-line', fontWeight: 600, color: 'Crimson' }}
                                    >
                                       {value}
                                    </Typography>
                                 ) : (
                                    <Typography component="span" sx={{ whiteSpace: 'pre-line' }}>
                                       {value}
                                    </Typography>
                                 )}
                              </>
                           }
                        />
                     </ListItem>
                  </>
               ))}
            </List>
         </Box>
         <Box sx={{ p: 2 }}>
            <strong>
               {`Usage – sharing your content, information, queries, feedback, suggestions, photos, videos, links etc.`}
               :
            </strong>
            <br />
            <List sx={{ ml: 4 }}>
               {usageSharing.map((value, index) => (
                  <>
                     <ListItem key={value} sx={{ p: 0, ml: 4, mt: 1 }}>
                        <ListItemText
                           sx={{ my: 0 }}
                           primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                           secondaryTypographyProps={{
                              whiteSpace: 'pre-line',
                              color: 'text.primary',
                              display: 'inline',
                              textAlign: 'left',
                           }}
                           primary={
                              <>
                                 <Typography component="span" sx={{ fontWeight: 600, mr: 2 }}>
                                    {index + 1}.
                                 </Typography>
                                 <Typography component="span" sx={{ whiteSpace: 'pre-line' }}>
                                    {value}
                                 </Typography>
                              </>
                           }
                        />
                     </ListItem>
                  </>
               ))}
            </List>
         </Box>
         <Box sx={{ p: 2 }}>
            <strong>iFrames / others:</strong>
            <br />
            <List sx={{ ml: 4 }}>
               {iFrames.map((value, index) => (
                  <>
                     <ListItem key={value} sx={{ p: 0, ml: 4, mt: 1 }}>
                        <ListItemText
                           sx={{ my: 0 }}
                           primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                           secondaryTypographyProps={{
                              whiteSpace: 'pre-line',
                              color: 'text.primary',
                              display: 'inline',
                              textAlign: 'left',
                           }}
                           primary={
                              <>
                                 <Typography component="span" sx={{ fontWeight: 600, mr: 2 }}>
                                    {index + 1}.
                                 </Typography>
                                 <Typography component="span" sx={{ whiteSpace: 'pre-line' }}>
                                    {value}
                                 </Typography>
                              </>
                           }
                        />
                     </ListItem>
                  </>
               ))}
            </List>
         </Box>
         <Box sx={{ p: 2 }}>
            <strong>Your privacy:</strong>
            <br />
            <List sx={{ ml: 4 }}>
               {privacy.map((value, index) => (
                  <>
                     <ListItem key={value} sx={{ p: 0, ml: 4, mt: 1 }}>
                        <ListItemText
                           sx={{ my: 0 }}
                           primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                           secondaryTypographyProps={{
                              whiteSpace: 'pre-line',
                              color: 'text.primary',
                              display: 'inline',
                              textAlign: 'left',
                           }}
                           primary={
                              <>
                                 <Typography component="span" sx={{ fontWeight: 600, mr: 2 }}>
                                    {index + 1}.
                                 </Typography>
                                 <Typography component="span" sx={{ whiteSpace: 'pre-line' }}>
                                    {value}
                                 </Typography>
                              </>
                           }
                        />
                     </ListItem>
                  </>
               ))}
            </List>
         </Box>
         <Box sx={{ p: 2 }}>
            <strong>Reservation of rights:</strong>
            <br />
            <List sx={{ ml: 4 }}>
               {rights.map((value, index) => (
                  <>
                     <ListItem key={value} sx={{ p: 0, ml: 4, mt: 1 }}>
                        <ListItemText
                           sx={{ my: 0 }}
                           primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                           secondaryTypographyProps={{
                              whiteSpace: 'pre-line',
                              color: 'text.primary',
                              display: 'inline',
                              textAlign: 'left',
                           }}
                           primary={
                              <>
                                 <Typography component="span" sx={{ fontWeight: 600, mr: 2 }}>
                                    {index + 1}.
                                 </Typography>
                                 <Typography component="span" sx={{ whiteSpace: 'pre-line' }}>
                                    {value}
                                 </Typography>
                              </>
                           }
                        />
                     </ListItem>
                  </>
               ))}
            </List>
         </Box>
         <Box sx={{ p: 2 }}>
            <strong>Removal of links from our website:</strong>
            <br />
            <List sx={{ ml: 4 }}>
               {removalOfLinks.map((value, index) => (
                  <>
                     <ListItem key={value} sx={{ p: 0, ml: 4, mt: 1 }}>
                        <ListItemText
                           sx={{ my: 0 }}
                           primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                           secondaryTypographyProps={{
                              whiteSpace: 'pre-line',
                              color: 'text.primary',
                              display: 'inline',
                              textAlign: 'left',
                           }}
                           primary={
                              <>
                                 <Typography component="span" sx={{ fontWeight: 600, mr: 2 }}>
                                    {index + 1}.
                                 </Typography>
                                 <Typography component="span" sx={{ whiteSpace: 'pre-line' }}>
                                    {value}
                                 </Typography>
                              </>
                           }
                        />
                     </ListItem>
                  </>
               ))}
            </List>
         </Box>
         <Box sx={{ p: 2 }}>
            <strong>Disclaimer:</strong>
            <br />
            <List sx={{ ml: 4 }}>
               {disclaimer.map((value, index) => (
                  <>
                     <ListItem key={value} sx={{ p: 0, ml: 4, mt: 1 }}>
                        <ListItemText
                           sx={{ my: 0 }}
                           primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                           secondaryTypographyProps={{
                              whiteSpace: 'pre-line',
                              color: 'text.primary',
                              display: 'inline',
                              textAlign: 'left',
                           }}
                           primary={
                              <>
                                 <Typography component="span" sx={{ fontWeight: 600, mr: 2 }}>
                                    {index + 1}.
                                 </Typography>
                                 <Typography component="span" sx={{ whiteSpace: 'pre-line' }}>
                                    {value}
                                 </Typography>
                              </>
                           }
                        />
                     </ListItem>
                  </>
               ))}
            </List>
         </Box>
         <Box sx={{ p: 2 }}>
            <strong>Amendments:</strong>
            <br />
            <List sx={{ ml: 4 }}>
               {amendments.map((value, index) => (
                  <>
                     <ListItem key={value} sx={{ p: 0, ml: 4, mt: 1 }}>
                        <ListItemText
                           sx={{ my: 0 }}
                           primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                           secondaryTypographyProps={{
                              whiteSpace: 'pre-line',
                              color: 'text.primary',
                              display: 'inline',
                              textAlign: 'left',
                           }}
                           primary={
                              <>
                                 <Typography component="span" sx={{ fontWeight: 600, mr: 2 }}>
                                    {index + 1}.
                                 </Typography>
                                 <Typography component="span" sx={{ whiteSpace: 'pre-line' }}>
                                    {value}
                                 </Typography>
                              </>
                           }
                        />
                     </ListItem>
                  </>
               ))}
            </List>
         </Box>
         <Box sx={{ p: 2 }}>
            <strong>Disputes:</strong>
            <br />
            <List sx={{ ml: 4 }}>
               {disputes.map((value, index) => (
                  <>
                     <ListItem key={value} sx={{ p: 0, ml: 4, mt: 1 }}>
                        <ListItemText
                           sx={{ my: 0 }}
                           primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                           secondaryTypographyProps={{
                              whiteSpace: 'pre-line',
                              color: 'text.primary',
                              display: 'inline',
                              textAlign: 'left',
                           }}
                           primary={
                              <>
                                 <Typography component="span" sx={{ fontWeight: 600, mr: 2 }}>
                                    {index + 1}.
                                 </Typography>
                                 <Typography component="span" sx={{ whiteSpace: 'pre-line' }}>
                                    {value}
                                 </Typography>
                              </>
                           }
                        />
                     </ListItem>
                  </>
               ))}
            </List>
         </Box>
         <Box sx={{ p: 2 }}>
            <strong>Commencement / effective date:</strong>
            <br />
            <List sx={{ ml: 4 }}>
               {commencement.map((value, index) => (
                  <>
                     <ListItem key={value} sx={{ p: 0, ml: 4, mt: 1 }}>
                        <ListItemText
                           sx={{ my: 0 }}
                           primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                           secondaryTypographyProps={{
                              whiteSpace: 'pre-line',
                              color: 'text.primary',
                              display: 'inline',
                              textAlign: 'left',
                           }}
                           primary={
                              <>
                                 <Typography component="span" sx={{ fontWeight: 600, mr: 2 }}>
                                    {index + 1}.
                                 </Typography>
                                 <Typography component="span" sx={{ whiteSpace: 'pre-line' }}>
                                    {value}
                                 </Typography>
                              </>
                           }
                        />
                     </ListItem>
                  </>
               ))}
            </List>
         </Box>
      </Box>
   )
}

export default Terms
