import React, { useEffect, useState } from 'react'
import myStyles from './Edits.module.css'
import { Box, Button, TextField } from '@mui/material'
import PropTypes from 'prop-types'
import validator from 'validator'

import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

function AddNewsElection({ handeAddNewsElection }) {
   const [weblinkDetails, setWeblinkDetails] = useState({
      Title: '',
      Weblink: '',
      Date: '',
      Description: '',
   })
   useEffect(() => {
      console.log('Weblink details on change of its value: ', weblinkDetails)
   }, [weblinkDetails])

   const [errorMessage, setErrorMessage] = useState('')

   const validate = (value) => {
      if (validator.isURL(value)) {
         setErrorMessage('Is Valid URL')
      } else {
         setErrorMessage('Is Not Valid URL')
      }
   }

   function handleDetails(event) {
      setWeblinkDetails({ ...weblinkDetails, [event.target.name]: event.target.value })
      if (event.target.name === 'Weblink') {
         validate(event.target.value)
      }
   }

   function handleSubmitTextAndWeblink(e) {
      e.preventDefault()

      if (errorMessage === 'Is Valid URL') {
         handeAddNewsElection(weblinkDetails)
      }

      reset()
   }

   function reset() {
      setWeblinkDetails({
         Title: '',
         Weblink: '',
         Date: '',
         Description: '',
      })
   }

   return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
         <Box sx={{ m: 2 }}>
            <Box>
               <TextField
                  onBlur={(event) => event}
                  onChange={handleDetails}
                  value={weblinkDetails.Title}
                  name="Title"
                  id="title"
                  label="Title"
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  size="small"
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               ></TextField>
            </Box>
            <Box>
               <TextField
                  onBlur={(event) => event}
                  onChange={handleDetails}
                  value={weblinkDetails.Weblink}
                  name="Weblink"
                  id="weblink"
                  label="Website"
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  size="small"
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               ></TextField>
            </Box>

            <Box>
               <DatePicker
                  label="Date"
                  name="Date"
                  value={weblinkDetails.Date != null ? dayjs(weblinkDetails.Date) : null}
                  format="DD-MMM-YYYY"
                  onChange={(newValue) =>
                     setWeblinkDetails({
                        ...weblinkDetails,
                        Date: new Date(newValue),
                     })
                  }
                  size="small"
                  views={['year', 'month', 'day']}
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               />
            </Box>

            <Box>
               <TextField
                  onBlur={(event) => event}
                  onChange={handleDetails}
                  value={weblinkDetails.Description}
                  name="Description"
                  id="description"
                  label="Description"
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  size="small"
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               ></TextField>
            </Box>
            <Box className={myStyles.text_align_center}>
               <Button
                  type="submit"
                  align="center"
                  variant="contained"
                  color="primary"
                  size="small"
                  margin="dense"
                  onClick={(e) => {
                     handleSubmitTextAndWeblink(e)
                  }}
                  disabled={errorMessage !== 'Is Valid URL' || weblinkDetails.Date === null}
               >
                  Submit
               </Button>
            </Box>
         </Box>
      </LocalizationProvider>
   )
}

AddNewsElection.propTypes = {
   handeAddNewsElection: PropTypes.func.isRequired,
}

export default AddNewsElection
