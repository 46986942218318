import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { db } from '../../../FirebaseConfig'
import { Avatar, Badge, Box, Card, CardContent, CardMedia, Link, Tabs, Typography, useTheme } from '@mui/material'
import HowToVoteIcon from '@mui/icons-material/HowToVote'
import moment from 'moment'
// import { styled } from '@mui/material/styles'

import { TabContext } from '@mui/lab'
import { a11yProps, StyledTab1, TabPanel2 } from '../../../Components/TabPanelSystem'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import SwipeableViews from 'react-swipeable-views'
import ElecCandiBasicInfo from '../ElecCandiBasicInfo/ElecCandiBasicInfo'
import ElecCandiCred from '../ElecCandiCred/ElecCandiCred'
import ElecCandiWorks from '../ElecCandiWorks/ElecCandiWorks'
import {
   SmallAvatar,
   stringAvatar150,
   stringAvatar150Single,
   stringAvatar50,
   stringAvatar50Single,
} from '../../../Utils/MUITheme/MUITheme'
import { countWordsUsingReplace } from '../../../Utils/Conversions/CaseConversion'
import ElecCandiInteract from '../ElecCandiInteract/ElecCandiInteract'
import { Helmet } from 'react-helmet'
import ElecCandiNews from '../ElecCandiNews/ElecCandiNews'
import ReactShareButtons from '../../../Utils/CustomButtons/ReactShareButtons'

function ElecCandi() {
   const { candiId, candiName } = useParams()
   const navigate = useNavigate()

   // const theme = useTheme()
   const [value, setValue] = useState(0)
   const handleChangeNavTab = (event, newValue) => {
      setValue(newValue)
   }
   const handleChangeNavTabIndex = (index) => {
      setValue(index)
   }

   const [fetchStatus, setFetchStatus] = useState('idle')
   const [candi, setCandi] = useState('')
   const [elec, setElec] = useState('')
   const [consti, setConsti] = useState('')
   const [candisList, setCandisList] = useState([])
   const [weblinksListRoot, setWeblinksListRoot] = useState([])
   const [weblinksList, setWeblinksList] = useState([])

   useEffect(() => {
      if (fetchStatus === 'idle') {
         fetchCandidate(candiId)
      }
   }, [])

   const fetchCandidate = async (candiId) => {
      try {
         const docRef = doc(db, 'ElectionCandidates', candiId)
         const docSnap = await getDoc(docRef)
         if (docSnap.exists()) {
            setCandi({ ...docSnap.data(), id: docSnap.id })
         } else {
            navigate('/', { replace: true })
         }

         setFetchStatus('success')
      } catch (err) {
         console.log('error', err)
         setFetchStatus('error')
      }
   }

   useEffect(() => {
      if (candi !== '') {
         if (candiId !== candi.id) {
            navigate('/', { replace: true })
         } else {
            fetchElection(candi.Election_id)
            fetchConstituency(candi.Constituency_id)
            fetchWeblinks(candi.Election_id)
         }
      }
   }, [candi])

   const fetchElection = async (ElecId) => {
      try {
         const docRef = doc(db, 'Elections', ElecId)
         const docSnap = await getDoc(docRef)
         if (docSnap.exists()) {
            setElec({ ...docSnap.data(), id: docSnap.id })
         } else {
            navigate('/', { replace: true })
         }
      } catch (err) {
         console.log('error', err)
      }
   }

   useEffect(() => {
      if (elec !== '') {
         fetchCandidatesOfThisConsti()
      }
   }, [elec])

   const fetchConstituency = async (id) => {
      try {
         const docRef = doc(db, 'LegisConstituencies', id)
         const docSnap = await getDoc(docRef)
         if (docSnap.exists()) {
            setConsti({ ...docSnap.data(), id: docSnap.id })
         } else {
            navigate('/', { replace: true })
         }
      } catch (err) {
         console.log('error', err)
      }
   }

   const fetchWeblinks = async (ElecId) => {
      try {
         const querryWeblinks = query(collection(db, 'Elections', ElecId, 'WeblinksLists'))
         const querySnapshot = await getDocs(querryWeblinks)
         if (querySnapshot && querySnapshot.length < 1) {
            setWeblinksList([])
         } else {
            const listFetched = []
            querySnapshot.forEach((docSnap) => {
               listFetched.push(...docSnap.data().Weblinks)
            })
            const filteredList = listFetched.filter(
               (item) => item.Candidate_id === candi.id || item.Constituency_id === candi.Constituency_id,
            )
            const sortedList = [...filteredList].sort((a, b) => {
               // bigger date first
               if (moment(a.Date.toDate()).isAfter(moment(b.Date.toDate()))) {
                  return -1
               } else if (moment(a.Date.toDate()).isBefore(moment(b.Date.toDate()))) {
                  return 1
               } else {
                  return 0
               }
            })
            setWeblinksListRoot(listFetched)
            setWeblinksList(sortedList)
         }
      } catch (err) {
         console.log('error fetching weblinks', err)
      }
   }

   const fetchCandidatesOfThisConsti = async () => {
      try {
         let candisListRoot = []
         if (elec.id === 'lfoOBVgQSifbNXkzh07j' || elec.id === 'eHt7k2NNw54Xaq2T8kAK') {
            // these are jk and haryana elections 2024
            // in these cases the CandidateCoreLists docs were placed inside the election collection
            // later the structure changed ...
            if (elec.CandidatesCoreLists.length > 0) {
               const listFetched = []

               elec.CandidatesCoreLists.forEach(async (item) => {
                  const docRefCore = doc(db, 'ElectionCandidates', item)
                  const docSnapCore = await getDoc(docRefCore)
                  listFetched.push(...docSnapCore.data().Candidates)
               })
               candisListRoot = listFetched
            }
         } else {
            //
            const querryCandis = query(
               collection(db, 'Elections', elec.id, 'CoreLists'),
               where('DocType', '==', 'CandidatesCoreList'),
            )
            const querySnapshot = await getDocs(querryCandis)

            const listFetched = []

            // if (querySnapshot.exists()) {
            querySnapshot.forEach((docSnap) => {
               listFetched.push(...docSnap.data().Candidates)
            })
            // }

            candisListRoot = listFetched
         }

         const candisListByConsti = candisListRoot.filter((item) => item.Constituency_id === candi.Constituency_id)

         const listForOnlineSupport = candisListByConsti.filter(
            (item) =>
               item.Rejected !== 'Rejected' &&
               item.Refused !== 'Refused' &&
               item.Withdrawn !== 'Withdrawn' &&
               item.AnnounceCancelled !== 'AnnounceCancelled',
         )

         const sortedListForOnlineSupport = [...listForOnlineSupport].sort((a, b) => b.OnlineSupport - a.OnlineSupport)

         setCandisList(sortedListForOnlineSupport)
      } catch (err) {
         console.log('error', err)
      }
   }

   const sortCandisList = (list) => {
      setCandisList(list)
   }

   const handleWeblinksUpdate = (obj) => {
      weblinksListRoot.push(obj)
   }

   const theme = useTheme()

   const title = 'Track Democracy -' + elec.Name + ' - Candidate: ' + candiName
   const hashTag = '#democracy'
   const hashTags = ['DelhiElections', 'Elections', 'Candidate', 'India', 'BJP', 'AAP', 'Congress']
   const image = candi.Image
   //
   return (
      <Box sx={{ minHeight: 600, px: 0.5, pb: 20 }}>
         <ReactShareButtons title={title} hashTags={hashTags} hashTag={hashTag} image={image} />
         <div>
            <Helmet>
               <title>{`Track Democracy - Candidate - ${candiName} - ${elec.Name}`}</title>
               <meta name="og:image" property="og:image" content={image} />
            </Helmet>
         </div>
         <Card
            sx={{
               my: 1,
               py: 1,
               px: 2,
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               // backgroundColor: '#000000',
               backgroundImage: 'linear-gradient(to right, #545159, #797580, #545159)',
            }}
         >
            <Box sx={{ display: 'flex', flexDirection: 'column', px: 2 }}>
               <CardContent sx={{ flex: '1 0 auto' }}>
                  {candi && (
                     <div>
                        {candi.NameLocal && (
                           <Typography
                              component="div"
                              variant="h5"
                              sx={{ fontWeight: 700, fontSize: 22, fontFamily: 'Poppins' }}
                              color="#FFFFFF"
                           >
                              {candi.NameLocal}
                           </Typography>
                        )}
                        {candi.NameHindi && candi.NameLocal === '' && (
                           <Typography
                              component="div"
                              variant="h5"
                              sx={{ fontWeight: 700, fontSize: 22, fontFamily: 'Poppins' }}
                              color="#FFFFFF"
                           >
                              {candi.NameHindi}
                           </Typography>
                        )}
                        {candi.NameHindi && candi.NameLocal !== '' && (
                           <Typography
                              component="div"
                              sx={{ fontWeight: 700, fontSize: 16, fontFamily: 'Poppins' }}
                              color="#FFFFFF"
                           >
                              {candi.NameHindi}
                           </Typography>
                        )}
                        {candi.NameLocal === '' && (
                           <Typography
                              component="div"
                              variant="h5"
                              sx={{ fontWeight: 700, fontSize: 22, fontFamily: 'Poppins' }}
                              color="#FFFFFF"
                           >
                              {candi.Name}
                           </Typography>
                        )}
                        {candi.NameLocal !== '' && (
                           <Typography
                              component="div"
                              sx={{ fontWeight: 700, fontSize: 16, fontFamily: 'Poppins' }}
                              color="#FFFFFF"
                           >
                              {candi.Name}
                           </Typography>
                        )}
                        <Box sx={{ display: 'flex', direction: 'row' }}>
                           <Box
                              sx={{
                                 display: 'inline',
                                 mx: 0.5,
                                 fontSize: 14,
                                 fontWeight: 400,
                                 color: '#D0D3D5',
                              }}
                           >
                              <HowToVoteIcon sx={{ ml: 1 }} />{' '}
                           </Box>
                           <Box
                              sx={{
                                 display: 'inline',
                                 mx: 0.5,
                                 fontSize: 14,
                              }}
                           >
                              {candi.ElectionNameLocal !== '' && (
                                 <Box>
                                    <Link
                                       sx={{ color: '#ffffff', fontWeight: 700, fontFamily: 'Poppins' }}
                                       href={`/election/${candi.ElectionName}/${candi.Election_id}`}
                                       rel="noreferrer"
                                       target="_blank"
                                    >
                                       {candi.ElectionNameLocal}
                                    </Link>
                                 </Box>
                              )}
                              <Box>
                                 <Link
                                    sx={{ color: '#ffffff', fontWeight: 700, fontFamily: 'Poppins' }}
                                    href={`/election/${candi.ElectionName}/${candi.Election_id}`}
                                    rel="noreferrer"
                                    target="_blank"
                                 >
                                    {candi.ElectionNameHindi}
                                 </Link>
                              </Box>
                              <Box>
                                 <Link
                                    sx={{ color: '#ffffff', fontWeight: 700, fontFamily: 'Poppins' }}
                                    href={`/election/${candi.ElectionName}/${candi.Election_id}`}
                                    rel="noreferrer"
                                    target="_blank"
                                 >
                                    {candi.ElectionName}{' '}
                                 </Link>
                              </Box>
                              {elec && elec.DateStart && (
                                 <Box
                                    sx={{
                                       display: 'inline',
                                       mx: 0.5,
                                       fontSize: 14,
                                       fontWeight: 400,
                                       color: '#ffffff',
                                    }}
                                 >
                                    {moment(elec.DateStart.toDate()).format('Do MMM YYYY')}

                                    {moment(elec.DateStart.toDate()).format('Do MMM YYYY') !==
                                       moment(elec.DateFinish.toDate()).format('Do MMM YYYY') && (
                                       <> - {moment(elec.DateFinish.toDate()).format('Do MMM YYYY')}</>
                                    )}
                                 </Box>
                              )}
                           </Box>
                        </Box>
                        {/* <Box>
                           <Box
                              sx={{
                                 display: 'inline',
                                 mx: 0.5,
                                 fontSize: 14,
                                 color: '#b3bdbd',
                              }}
                           >
                              Poll end:
                           </Box>
                           <Box
                              sx={{
                                 display: 'inline',
                                 mx: 0.5,
                                 fontSize: 14,
                                 color: '#ffffff',
                              }}
                           >
                              {moment(elec.DateFinish.toDate()).format('Do MMMM YYYY')}
                           </Box>
                        </Box> */}
                     </div>
                  )}
               </CardContent>
            </Box>
            <CardMedia
            // component="img"
            // sx={{ width: 151, borderRadius: 1, mr: 1 }}
            // image="https://images.unsplash.com/photo-1622993288089-18298ec89b78?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            // alt="Live from space album cover"
            >
               {candi.Name && (
                  <Badge
                     overlap="circular"
                     anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                     badgeContent={
                        countWordsUsingReplace(candi.Name) > 1 ? (
                           <SmallAvatar
                              {...stringAvatar50(candi.Name)}
                              alt={candi.Name}
                              src={candi.PartyFlag ? candi.PartyFlag : candi.AllottedSymbol}
                           />
                        ) : (
                           <SmallAvatar
                              {...stringAvatar50Single(candi.Name)}
                              src={candi.PartyFlag ? candi.PartyFlag : candi.AllottedSymbol}
                           />
                        )
                     }
                  >
                     {countWordsUsingReplace(candi.Name) > 1 ? (
                        <Avatar {...stringAvatar150(candi.Name)} alt="Travis Howard" src={candi.Image} />
                     ) : (
                        <Avatar {...stringAvatar150Single(candi.Name)} alt="Travis Howard" src={candi.Image} />
                     )}
                  </Badge>
               )}

               {/* <Avatar alt="An apple" sx={{ height: 150, width: 150 }} src={candi.Image} /> */}
            </CardMedia>
         </Card>
         <TabContext value={value}>
            <div>
               <Tabs
                  value={value}
                  onChange={handleChangeNavTab}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="full width tabs example"
                  centered
                  // variant="scrollable"
                  // scrollButtons="auto"
                  sx={{
                     marginBottom: 1,
                     py: 0.5,
                     minHeight: 25,
                     backgroundColor: '#e7e9bb',
                  }}
               >
                  <StyledTab1 label={`Basic info \n मूल सूचना`} {...a11yProps(0)} />
                  <StyledTab1 label={`Credentials \n प्रामाणिकताएँ`} {...a11yProps(1)} />
                  <StyledTab1 label={`News / Statements \n समाचार / वक्तव्य`} {...a11yProps(2)} />
                  <StyledTab1 label={`Works \n कार्य`} {...a11yProps(3)} />
                  <StyledTab1 label={`Interact \n संवाद करें`} {...a11yProps(4)} />
               </Tabs>
            </div>
            <Grid2 container sx={{ mt: 2 }} id="vBoxGridContainer">
               <Grid2 item xs={8} id="vboxGrid1">
                  <Box>
                     <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeNavTabIndex}
                     >
                        <TabPanel2 value={value} index={0} dir={theme.direction}>
                           {candi && (
                              <ElecCandiBasicInfo
                                 candi={candi}
                                 elec={elec}
                                 candisList={candisList}
                                 sortCandisList={sortCandisList}
                                 consti={consti}
                              />
                           )}
                        </TabPanel2>
                        <TabPanel2 value={value} index={1} dir={theme.direction}>
                           {candi && <ElecCandiCred candi={candi} elec={elec} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={2} dir={theme.direction}>
                           {candi && (
                              <ElecCandiNews
                                 candi={candi}
                                 elec={elec}
                                 weblinksListRoot={weblinksListRoot}
                                 weblinksList={weblinksList}
                                 handleWeblinksUpdate={handleWeblinksUpdate}
                              />
                           )}
                        </TabPanel2>
                        <TabPanel2 value={value} index={3} dir={theme.direction}>
                           {candi && <ElecCandiWorks candi={candi} elec={elec} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={4} dir={theme.direction}>
                           {candi && <ElecCandiInteract candi={candi} elec={elec} />}
                        </TabPanel2>
                     </SwipeableViews>
                  </Box>
               </Grid2>
               <Grid2 item xs={12} sm={12} md={4}></Grid2>
            </Grid2>
         </TabContext>
      </Box>
   )
}

export default ElecCandi
