import React, { useState } from 'react'
import styles from './MainHeader.module.css'
import logo from '../../Images/12345.png'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { signOut, sendPasswordResetEmail, signInWithEmailAndPassword } from 'firebase/auth'
import { auth, db } from '../../FirebaseConfig.js'
import { selectUserProfile, selectUsers, setUser, setUserProfile } from '../../redux/reducers/users-slice.js'
import {
   Alert,
   Avatar,
   Box,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogContentText,
   DialogTitle,
   IconButton,
   Link,
   Stack,
   TextField,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { collection, getDocs, query, where } from 'firebase/firestore'

function MainHeader() {
   const user = useSelector(selectUsers).currentUser
   const userProfile = useSelector(selectUserProfile)
   const dispatch = useDispatch()

   const [openLogIn, setOpenLogIn] = useState(false)
   const [errorLogIn, setErrorLogIn] = useState('')

   const openDialogLogIn = () => {
      setLoginCreds({ phone: '', password: '' })
      setOpenLogIn(true)
   }
   const closeDialogLogIn = () => {
      setLoginCreds({ phone: '', password: '' })
      setOpenLogIn(false)
   }
   const [loginCreds, setLoginCreds] = useState({ phone: '', password: '' })

   function handleLoginCreds(e) {
      setLoginCreds({ ...loginCreds, [e.target.name]: e.target.value })
   }

   function handlePhone4Login(e) {
      if (e.target.value !== '') {
         const newValue = parseInt(e.target.value, 10)

         newValue && !isNaN(newValue)
            ? setLoginCreds({ ...loginCreds, [e.target.name]: newValue })
            : setLoginCreds({ ...loginCreds, [e.target.name]: '' })
      } else {
         setLoginCreds({ ...loginCreds, [e.target.name]: '' })
      }
   }

   const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
         handleLogin(event)
         setLoginCreds({ phone: '', password: '' })
      }
   }

   const [phoneIsNotRegistered, setPhoneIsNotRegistered] = useState(false)

   async function handleLogin(e) {
      e.preventDefault()
      setErrorLogIn('')
      setPhoneIsNotRegistered(false)
      if (!isNaN(loginCreds.phone) && loginCreds.phone.toString().length === 10 && loginCreds.password.length > 7) {
         const querryUserProfile = query(collection(db, 'UserProfiles'), where('MobilePhone', '==', loginCreds.phone))
         const querySnapshot = await getDocs(querryUserProfile)

         let email = ''

         querySnapshot.forEach((docSnap) => {
            email = docSnap.data().Email
         })

         if (email !== '') {
            // try to log in the user
            signInWithEmailAndPassword(auth, email, loginCreds.password)
               .then((userCredential) => {
                  setLoginCreds({ phone: '', password: '' })
                  dispatch(
                     setUser({
                        id: userCredential.user.uid,
                        email: userCredential.user.email,
                        displayName: userCredential.user.displayName,
                     }),
                  )
                  setOpenLogIn(false)
                  window.location.reload()
               })
               .catch((err) => {
                  setLoginCreds({ phone: '', password: '' })
                  setErrorLogIn(err.message)
               })
         } else {
            // show that the phone is not registered
            setLoginCreds({ phone: '', password: '' })
            setPhoneIsNotRegistered(true)
         }
      }
   }

   function handlePasswordReset(e) {
      e.preventDefault()
      setLoginCreds({ phone: '', password: '' })
      const email = prompt('Please enter your email')
      if (email) {
         sendPasswordResetEmail(auth, email)
            .then(() => {
               setOpenLogIn(false)
               alert('Email sent! Check your inbox for password reset instructions.')
            })
            .catch((error) => {
               setLoginCreds({ phone: '', password: '' })
               setErrorLogIn(error.message)
            })
      } else {
         //
      }
   }

   // const [openSignUp, setOpenSignUp] = useState(false)
   // const openDialogSignUp = () => {
   //    setOpenSignUp(true)
   // }
   // const closeDialogSignUp = () => {
   //    setOpenSignUp(false)
   // }

   function handleSignOut() {
      if (confirm('Are you sure you want to log out?')) {
         signOut(auth)
            .then(() => {
               dispatch(setUser(null))
               dispatch(setUserProfile(null))
               window.location.reload()
            })
            .catch((error) => {
               // An error happened.
               console.log(error)
            })
      }
   }
   return (
      <div>
         <div className={styles.header}>
            <NavLink style={{ textDecoration: 'none' }} to="/">
               <div className={styles.header__logo}>
                  <img className={styles.logo} src={logo} />
                  {/* <h2 className={styles.header__logoTitle}>Track Democracy</h2> */}
                  <span className={styles.header__logoTitle}>Track Democracy</span>
               </div>
            </NavLink>

            <div className={styles.header__nav}>
               {/* <div className={styles.nav__item}>
                  <span className={styles.nav__itemLineOne}>Your</span>
                  <span className={styles.nav__itemLineTwo}>Shop</span>
               </div> */}
               <Box sx={{ textDecoration: 'none !important', display: 'flex', flexDirection: 'row' }}>
                  <Box>
                     <Avatar
                        alt={userProfile && userProfile.NameFirst ? userProfile.NameFirst : 'Guest'}
                        src={userProfile && userProfile.Image && userProfile.Image.Image}
                     />
                  </Box>
                  <div className={styles.nav__item}>
                     {userProfile ? (
                        <>
                           {/* <span className={styles.nav__itemLineTwo_Two}>Hello</span> */}
                           <span
                              className={styles.nav__itemLineTwo}
                           >{`${userProfile.NameFirst} ${userProfile.NameMiddle} ${userProfile.NameLast}`}</span>
                        </>
                     ) : (
                        <span className={styles.nav__itemLineTwo_Two}>Hello Guest</span>
                     )}
                     {/* {user.currentUser ? ( */}

                     {/* ) : (
                        <span className={styles.nav__itemLineOne}>Hello Guest</span>
                     )} */}
                     {/* <span className={styles.nav__itemLineOne}>Hello Guest</span> */}
                     {/* <span className={styles.nav__itemLineTwo}>Sign In</span> */}
                     {user ? (
                        <Box>
                           <Button
                              variant="contained"
                              sx={{ py: 0, px: 1, mt: 0.5, fontSize: 11 }}
                              onClick={() => handleSignOut('login')}
                           >
                              Log Out
                           </Button>
                           <Link href={`/my-dashboard`}>
                              <Button variant="contained" sx={{ py: 0, px: 1, mt: 0.5, ml: 0.5, fontSize: 11 }}>
                                 My dashboard
                              </Button>
                           </Link>
                        </Box>
                     ) : (
                        <Box>
                           <Button
                              variant="contained"
                              sx={{
                                 fontSize: 13,
                                 fontWeight: 800,
                                 textTransform: 'none',
                                 py: 0,
                                 px: 1,
                                 minWidth: 0,
                                 mr: 1,
                              }}
                              onClick={openDialogLogIn}
                           >
                              Sign In
                           </Button>
                           <Link href={`/my-dashboard`}>
                              <Button
                                 variant="contained"
                                 sx={{
                                    fontSize: 13,
                                    fontWeight: 800,
                                    textTransform: 'none',
                                    py: 0,
                                    px: 1,
                                    minWidth: 0,
                                 }}
                              >
                                 Sign Up
                              </Button>
                           </Link>
                        </Box>
                     )}
                     {/* )} */}
                  </div>
               </Box>

               {/* <div style={{ textDecoration: 'none' }}>
                  <div className={styles.nav__itemBasket}>
                     <CartButton />
                     <span className={(styles.nav__itemLineTwo, styles.nav__basketCount)}>xxx</span>
                  </div>
               </div> */}
            </div>
         </div>
         <div className={styles.subHeader}>
            <span className={styles.subHeaderText}>... participate in democratic governance</span>
         </div>
         <Box id="boxModals">
            <Dialog
               open={openLogIn}
               onClose={closeDialogLogIn}
               aria-labelledby="dialog-title"
               aria-describedby="dialog-content"
               fullWidth
            >
               <DialogTitle id="dialog-title">
                  Log in{' '}
                  <IconButton sx={{ float: 'right' }} onClick={closeDialogLogIn}>
                     <CloseIcon />
                  </IconButton>
               </DialogTitle>
               <DialogContent>
                  <DialogContentText id="dialog-content" sx={{ whiteSpace: 'pre-line' }}>
                     Your mobile number is your user id. {'\n'} आपका मोबाइल नंबर ही आपका यूजर आईडी है|
                  </DialogContentText>
                  <Stack spacing={2} margin={2}>
                     <TextField
                        onBlur={(event) => event}
                        onChange={(e) => {
                           handlePhone4Login(e)
                        }}
                        value={loginCreds.phone}
                        name="phone"
                        id="phone"
                        label="Mobile Phone Number"
                        variant="outlined"
                        margin="dense"
                        required
                        fullWidth
                        inputProps={{ maxLength: 10 }}
                        size="small"
                        helperText="10 digit phone number*."
                        sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
                     ></TextField>
                     <TextField
                        onBlur={(event) => event}
                        onChange={(e) => {
                           handleLoginCreds(e)
                        }}
                        value={loginCreds.password}
                        name="password"
                        id="password"
                        label="Password"
                        type="password"
                        variant="outlined"
                        margin="dense"
                        align="center"
                        fullWidth
                        required
                        sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
                        onKeyDown={handleKeyDown}
                     ></TextField>
                     {phoneIsNotRegistered && (
                        <Alert variant="outlined" severity="warning">
                           The entered phone number is not registered.
                        </Alert>
                     )}
                     {errorLogIn && (
                        <Alert variant="outlined" severity="error">
                           The entered password did not match.
                        </Alert>
                     )}
                  </Stack>
                  <Button
                     type="submit"
                     align="center"
                     variant="outlined"
                     color="error"
                     onClick={(e) => {
                        handlePasswordReset(e)
                     }}
                  >
                     Forgot Password?
                  </Button>
               </DialogContent>
               <DialogActions>
                  <Button
                     variant="contained"
                     color="success"
                     onClick={(e) => {
                        handleLogin(e)
                     }}
                     disabled={
                        isNaN(loginCreds.phone) ||
                        loginCreds.phone.toString().length < 10 ||
                        loginCreds.password.length < 8
                     }
                  >
                     Submit
                  </Button>
                  <Button variant="contained" color="error" onClick={closeDialogLogIn}>
                     Cancel
                  </Button>
               </DialogActions>
            </Dialog>

            {/* <Dialog
               open={openSignUp}
               onClose={closeDialogSignUp}
               aria-labelledby="dialog-title"
               aria-describedby="dialog-content"
               fullWidth
            >
               <DialogTitle id="dialog-title">
                  Log in{' '}
                  <IconButton sx={{ float: 'right' }}>
                     <CloseIcon />
                  </IconButton>
               </DialogTitle>
               <DialogContent>
                  <DialogContentText id="dialog-content" sx={{ whiteSpace: 'pre-line' }}>
                     Please register with your official name and birth date strictly.
                     {'\n'}
                     कृपया अपने आधिकारिक नाम और जन्मतिथि के साथ ही पंजीकरण करें।
                  </DialogContentText>
                  <Stack spacing={2} margin={2}>
                     <TextField
                        onBlur={(event) => event}
                        // onChange={(e) => {
                        //    handlePhone4Login(e)
                        // }}
                        // value={loginCreds.phone}
                        name="phone"
                        id="phone"
                        label="Mobile Phone Number"
                        variant="outlined"
                        margin="dense"
                        required
                        fullWidth
                        inputProps={{ maxLength: 10 }}
                        size="small"
                        // helperText="10 digit phone number*."
                        sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
                     ></TextField>
                     <TextField
                        onBlur={(event) => event}
                        // onChange={(event) => setPassword(event.target.value)}
                        // onChange={(e) => {
                        //    handleLoginCreds(e)
                        // }}
                        // value={loginCreds.password}
                        name="password"
                        id="password"
                        label="Password"
                        type="password"
                        variant="outlined"
                        margin="dense"
                        align="center"
                        fullWidth
                        required
                        sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
                        // onKeyDown={handleKeyDown}
                     ></TextField>
                  </Stack>
               </DialogContent>
               <DialogActions>
                  <Button variant="contained" color="success">
                     Submit
                  </Button>
                  <Button variant="contained" color="error" onClick={closeDialogSignUp}>
                     Cancel
                  </Button>
               </DialogActions>
            </Dialog> */}
         </Box>
      </div>
   )
}

export default MainHeader
