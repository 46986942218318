import React, { useEffect, useRef, useState } from 'react'

import PropTypes from 'prop-types'
import {
   Alert,
   Box,
   Button,
   FormControl,
   FormControlLabel,
   FormLabel,
   InputLabel,
   MenuItem,
   Radio,
   RadioGroup,
   Select,
   TextField,
} from '@mui/material'
import MyLoaderCircularGradient from '../../../Utils/Loaders/MyLoaderCircularGradient'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'

import myStyles from './ElecCandis.module.css'
import { sentenceCase } from '../../../Utils/Conversions/CaseConversion'

function AddCandis({
   partiesList,
   lastCandi,
   handleAddCandis,
   uploadingCandis,
   uploadedCandis,
   uploadingFailedCandis,
}) {
   /// /

   const [candiDetails, setCandiDetails] = useState({
      Name: '',
      NameOld: [],
      NameHindi: '',
      NameHindiOld: [],
      NameLocal: '',
      NameLocalOld: [],
      NameOthers: [],
      NameOthersHindi: [],
      NameOthersLocal: [],
      Age: '',
      AgeOld: [],
      DoB: '',
      DoBOld: [],

      Caste: '',
      CasteOld: [],
      Religion: '',
      ReligionOld: [],

      ReservationCat: '',
      ReservationCatOld: [],

      Gender: '',
      Address: {
         State_id: '',
         StateName: '',
         District_id: '',
         DistrictName: '',
         PinCode: '',
         Address: '',
         RecordedDate: '',
      },
      Party_id: '',
      PartyName: '',
      PartyNameHindi: '',
      PartyNameLocal: '',
      PartyAbbreviation: '',
      PartyFlag: '',
      PartySymbol: '',
      PartyOld: [],
      Alliances: [],
      AlliancesForMainDoc: [],
      AllottedSymbol: '',
      Announced: '',
      AnnouncedDate: '',
      AnnounceCancelled: '',
      AnnounceCancelledDate: '',
      Filed: '',
      FiledDate: '',
      Accepted: '',
      AcceptedDate: '',
      Rejected: '',
      RejectedDate: '',
      Refused: '',
      RefusedDate: '',
      Withdrawn: '',
      WithdrawnDate: '',
      Contesting: '',
      Image: '',
      ImagesOld: [],
      PhoneNumbers: [],
      Emails: [],
      WebLinks: [],
      Qualifications: [],
      PoliticalAffiliations: [],
      LegalCases: [],
      Occupations: [],
      NGO: [],
      Sports: [],
      Businesses: [],
      Relatives: [],
      Works: [],
      IssuesTakenUp: [],

      OnlineSupport: 0,
      OnlineSupportCancelled: 0,
      OnlineSwingSupport: 0,
      ExitSupport: 0,

      Votes: '',
      Winner: false,
      SeatType: '',
      User_Id: '',
      NetaProfile_id: '',
      Helpers: [],
   })

   useEffect(() => {
      // console.log('candidate details on change: ', candiDetails)
   }, [candiDetails])

   function handleCandiDetails(e) {
      setCandiDetails({ ...candiDetails, [e.target.name]: e.target.value })
   }

   function handleAge(e) {
      if (e.target.value !== '') {
         const newValue = parseInt(e.target.value, 10)

         newValue && !isNaN(newValue)
            ? setCandiDetails({ ...candiDetails, [e.target.name]: newValue })
            : setCandiDetails({ ...candiDetails, [e.target.name]: '' })
      } else {
         setCandiDetails({ ...candiDetails, [e.target.name]: '' })
      }
   }

   const [selectedPoliticalAffiliationType, setSelectedPoliticalAffiliationType] = useState('')
   const [enablePartiesList, setEnablePartiesList] = useState(false)
   const [selectedPartyId, setSelectedPartyId] = useState('')

   function handlePoliticalAffiliationType(e) {
      e.preventDefault()

      setSelectedPoliticalAffiliationType(e.target.value)

      setSelectedPartyId('')
      setEnablePartiesList(false)
      setCandiDetails({
         ...candiDetails,
         Party_id: '',
         PartyName: '',
         PartyNameHindi: '',
         PartyNameLocal: '',
         PartyAbbreviation: '',
         PartyFlag: '',
         PartySymbol: '',
      })

      if (e.target.value === 'PoliticalParty') {
         setEnablePartiesList(true)
      } else {
         setEnablePartiesList(false)
         setCandiDetails({
            ...candiDetails,
            PartyName: 'Independent',
            PartyNameHindi: 'निर्दलीय',
         })
      }
   }

   function handleChangeParty(e) {
      e.preventDefault()

      if (e.target.value !== '') {
         setSelectedPartyId(e.target.value)
         setEnablePartiesList(true)

         const party = partiesList.find((item) => item.Party_id === e.target.value)
         // console.log('party flag', party.Flag)
         // console.log('party name', party.Name)
         setCandiDetails({
            ...candiDetails,
            Party_id: party.Party_id,
            PartyName: party.Name,
            PartyNameHindi: party.NameHindi,
            PartyNameLocal: party.NameLocal,
            PartyAbbreviation: party.Abbreviation,
            PartyFlag: party.Flag,
            PartySymbol: party.Symbol,
         })
      } else {
         setSelectedPartyId('')
         setEnablePartiesList(false)
         setCandiDetails({
            ...candiDetails,
            Party_id: '',
            PartyName: '',
            PartyNameHindi: '',
            PartyNameLocal: '',
            PartyAbbreviation: '',
            PartyFlag: '',
            PartySymbol: '',
         })
      }
   }

   // const [dateOfBirth, setDateOfBirth] = useState('')

   // useEffect(() => {
   //    if (dateOfBirth) {
   //       setCandiDetails({ ...candiDetails, DateOfBirth: dateOfBirth.$d })
   //    }
   // }, [dateOfBirth])

   // const [selectedFiledOrAnnounced, setSelectedFiledOrAnnounced] = useState('')
   // const [dateOfFiledOrAnnounced, setDateOfFiledOrAnnounced] = useState('')

   // function handleFiledOrAnnounced(e) {
   //    e.preventDefault()
   //    console.log('filed', e.target.value)
   //    setSelectedFiledOrAnnounced(e.target.value)
   // }

   const [address, setAddress] = useState('')
   const handleAddress = (e) => {
      if (e.target.value !== '') {
         setAddress(sentenceCase(e.target.value))
         setCandiDetails({
            ...candiDetails,
            Address: [
               {
                  State_id: '',
                  StateName: '',
                  District_id: '',
                  DistrictName: '',
                  PinCode: '',
                  Address: sentenceCase(e.target.value).trim(),
                  RecordedDate: '',
               },
            ],
            AddressOld: [
               {
                  State_id: '',
                  StateName: '',
                  District_id: '',
                  DistrictName: '',
                  PinCode: '',
                  Address: sentenceCase(e.target.value).trim(),
                  RecordedDate: '',
               },
            ],
         })
      } else {
         setAddress('')
         setCandiDetails({
            ...candiDetails,
            Address: [],
            AddressOld: [],
         })
      }
   }

   const [date, setDate] = useState('')
   const [candiStatus, setCandiStatus] = useState('')

   function handleCandiStatus(e) {
      e.preventDefault()

      setCandiStatus(e.target.value)
      switch (e.target.value) {
         case 'Announced':
            setCandiDetails({
               ...candiDetails,
               Announced: 'Announced',
            })
            return
         case 'AnnounceCancelled':
            setCandiDetails({
               ...candiDetails,
               Announced: 'Announced',
               AnnounceCancelled: 'AnnounceCancelled',
            })
            return
         case 'Filed':
            setCandiDetails({
               ...candiDetails,
               Filed: 'Filed',
            })
            return
         case 'Accepted':
            setCandiDetails({
               ...candiDetails,
               Filed: 'Filed',
               Accepted: 'Accepted',
            })
            return
         case 'Rejected':
            setCandiDetails({
               ...candiDetails,
               Filed: 'Filed',
               Rejected: 'Rejected',
            })
            return
         case 'Refused':
            setCandiDetails({
               ...candiDetails,
               Announced: 'Announced',
               Refused: 'Refused',
            })
            return
         case 'Withdrawn':
            setCandiDetails({
               ...candiDetails,
               Filed: 'Filed',
               Accepted: 'Accepted',
               Withdrawn: 'Withdrawn',
            })
      }
   }

   useEffect(() => {
      if (date !== '') {
         if (candiDetails.Announced === 'Announced') {
            setCandiDetails({
               ...candiDetails,
               AnnouncedDate: date.$d,
            })
         } else if (candiDetails.AnnounceCancelled === 'AnnounceCancelled') {
            setCandiDetails({
               ...candiDetails,
               AnnounceCancelledDate: date.$d,
            })
         } else if (candiDetails.Filed === 'Filed') {
            setCandiDetails({
               ...candiDetails,
               FiledDate: date.$d,
            })
         } else if (candiDetails.Accepted === 'Accepted') {
            setCandiDetails({
               ...candiDetails,
               AcceptedDate: date.$d,
            })
         } else if (candiDetails.Rejected === 'Rejected') {
            setCandiDetails({
               ...candiDetails,
               RejectedDate: date.$d,
            })
         } else if (candiDetails.Refused === 'Refused') {
            setCandiDetails({
               ...candiDetails,
               RefusedDate: date.$d,
            })
         } else if (candiDetails.Withdrawn === 'Withdrawn') {
            setCandiDetails({
               ...candiDetails,
               WithdrawnDate: date.$d,
            })
         }
      } else {
         setCandiDetails({
            ...candiDetails,
            AnnouncedDate: '',
            AnnounceCancelledDate: '',
            FiledDate: '',
            AcceptedDate: '',
            RejectedDate: '',
            RefusedDate: '',
            WithdrawnDate: '',
         })
      }
   }, [date])

   function handleVotes(e) {
      if (e.target.value !== '') {
         const newValue = parseInt(e.target.value, 10)

         newValue && !isNaN(newValue)
            ? setCandiDetails({ ...candiDetails, [e.target.name]: newValue })
            : setCandiDetails({ ...candiDetails, [e.target.name]: '' })
      } else {
         setCandiDetails({ ...candiDetails, [e.target.name]: '' })
      }
   }

   const handleSubmitCandi = async (e) => {
      e.preventDefault()
      if (
         candiDetails.Name.length > 3 &&
         // !isNaN(parseInt(candiDetails.Age, 10)) &&
         (candiDetails.Age === '' || parseInt(candiDetails.Age, 10) > 17) &&
         candiStatus !== ''
      ) {
         // console.log('candiDetails before submit: ', candiDetails)

         // if (address !== '') {
         //    candiDetails.Address = {
         //       Address: toTitleCase(address.trim()),
         //       PostalCode: '',
         //       District: '',
         //       State: '',
         //       CrBy: auth.currentUser.uid,
         //       CrDt: new Date(),
         //       CrByIP: ip.data.ip,
         //       Main: true,
         //    }
         // }

         handleAddCandis(candiDetails)

         setCandiDetails({
            Name: '',
            NameOld: [],
            NameHindi: '',
            NameHindiOld: [],
            NameLocal: '',
            NameLocalOld: [],
            NameOthers: [],
            NameOthersHindi: [],
            NameOthersLocal: [],
            Age: '',
            AgeOld: [],
            DoB: '',
            DoBOld: [],

            Caste: '',
            CasteOld: [],
            Religion: '',
            ReligionOld: [],

            ReservationCat: '',
            ReservationCatOld: [],

            Gender: '',
            Address: {
               State_id: '',
               StateName: '',
               District_id: '',
               DistrictName: '',
               PinCode: '',
               Address: '',
               RecordedDate: '',
            },
            Party_id: '',
            PartyName: '',
            PartyNameHindi: '',
            PartyNameLocal: '',
            PartyAbbreviation: '',
            PartyFlag: '',
            PartySymbol: '',
            PartyOld: [],
            Alliances: [],
            AlliancesForMainDoc: [],
            AllottedSymbol: '',
            Announced: '',
            AnnouncedDate: '',
            AnnounceCancelled: '',
            AnnounceCancelledDate: '',
            Filed: '',
            FiledDate: '',
            Accepted: '',
            AcceptedDate: '',
            Rejected: '',
            RejectedDate: '',
            Refused: '',
            RefusedDate: '',
            Withdrawn: '',
            WithdrawnDate: '',
            Contesting: '',
            Image: '',
            ImagesOld: [],
            PhoneNumbers: [],
            Emails: [],
            WebLinks: [],
            Qualifications: [],
            PoliticalAffiliations: [],
            LegalCases: [],
            Occupations: [],
            NGO: [],
            Sports: [],
            Businesses: [],
            Relatives: [],
            Works: [],
            IssuesTakenUp: [],

            OnlineSupports: 0,
            OnlineSupportsCancelled: 0,
            OnlineSwingSupport: 0,
            ExitSupport: 0,

            Votes: '',
            Winner: false,
            SeatType: '',
            User_Id: '',
            NetaProfile_id: '',
            Helpers: [],
         })
         setCandiStatus('')
         setDate('')
         setSelectedPoliticalAffiliationType('')
         setSelectedPartyId('')
         setEnablePartiesList(false)
         setAddress('')

         setSelectedImage(null)
         setImageUrl(null)
         refImageInput.current.value = ''
         // setSelectedFiledOrAnnounced('')
      }
   }

   const [selectedImage, setSelectedImage] = useState(null)
   const [imageUrl, setImageUrl] = useState(null)
   const refImageInput = useRef()

   function handleChangeImageInput(e) {
      if (e.target.files[0]) {
         setSelectedImage(e.target.files[0])
         // setImageUrl(URL.createObjectURL(e.target.files[0]))
      } else {
         setSelectedImage(null)
         setImageUrl(null)
      }
   }

   useEffect(() => {
      if (selectedImage) {
         setImageUrl(URL.createObjectURL(selectedImage))
         setCandiDetails({
            ...candiDetails,
            Image: selectedImage,
         })
      }
   }, [selectedImage])

   function RemoveImage() {
      setSelectedImage(null)
      setImageUrl(null)
      refImageInput.current.value = ''
      setCandiDetails({
         ...candiDetails,
         Image: '',
      })
   }

   return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
         <Box
            sx={{
               //    mb: 1,
               mx: 1,
               pb: 1,
               borderRadius: 2,
               display: 'flex',
               flexDirection: 'column',
               '& .MuiButton-root': { width: '15ch', mt: 1 },
               '& .MuiTextField-root': { width: '50ch', mb: 0.5, mt: 0 },
               // '& .MuiInputBase-root': { mb: 2 },
               '& .MuiFormControl-root': { width: '50ch', mb: 0.5 },
               justifyContent: 'center',
               alignItems: 'center',
            }}
         >
            <div>
               <TextField
                  onBlur={(event) => event}
                  onChange={(e) => {
                     handleCandiDetails(e)
                  }}
                  value={candiDetails.Name}
                  name="Name"
                  id="name"
                  label="Name"
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  size="small"
                  autoComplete="off"
                  autoFocus={true}
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               ></TextField>
            </div>
            <div>
               <TextField
                  onBlur={(event) => event}
                  onChange={(e) => {
                     handleCandiDetails(e)
                  }}
                  value={candiDetails.NameHindi}
                  name="NameHindi"
                  id="nameHindi"
                  label="Name in Hindi"
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  size="small"
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               ></TextField>
            </div>
            <div>
               <TextField
                  onBlur={(event) => event}
                  onChange={(e) => {
                     handleCandiDetails(e)
                  }}
                  value={candiDetails.NameLocal}
                  name="NameLocal"
                  id="nameLocal"
                  label="Name in Local Language"
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  size="small"
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               ></TextField>
            </div>
            <Box sx={{ display: 'flex', flexDirection: 'column', border: 1, borderRadius: 1, mb: 1 }}>
               <FormControl
                  sx={{
                     py: 0,
                     px: 1,
                  }}
               >
                  <FormLabel id="demo-controlled-radio-buttons-group">Political Affiliation Type:</FormLabel>
                  <RadioGroup
                     row
                     aria-labelledby="demo-controlled-radio-buttons-group"
                     name="PoliticalAffiliationType"
                     value={selectedPoliticalAffiliationType}
                     // style={{ display: 'flex', gap: '2rem' }}
                     onChange={(e) => {
                        handlePoliticalAffiliationType(e)
                     }}
                     sx={{
                        backgroundColor: '#ffffff',
                        borderRadius: 1,
                        py: 0,
                        '& .MuiSvgIcon-root': {
                           fontSize: 15,
                        },
                        '& .MuiButtonBase-root': {
                           py: 0,
                        },
                        '& MuiFormControlLabel-label': {
                           fontSize: 13,
                           lineHeight: 16,
                           py: 0,
                        },
                     }}
                  >
                     <FormControlLabel
                        value="Independent"
                        control={<Radio size="small" />}
                        label="Independent"
                        sx={{ py: 0, fontSize: 15 }}
                     />
                     <FormControlLabel
                        value="PoliticalParty"
                        control={<Radio size="small" />}
                        label="Political Party"
                        sx={{ py: 0, fontSize: 15 }}
                     />
                  </RadioGroup>
               </FormControl>
               <FormControl sx={{ m: 1 }} size="small" disabled={!enablePartiesList}>
                  <InputLabel>Party</InputLabel>
                  <Select
                     value={selectedPartyId}
                     label="Party"
                     onChange={(e) => {
                        handleChangeParty(e)
                     }}
                     sx={{ backgroundColor: '#ffffff' }}
                  >
                     <MenuItem value="">
                        <em>None</em>
                     </MenuItem>
                     {partiesList &&
                        partiesList.map((item) => (
                           <MenuItem key={item.Party_id} value={item.Party_id} sx={{ fontSize: 13 }}>
                              {item.Name} - <strong> {item.Abbreviation}</strong>
                           </MenuItem>
                        ))}
                  </Select>
               </FormControl>
            </Box>
            <div>
               <TextField
                  id="address"
                  label="Address"
                  multiline
                  value={address}
                  rows={2}
                  onChange={(e) => {
                     handleAddress(e)
                  }}
               />
            </div>
            <div>
               <FormControl
                  size="small"
                  sx={{
                     py: 0,
                     px: 1,
                     border: 1,
                     borderRadius: 1,
                  }}
               >
                  <FormLabel id="demo-controlled-radio-buttons-group">Gender:</FormLabel>
                  <RadioGroup
                     row
                     aria-labelledby="demo-controlled-radio-buttons-group"
                     name="Gender"
                     value={candiDetails.Gender}
                     // style={{ display: 'flex', gap: '2rem' }}
                     onChange={(e) => {
                        handleCandiDetails(e)
                     }}
                     sx={{
                        backgroundColor: '#ffffff',
                        borderRadius: 1,
                        py: 0,
                        '& .MuiSvgIcon-root': {
                           fontSize: 15,
                        },
                        '& .MuiButtonBase-root': {
                           py: 0,
                        },
                        '& MuiFormControlLabel-label': {
                           fontSize: 13,
                           lineHeight: 16,
                           py: 0,
                        },
                     }}
                  >
                     <FormControlLabel
                        value="Male"
                        control={<Radio size="small" />}
                        label="Male / पुरुष"
                        sx={{ py: 0, fontSize: 15 }}
                     />
                     <FormControlLabel
                        value="Female"
                        control={<Radio size="small" />}
                        label="Female / स्त्री"
                        sx={{ py: 0, fontSize: 15 }}
                     />
                     <FormControlLabel
                        value="Transgender"
                        control={<Radio size="small" />}
                        label="Third Gender / तृतीय लिंग"
                        sx={{ py: 0, fontSize: 15 }}
                     />
                  </RadioGroup>
               </FormControl>
            </div>
            <div>
               <TextField
                  onBlur={(event) => event}
                  onChange={(e) => {
                     handleAge(e)
                  }}
                  value={candiDetails.Age}
                  name="Age"
                  id="age"
                  label="Age"
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  size="small"
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               ></TextField>
            </div>
            <div>
               <FormControl
                  size="small"
                  sx={{
                     py: 0,
                     px: 1,
                     border: 1,
                     borderRadius: 1,
                  }}
               >
                  <FormLabel id="demo-controlled-radio-buttons-group">Status:</FormLabel>
                  <RadioGroup
                     row
                     aria-labelledby="demo-controlled-radio-buttons-group"
                     name="Status"
                     value={candiStatus}
                     // style={{ display: 'flex', gap: '2rem' }}
                     onChange={(e) => {
                        handleCandiStatus(e)
                     }}
                     sx={{
                        backgroundColor: '#ffffff',
                        borderRadius: 1,
                        py: 0,
                        '& .MuiSvgIcon-root': {
                           fontSize: 15,
                        },
                        '& .MuiButtonBase-root': {
                           py: 0,
                        },
                        '& MuiFormControlLabel-label': {
                           fontSize: 13,
                           lineHeight: 16,
                           py: 0,
                        },
                     }}
                  >
                     <FormControlLabel
                        value="Announced"
                        control={<Radio size="small" />}
                        label="Announced"
                        sx={{ py: 0, fontSize: 15 }}
                     />
                     <FormControlLabel
                        value="AnnounceCancelled"
                        control={<Radio size="small" />}
                        label="Announcement Cancelled"
                        sx={{ py: 0, fontSize: 15 }}
                     />
                     <FormControlLabel
                        value="Filed"
                        control={<Radio size="small" />}
                        label="Filed"
                        sx={{ py: 0, fontSize: 15 }}
                     />
                     <FormControlLabel
                        value="Accepted"
                        control={<Radio size="small" />}
                        label="Accepted"
                        sx={{ py: 0, fontSize: 15 }}
                     />
                     <FormControlLabel
                        value="Rejected"
                        control={<Radio size="small" />}
                        label="Rejected"
                        sx={{ py: 0, fontSize: 15 }}
                     />
                     <FormControlLabel
                        value="Refused"
                        control={<Radio size="small" />}
                        label="Refused by Candidate"
                        sx={{ py: 0, fontSize: 15 }}
                     />
                     <FormControlLabel
                        value="Withdrawn"
                        control={<Radio size="small" />}
                        label="Withdrawn"
                        sx={{ py: 0, fontSize: 15 }}
                     />
                  </RadioGroup>
               </FormControl>
            </div>
            <Box>
               <DatePicker
                  label="Date"
                  name="Date"
                  value={date != null ? dayjs(date) : null}
                  format="DD-MMM-YYYY"
                  onChange={(newValue) => setDate(newValue)}
                  views={['year', 'month', 'day']}
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               />
            </Box>

            {/* <div>
               <FormControl
                  sx={{
                     py: 0,
                     px: 1,
                     border: 1,
                     borderRadius: 1,
                  }}
               >
                  <FormLabel id="demo-controlled-radio-buttons-group">Status:</FormLabel>
                  <RadioGroup
                     row
                     aria-labelledby="demo-controlled-radio-buttons-group"
                     name="FiledOrAnnounced"
                     value={selectedFiledOrAnnounced}
                     // style={{ display: 'flex', gap: '2rem' }}
                     onChange={(e) => {
                        handleFiledOrAnnounced(e)
                     }}
                     sx={{
                        backgroundColor: '#ffffff',
                        borderRadius: 1,
                        py: 0,
                        '& .MuiSvgIcon-root': {
                           fontSize: 15,
                        },
                        '& .MuiButtonBase-root': {
                           py: 0,
                        },
                        '& MuiFormControlLabel-label': {
                           fontSize: 13,
                           lineHeight: 16,
                           py: 0,
                        },
                     }}
                  >
                     <FormControlLabel
                        value="Filed"
                        control={<Radio size="small" />}
                        label="Filed"
                        sx={{ py: 0, fontSize: 15 }}
                     />
                     <FormControlLabel
                        value="Announced"
                        control={<Radio size="small" />}
                        label="Announced by party"
                        sx={{ py: 0, fontSize: 15 }}
                     />
                  </RadioGroup>
               </FormControl>
            </div>
            <div>
               <DatePicker
                  label="Date Of filing / announcement"
                  name="DateOfFiledOrAnnounced"
                  value={candiDetails.FiledDate != null ? dayjs(candiDetails.FiledDate) : null}
                  format="DD-MMM-YYYY"
                  onChange={(newValue) => setDateOfFiledOrAnnounced(newValue)}
                  views={['year', 'month', 'day']}
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               />
            </div> */}
            <div>
               <TextField
                  onBlur={(event) => event}
                  onChange={(e) => {
                     handleVotes(e)
                  }}
                  value={candiDetails.Votes}
                  name="Votes"
                  id="votes"
                  label="Votes"
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  size="small"
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               ></TextField>
            </div>
            <div className={myStyles.flexRow}>
               <input
                  type="file"
                  id="select-image"
                  onChange={handleChangeImageInput}
                  accept="image/*"
                  ref={refImageInput}
               />
               {imageUrl && selectedImage && (
                  <Button variant="outlined" sx={{ textTransform: 'none', py: 0, minWidth: 0 }} onClick={RemoveImage}>
                     {' '}
                     Clear image
                  </Button>
               )}
            </div>
            {imageUrl && selectedImage && <img src={imageUrl} className={myStyles.imgX} />}

            <Box>
               <Button
                  type="submit"
                  align="center"
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={(e) => {
                     handleSubmitCandi(e)
                  }}
                  disabled={
                     candiDetails.Name.length < 4 ||
                     // isNaN(parseInt(candiDetails.Age, 10)) ||
                     parseInt(candiDetails.Age, 10) < 18 ||
                     candiStatus === ''
                  }
               >
                  Submit
               </Button>
            </Box>
            <Box>
               {uploadingCandis && (
                  <MyLoaderCircularGradient title={'Listing the candidate ...'}></MyLoaderCircularGradient>
               )}
               {uploadedCandis && (
                  <Alert variant="outlined" severity="success">
                     <strong>{lastCandi}</strong> listed successfully.
                  </Alert>
               )}
               {uploadingFailedCandis && (
                  <Alert variant="outlined" severity="error">
                     Error occured! <strong>{lastCandi}</strong> could not be listed.
                  </Alert>
               )}
            </Box>
         </Box>
      </LocalizationProvider>
   )
}

AddCandis.propTypes = {
   partiesList: PropTypes.object.isRequired,
   lastCandi: PropTypes.string.isRequired,
   handleAddCandis: PropTypes.func.isRequired,
   uploadingCandis: PropTypes.bool.isRequired,
   uploadedCandis: PropTypes.bool.isRequired,
   uploadingFailedCandis: PropTypes.bool.isRequired,
}

export default AddCandis
