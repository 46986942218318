import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
   Box,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogContentText,
   DialogTitle,
   IconButton,
   Link,
   Modal,
   Slide,
   Table,
   TableCell,
   TableRow,
   Typography,
} from '@mui/material'

import myStyles from './ElecCandiBasicInfo.module.css'
import {
   arrayRemove,
   arrayUnion,
   collection,
   doc,
   getDoc,
   getDocs,
   increment,
   query,
   setDoc,
   updateDoc,
   where,
} from 'firebase/firestore'
import { auth, db, dbStorage } from '../../../FirebaseConfig'
import { v4 } from 'uuid'
import moment from 'moment'

// import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import XIcon from '@mui/icons-material/X'
import YouTubeIcon from '@mui/icons-material/YouTube'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt'
import HowToVoteIcon from '@mui/icons-material/HowToVote'

import AddWeblink from '../../../Components/Edits/AddWeblink'
import AddImageOnly from '../../../Components/Edits/AddImageOnly'
import AddAddress from '../../../Components/Edits/AddAddress'
import AddText from '../../../Components/Edits/AddText'
import AddEmail from '../../../Components/Edits/AddEmail'

import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { useDispatch, useSelector } from 'react-redux'
import { selectUserProfile, selectUsers, setUserProfile } from '../../../redux/reducers/users-slice'
import { useNavigate } from 'react-router-dom'
import AddPhone from '../../../Components/Edits/AddPhone'
import AddDate from '../../../Components/Edits/AddDate'
import AddNumber from '../../../Components/Edits/AddNumber'
import { sentenceCase } from '../../../Utils/Conversions/CaseConversion'
import { styleModalBox, styleModalTypography } from '../../../Utils/MUITheme/MUITheme'
// import { Helmet } from 'react-helmet'
import winner from '../../../Images/winner.png'
import AddParty from '../../../Components/Edits/AddParty'
import AddTextMultiline from '../../../Components/Edits/AddTextMultiline'
import AddCandiOnlineSupport from '../../Election/ElecCandis/AddCandiOnlineSupport'
import ChangePageTitle from '../../../Components/PageTitles/ChangePageTitle '

const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction="up" ref={ref} {...props} />
})

function ElecCandiBasicInfo({ consti, candi, elec, candisList, sortCandisList }) {
   // console.log('prelims: Candi: ', candi, 'Elec: ', elec)
   const user = useSelector(selectUsers)
   const ip = user.ip
   const candidate = candi
   const election = elec

   const dispatch = useDispatch()

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')
   const [record, setRecord] = useState('')
   const [phaseDate, setPhaseDate] = useState('')
   const [nameOfUser, setNameOfUser] = useState('')
   const [attachedConstiName, setAttachedConstiName] = useState('')

   const userProfile = useSelector(selectUserProfile)
   // console.log('user profile: ', userProfile)

   useEffect(() => {
      if (userProfile && userProfile !== '') {
         // console.log('userProfile use state: ', userProfile, 'strata', consti.Strata)
         const name = userProfile.NameFirst + ' ' + userProfile.NameMiddle + ' ' + userProfile.NameLast
         setNameOfUser(name)
         console.log('Strata: ', consti.Strata)
      }
   }, [userProfile])

   useEffect(() => {
      // console.log('states - first use effect entered')

      if (user.currentUser !== null) {
         // console.log('states - found user state not-null')
         setThisUser(user.currentUser.id)
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
         }
      } else {
         // console.log('states - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         //
         fetchRecordFromElection()

         setFirstLoadStatus('success')

         // console.log('ElecCandis - first use effect blank')
      }
   }, [])

   const fetchRecordFromElection = async () => {
      try {
         // get the candidate from election's core list as record
         const docRefElecSubColCandiCoreList = doc(
            db,
            'Elections',
            candidate.Election_id,
            'CoreLists',
            candidate.CoreListId,
         )
         const docSnap = await getDoc(docRefElecSubColCandiCoreList)

         if (docSnap.exists()) {
            const list = docSnap.data().Candidates
            const thisCandidate = list.find((item) => item.Candidate_id === candidate.id)
            setRecord(thisCandidate)
         } else {
            navigate('/', { replace: true })
         }
      } catch (err) {
         console.log('error', err)
      }
   }

   useEffect(() => {
      if (record !== '') {
         switch (consti.Strata) {
            case 'Central':
               if (userProfile && userProfile !== '') {
                  getLastSupportCenter()
                  if (userProfile.AtCentralConstituency && userProfile.AtCentralConstituency.ConstituencyNameLocal) {
                     setAttachedConstiName(
                        userProfile.AtCentralConstituency.ConstituencyName +
                           ' (' +
                           userProfile.AtCentralConstituency.ConstituencyNumber +
                           ') ' +
                           userProfile.AtCentralConstituency.ConstituencyNameLocal,
                     )
                  } else {
                     setAttachedConstiName(
                        userProfile.AtCentralConstituency.ConstituencyName +
                           ' (' +
                           userProfile.AtCentralConstituency.ConstituencyNumber +
                           ') ' +
                           userProfile.AtCentralConstituency.ConstituencyNameHindi,
                     )
                  }
               }
               break
            case 'State':
               if (userProfile && userProfile !== '') {
                  getLastSupportState()
                  if (userProfile.AtStateConstituency && userProfile.AtStateConstituency.ConstituencyNameLocal) {
                     setAttachedConstiName(
                        userProfile.AtStateConstituency.ConstituencyName +
                           ' (' +
                           userProfile.AtStateConstituency.ConstituencyNumber +
                           ') ' +
                           userProfile.AtStateConstituency.ConstituencyNameLocal,
                     )
                  } else {
                     setAttachedConstiName(
                        userProfile.AtStateConstituency.ConstituencyName +
                           ' (' +
                           userProfile.AtStateConstituency.ConstituencyNumber +
                           ') ' +
                           userProfile.AtStateConstituency.ConstituencyNameHindi,
                     )
                  }
               }
               break
            case 'Division':
               //
               break
            case 'District':
               //
               break
            case 'SubDistrict':
               //
               break
            case 'Block':
               //
               break
            case 'Municipality':
               //
               break
            case 'Village':
               //
               break
         }
      }
   }, [record])

   const [sourceAllottedSymbol, setSourceAllottedSymbol] = useState(candidate.AllottedSymbol)
   const [sourceImage, setSourceImage] = useState(candidate.Image)

   // related to Name modal

   const [openModalName, setOpenModalName] = useState(false)

   const handleOpenModalName = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalName(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalName = () => setOpenModalName(false)

   const handleAddName = async (textDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            //
            const refCandi = doc(db, 'ElectionCandidates', candidate.id)
            const refElecCandi = doc(db, 'Elections', candidate.Election_id, 'CoreLists', candidate.CoreListId)

            await updateDoc(refCandi, {
               Name: textDetails.Text,
               NameOld: arrayUnion({
                  Name: textDetails.Text,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               }),
            })

            const recordNew = { ...record, Name: textDetails.Text }

            await updateDoc(refElecCandi, {
               Candidates: arrayRemove(record),
            })

            await updateDoc(refElecCandi, {
               Candidates: arrayUnion(recordNew),
            })

            // update the name display
            candidate.Name = textDetails.Text
            setRecord({ ...record, Name: textDetails.Text })
            // close the modal
            setOpenModalName(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to NameHindi modal

   const [openModalNameHindi, setOpenModalNameHindi] = useState(false)

   const handleOpenModalNameHindi = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalNameHindi(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalNameHindi = () => setOpenModalNameHindi(false)

   const handleAddNameHindi = async (textDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            //
            const refCandi = doc(db, 'ElectionCandidates', candidate.id)
            const refElecCandi = doc(db, 'Elections', candidate.Election_id, 'CoreLists', candidate.CoreListId)

            await updateDoc(refCandi, {
               NameHindi: textDetails.Text,
               NameHindiOld: arrayUnion({
                  NameHindi: textDetails.Text,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               }),
            })

            const recordNew = { ...record, NameHindi: textDetails.Text }

            await updateDoc(refElecCandi, {
               Candidates: arrayRemove(record),
            })

            await updateDoc(refElecCandi, {
               Candidates: arrayUnion(recordNew),
            })

            // update the name display
            candidate.NameHindi = textDetails.Text
            setRecord({ ...record, NameHindi: textDetails.Text })
            // close the modal
            setOpenModalNameHindi(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to NameLocal modal

   const [openModalNameLocal, setOpenModalNameLocal] = useState(false)

   const handleOpenModalNameLocal = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalNameLocal(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalNameLocal = () => setOpenModalNameLocal(false)

   const handleAddNameLocal = async (textDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            //
            const refCandi = doc(db, 'ElectionCandidates', candidate.id)
            const refElecCandi = doc(db, 'Elections', candidate.Election_id, 'CoreLists', candidate.CoreListId)

            await updateDoc(refCandi, {
               NameLocal: textDetails.Text,
               NameLocalOld: arrayUnion({
                  NameLocal: textDetails.Text,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               }),
            })

            const recordNew = { ...record, NameLocal: textDetails.Text }
            // console.log('record', record)

            await updateDoc(refElecCandi, {
               Candidates: arrayRemove(record),
            })

            await updateDoc(refElecCandi, {
               Candidates: arrayUnion(recordNew),
            })

            // update the name display
            candidate.NameLocal = textDetails.Text
            setRecord({ ...record, NameLocal: textDetails.Text })
            // close the modal
            setOpenModalNameLocal(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to DoB modal

   const [openModalDoB, setOpenModalDoB] = useState(false)

   const handleOpenModalDoB = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalDoB(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalDoB = () => setOpenModalDoB(false)

   const handleAddDoB = async (dateDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (dateDetails.Date !== '') {
               const dobForOld = {
                  DoB: dateDetails.Date,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               }
               //
               const refCandi = doc(db, 'ElectionCandidates', candidate.id)
               const refElecCandi = doc(db, 'Elections', candidate.Election_id, 'CoreLists', candidate.CoreListId)

               await updateDoc(refCandi, {
                  DoB: dateDetails.Date,
                  DoBOld: arrayUnion(dobForOld),
               })

               const recordNew = { ...record, DoB: dateDetails.Date }

               await updateDoc(refElecCandi, {
                  Candidates: arrayRemove(record),
               })

               await updateDoc(refElecCandi, {
                  Candidates: arrayUnion(recordNew),
               })

               // update the name display
               candidate.DoB = dateDetails.Date
               setRecord({ ...record, Dob: dateDetails.Date })

               candidate.DoBOld.push(dobForOld)
               setOpenModalDoB(false)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Age modal

   const [openModalAge, setOpenModalAge] = useState(false)

   const handleOpenModalAge = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalAge(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalAge = () => setOpenModalAge(false)

   const handleAddAge = async (numberDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (numberDetails.Number !== '') {
               const ageForOld = {
                  Age: numberDetails.Number,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               }
               //
               const refCandi = doc(db, 'ElectionCandidates', candidate.id)
               const refElecCandi = doc(db, 'Elections', candidate.Election_id, 'CoreLists', candidate.CoreListId)

               await updateDoc(refCandi, {
                  Age: numberDetails.Number,
                  AgeOld: arrayUnion(ageForOld),
               })

               const recordNew = { ...record, Age: numberDetails.Number }

               await updateDoc(refElecCandi, {
                  Candidates: arrayRemove(record),
               })

               await updateDoc(refElecCandi, {
                  Candidates: arrayUnion(recordNew),
               })

               // update the age display
               candidate.Age = numberDetails.Number
               setRecord({ ...record, Age: numberDetails.Number })

               candidate.AgeOld.push(ageForOld)
               setOpenModalAge(false)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Address modal

   const [openModalAddress, setOpenModalAddress] = useState(false)

   const handleOpenModalAddress = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalAddress(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalAddress = () => setOpenModalAddress(false)

   const handleAddAddress = async (addressDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (
               addressDetails.State_id.length >= 3 &&
               // addressDetails.District_id.length >= 3 &&
               addressDetails.Address.length >= 10 &&
               addressDetails.ValueRadio !== ''
            ) {
               ///
               addressDetails.CrBy = auth.currentUser.uid
               addressDetails.CrDt = new Date()
               addressDetails.CrIP = ip.data.ip
               // console.log('stage 4 submit')

               addressDetails.Address = sentenceCase(addressDetails.Address)
               // console.log('stage 5 submit')
               //
               const refCandi = doc(db, 'ElectionCandidates', candidate.id)

               if (addressDetails.ValueRadio === 'Current address') {
                  ///
                  await updateDoc(refCandi, {
                     Address: arrayUnion(addressDetails),
                     AddressOld: arrayUnion(addressDetails),
                  })
                  // console.log('stage 6 submit')
                  // update the address display
                  candidate.Address.push(addressDetails)
                  // console.log('stage 7 submit')
               } else {
                  await updateDoc(refCandi, {
                     AddressOld: arrayUnion(addressDetails),
                  })
                  // console.log('stage 8 submit')
               }

               // close modal
               setOpenModalAddress(false)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to image modal
   const [openModalImage, setOpenModalImage] = useState(false)

   const handleOpenModalImage = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalImage(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalImage = () => setOpenModalImage(false)

   const handleAddImage = async (imageDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            //
            // console.log('stage xxx 1')
            const imageRef = ref(
               dbStorage,
               `ElectionCandidates/${candidate.Election_id}/${imageDetails.image.name + v4()}`,
            )
            // console.log('stage xxx 2')
            await uploadBytes(imageRef, imageDetails.image).then((snapshot) => {
               getDownloadURL(snapshot.ref).then(async (url) => {
                  //
                  const forOld = {
                     Image: url,
                     CrBy: auth.currentUser.uid,
                     CrDt: new Date(),
                     CrIP: ip.data.ip,
                  }

                  const recordNew = { ...record, Image: url }
                  //
                  // console.log('stage xxx 3')
                  const refCandi = doc(db, 'ElectionCandidates', candidate.id)
                  // console.log('stage xxx 3A')
                  const refElecCandi = doc(db, 'Elections', candidate.Election_id, 'CoreLists', candidate.CoreListId)

                  await updateDoc(refCandi, {
                     Image: url,
                     ImageOld: arrayUnion(forOld),
                  })
                  // console.log('stage xxx 4')
                  await updateDoc(refElecCandi, {
                     Candidates: arrayRemove(record),
                  })
                  // console.log('stage xxx 5')
                  await updateDoc(refElecCandi, {
                     Candidates: arrayUnion(recordNew),
                  })

                  setRecord({ ...record, Image: url })
                  setSourceImage(url)
               })
            })

            // close modal
            setOpenModalImage(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Allotted Symbol modal
   const [openModalAllottedSymbol, setOpenModalAllottedSymbol] = useState(false)

   const handleOpenModalAllottedSymbol = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalAllottedSymbol(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalAllottedSymbol = () => setOpenModalAllottedSymbol(false)

   const handleAddImageAllottedSymbol = async (imageDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            const imageRef = ref(dbStorage, `ElectionCandidates/${election.id}/${imageDetails.image.name + v4()}`)
            await uploadBytes(imageRef, imageDetails.image).then((snapshot) => {
               getDownloadURL(snapshot.ref).then(async (url) => {
                  //
                  const forOld = {
                     AllottedSymbol: url,
                     CrBy: auth.currentUser.uid,
                     CrDt: new Date(),
                     CrIP: ip.data.ip,
                  }
                  //
                  const refCandi = doc(db, 'ElectionCandidates', candidate.id)
                  const refElecCandi = doc(db, 'Elections', candidate.Election_id, 'CoreLists', candidate.CoreListId)

                  const recordNew = { ...record, AllottedSymbol: url }

                  updateDoc(refCandi, {
                     AllottedSymbol: url,
                     AllottedSymbolOld: arrayUnion(forOld),
                  })

                  await updateDoc(refElecCandi, {
                     Candidates: arrayRemove(record),
                  })

                  await updateDoc(refElecCandi, {
                     Candidates: arrayUnion(recordNew),
                  })

                  setRecord({ ...record, AllottedSymbol: url })

                  setSourceAllottedSymbol(url)
               })
            })

            // close modal
            setOpenModalAllottedSymbol(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Party modal
   const [openModalParty, setOpenModalParty] = useState(false)

   const handleOpenModalParty = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalParty(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalParty = () => setOpenModalParty(false)

   const [lastParty, setLastParty] = useState('')
   const [uploadingParty, setUploadingParty] = useState(false)
   const [uploadedParty, setUploadedParty] = useState(false)
   const [uploadingFailedParty, setUploadingFailedParty] = useState(false)

   const handleAddParty = async (partyDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            try {
               setUploadingParty(true)

               partyDetails.CrBy = auth.currentUser.uid
               partyDetails.CrDt = new Date()
               partyDetails.CrIP = ip.data.ip

               const queryAlliance = query(
                  collection(db, 'PPAlliances'),
                  where('Party_id_s', 'array-contains', partyDetails.Party_id),
               )
               const alliances = []
               const alliancesForMainDoc = []
               const querySnapshotAlliance = await getDocs(queryAlliance)
               querySnapshotAlliance.forEach(async (docSnap) => {
                  alliances.push({
                     Alliance_id: docSnap.id,
                     Name: docSnap.data().Name,
                     Abbreviation: docSnap.data().Abbreviation,
                  })
                  alliancesForMainDoc.push({
                     Alliance_id: docSnap.id,
                     Name: docSnap.data().Name,
                     Abbreviation: docSnap.data().Abbreviation,
                     Parties: docSnap.data().Parties,
                  })
               })

               const docRefCandi = doc(db, 'ElectionCandidates', candidate.id)

               await updateDoc(docRefCandi, {
                  Party_id: partyDetails.Party_id,
                  PartyName: partyDetails.PartyName,
                  PartyNameHindi: partyDetails.PartyNameHindi,
                  PartyNameLocal: partyDetails.PartyNameLocal,
                  PartyAbbreviation: partyDetails.PartyAbbreviation,
                  PartyFlag: partyDetails.PartyFlag,
                  PartySymbol: partyDetails.PartySymbol,
                  Alliances: alliances,
                  AlliancesForMainDoc: alliancesForMainDoc,
               })

               const refElecCandi = doc(db, 'Elections', candidate.Election_id, 'CoreLists', candidate.CoreListId)

               const recordNew = {
                  ...record,
                  Party_id: partyDetails.Party_id,
                  PartyName: partyDetails.PartyName,
                  PartyNameHindi: partyDetails.PartyNameHindi,
                  PartyNameLocal: partyDetails.PartyNameLocal,
                  PartyAbbreviation: partyDetails.PartyAbbreviation,
                  PartyFlag: partyDetails.PartyFlag,
                  PartySymbol: partyDetails.PartySymbol,
                  Alliances: alliances,
               }

               await updateDoc(refElecCandi, {
                  Candidates: arrayRemove(record),
               })

               await updateDoc(refElecCandi, {
                  Candidates: arrayUnion(recordNew),
               })

               // console.log('partyDetails 1: ', partyDetails)

               setLastParty(partyDetails.Name)

               setUploadingParty(false)
               setUploadedParty(true)
               setUploadingFailedParty(false)
            } catch (error) {
               alert('Error adding party: ', error.message)
               console.log('Error adding party: ', error)
               setUploadingParty(false)
               setUploadedParty(false)
               setUploadingFailedParty(true)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Phone modal
   const [openModalPhone, setOpenModalPhone] = useState(false)

   const handleOpenModalPhone = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalPhone(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalPhone = () => setOpenModalPhone(false)

   const [lastPhone, setLastPhone] = useState('')
   const [uploadingPhone, setUploadingPhone] = useState(false)
   const [uploadedPhone, setUploadedPhone] = useState(false)
   const [uploadingFailedPhone, setUploadingFailedPhone] = useState(false)

   const handleAddPhone = async (phoneDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            phoneDetails.CrBy = auth.currentUser.uid
            phoneDetails.CrDt = new Date()
            phoneDetails.CrIP = ip.data.ip

            setLastPhone(phoneDetails.Phone.toString())
            setUploadingPhone(true)
            try {
               //
               const refCandi = doc(db, 'ElectionCandidates', candidate.id)

               await updateDoc(refCandi, {
                  Phones: arrayUnion(phoneDetails.Phone),
                  PhonesOld: arrayUnion(phoneDetails),
               })

               // update the phone display
               candidate.Phones.push(phoneDetails.Phone.toString())

               // close modal
               // setOpenModalPhone(false)

               setUploadingPhone(false)
               setUploadedPhone(true)
               setUploadingFailedPhone(false)
            } catch (error) {
               alert('Error adding Phone: ', error.message)
               console.log('Error adding Phone', error)
               setUploadingPhone(false)
               setUploadedPhone(false)
               setUploadingFailedPhone(true)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Website modal
   const [openModalWebsite, setOpenModalWebsite] = useState(false)

   const handleOpenModalWebsite = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalWebsite(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalWebsite = () => setOpenModalWebsite(false)

   const handleAddWebsite = async (weblinkDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            weblinkDetails.CrBy = auth.currentUser.uid
            weblinkDetails.CrDt = new Date()
            weblinkDetails.CrIP = ip.data.ip
            //
            const refCandi = doc(db, 'ElectionCandidates', candidate.id)

            await updateDoc(refCandi, {
               WebLinks: arrayUnion(weblinkDetails.Weblink),
               WebLinksOld: arrayUnion(weblinkDetails),
            })

            // update the website display
            candidate.WebLinks.push(weblinkDetails.Weblink)

            // close modal
            // setOpenModalWebsite(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Email modal
   const [openModalEmail, setOpenModalEmail] = useState(false)

   const handleOpenModalEmail = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalEmail(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalEmail = () => setOpenModalEmail(false)

   const handleAddEmail = async (emailDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            emailDetails.CrBy = auth.currentUser.uid
            emailDetails.CrDt = new Date()
            emailDetails.CrIP = ip.data.ip
            //
            const refCandi = doc(db, 'ElectionCandidates', candidate.id)

            await updateDoc(refCandi, {
               Emails: arrayUnion(emailDetails.Email),
               EmailsOld: arrayUnion(emailDetails),
            })

            // update the website display
            candidate.Emails.push(emailDetails.Email)

            // close modal
            setOpenModalEmail(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Intro modal
   const [openModalIntro, setOpenModalIntro] = useState(false)

   const handleOpenModalIntro = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalIntro(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalIntro = () => setOpenModalIntro(false)

   const handleAddIntro = async (introDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (candidate.Intro && introDetails.Text === candidate.Intro) {
               setOpenModalIntro(false)
               return
            }
            try {
               introDetails.CrBy = auth.currentUser.uid
               introDetails.CrDt = new Date()
               introDetails.CrIP = ip.data.ip
               //
               const refCandi = doc(db, 'ElectionCandidates', candidate.id)
               const docRefCandiSubCollectionIntro = doc(db, 'ElectionCandidates', candidate.id, 'Intros', '0001')
               const docSnapCandiSubCollectionIntro = await getDoc(docRefCandiSubCollectionIntro)

               await updateDoc(refCandi, {
                  Intro: introDetails.Text,
               })

               if (docSnapCandiSubCollectionIntro.exists()) {
                  await updateDoc(docRefCandiSubCollectionIntro, {
                     Intro: arrayUnion(introDetails),
                  })
               } else {
                  setDoc(docRefCandiSubCollectionIntro, { Intro: [introDetails] }, { merge: true })
               }

               // update the intro display
               candidate.Intro = introDetails.Text

               // close modal
               setOpenModalIntro(false)
            } catch (error) {
               alert('Error adding intro: Please try again')
               console.log('Error adding intro: ', error)

               setOpenModalIntro()
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleRemoveIntro = async (introDetails) => {
      if (confirm('Are you sure you want to remove this intro?')) {
         try {
            const refCandi = doc(db, 'ElectionCandidates', candidate.id)
            await updateDoc(refCandi, {
               Intro: '',
            })
            // update the intro display
            candidate.Intro = ''
         } catch (error) {
            alert('Error removing intro: Please try again')
            console.log('Error removing intro: ', error)
         }
      }
   }

   // online support: related to last support

   const [lastCandiSupported, setLastCandiSupported] = useState('')
   const [hoursCenter, setHoursCenter] = useState('')
   const [hoursState, setHoursState] = useState('')

   const getLastSupportCenter = async () => {
      console.log('getLastSupportCenter : enetered')

      const lastSupportedCenter = userProfile.LS_Candi_CenterConstituency
      console.log('lastSupportedCenter: ', lastSupportedCenter)

      if (lastSupportedCenter) {
         if (lastSupportedCenter.Election_id === elec.id) {
            // find the difference in hours
            // console.log('center check')
            const lastDateCenter = new Date(lastSupportedCenter.CrDt.toDate())
            // console.log('center check 2')
            const date1 = moment(lastDateCenter)
            const date2 = moment(new Date())
            const resHousrs = date2.diff(date1, 'hours')
            setHoursCenter(resHousrs)
            setLastCandiSupported(lastSupportedCenter)
         } else {
            setHoursCenter('')
            setLastCandiSupported('')
         }
      } else {
         setHoursCenter('')
         setLastCandiSupported('')
      }
   }

   useEffect(() => {
      if (hoursCenter !== '') {
         // console.log('hours Center: ', hoursCenter)
      }
   }, [hoursCenter])

   const getLastSupportState = async () => {
      console.log('getLastSupportState : enetered')
      if (userProfile && userProfile !== '') {
         console.log('getLastSupportState : enetered - 2')
         const lastSupportedState = userProfile.LS_Candi_StateConstituency
         // console.log('lastSupportedState: ', lastSupportedState)

         if (lastSupportedState) {
            if (lastSupportedState.Election_id === elec.id) {
               // find the difference in hours

               // console.log('state check')
               const lastDateState = new Date(lastSupportedState.CrDt.toDate())

               // console.log('state check 2')
               const date1 = moment(lastDateState)
               const date2 = moment(new Date())
               const resHousrs = date2.diff(date1, 'hours')
               console.log('resHousrs state: ', resHousrs, ' : ', lastSupportedState)
               setHoursState(resHousrs)
               setLastCandiSupported(lastSupportedState)
            } else {
               setHoursState('')
               setLastCandiSupported('')
            }
         } else {
            setHoursState('')
            setLastCandiSupported('')
         }
      }
      console.log('getLastSupportState : enetered - 3')
   }

   useEffect(() => {
      if (hoursState !== '') {
         // console.log('hours State: ', hoursState)
      }
   }, [hoursState])

   // online support: opening the modal

   const [openModalOnlineSupport, setOpenModalOnlineSupport] = useState(false)
   const [uploadingOnlineSupport, setUploadingOnlineSupport] = useState(false)
   const [uploadedOnlineSupport, setUploadedOnlineSupport] = useState(false)
   const [uploadingFailedOnlineSupport, setUploadingFailedOnlineSupport] = useState(false)
   const [actionForSupport, setActionForSupport] = useState('')

   const handleOpenModalOnlineSupport = async (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         //
         const phase = elec.Phases.find((item) => item.PhaseNum === candi.PhaseNum)
         const phaseDateFetched = phase.DateOfPoll
         setPhaseDate(phaseDateFetched)
         // console.log(
         //    'reached stage prior to open modal for support - you are logged in.',
         //    new Date().toDateString(),
         //    ' phase date: ',
         //    new Date(phaseDateFetched.toDate()).toDateString(),
         // )

         if (new Date() > new Date(phaseDateFetched.toDate())) {
            // console.log(
            //    'today date ',
            //    new Date(),
            //    ' is greater than election date ',
            //    new Date(phaseDateFetched.toDate().toDateString()),
            // )
            handleOpenDialogDatePassed()
         } else {
            switch (consti.Strata) {
               case 'Central':
                  if (userProfile && userProfile !== '' && userProfile.AtCentralConstituency === '') {
                     // means he is new and fresh at Central level, so no need of any calculation
                     // proceed to vote and prompt the user at submit that he will be automatically
                     // attached to certain constituencies
                     setActionForSupport('attach and then accept the support')

                     setUploadingOnlineSupport(false)
                     setUploadedOnlineSupport(false)
                     setUploadingFailedOnlineSupport(false)

                     setOpenModalOnlineSupport(true)
                  } else {
                     // means (1) he is NOT fresh at Central level (2) he is already attached to some constituency at Central level
                     // Now, find if he is attached to the selected constituency
                     if (
                        userProfile &&
                        userProfile !== '' &&
                        userProfile.AtCentralConstituency.Constituency_id === candi.Constituency_id
                     ) {
                        // means he is attached to this constituency, so proceed further

                        // fetch last support center
                        let hoursCenterHere = ''
                        // let lastCandiSupportedHere = ''

                        const lastSupportedCenter = userProfile.LS_Candi_CenterConstituency
                        console.log('lastSupportedCenter: ', lastSupportedCenter)

                        if (lastSupportedCenter) {
                           if (lastSupportedCenter.Election_id === elec.id) {
                              // find the difference in hours
                              // console.log('center check')
                              const lastDateCenter = new Date(lastSupportedCenter.CrDt.toDate())
                              // console.log('center check 2')
                              const date1 = moment(lastDateCenter)
                              const date2 = moment(new Date())
                              const resHousrs = date2.diff(date1, 'hours')
                              setHoursCenter(resHousrs)
                              hoursCenterHere = resHousrs
                              setLastCandiSupported(lastSupportedCenter)
                              // lastCandiSupportedHere = lastSupportedCenter
                           } else {
                              setHoursCenter('')
                              setLastCandiSupported('')
                           }
                        } else {
                           setHoursCenter('')
                           setLastCandiSupported('')
                        }

                        if (hoursCenterHere !== '') {
                           // means (1) he is attached to this constituency (2) he has already supported a candidate in this election
                           if (parseInt(hoursCenterHere) < 24) {
                              // prompt the user that (1) he has supported within last 24 hours (2) he can support another one after 24 hours
                              setOpenModalOnlineSupport(false)
                              handleOpenDialogHours()
                           } else {
                              // he is past 24 hours and now he can support another candidate
                              setActionForSupport('accept the support if another candidate')
                              setOpenModalOnlineSupport(true)
                           }
                        } else {
                           // means (1) he is attached to this constituency (2) he has NOT yet supported any candidate for this election
                           // proceed to vote and accept the vote
                           setActionForSupport('accept the support')

                           setUploadingOnlineSupport(false)
                           setUploadedOnlineSupport(false)
                           setUploadingFailedOnlineSupport(false)

                           setOpenModalOnlineSupport(true)
                        }
                     } else {
                        // means (1) he is NOT attached to this constituency
                        // means (2) he is ATTACHED to another constituency
                        // means (3) he is trying to support a candidate here
                        // prompt the user that he is attached to some other constituency at this level
                        setActionForSupport('attached to other consti')

                        handleOpenDialogAtOtherConsti()
                     }
                  }
                  break
               case 'State':
                  if (userProfile && userProfile !== '' && userProfile.AtStateConstituency === '') {
                     // means he is new and fresh at State level, so no need of any calculation
                     // proceed to vote and prompt the user at submit that he will be automatically
                     // attached to certain constituencies
                     console.log('prior to open support modal - state level - attached consti state absent')
                     setActionForSupport('attach and then accept the support')

                     setUploadingOnlineSupport(false)
                     setUploadedOnlineSupport(false)
                     setUploadingFailedOnlineSupport(false)

                     setOpenModalOnlineSupport(true)
                  } else {
                     // means (1) he is NOT fresh at State level (2) he is already attached to some constituency at State level
                     // Now, find if he is attached to the selected constituency
                     if (
                        userProfile &&
                        userProfile !== '' &&
                        userProfile.AtStateConstituency.Constituency_id === candi.Constituency_id
                     ) {
                        console.log(
                           'prior to open support modal - state level - attached consti - ',
                           userProfile.AtStateConstituency.Constituency_id,
                           ' selected consti - ',
                           candi.Constituency_id,
                        )
                        // means he is attached to this constituency, so proceed further

                        // fetch last support state
                        let hoursStateHere = ''
                        // let lastCandiSupportedHere = ''

                        const lastSupportedState = userProfile.LS_Candi_StateConstituency
                        console.log('lastSupportedState: ', lastSupportedState)

                        if (lastSupportedState) {
                           if (lastSupportedState.Election_id === elec.id) {
                              // find the difference in hours
                              // console.log('center check')
                              const lastDateState = new Date(lastSupportedState.CrDt.toDate())
                              // console.log('center check 2')
                              const date1 = moment(lastDateState)
                              const date2 = moment(new Date())
                              const resHousrs = date2.diff(date1, 'hours')
                              setHoursState(resHousrs)
                              hoursStateHere = resHousrs
                              setLastCandiSupported(lastSupportedState)
                              // lastCandiSupportedHere = lastSupportedState
                           } else {
                              setHoursState('')
                              setLastCandiSupported('')
                           }
                        } else {
                           setHoursState('')
                           setLastCandiSupported('')
                        }

                        if (hoursStateHere !== '') {
                           // // means (1) he is attached to this constituency (2) he has already supported a candidate in this election
                           if (parseInt(hoursStateHere) < 24) {
                              // prompt the user that (1) he has supported within last 24 hours (2) he can support another one after 24 hours
                              setOpenModalOnlineSupport(false)
                              handleOpenDialogHours()
                           } else {
                              // he is past 24 hours and now he can support another candidate
                              setActionForSupport('accept the support if another candidate')
                              setOpenModalOnlineSupport(true)
                           }
                        } else {
                           // means (1) he is attached to this constituency (2) he has NOT yet supported any candidate for this election
                           // proceed to vote and accept the vote
                           setActionForSupport('accept the support')

                           setUploadingOnlineSupport(false)
                           setUploadedOnlineSupport(false)
                           setUploadingFailedOnlineSupport(false)

                           setOpenModalOnlineSupport(true)
                        }
                     } else {
                        // means (1) he is NOT attached to this constituency
                        // means (2) he is trying to support a candidate here
                        // prompt the user that he is attached to some other constituency at this level
                        // console.log(
                        //    'prior to open support modal - state level - attached consti - ',
                        //    userProfile.AtStateConstituency.Constituency_id,
                        //    ' selected consti - ',
                        //    candi.Constituency_id,
                        // )
                        setActionForSupport('attached to other consti')

                        handleOpenDialogAtOtherConsti()
                     }
                  }
                  break
               case 'Division':
                  //
                  break
               case 'District':
                  //
                  break
               case 'SubDistrict':
                  //
                  break
               case 'Block':
                  //
                  break
               case 'Village':
                  //
                  break
            }
         }
      } else {
         // console.log('reached stage prior to open modal for support - You are NOT logged in.')
         handleOpenDialogNotLoggedIn()
      }
   }

   const closeModalOnlineSupport = () => setOpenModalOnlineSupport(false)

   // online support: final record

   async function attachUserToConsti() {
      try {
         const docRefUserProfile = doc(db, 'UserProfiles', userProfile.id)
         const docRefUserProfileSubCollAttachedConstis = doc(
            db,
            'UserProfiles',
            userProfile.id,
            'CoreLists',
            'AttachedConstituencies',
         )
         const docSnapUserProfileSubCollAttachedConstis = await getDoc(docRefUserProfileSubCollAttachedConstis)

         const constiObjForUser = {
            Constituency_id: consti.id,
            ConstituencyNumber: consti.ConstituencyNumber,
            ConstituencyName: consti.Name,
            ConstituencyNameHindi: consti.NameHindi,
            ConstituencyNameLocal: consti.NameLocal,

            StateName: consti.StateName,
            State_id: consti.State_id,
            CrDt: new Date(),
            CrIP: ip.data.ip,
            ModDt: '',
         }
         const constiObjForUserCentral = { ...constiObjForUser, Level: 'Central' }
         const constiObjForUserState = { ...constiObjForUser, Level: 'State' }

         console.log(
            'attaching user stage 1: objCentra: ',
            constiObjForUserCentral,
            ' objState: ',
            constiObjForUserState,
         )

         switch (consti.Strata) {
            case 'Central':
               console.log('attaching user stage 2: ')
               await updateDoc(docRefUserProfile, {
                  AtCentralConstituency: constiObjForUserCentral,
               })
               console.log('attaching user stage 3: ')
               if (docSnapUserProfileSubCollAttachedConstis.exists()) {
                  await updateDoc(docRefUserProfileSubCollAttachedConstis, {
                     AttachedConstituencies: arrayUnion(constiObjForUserCentral),
                  })
                  console.log('attaching user stage 4: ')
               } else {
                  setDoc(
                     docRefUserProfileSubCollAttachedConstis,
                     {
                        AttachedConstituencies: [constiObjForUserCentral],
                     },
                     { merge: true },
                  )
                  console.log('attaching user stage 5: ')
               }
               break
            case 'State':
               console.log('attaching user stage 6: ')
               await updateDoc(docRefUserProfile, {
                  AtStateConstituency: constiObjForUserState,
               })
               console.log('attaching user stage 7: ')
               if (docSnapUserProfileSubCollAttachedConstis.exists()) {
                  await updateDoc(docRefUserProfileSubCollAttachedConstis, {
                     AttachedConstituencies: arrayUnion(constiObjForUserState),
                  })
                  console.log('attaching user stage 8: ')
               } else {
                  setDoc(
                     docRefUserProfileSubCollAttachedConstis,
                     {
                        AttachedConstituencies: [constiObjForUserState],
                     },
                     { merge: true },
                  )
                  console.log('attaching user stage 9: ')
               }
               break
         }
         console.log('attaching user stage 10: ')
         const docRefConsti = doc(db, 'LegisConstituencies', consti.id)
         const docSnapConsti = await getDoc(docRefConsti)
         const slab = 5000
         const count = docSnapConsti.data().AttachedUsersCount
         const quotient = Math.floor(count / slab)
         const num = quotient + 1
         const numStr = String(num).padStart(6, '0')

         await updateDoc(docRefConsti, {
            AttachedUsersCount: increment(1),
         })
         console.log('attaching user stage 11: ')
         // The above is for now. Later when people start changing constis after 2 years, the code need to be revised

         const docRefConstiSubCollAttached = doc(db, 'LegisConstituencies', consti.id, 'AttachedUsersLists', numStr)
         const docSnapConstiSubCollAttached = await getDoc(docRefConstiSubCollAttached)

         if (docSnapConstiSubCollAttached.exists()) {
            console.log('attaching user stage 12: ', {
               Name: nameOfUser,
               UserProfile_id: userProfile.id,
               User_id: auth.currentUser.uid,
               CrDt: new Date(),
               CrIP: ip.data.ip,
               ModDt: '',
            })
            await updateDoc(docRefConstiSubCollAttached, {
               Users: arrayUnion({
                  Name: nameOfUser,
                  UserProfile_id: userProfile.id,
                  User_id: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
                  ModDt: '',
               }),
            })
         } else {
            console.log('attaching user stage 13: ')
            setDoc(
               docRefConstiSubCollAttached,
               {
                  Users: [
                     {
                        Name: nameOfUser,
                        UserProfile_id: userProfile.id,
                        User_id: auth.currentUser.uid,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                        ModDt: '',
                     },
                  ],
               },
               { merge: true },
            )
            console.log('attaching user stage 14: ', {
               Name: nameOfUser,
               UserProfile_id: userProfile.id,
               User_id: auth.currentUser.uid,
               CrDt: new Date(),
               CrIP: ip.data.ip,
               ModDt: '',
            })
         }
      } catch (error) {
         console.log('error attaching user', error)
      }
   }

   const addOnlineSupport = async (onlineSupportDetails) => {
      console.log('reached adding stage of vote.')
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         console.log('reached adding stage of vote - you are logged in.')
         try {
            if (onlineSupportDetails.Candidate_id !== '') {
               setUploadingOnlineSupport(true)

               try {
                  if (new Date() > new Date(phaseDate.toDate())) {
                     console.log('Add - stage - 1')
                     // means the date is over
                     // this condition was already checked befor opening the add modal. So reaching this stage means fraud.
                     navigate('/', { replace: true })
                  } else {
                     // means the date is NOT over
                     console.log('Add - stage - 2')
                     switch (consti.Strata) {
                        case 'Central':
                           console.log('Add - stage - 2.1')
                           if (!userProfile.AtCentralConstituency || userProfile.AtCentralConstituency === '') {
                              // 0. The user is NOT YET attached to any constituency at central level
                              // 1. fetch constituency - in this case the present selected consti is applicable
                              // 2. attach the user to constituency
                              attachUserToConsti()
                              // 3. proceed to record the support vote
                              recordOnlineSupport(onlineSupportDetails, 'positive')
                           } else {
                              if (userProfile.AtCentralConstituency.Constituency_id === consti.id) {
                                 // means he is attached to this constituency, so proceed further

                                 // fetch last support state
                                 let hoursCenterHere = ''
                                 // let lastCandiSupportedHere = ''

                                 const lastSupportedCenter = userProfile.LS_Candi_CenterConstituency
                                 console.log('lastSupportedCenter: ', lastSupportedCenter)

                                 if (lastSupportedCenter) {
                                    if (lastSupportedCenter.Election_id === elec.id) {
                                       // find the difference in hours
                                       // console.log('center check')
                                       const lastDateCenter = new Date(lastSupportedCenter.CrDt.toDate())
                                       // console.log('center check 2')
                                       const date1 = moment(lastDateCenter)
                                       const date2 = moment(new Date())
                                       const resHousrs = date2.diff(date1, 'hours')
                                       setHoursState(resHousrs)
                                       hoursCenterHere = resHousrs
                                       setLastCandiSupported(lastSupportedCenter)
                                       // lastCandiSupportedHere = lastSupportedCenter
                                    } else {
                                       setHoursCenter('')
                                       setLastCandiSupported('')
                                    }
                                 } else {
                                    setHoursCenter('')
                                    setLastCandiSupported('')
                                 }

                                 if (hoursCenterHere !== '') {
                                    // means (1) he is attached to this constituency (2) he has already supported a candidate in this election
                                    if (parseInt(hoursCenterHere) < 24) {
                                       // it is less than 24 hrs, how did he reach here?
                                       navigate('/', { replace: true })
                                    } else {
                                       // he is past 24 hours and now he can support another candidate
                                       // now check if he is again supporting the last supported candidate
                                       if (lastCandiSupported.Candidate_id === onlineSupportDetails.Candidate_id) {
                                          setOpenModalOnlineSupport(false)
                                          handleOpenDialogSameCandi()
                                       } else {
                                          recordOnlineSupport(onlineSupportDetails, 'negative')
                                          // handleOpenDialogDifferentCandi()
                                       }
                                    }
                                 } else {
                                    // means (1) he is attached to this constituency (2) he has NOT yet supported any candidate for this election
                                    // proceed to record and accept the vote
                                    recordOnlineSupport(onlineSupportDetails, 'positive')
                                 }
                              } else {
                                 // 0. The user is NOT attached to this consti. How did he reach here?
                                 // 1. Prompt the user that he is attached to some other constituency at this level.
                                 // 2. This condition was already checked befor opening the add modal. So reaching this stage means fraud.
                                 navigate('/', { replace: true })
                              }
                           }
                           break
                        case 'State':
                           console.log('Add - stage - 2.2')
                           // 1. fetch constituency
                           // 2. fetch parent constituency
                           // 2. attach the user to constituency and the parent constituency
                           // 3. record the support vote
                           if (userProfile && userProfile !== '' && userProfile.AtStateConstituency === '') {
                              console.log('Add - stage - 2.2.1')
                              // 0. The user is FRESH and NOT not attached to any constituency at state level.
                              // 1. &  2. fetch constituency and parent constituency - in this case the present selected consti is applicable
                              // 3. attach the user to constituency
                              attachUserToConsti()
                              // 3. proceed to record the support vote
                              recordOnlineSupport(onlineSupportDetails, 'positive')
                           } else {
                              console.log('Add - stage - 2.2.2')
                              // the user is attached to some constituency at this level
                              // hence:
                              if (
                                 userProfile &&
                                 userProfile !== '' &&
                                 userProfile.AtStateConstituency.Constituency_id === consti.id
                              ) {
                                 console.log('Add - stage - 2.2.2.1 hours state: ', hoursState)
                                 // means he is attached to this constituency, so proceed further

                                 // fetch last support state
                                 let hoursStateHere = ''
                                 // let lastCandiSupportedHere = ''

                                 const lastSupportedState = userProfile.LS_Candi_StateConstituency
                                 console.log('lastSupportedState: ', lastSupportedState)

                                 if (lastSupportedState) {
                                    if (lastSupportedState.Election_id === elec.id) {
                                       // find the difference in hours
                                       // console.log('state check')
                                       const lastDateState = new Date(lastSupportedState.CrDt.toDate())
                                       // console.log('center check 2')
                                       const date1 = moment(lastDateState)
                                       const date2 = moment(new Date())
                                       const resHousrs = date2.diff(date1, 'hours')
                                       setHoursState(resHousrs)
                                       hoursStateHere = resHousrs
                                       setLastCandiSupported(lastSupportedState)
                                       // lastCandiSupportedHere = lastSupportedState
                                    } else {
                                       setHoursState('')
                                       setLastCandiSupported('')
                                    }
                                 } else {
                                    setHoursState('')
                                    setLastCandiSupported('')
                                 }

                                 if (hoursStateHere !== '') {
                                    console.log('Add - stage - 2.2.2.1.1')
                                    // means (1) he is attached to this constituency (2) he has already supported a candidate in this election
                                    if (parseInt(hoursStateHere) < 24) {
                                       // it is less than 24 hrs, how did he reach here?
                                       navigate('/', { replace: true })
                                    } else {
                                       // he is past 24 hours and now he can support another candidate
                                       // now check if he is again supporting the last supported candidate
                                       if (lastCandiSupported.Candidate_id === onlineSupportDetails.Candidate_id) {
                                          setOpenModalOnlineSupport(false)
                                          handleOpenDialogSameCandi()
                                       } else {
                                          recordOnlineSupport(onlineSupportDetails, 'negative')
                                          // handleOpenDialogDifferentCandi()
                                       }
                                    }
                                 } else {
                                    console.log('Add - stage - 2.2.2.1.2')
                                    // means (1) he is attached to this constituency (2) he has NOT yet supported any candidate for this election
                                    // proceed to vote and accept the vote
                                    recordOnlineSupport(onlineSupportDetails, 'positive')
                                 }
                              } else {
                                 console.log('Add - stage - 2.2.2.2')
                                 // 0. The user is NOT attached to this consti. How did he reach here?
                                 // 1. Prompt the user that he is attached to some other constituency at this level
                                 // 2. This condition was already checked befor opening the add modal. So reaching this stage means fraud.
                                 navigate('/', { replace: true })
                              }
                           }
                           break
                        case 'Division':
                           //
                           break
                        case 'District':
                           //
                           break
                        case 'SubDistrict':
                           //
                           break
                        case 'Block':
                           //
                           break
                        case 'Village':
                           //
                           break
                     }
                  }

                  // closeModalOnlineSupport()
                  // handleOpenDialogThanks()

                  // closeModalOnlineSupport()

                  // setUploadingOnlineSupport(false)
                  // setUploadedOnlineSupport(true)
                  // setUploadingFailedOnlineSupport(false)
               } catch (error) {
                  alert(`Error adding public vote: `, error.message)
                  console.log(`Error adding public vote: `, error)
                  setUploadingOnlineSupport(false)
                  setUploadedOnlineSupport(false)
                  setUploadingFailedOnlineSupport(true)
               }
            } else {
               alert('Please select a candidate to proceed. \n कृपया आगे बढ़ने के लिए एक प्रत्याशी का चयन करें।')
            }
         } catch (error) {
            console.log(error)
         }
      } else {
         console.log('reached adding stage of vote - You are NOT logged in.')
         // this condition was already checked befor opening the add modal. So reaching this stage means fraud.
         navigate('/', { replace: true })
      }
   }

   const recordOnlineSupport = async (onlineSupportDetails, type) => {
      try {
         const supportObj4Candi = {
            CrBy: auth.currentUser.uid,
            CrDt: new Date(),
            CrIP: ip.data.ip,
            ModDt: '',
            UserProfile_id: userProfile.id,
            Name: nameOfUser,
         }
         console.log('recordOnlineSupport stage: 1')

         if (!consti.OnlineSupportStarted) {
            console.log('recordOnlineSupport stage: 2')
            const docRefConsti = doc(db, 'LegisConstituencies', consti.id)
            await updateDoc(docRefConsti, { OnlineSupportStarted: true })

            const docRefElecSubCollConsti = doc(db, 'Elections', elec.id, 'CoreLists', 'ConstituencyCoreList')
            const docSnapElecSubCollConsti = await getDoc(docRefElecSubCollConsti)
            const thisConsti = docSnapElecSubCollConsti
               .data()
               .Constituencies.find((item) => item.Constituency_id === consti.id)
            const constiNew = { ...thisConsti, OnlineSupportStarted: true }
            await updateDoc(docRefElecSubCollConsti, {
               Constituencies: arrayRemove(thisConsti),
            })
            await updateDoc(docRefElecSubCollConsti, {
               Constituencies: arrayUnion(constiNew),
            })
         }
         console.log('recordOnlineSupport stage: 3')
         const docRefCandi = doc(db, 'ElectionCandidates', onlineSupportDetails.Candidate_id)
         const docSnapCandi = await getDoc(docRefCandi)
         console.log('recordOnlineSupport stage: 3.3')

         console.log('recordOnlineSupport stage: 4')
         //
         const slab = 7500
         const supportCount = docSnapCandi.data().OnlineSupports + docSnapCandi.data().OnlineSupportsCancelled
         const quotient = Math.floor(supportCount / slab)
         const num = quotient + 1
         const numStr = String(num).padStart(6, '0')
         console.log('recordOnlineSupport stage: 5')
         const supportObj4UserProfile = {
            Election_id: elec.id,
            ElectionName: elec.Name,
            ElectionNameHindi: elec.NameHindi,
            ElectionNameLocal: elec.NameLocal,
            Constituency_id: consti.id,
            ConstituencyName: consti.Name,
            ConstituencyNameHindi: consti.NameHindi,
            ConstituencyNameLocal: consti.NameLocal,
            Candidate_id: onlineSupportDetails.Candidate_id,
            CandidateName: onlineSupportDetails.CandidateName,
            CandidateNameHindi: onlineSupportDetails.CandidateNameHindi,
            CandidateNameLocal: onlineSupportDetails.CandidateNameLocal,
            PartyName: onlineSupportDetails.PartyName,
            PartyNameHindi: onlineSupportDetails.PartyNameHindi,
            PartyNameLocal: onlineSupportDetails.PartyNameLocal,
            PartyId: onlineSupportDetails.Party_id,
            Alliances: onlineSupportDetails.Alliances,
            CrDt: new Date(),
            CrIP: ip.data.ip,
            CoreListId_InCandidate: numStr,
            CoreListId_InElection: onlineSupportDetails.CoreListId,
            Type: 'CandiSupport',
         }
         console.log('supportObj4UserProfile: ', supportObj4UserProfile)
         console.log('recordOnlineSupport stage: 6')

         // update the support count in main candidate doc

         if (lastCandiSupported && lastCandiSupported.Candidate_id !== onlineSupportDetails.Candidate_id) {
            console.log('recordOnlineSupport stage: 6A')
            const docRefCandiPrev = doc(db, 'ElectionCandidates', lastCandiSupported.Candidate_id)
            await updateDoc(docRefCandiPrev, {
               OnlineSupports: increment(-1),
               OnlineSupportsCancelled: increment(1),
            })
            console.log('recordOnlineSupport stage: 6B')
         }
         console.log('recordOnlineSupport stage: 7')
         await updateDoc(docRefCandi, { OnlineSupports: increment(1) })

         // add fresh record to candidate's subcollection named "OnlineSupports"
         console.log('recordOnlineSupport stage: 8')
         if (lastCandiSupported && lastCandiSupported.Candidate_id !== onlineSupportDetails.Candidate_id) {
            console.log('recordOnlineSupport stage: 9')
            const docRefCandiSubCollectionOnlineSupportsPrev = doc(
               db,
               'ElectionCandidates',
               lastCandiSupported.Candidate_id,
               'OnlineSupports',
               lastCandiSupported.CoreListId_InCandidate,
            )
            console.log('XX XX XX')
            const docSnapCandiSubCollectionOnlineSupportsPrev = await getDoc(docRefCandiSubCollectionOnlineSupportsPrev)
            const oldRecord = docSnapCandiSubCollectionOnlineSupportsPrev
               .data()
               .Supports.find((item) => item.CrBy === auth.currentUser.uid && item.ModDt === '')

            console.log('XX XX: ', oldRecord)

            if (oldRecord) {
               console.log('recordOnlineSupport stage: 10')
               const oldRecordModified = { ...oldRecord, ModDt: new Date() }

               await updateDoc(docRefCandiSubCollectionOnlineSupportsPrev, {
                  Supports: arrayRemove(oldRecord),
               })
               await updateDoc(docRefCandiSubCollectionOnlineSupportsPrev, {
                  Supports: arrayUnion(oldRecordModified),
               })
            }
         }
         console.log('recordOnlineSupport stage: 11')
         const docRefCandiSubCollectionOnlineSupports = doc(
            db,
            'ElectionCandidates',
            onlineSupportDetails.Candidate_id,
            'OnlineSupports',
            numStr,
         )
         const docSnapCandiSubCollectionOnlineSupports = await getDoc(docRefCandiSubCollectionOnlineSupports)
         console.log('recordOnlineSupport stage: 12')
         if (docSnapCandiSubCollectionOnlineSupports.exists()) {
            console.log('recordOnlineSupport stage: 13')
            await updateDoc(docRefCandiSubCollectionOnlineSupports, {
               Supports: arrayUnion(supportObj4Candi),
            })
         } else {
            console.log('recordOnlineSupport stage: 14')
            setDoc(docRefCandiSubCollectionOnlineSupports, { Supports: [supportObj4Candi] }, { merge: true })
         }

         // add record to user profile main doc
         console.log('recordOnlineSupport stage: 15')
         const docRefUserProfile = doc(db, 'UserProfiles', userProfile.id)
         switch (consti.Strata) {
            case 'Central':
               await updateDoc(docRefUserProfile, {
                  LS_Candi_CenterConstituency: supportObj4UserProfile,
                  SupportedCount: increment(1),
               })
               break
            case 'State':
               await updateDoc(docRefUserProfile, {
                  LS_Candi_StateConstituency: supportObj4UserProfile,
                  SupportedCount: increment(1),
               })
               break
         }

         // add record to user profile subCollection
         console.log('recordOnlineSupport stage: 16')
         const docRefUserSubCollectionCandiadtesSupported = doc(
            db,
            'UserProfiles',
            userProfile.id,
            'CandidatesSupported',
            '000001',
         )
         const docSnapUserSubCollectionCandiadtesSupported = await getDoc(docRefUserSubCollectionCandiadtesSupported)
         console.log('recordOnlineSupport stage: 17')
         if (docSnapUserSubCollectionCandiadtesSupported.exists()) {
            console.log('recordOnlineSupport stage: 18')
            await updateDoc(docRefUserSubCollectionCandiadtesSupported, {
               SupportedList: arrayUnion(supportObj4UserProfile),
            })
         } else {
            console.log('recordOnlineSupport stage: 19')
            setDoc(
               docRefUserSubCollectionCandiadtesSupported,
               { SupportedList: [supportObj4UserProfile] },
               { merge: true },
            )
         }
         console.log('recordOnlineSupport stage: 20')
         // update the support count in candidates core list inside Elections
         if (lastCandiSupported && lastCandiSupported.Candidate_id !== onlineSupportDetails.Candidate_id) {
            console.log('recordOnlineSupport stage: 21')
            const docRefElectionCandiCoreListPrev = doc(
               db,
               'Elections',
               elec.id,
               'CoreLists',
               lastCandiSupported.CoreListId_InElection,
            )
            const docSnapElectionCandiCoreListPrev = await getDoc(docRefElectionCandiCoreListPrev)
            const candiRecordNegative = docSnapElectionCandiCoreListPrev
               .data()
               .Candidates.find((item) => item.Candidate_id === lastCandiSupported.Candidate_id)

            const candiRecordNegativeNew = {
               ...candiRecordNegative,
               OnlineSupport: candiRecordNegative.OnlineSupport - 1,
            }

            await updateDoc(docRefElectionCandiCoreListPrev, {
               Candidates: arrayRemove(candiRecordNegative),
            })
            await updateDoc(docRefElectionCandiCoreListPrev, {
               Candidates: arrayUnion(candiRecordNegativeNew),
            })
         }
         console.log('recordOnlineSupport stage: 22')
         const docRefElectionCandiCoreList = doc(db, 'Elections', elec.id, 'CoreLists', onlineSupportDetails.CoreListId)
         const docSnapElectionCandiCoreList = await getDoc(docRefElectionCandiCoreList)

         const candiRecordPositive = docSnapElectionCandiCoreList
            .data()
            .Candidates.find((item) => item.Candidate_id === onlineSupportDetails.Candidate_id)

         const candiRecordPositiveNew = { ...candiRecordPositive, OnlineSupport: candiRecordPositive.OnlineSupport + 1 }
         console.log('recordOnlineSupport stage: 23')
         await updateDoc(docRefElectionCandiCoreList, {
            Candidates: arrayRemove(candiRecordPositive),
         })
         await updateDoc(docRefElectionCandiCoreList, {
            Candidates: arrayUnion(candiRecordPositiveNew),
         })

         // update the support in local state
         console.log('recordOnlineSupport stage: 24')
         const candiRecord = candisList.find((item) => item.Candidate_id === onlineSupportDetails.Candidate_id)
         if (candiRecord) {
            candiRecord.OnlineSupport = candiRecord.OnlineSupport + 1
         }

         if (lastCandiSupported && lastCandiSupported.Candidate_id !== onlineSupportDetails.Candidate_id) {
            const candiRecordPrev = candisList.find((item) => item.Candidate_id === lastCandiSupported.Candidate_id)
            if (candiRecordPrev) {
               candiRecordPrev.OnlineSupport = candiRecordPrev.OnlineSupport - 1
            }
            if (candi.id === onlineSupportDetails.Candidate_id) {
               candi.OnlineSupports = candi.OnlineSupports - 1
            }
         } else {
            if (candi.id === onlineSupportDetails.Candidate_id) {
               candi.OnlineSupports = candi.OnlineSupports + 1
            }
         }

         const sortedListForOnineSupport = [...candisList].sort((a, b) => b.OnlineSupport - a.OnlineSupport)
         sortCandisList(sortedListForOnineSupport)

         // set the state of userProfile afresh
         const querryUser = query(collection(db, 'UserProfiles'), where('User_id', '==', auth.currentUser.uid))
         const querySnapshot = await getDocs(querryUser)
         let userFetched = ''
         querySnapshot.forEach((docSnap) => {
            userFetched = { id: docSnap.id, ...docSnap.data() }
         })
         dispatch(setUserProfile(userFetched))

         handleOpenDialogThanks()
         setUploadingOnlineSupport(false)
         setOpenModalOnlineSupport(false)
      } catch (error) {
         console.log('error recordOnlineSupport: ', error)
         setUploadingOnlineSupport(false)
      }
   }

   // const recordOnlineSupport2 = async (onlineSupportDetails, type) => {
   //    try {
   //       const supportObj4Candi = {
   //          CrBy: auth.currentUser.uid,
   //          CrDt: new Date(),
   //          CrIP: ip.data.ip,
   //          ModDt: '',
   //          UserProfile_id: userProfile.id,
   //          Name: nameOfUser,
   //       }
   //       console.log('recordOnlineSupport stage: 1')

   //       if (!consti.OnlineSupportStarted) {
   //          console.log('recordOnlineSupport stage: 2')
   //          const docRefConsti = doc(db, 'LegisConstituencies', consti.id)
   //          await updateDoc(docRefConsti, { OnlineSupportStarted: true })

   //          const docRefElecSubCollConsti = doc(db, 'Elections', elec.id, 'CoreLists', 'ConstituencyCoreList')
   //          const docSnapElecSubCollConsti = await getDoc(docRefElecSubCollConsti)
   //          const thisConsti = docSnapElecSubCollConsti
   //             .data()
   //             .Constituencies.find((item) => item.Constituency_id === consti.id)
   //          const thisConstiNew = { ...thisConsti, OnlineSupportStarted: true }
   //          await updateDoc(docRefElecSubCollConsti, {
   //             Constituencies: arrayRemove(thisConsti),
   //          })
   //          await updateDoc(docRefElecSubCollConsti, {
   //             Constituencies: arrayUnion(thisConstiNew),
   //          })
   //       }
   //       console.log('recordOnlineSupport stage: 3')
   //       const docRefCandi = doc(db, 'ElectionCandidates', onlineSupportDetails.Candidate_id)
   //       const docSnapCandi = await getDoc(docRefCandi)
   //       console.log('recordOnlineSupport stage: 3.3')

   //       console.log('recordOnlineSupport stage: 4')
   //       //
   //       const slab = 7500
   //       const supportCount = docSnapCandi.data().OnlineSupports + docSnapCandi.data().OnlineSupportsCancelled
   //       const quotient = Math.floor(supportCount / slab)
   //       const num = quotient + 1
   //       const numStr = String(num).padStart(6, '0')
   //       console.log('recordOnlineSupport stage: 5')
   //       const supportObj4UserProfile = {
   //          Election_id: elec.id,
   //          ElectionName: elec.Name,
   //          ElectionNameHindi: elec.NameHindi,
   //          ElectionNameLocal: elec.NameLocal,
   //          Constituency_id: consti.id,
   //          ConstituencyName: consti.Name,
   //          ConstituencyNameHindi: consti.NameHindi,
   //          ConstituencyNameLocal: consti.NameLocal,
   //          Candidate_id: onlineSupportDetails.Candidate_id,
   //          CandidateName: onlineSupportDetails.CandidateName,
   //          CandidateNameHindi: onlineSupportDetails.CandidateNameHindi,
   //          CandidateNameLocal: onlineSupportDetails.CandidateNameLocal,
   //          PartyName: onlineSupportDetails.PartyName,
   //          PartyNameHindi: onlineSupportDetails.PartyNameHindi,
   //          PartyNameLocal: onlineSupportDetails.PartyNameLocal,
   //          PartyId: onlineSupportDetails.Party_id,
   //          Alliances: onlineSupportDetails.Alliances,
   //          CrDt: new Date(),
   //          CrIP: ip.data.ip,
   //          CoreListId_InCandidate: numStr,
   //          CoreListId_InElection: onlineSupportDetails.CoreListId,
   //          Type: 'CandiSupport',
   //       }
   //       console.log('supportObj4UserProfile: ', supportObj4UserProfile)
   //       console.log('recordOnlineSupport stage: 6')
   //       // update the support count in main candidate doc

   //       if (lastCandiSupported && lastCandiSupported.Candidate_id !== onlineSupportDetails.Candidate_id) {
   //          const docRefCandiPrev = doc(db, 'ElectionCandidates', lastCandiSupported.Candidate_id)
   //          await updateDoc(docRefCandiPrev, { OnlineSupports: increment(-1), OnlineSupportsCancelled: increment(1) })
   //       }
   //       console.log('recordOnlineSupport stage: 7')
   //       await updateDoc(docRefCandi, { OnlineSupports: increment(1) })

   //       // add fresh record to candidate's subcollection named "OnlineSupports"
   //       console.log('recordOnlineSupport stage: 8')
   //       if (lastCandiSupported && lastCandiSupported.Candidate_id !== onlineSupportDetails.Candidate_id) {
   //          console.log('recordOnlineSupport stage: 9')
   //          const docRefCandiSubCollectionOnlineSupportsPrev = doc(
   //             db,
   //             'ElectionCandidates',
   //             lastCandiSupported.Candidate_id,
   //             'OnlineSupports',
   //             lastCandiSupported.CoreListId_InCandidate,
   //          )
   //          console.log('XX XX XX')
   //          const docSnapCandiSubCollectionOnlineSupportsPrev = await getDoc(docRefCandiSubCollectionOnlineSupportsPrev)
   //          const oldRecord = docSnapCandiSubCollectionOnlineSupportsPrev
   //             .data()
   //             .Supports.find((item) => item.CrBy === auth.currentUser.uid && item.ModDt === '')

   //          console.log('XX XX: ', oldRecord)

   //          if (oldRecord) {
   //             console.log('recordOnlineSupport stage: 10')
   //             const oldRecordModified = { ...oldRecord, ModDt: new Date() }

   //             await updateDoc(docRefCandiSubCollectionOnlineSupportsPrev, {
   //                Supports: arrayRemove(oldRecord),
   //             })
   //             await updateDoc(docRefCandiSubCollectionOnlineSupportsPrev, {
   //                Supports: arrayUnion(oldRecordModified),
   //             })
   //          }
   //       }
   //       console.log('recordOnlineSupport stage: 11')
   //       const docRefCandiSubCollectionOnlineSupports = doc(
   //          db,
   //          'ElectionCandidates',
   //          onlineSupportDetails.Candidate_id,
   //          'OnlineSupports',
   //          numStr,
   //       )
   //       const docSnapCandiSubCollectionOnlineSupports = await getDoc(docRefCandiSubCollectionOnlineSupports)
   //       console.log('recordOnlineSupport stage: 12')
   //       if (docSnapCandiSubCollectionOnlineSupports.exists()) {
   //          console.log('recordOnlineSupport stage: 13')
   //          await updateDoc(docRefCandiSubCollectionOnlineSupports, {
   //             Supports: arrayUnion(supportObj4Candi),
   //          })
   //       } else {
   //          console.log('recordOnlineSupport stage: 14')
   //          setDoc(docRefCandiSubCollectionOnlineSupports, { Supports: [supportObj4Candi] }, { merge: true })
   //       }

   //       // add record to user profile main doc
   //       console.log('recordOnlineSupport stage: 15')
   //       const docRefUserProfile = doc(db, 'UserProfiles', userProfile.id)
   //       switch (consti.Strata) {
   //          case 'Central':
   //             await updateDoc(docRefUserProfile, {
   //                LS_Candi_CenterConstituency: supportObj4UserProfile,
   //                SupportedCount: increment(1),
   //             })
   //             break
   //          case 'State':
   //             await updateDoc(docRefUserProfile, {
   //                LS_Candi_StateConstituency: supportObj4UserProfile,
   //                SupportedCount: increment(1),
   //             })
   //             break
   //       }

   //       // add record to user profile subCollection
   //       console.log('recordOnlineSupport stage: 16')
   //       const docRefUserSubCollectionCandiadtesSupported = doc(
   //          db,
   //          'UserProfiles',
   //          userProfile.id,
   //          'SupportedLists',
   //          '000001',
   //       )
   //       const docSnapUserSubCollectionCandiadtesSupported = await getDoc(docRefUserSubCollectionCandiadtesSupported)
   //       console.log('recordOnlineSupport stage: 17')
   //       if (docSnapUserSubCollectionCandiadtesSupported.exists()) {
   //          console.log('recordOnlineSupport stage: 18')
   //          await updateDoc(docRefUserSubCollectionCandiadtesSupported, {
   //             SupportedList: arrayUnion(supportObj4UserProfile),
   //          })
   //       } else {
   //          console.log('recordOnlineSupport stage: 19')
   //          setDoc(
   //             docRefUserSubCollectionCandiadtesSupported,
   //             { SupportedList: [supportObj4UserProfile] },
   //             { merge: true },
   //          )
   //       }
   //       console.log('recordOnlineSupport stage: 20')
   //       // update the support count in candidates core list inside Elections
   //       if (lastCandiSupported && lastCandiSupported.Candidate_id !== onlineSupportDetails.Candidate_id) {
   //          console.log('recordOnlineSupport stage: 21')
   //          const docRefElectionCandiCoreListPrev = doc(
   //             db,
   //             'Elections',
   //             elec.id,
   //             'CoreLists',
   //             lastCandiSupported.CoreListId_InElection,
   //          )
   //          const docSnapElectionCandiCoreListPrev = await getDoc(docRefElectionCandiCoreListPrev)
   //          const candiRecordNegative = docSnapElectionCandiCoreListPrev
   //             .data()
   //             .Candidates.find((item) => item.Candidate_id === lastCandiSupported.Candidate_id)

   //          const candiRecordNegativeNew = {
   //             ...candiRecordNegative,
   //             OnlineSupport: candiRecordNegative.OnlineSupport - 1,
   //             OnlineSupportCancelled: candiRecordNegative.OnlineSupportCancelled + 1,
   //          }

   //          await updateDoc(docRefElectionCandiCoreListPrev, {
   //             Candidates: arrayRemove(candiRecordNegative),
   //          })
   //          await updateDoc(docRefElectionCandiCoreListPrev, {
   //             Candidates: arrayUnion(candiRecordNegativeNew),
   //          })
   //       }
   //       console.log('recordOnlineSupport stage: 22')
   //       const docRefElectionCandiCoreList = doc(db, 'Elections', elec.id, 'CoreLists', onlineSupportDetails.CoreListId)
   //       const docSnapElectionCandiCoreList = await getDoc(docRefElectionCandiCoreList)

   //       const candiRecordPositive = docSnapElectionCandiCoreList
   //          .data()
   //          .Candidates.find((item) => item.Candidate_id === onlineSupportDetails.Candidate_id)

   //       const candiRecordPositiveNew = { ...candiRecordPositive, OnlineSupport: candiRecordPositive.OnlineSupport + 1 }
   //       console.log('recordOnlineSupport stage: 23')
   //       await updateDoc(docRefElectionCandiCoreList, {
   //          Candidates: arrayRemove(candiRecordPositive),
   //       })
   //       await updateDoc(docRefElectionCandiCoreList, {
   //          Candidates: arrayUnion(candiRecordPositiveNew),
   //       })

   //       // update the support in local state
   //       console.log('recordOnlineSupport stage: 24')
   //       const candiRecord = candisList.find((item) => item.Candidate_id === onlineSupportDetails.Candidate_id)
   //       if (candiRecord) {
   //          candiRecord.OnlineSupport = candiRecord.OnlineSupport + 1
   //       }

   //       if (lastCandiSupported && lastCandiSupported.Candidate_id !== onlineSupportDetails.Candidate_id) {
   //          const candiRecordPrev = candisList.find((item) => item.Candidate_id === lastCandiSupported.Candidate_id)
   //          if (candiRecordPrev) {
   //             candiRecordPrev.OnlineSupport = candiRecordPrev.OnlineSupport - 1
   //          }

   //          candi.OnlineSupports = candi.OnlineSupports - 1
   //       } else {
   //          candi.OnlineSupports = candi.OnlineSupports + 1
   //       }

   //       const sortedListForOnineSupport = [...candisList].sort((a, b) => b.OnlineSupport - a.OnlineSupport)
   //       sortCandisList(sortedListForOnineSupport)

   //       // set the state of userProfile afresh
   //       const querryUser = query(collection(db, 'UserProfiles'), where('User_id', '==', auth.currentUser.uid))
   //       const querySnapshot = await getDocs(querryUser)
   //       let userFetched = ''
   //       querySnapshot.forEach((docSnap) => {
   //          userFetched = { id: docSnap.id, ...docSnap.data() }
   //       })
   //       dispatch(setUserProfile(userFetched))

   //       handleOpenDialogThanks()
   //       setUploadingOnlineSupport(false)
   //       setOpenModalOnlineSupport(false)
   //    } catch (error) {
   //       console.log('error recording support', error)
   //       setUploadingOnlineSupport(false)
   //    }
   // }

   const cancelOnlineSupport = async () => {
      setOpenModalOnlineSupport(false)
      setUploadingOnlineSupport(false)
   }

   // not logged in

   const [openDialogNotLoggedIn, setOpenDialogNotLoggedIn] = useState(false)

   const handleOpenDialogNotLoggedIn = async () => {
      setOpenDialogNotLoggedIn(true)
   }

   const handleCloseDialogNotLoggedIn = () => setOpenDialogNotLoggedIn(false)

   // date passed

   const [openDialogDatePassed, setOpenDialogDatePassed] = useState(false)

   const handleOpenDialogDatePassed = async () => {
      setOpenDialogDatePassed(true)
   }

   const handleCloseDialogDatePassed = () => setOpenDialogDatePassed(false)

   // hours remaining

   const [openDialogHours, setOpenDialogHours] = useState(false)

   const handleOpenDialogHours = async () => {
      setOpenDialogHours(true)
   }

   const handleCloseDialogHours = () => setOpenDialogHours(false)

   // attached to other consti at the same level

   const [openDialogAtOtherConsti, setOpenDialogAtOtherConsti] = useState(false)

   const handleOpenDialogAtOtherConsti = async () => {
      setOpenDialogAtOtherConsti(true)
   }

   const handleCloseDialogAtOtherConsti = () => setOpenDialogAtOtherConsti(false)

   // supporting same candidate

   const [openDialogSameCandi, setOpenDialogSameCandi] = useState(false)

   const handleOpenDialogSameCandi = async () => {
      setOpenDialogSameCandi(true)
   }

   const handleCloseDialogSameCandi = () => setOpenDialogSameCandi(false)

   // supporting different candidate

   // const [openDialogDifferentCandi, setOpenDialogDifferentCandi] = useState(false)

   // const handleOpenDialogDifferentCandi = async () => {
   //    setOpenDialogDifferentCandi(true)
   // }

   // const handleCloseDialogDifferentCandi = () => setOpenDialogDifferentCandi(false)

   // thanks for support

   const [openDialogThanks, setOpenDialogThanks] = useState(false)

   const handleOpenDialogThanks = async () => {
      setOpenDialogThanks(true)
   }

   const handleCloseDialogThanks = () => setOpenDialogThanks(false)

   return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
         <div>
            <div>
               {/* <Helmet>
                  <title>{`Track Democracy - Candidate - ${candidate.Name} for ${election.Name}`}</title>
               </Helmet> */}
               <ChangePageTitle
                  pageTitle={`Track Democracy vvv - Candidate - ${candidate.Name} for ${election.Name}`}
               />
            </div>
            <Box
               sx={{
                  display: 'flex',
                  borderRadius: 1,
                  flexDirection: 'column',
                  px: 2,
               }}
            >
               <Box
                  sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', mb: 2 }}
               >
                  {candidate.Intro && candidate.Intro !== '' && (
                     <Typography
                        variant="body2"
                        sx={{
                           backgroundColor: '#F1F1F1',
                           p: 1,
                           borderRadius: 2,
                        }}
                     >
                        {candidate.Intro}
                        {(isDataEditor || isSuper) && (
                           <>
                              <IconButton
                                 edge="end"
                                 aria-label="editIntro"
                                 onClick={(e) => {
                                    handleOpenModalIntro(e)
                                 }}
                                 sx={{ ml: 2, height: '25px', width: '25px' }}
                              >
                                 <EditIcon fontSize="small" />
                              </IconButton>
                              <IconButton
                                 edge="end"
                                 aria-label="deleteIntro"
                                 onClick={(e) => {
                                    handleRemoveIntro(e)
                                 }}
                                 sx={{ ml: 2, height: '25px', width: '25px' }}
                              >
                                 <DeleteIcon fontSize="small" />
                              </IconButton>
                           </>
                        )}
                     </Typography>
                  )}

                  {(isDataEditor || isSuper) && (!candidate.Intro || candidate.Intro.Text === '') && (
                     <Button
                        variant="outlined"
                        endIcon={<EditIcon />}
                        sx={{ ml: 1 }}
                        onClick={(e) => {
                           handleOpenModalIntro(e)
                        }}
                     >
                        Intro
                     </Button>
                  )}
               </Box>
               <Table>
                  <tbody>
                     <TableRow id="rowBody">
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Body: {'\n'} संस्था:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              <Box sx={{ fontWeight: 600 }}>
                                 {candidate.LegislatureName && (
                                    <Link
                                       href={`/legislature/${candidate.LegislatureName}/${candidate.Legislature_id}`}
                                       sx={{ textDecoration: 'none !important', px: 0 }}
                                       rel="noreferrer"
                                       target="_blank"
                                    >
                                       {candidate.LegislatureNameLocal
                                          ? `${candidate.LegislatureNameLocal} - ${candidate.LegislatureName}`
                                          : `${candidate.LegislatureName} - ${candidate.LegislatureNameHindi}`}
                                    </Link>
                                 )}
                              </Box>
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow id="rowHouseNumber">
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           House number: {'\n'} सदन संख्या:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              <Box sx={{ fontWeight: 600 }}>{election.HouseNumber}</Box>
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow id="rowConstituency">
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Constituency: {'\n'} निर्वाचन क्षेत्र:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              <Box sx={{ fontWeight: 600 }}>
                                 {candidate.ConstituencyNameLocal
                                    ? `${candidate.ConstituencyNameLocal} (${candidate.ConstituencyNumber}) - ${candidate.ConstituencyName} - ${candidate.ConstituencyNameHindi}`
                                    : `${candidate.ConstituencyName} (${candidate.ConstituencyNumber}) - ${candidate.ConstituencyNameHindi}`}
                              </Box>
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow id="rowSeatType">
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Seat type: {'\n'} सीट का प्रकार::
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              <Box sx={{ fontWeight: 600 }}>{candidate.SeatType}</Box>
                           </Box>
                        </TableCell>
                     </TableRow>

                     <TableRow id="rowVotes">
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Number of votes: {'\n'} मत संख्या:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box>
                              <Box>{candidate.Votes}</Box>
                              <Box>
                                 {candidate.Votes === '' && (
                                    <Typography
                                       variant="body2"
                                       sx={{ color: '#ff9800', textAlign: 'left', fontWeight: 600 }}
                                    >
                                       Waiting for election / resulsts
                                    </Typography>
                                 )}
                                 {candidate.Winner === 'true' && <img src={winner} className={myStyles.imgXX} />}
                              </Box>
                           </Box>
                        </TableCell>
                     </TableRow>

                     <TableRow id="rowOnlineSupports">
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Online Supports: {'\n'} ऑनलाइन समर्थन:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box sx={{ fontWeight: 600, display: 'flex', flexDirection: 'row', my: 0.5 }}>
                              <Box
                                 sx={{
                                    color: '#4caf50',
                                    px: 1,
                                    mr: 2,
                                    borderRadius: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                 }}
                              >
                                 <ThumbUpAltIcon sx={{ fontSize: 20, verticalAlign: 'middle', mr: 1 }} />
                                 {candidate.OnlineSupports}
                              </Box>
                              <Box
                                 sx={{
                                    color: 'Crimson',
                                    px: 1,
                                    mr: 5,
                                    display: 'none',
                                 }}
                              >
                                 <ThumbDownAltIcon sx={{ fontSize: 20, verticalAlign: 'middle', mr: 1 }} />
                                 {candidate.OnlineSupportsCancelled}
                              </Box>
                              <Button
                                 variant="contained"
                                 sx={{
                                    textTransform: 'none',
                                    py: 0.5,
                                    minWidth: 0,
                                    px: 2,
                                    backgroundColor: '#03a9f4',
                                    whiteSpace: 'pre-line',
                                    lineHeight: 1.2,
                                    color: '#FFFFFF',
                                    '&:hover': { backgroundColor: '#01579b' },
                                    // display: 'none',
                                 }}
                                 onClick={(e) => {
                                    handleOpenModalOnlineSupport(e)
                                 }}
                              >
                                 Support Online <HowToVoteIcon sx={{ mx: 1 }} /> Online समर्थन करें
                              </Button>
                           </Box>
                        </TableCell>
                     </TableRow>
                     {(isDataEditor || isSuper) && (
                        <TableRow id="rowName">
                           <TableCell className={myStyles.cell_style_left} variant="head">
                              Name:
                           </TableCell>
                           <TableCell className={myStyles.cell_style_right}>
                              <Box
                                 sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                 }}
                              >
                                 <Box>{candidate.Name}</Box>

                                 {(isDataEditor || isSuper) && (
                                    <IconButton
                                       edge="end"
                                       aria-label="editName"
                                       onClick={(e) => {
                                          handleOpenModalName(e)
                                       }}
                                       sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                    >
                                       <EditIcon fontSize="small" />
                                    </IconButton>
                                 )}
                              </Box>
                           </TableCell>
                        </TableRow>
                     )}
                     {(isDataEditor || isSuper) && (
                        <TableRow id="rowNameHindi" className={myStyles.row_style}>
                           <TableCell className={myStyles.cell_style_left} variant="head">
                              Name hindi:
                           </TableCell>
                           <TableCell className={myStyles.cell_style_right}>
                              <Box
                                 sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                 }}
                              >
                                 <Box>{candidate.NameHindi}</Box>

                                 {(isDataEditor || isSuper) && (
                                    <IconButton
                                       edge="end"
                                       aria-label="editNameHindi"
                                       onClick={(e) => {
                                          handleOpenModalNameHindi(e)
                                       }}
                                       sx={{
                                          ml: 'auto',
                                          height: '25px',
                                          width: '25px',
                                       }}
                                    >
                                       <EditIcon fontSize="small" />
                                    </IconButton>
                                 )}
                              </Box>
                           </TableCell>
                        </TableRow>
                     )}
                     {(isDataEditor || isSuper) && (
                        <TableRow id="rowNameLocal" className={myStyles.row_style}>
                           <TableCell className={myStyles.cell_style_left} variant="head">
                              Name local:
                           </TableCell>
                           <TableCell className={myStyles.cell_style_right}>
                              <Box
                                 sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                 }}
                              >
                                 <Box>{candidate.NameLocal}</Box>

                                 {(isDataEditor || isSuper) && (
                                    <IconButton
                                       edge="end"
                                       aria-label="editNameLocal"
                                       onClick={(e) => {
                                          handleOpenModalNameLocal(e)
                                       }}
                                       sx={{
                                          ml: 'auto',
                                          height: '25px',
                                          width: '25px',
                                       }}
                                    >
                                       <EditIcon fontSize="small" />
                                    </IconButton>
                                 )}
                              </Box>
                           </TableCell>
                        </TableRow>
                     )}
                     <TableRow id="rowGender">
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Gender: {'\n'} लिंग:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box>
                              <Box>{candidate.Gender}</Box>
                              <Box>
                                 {candidate.Gender === 'Male' && 'पुरुष'}
                                 {candidate.Gender === 'Female' && 'स्त्री'}
                                 {candidate.Gender === 'Transgender' && 'तृतीय लिंग'}
                              </Box>

                              {/* {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editDoB"
                                    onClick={(e) => {
                                       handleOpenModalDoB(e)
                                    }}
                                    sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )} */}
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow id="rowDoB">
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Birth date {'\n'} जन्म तिथि:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              <Box>
                                 {candidate.DoB !== '' && (
                                    <Box>{moment(candidate.DoB.toDate()).format('Do MMMM YYYY')}</Box>
                                 )}
                              </Box>

                              {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editDoB"
                                    onClick={(e) => {
                                       handleOpenModalDoB(e)
                                    }}
                                    sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow id="rowAge">
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Age {'\n'} आयु:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              <Box>{candidate.Age}</Box>

                              {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editAge"
                                    onClick={(e) => {
                                       handleOpenModalAge(e)
                                    }}
                                    sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}
                           </Box>
                        </TableCell>
                     </TableRow>
                     {(isDataEditor || isSuper) && (
                        <TableRow id="rowImage">
                           <TableCell className={myStyles.cell_style_left} variant="head">
                              Image:
                           </TableCell>
                           <TableCell className={myStyles.cell_style_right}>
                              <Box
                                 sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                 }}
                              >
                                 <Box>
                                    {sourceImage !== '' ? <img src={sourceImage} className={myStyles.imgX} /> : null}
                                 </Box>

                                 {(isDataEditor || isSuper) && (
                                    <IconButton
                                       edge="end"
                                       aria-label="editImage"
                                       onClick={(e) => {
                                          handleOpenModalImage(e)
                                       }}
                                       sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                    >
                                       <EditIcon fontSize="small" />
                                    </IconButton>
                                 )}
                              </Box>
                           </TableCell>
                        </TableRow>
                     )}
                     <TableRow id="rowParty">
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Party: {'\n'} दल:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                                 alignItems: 'center',
                                 pt: 0.5,
                              }}
                           >
                              {candidate.PartyFlag && (
                                 <Link
                                    href={`/political-party/${candidate.PartyName}/${candidate.Party_id}`}
                                    sx={{ mr: 1, ml: 0.5, textDecoration: 'none !important' }}
                                    rel="noreferrer"
                                    target="_blank"
                                 >
                                    <Box>
                                       <img src={candidate.PartyFlag} className={myStyles.imgX} />
                                    </Box>
                                 </Link>
                              )}
                              {candidate.PartyName === 'Independent' ? (
                                 <Box
                                    sx={{
                                       whiteSpace: 'pre-line',
                                    }}
                                 >
                                    {candidate.PartyName} {'\n'} {candidate.PartyNameHindi} {'\n'}{' '}
                                    {candidate.PartyNameLocal}
                                 </Box>
                              ) : (
                                 <Link
                                    href={`/political-party/${candidate.PartyName}/${candidate.Party_id}`}
                                    sx={{ mr: 1, ml: 0.5, textDecoration: 'none !important' }}
                                    rel="noreferrer"
                                    target="_blank"
                                 >
                                    <Box
                                       sx={{
                                          whiteSpace: 'pre-line',
                                       }}
                                    >
                                       {candidate.PartyName} {'\n'} {candidate.PartyNameHindi} {'\n'}{' '}
                                       {candidate.PartyNameLocal}
                                    </Box>
                                 </Link>
                              )}
                              {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editAllottedSymbol"
                                    onClick={(e) => {
                                       handleOpenModalParty(e)
                                    }}
                                    sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow id="rowSymbol">
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Symbol {'\n'} चुनाव चिन्ह:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                                 alignItems: 'center',
                                 pt: 0.5,
                              }}
                           >
                              {candidate.PartySymbol && <img src={candidate.PartySymbol} className={myStyles.imgX} />}
                              {sourceAllottedSymbol && <img src={sourceAllottedSymbol} className={myStyles.imgX} />}
                              {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editAllottedSymbol"
                                    onClick={(e) => {
                                       handleOpenModalAllottedSymbol(e)
                                    }}
                                    sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow id="rowAlliances">
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Alliances {'\n'} गठबंधन:
                        </TableCell>
                        <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              <Box>
                                 {candidate.Alliances.map((itemAl, index) => (
                                    <>
                                       {index + 1}.
                                       <Link
                                          key={itemAl.Alliance_id}
                                          href={`/alliance/${itemAl.Name}/${itemAl.Alliance_id}`}
                                          sx={{ mr: 1, ml: 0.5, textDecoration: 'none !important' }}
                                          rel="noreferrer"
                                          target="_blank"
                                       >
                                          {itemAl.Abbreviation}
                                       </Link>
                                    </>
                                 ))}
                              </Box>

                              {/* {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editAddress"
                                    onClick={(e) => {
                                       handleOpenModalAddress(e)
                                    }}
                                    sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )} */}
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow id="rowAddress">
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Addresses {'\n'} पता:
                        </TableCell>
                        <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              {candidate.Address && (
                                 <Box>
                                    <div>{candidate.Address.Address}</div>
                                    <span>
                                       {candidate.Address.DistrictName} - {candidate.Address.StateName} -{' '}
                                       {candidate.Address.PinCode}
                                    </span>
                                 </Box>
                              )}

                              <Box>
                                 {candidate.Address &&
                                    candidate.Address.length > 0 &&
                                    candidate.Address.map((item, index) => (
                                       <div key={index}>
                                          <div>{item.Address}</div>
                                          <span>
                                             {item.DistrictName} - {item.StateName} - {item.PinCode}
                                          </span>
                                       </div>
                                    ))}
                              </Box>

                              {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editAddress"
                                    onClick={(e) => {
                                       handleOpenModalAddress(e)
                                    }}
                                    sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow id="rowPhones">
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Phones {'\n'} दूरभाष:
                        </TableCell>
                        <TableCell className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              <Box>
                                 {candidate.Phones &&
                                    candidate.Phones.length > 0 &&
                                    candidate.Phones.map((item, index) => <div key={index}>{item}</div>)}
                              </Box>

                              {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editPhone"
                                    onClick={(e) => {
                                       handleOpenModalPhone(e)
                                    }}
                                    sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow id="rowWeblinks">
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Web Links {'\n'} वेब लिंक:
                        </TableCell>
                        <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                                 justifyContent: 'center',
                                 alignItems: 'center',
                              }}
                           >
                              <Box>
                                 {candidate.WebLinks &&
                                    candidate.WebLinks.length > 0 &&
                                    candidate.WebLinks.map((item, index) => (
                                       <div key={index}>
                                          {item &&
                                             (item.indexOf('http://') === 0 || item.indexOf('https://') === 0) && (
                                                <Link
                                                   underline="hover"
                                                   href={item}
                                                   target="_blank"
                                                   rel="noopener noreferrer"
                                                >
                                                   {item.substring(0, 25) === 'https://www.facebook.com/' && (
                                                      <FacebookIcon />
                                                   )}
                                                   {item.substring(0, 26) === 'https://www.instagram.com/' && (
                                                      <InstagramIcon />
                                                   )}
                                                   {item.substring(0, 25) === 'https://www.linkedin.com/' && (
                                                      <LinkedInIcon />
                                                   )}
                                                   {item.substring(0, 14) === 'https://x.com/' && <XIcon />}
                                                </Link>
                                             )}
                                          {item && item.indexOf('http://') !== 0 && item.indexOf('https://') !== 0 && (
                                             <Link
                                                underline="hover"
                                                href={`//${item}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                             >
                                                {item.substring(0, 25) === 'https://www.facebook.com/' ? (
                                                   <FacebookIcon />
                                                ) : item.substring(0, 26) === 'https://www.instagram.com/' ? (
                                                   <InstagramIcon />
                                                ) : item.substring(0, 25) === 'https://www.linkedin.com/' ? (
                                                   <LinkedInIcon />
                                                ) : item.substring(0, 14) === 'https://x.com/' ? (
                                                   <XIcon sx={{ color: '#000000' }} />
                                                ) : item.substring(0, 24) === 'https://www.youtube.com/' ? (
                                                   <YouTubeIcon />
                                                ) : (
                                                   item
                                                )}
                                             </Link>
                                          )}
                                       </div>
                                    ))}
                              </Box>

                              {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editWebsite"
                                    onClick={(e) => {
                                       handleOpenModalWebsite(e)
                                    }}
                                    sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow id="rowEmails">
                        <TableCell
                           sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                           className={myStyles.cell_style_left}
                           variant="head"
                        >
                           Emails {'\n'} ईमेल:
                        </TableCell>
                        <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={myStyles.cell_style_right}>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                              }}
                           >
                              <Box>
                                 {candidate.Emails &&
                                    candidate.Emails.length > 0 &&
                                    candidate.Emails.map((item, index) => <div key={index}>{item}</div>)}
                              </Box>

                              {(isDataEditor || isSuper) && (
                                 <IconButton
                                    edge="end"
                                    aria-label="editEmail"
                                    onClick={(e) => {
                                       handleOpenModalEmail(e)
                                    }}
                                    sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                 >
                                    <EditIcon fontSize="small" />
                                 </IconButton>
                              )}
                           </Box>
                        </TableCell>
                     </TableRow>
                  </tbody>
               </Table>
               {(isDataEditor || isSuper) && <Typography id="core-list-id">{candidate.CoreListId}</Typography>}
            </Box>
            <Box id="boxModals">
               <Modal
                  open={openModalIntro}
                  onClose={handleCloseModalIntro}
                  aria-labelledby="modal-modal-title-intro"
                  aria-describedby="modal-modal-description-intro"
                  disableScrollLock
               >
                  <Box sx={styleModalBox}>
                     <Typography
                        id="modal-modal-title-intro"
                        variant="h6"
                        component="h2"
                        align="center"
                        sx={styleModalTypography}
                     >
                        Add or Change Intro
                     </Typography>
                     <AddTextMultiline
                        handleAddTextMultiline={handleAddIntro}
                        labelName="Intro"
                        textLength="500"
                        prevText={candidate.Intro ? candidate.Intro : ''}
                     ></AddTextMultiline>
                  </Box>
               </Modal>
               <Modal
                  open={openModalName}
                  onClose={handleCloseModalName}
                  aria-labelledby="modal-modal-title-name"
                  aria-describedby="modal-modal-description-name"
                  disableScrollLock
               >
                  <Box sx={styleModalBox}>
                     <Typography
                        id="modal-modal-title-name"
                        variant="h6"
                        component="h6"
                        align="center"
                        sx={styleModalTypography}
                     >
                        Change name
                     </Typography>
                     <AddText handleAddText={handleAddName} labelName="Name"></AddText>
                  </Box>
               </Modal>
               <Modal
                  open={openModalNameHindi}
                  onClose={handleCloseModalNameHindi}
                  aria-labelledby="modal-modal-title-Hindiname"
                  aria-describedby="modal-modal-description-Hindiname"
                  disableScrollLock
               >
                  <Box sx={styleModalBox}>
                     <Typography
                        id="modal-modal-title-hindiname"
                        variant="h6"
                        component="h6"
                        align="center"
                        sx={styleModalTypography}
                     >
                        Add name in hindi
                     </Typography>
                     <AddText handleAddText={handleAddNameHindi} labelName="Name in hindi"></AddText>
                  </Box>
               </Modal>
               <Modal
                  open={openModalNameLocal}
                  onClose={handleCloseModalNameLocal}
                  aria-labelledby="modal-modal-title-localname"
                  aria-describedby="modal-modal-description-localname"
                  disableScrollLock
               >
                  <Box sx={styleModalBox}>
                     <Typography
                        id="modal-modal-title-localname"
                        variant="h6"
                        component="h6"
                        align="center"
                        sx={styleModalTypography}
                     >
                        Add name in local language
                     </Typography>
                     <AddText handleAddText={handleAddNameLocal} labelName="Name (in local language)"></AddText>
                  </Box>
               </Modal>
               <Modal
                  open={openModalDoB}
                  onClose={handleCloseModalDoB}
                  aria-labelledby="modal-modal-title-dob"
                  aria-describedby="modal-modal-description-dob"
                  disableScrollLock
               >
                  <Box sx={styleModalBox}>
                     <Typography
                        id="modal-modal-title-dob"
                        variant="h6"
                        component="h2"
                        align="center"
                        sx={styleModalTypography}
                     >
                        Add Date Of Birth {'\n'} <strong>जन्म तिथि जोड़ें</strong>
                     </Typography>
                     <AddDate labelName="Date of Birth / जन्म तिथि" handleAddDate={handleAddDoB}></AddDate>
                  </Box>
               </Modal>
               <Modal
                  open={openModalAge}
                  onClose={handleCloseModalAge}
                  aria-labelledby="modal-modal-title-age"
                  aria-describedby="modal-modal-description-age"
                  disableScrollLock
               >
                  <Box sx={styleModalBox}>
                     <Typography
                        id="modal-modal-title-age"
                        variant="h6"
                        component="h2"
                        align="center"
                        sx={styleModalTypography}
                     >
                        Add Age
                     </Typography>
                     <AddNumber label="Age / आयु" handleAddNumber={handleAddAge}></AddNumber>
                  </Box>
               </Modal>
               <Modal
                  open={openModalImage}
                  onClose={handleCloseModalImage}
                  aria-labelledby="modal-modal-title-logo"
                  aria-describedby="modal-modal-description-logo"
                  disableScrollLock
               >
                  <Box sx={styleModalBox}>
                     <Typography
                        id="modal-modal-title-logo"
                        variant="h6"
                        component="h2"
                        align="center"
                        sx={styleModalTypography}
                     >
                        Add image of Candidate
                     </Typography>
                     <AddImageOnly handleAddImage={handleAddImage}></AddImageOnly>
                  </Box>
               </Modal>
               <Modal
                  open={openModalParty}
                  onClose={handleCloseModalParty}
                  aria-labelledby="modal-modal-title-party"
                  aria-describedby="modal-modal-description-party"
                  disableScrollLock
               >
                  <Box sx={styleModalBox}>
                     <Typography
                        id="modal-modal-title-flag"
                        variant="h6"
                        component="h2"
                        align="center"
                        sx={styleModalTypography}
                     >
                        Add / change party
                     </Typography>
                     <AddParty
                        lastParty={lastParty}
                        uploadingParty={uploadingParty}
                        uploadedParty={uploadedParty}
                        uploadingFailedParty={uploadingFailedParty}
                        handleAddParty={handleAddParty}
                     ></AddParty>
                  </Box>
               </Modal>
               <Modal
                  open={openModalAllottedSymbol}
                  onClose={handleCloseModalAllottedSymbol}
                  aria-labelledby="modal-modal-title-flag"
                  aria-describedby="modal-modal-description-flag"
                  disableScrollLock
               >
                  <Box sx={styleModalBox}>
                     <Typography
                        id="modal-modal-title-flag"
                        variant="h6"
                        component="h2"
                        align="center"
                        sx={styleModalTypography}
                     >
                        Add image of symbol
                     </Typography>
                     <AddImageOnly handleAddImage={handleAddImageAllottedSymbol}></AddImageOnly>
                  </Box>
               </Modal>
               <Modal
                  open={openModalAddress}
                  onClose={handleCloseModalAddress}
                  aria-labelledby="modal-modal-title-headquarter"
                  aria-describedby="modal-modal-description-headquarter"
                  disableScrollLock
               >
                  <Box sx={styleModalBox}>
                     <Typography
                        id="modal-modal-title-headquarter"
                        variant="h6"
                        component="h2"
                        align="center"
                        sx={styleModalTypography}
                     >
                        Add Address
                     </Typography>
                     <AddAddress countryId={candidate.Country_id} handleAddAddress={handleAddAddress}></AddAddress>
                  </Box>
               </Modal>
               <Modal
                  open={openModalPhone}
                  onClose={handleCloseModalPhone}
                  aria-labelledby="modal-modal-title-phones"
                  aria-describedby="modal-modal-description-phones"
                  disableScrollLock
               >
                  <Box sx={styleModalBox}>
                     <Typography
                        id="modal-modal-title-phones"
                        variant="h6"
                        component="h2"
                        align="center"
                        sx={styleModalTypography}
                     >
                        Add Phones:
                     </Typography>
                     <AddPhone
                        lastPhone={lastPhone}
                        handleAddPhone={handleAddPhone}
                        uploadingPhone={uploadingPhone}
                        uploadedPhone={uploadedPhone}
                        uploadingFailedPhone={uploadingFailedPhone}
                     ></AddPhone>
                  </Box>
               </Modal>
               <Modal
                  open={openModalWebsite}
                  onClose={handleCloseModalWebsite}
                  aria-labelledby="modal-modal-title-website"
                  aria-describedby="modal-modal-description-website"
                  disableScrollLock
               >
                  <Box sx={styleModalBox}>
                     <Typography
                        id="modal-modal-title-website"
                        variant="h6"
                        component="h2"
                        align="center"
                        sx={styleModalTypography}
                     >
                        Add a web link
                     </Typography>
                     <AddWeblink handleAddWeblink={handleAddWebsite}></AddWeblink>
                  </Box>
               </Modal>
               <Modal
                  open={openModalEmail}
                  onClose={handleCloseModalEmail}
                  aria-labelledby="modal-modal-title-email"
                  aria-describedby="modal-modal-description-email"
                  disableScrollLock
               >
                  <Box sx={styleModalBox}>
                     <Typography
                        id="modal-modal-title-email"
                        variant="h6"
                        component="h2"
                        align="center"
                        sx={styleModalTypography}
                     >
                        Add an email
                     </Typography>
                     <AddEmail handleAddEmail={handleAddEmail}></AddEmail>
                  </Box>
               </Modal>

               <Modal
                  open={openModalOnlineSupport}
                  onClose={closeModalOnlineSupport}
                  aria-labelledby="modal-modal-title-addOnlineSupport"
                  aria-describedby="modal-modal-description-addOnlineSupport"
                  disableScrollLock
               >
                  <Box sx={styleModalBox}>
                     {consti && consti.Name !== '' && (
                        <Typography
                           id="modal-modal-title-addOnlineSupport"
                           variant="h6"
                           component="h6"
                           align="center"
                           sx={styleModalTypography}
                        >
                           Support a candidate for{' '}
                           <strong>
                              {consti.Name.toUpperCase()} ({consti.ConstituencyNumber})
                           </strong>
                           {'\n'}{' '}
                           <strong>
                              {consti.NameHindi} ({consti.ConstituencyNumber})
                           </strong>{' '}
                           के लिए एक प्रत्याशी का समर्थन करें
                        </Typography>
                     )}
                     <AddCandiOnlineSupport
                        actionForSupport={actionForSupport}
                        candidatesListForOnlineSupport={candisList}
                        uploadingOnlineSupport={uploadingOnlineSupport}
                        uploadingFailedOnlineSupport={uploadingFailedOnlineSupport}
                        uploadedOnlineSupport={uploadedOnlineSupport}
                        addOnlineSupport={addOnlineSupport}
                        cancelOnlineSupport={cancelOnlineSupport}
                     ></AddCandiOnlineSupport>
                  </Box>
               </Modal>
               <Dialog
                  open={openDialogNotLoggedIn}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleCloseDialogNotLoggedIn}
                  aria-describedby="alert-dialog-slide-notLoggedIn"
               >
                  <DialogTitle
                     sx={{
                        whiteSpace: 'pre-line',
                        textAlign: 'center',
                     }}
                  >{`Please log in. ${'\n'} कृपया लॉगिन करें।`}</DialogTitle>
                  <DialogContent>
                     <DialogContentText
                        id="alert-dialog-slide-notLoggedIn"
                        sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}
                     >
                        You are not logged-in.
                        {'\n'}
                        आपने लॉग - इन नहीं किया है।
                     </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                     <Button onClick={handleCloseDialogNotLoggedIn}>Close</Button>
                  </DialogActions>
               </Dialog>
               <Dialog
                  open={openDialogDatePassed}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleCloseDialogDatePassed}
                  aria-describedby="alert-dialog-slide-datePassed"
               >
                  <DialogTitle
                     sx={{
                        whiteSpace: 'pre-line',
                        textAlign: 'center',
                     }}
                  >{`Online Support ${'\n'} ऑनलाइन समर्थन`}</DialogTitle>
                  <DialogContent>
                     <DialogContentText
                        id="alert-dialog-slide-datePassed"
                        sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}
                     >
                        The date for <strong>phase {consti.PhaseNum}</strong> of <strong>{elec.Name}</strong> is over
                        for this facility.
                     </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                     <Button onClick={handleCloseDialogDatePassed}>Close</Button>
                  </DialogActions>
               </Dialog>
               <Dialog
                  open={openDialogHours}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleCloseDialogHours}
                  aria-describedby="alert-dialog-slide-hours"
               >
                  <DialogTitle
                     sx={{
                        whiteSpace: 'pre-line',
                        textAlign: 'center',
                     }}
                  >{`Online Support ${'\n'} ऑनलाइन समर्थन`}</DialogTitle>
                  <DialogContent>
                     <DialogContentText id="alert-dialog-slide-hours" sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}>
                        You supported{' '}
                        {userProfile &&
                           userProfile !== '' &&
                           userProfile.LS_Candi_StateConstituency &&
                           `${userProfile.LS_Candi_StateConstituency.CandidateName}`}{' '}
                        on{' '}
                        {userProfile &&
                           userProfile !== '' &&
                           userProfile.LS_Candi_StateConstituency &&
                           userProfile.LS_Candi_StateConstituency.CrDt &&
                           `${moment(userProfile.LS_Candi_StateConstituency.CrDt.toDate()).format('Do MMMM YYYY')}`}
                        . You can change your support after expiry of 24 hours.
                        {'\n'}
                        आपने{' '}
                        {userProfile &&
                           userProfile !== '' &&
                           userProfile.LS_Candi_StateConstituency &&
                           userProfile.LS_Candi_StateConstituency.CrDt &&
                           `${moment(userProfile.LS_Candi_StateConstituency.CrDt.toDate()).format('Do MMMM YYYY')}`}{' '}
                        को{' '}
                        {userProfile &&
                           userProfile !== '' &&
                           userProfile.LS_Candi_StateConstituency &&
                           `${userProfile.LS_Candi_StateConstituency.CandidateNameHindi}`}{' '}
                        का समर्थन किया था| आप 24 घंटे की समाप्ति के बाद अपना समर्थन बदल सकते हैं|
                     </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                     <Button onClick={handleCloseDialogHours}>Close</Button>
                  </DialogActions>
               </Dialog>
               <Dialog
                  open={openDialogSameCandi}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleCloseDialogSameCandi}
                  aria-describedby="alert-dialog-slide-sameCandi"
               >
                  <DialogTitle
                     sx={{
                        whiteSpace: 'pre-line',
                        textAlign: 'center',
                     }}
                  >{`Online Support ${'\n'} ऑनलाइन समर्थन`}</DialogTitle>
                  <DialogContent>
                     <DialogContentText id="alert-dialog-slide-hours" sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}>
                        <Typography sx={{ color: '#4caf50' }}>
                           Thanks for showing support to your favourite candidate.
                        </Typography>
                        You have supported the same candidate you supported last time. Both will be counted as one.
                        <br />
                        <strong>
                           Please make sure to go to the polling booth on{' '}
                           {phaseDate && `${moment(phaseDate.toDate()).format('Do MMM YYYY')}`}
                           to caste your vote. This is your national duty.
                        </strong>
                        <br />
                        <br />
                        <Typography sx={{ color: '#4caf50' }}>
                           अपने पसंदीदा प्रत्याशी को समर्थन दिखाने के लिए धन्यवाद।
                        </Typography>
                        आपने उसी प्रत्याशी का समर्थन किया है जिसका आपने पिछली बार समर्थन किया था। दोनों को एक ही माना
                        जाएगा।
                        <br />
                        <strong>
                           कृपया {phaseDate && `${moment(phaseDate.toDate()).format('Do MMM YYYY')}`} को मतदान केंद्र पर
                           जाकर अपना वोट अवश्य डालें। यह आपका राष्ट्रीय कर्तव्य है।
                        </strong>
                     </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                     <Button onClick={handleCloseDialogSameCandi}>Close</Button>
                  </DialogActions>
               </Dialog>
               {/* <Dialog
                  open={openDialogDifferentCandi}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleCloseDialogDifferentCandi}
                  aria-describedby="alert-dialog-slide-differentCandi"
               >
                  <DialogTitle
                     sx={{
                        whiteSpace: 'pre-line',
                        textAlign: 'center',
                     }}
                  >{`Online Support ${'\n'} ऑनलाइन समर्थन`}</DialogTitle>
                  <DialogContent>
                     <DialogContentText
                        id="alert-dialog-slide-differentCandi"
                        sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}
                     >
                        <Typography sx={{ color: '#4caf50' }}>
                           Thanks for showing support to your favourite candidate.
                        </Typography>
                        <br />
                        You have supported a candidate other than the one you supported last time. The earlier support
                        will be dismissed and this support will be counted.
                        <br />
                        <strong>
                           Please make sure to go to the polling booth on{' '}
                           {phaseDate && `${moment(phaseDate.toDate()).format('Do MMM YYYY')}`} to caste your vote. This
                           is your national duty.
                        </strong>
                        <br />
                        <Typography sx={{ color: '#4caf50' }}>
                           अपने पसंदीदा प्रत्याशी को समर्थन दिखाने के लिए धन्यवाद।
                        </Typography>
                        <br />
                        आपने पिछली बार जिस प्रत्याशी का समर्थन किया था, उसके अलावा किसी अन्य प्रत्याशी का समर्थन किया
                        है। पहले किया गया समर्थन निरस्त कर दिया जाएगा और इस समर्थन को गिना जाएगा।
                        <br />
                        <strong>
                           कृपया {phaseDate && `${moment(phaseDate.toDate()).format('Do MMM YYYY')}`} को मतदान केंद्र पर
                           जाकर अपना वोट अवश्य डालें। यह आपका राष्ट्रीय कर्तव्य है।
                        </strong>
                     </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                     <Button onClick={handleCloseDialogDifferentCandi}>Close</Button>
                  </DialogActions>
               </Dialog> */}
               <Dialog
                  id="atOtherConsti"
                  open={openDialogAtOtherConsti}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleCloseDialogAtOtherConsti}
                  aria-describedby="alert-dialog-slide-atOtherConsti"
               >
                  <DialogTitle
                     sx={{
                        whiteSpace: 'pre-line',
                        textAlign: 'center',
                     }}
                  >{`Online Support ${'\n'} ऑनलाइन समर्थन`}</DialogTitle>
                  <DialogContent>
                     <DialogContentText
                        id="alert-dialog-slide-atOtherConsti"
                        sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}
                     >
                        <strong>
                           You are attached to {attachedConstiName} at the same level. Hence you cant support candidate
                           of this constituency.
                        </strong>
                        {'\n'}
                        आप इस स्तर पर {attachedConstiName} निर्वाचन क्षेत्र से जुड़े हुए हैं। इसलिए आप इस निर्वाचन
                        क्षेत्र के प्रत्याशी का समर्थन नहीं कर सकते।
                     </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                     <Button onClick={handleCloseDialogAtOtherConsti}>Close</Button>
                  </DialogActions>
               </Dialog>

               <Dialog
                  open={openDialogThanks}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleCloseDialogThanks}
                  aria-describedby="alert-dialog-slide-Thanks"
               >
                  <DialogTitle
                     sx={{
                        whiteSpace: 'pre-line',
                        textAlign: 'center',
                     }}
                  >{`Online Support ${'\n'} ऑनलाइन समर्थन`}</DialogTitle>
                  <DialogContent>
                     {phaseDate && (
                        <DialogContentText id="alert-dialog-slide-su" sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}>
                           <Typography sx={{ color: '#4caf50' }}>
                              Thanks for showing support to your favourite candidate.
                           </Typography>
                           <strong>
                              Please make sure to go to the polling booth on{' '}
                              {phaseDate && `${moment(phaseDate.toDate()).format('Do MMM YYYY')}`} to caste your vote.
                              This is your national duty.
                           </strong>
                           <br /> <br />
                           <Typography sx={{ color: '#4caf50' }}>
                              अपने पसंदीदा प्रत्याशी को समर्थन दिखाने के लिए धन्यवाद।
                           </Typography>
                           {phaseDate && (
                              <strong>
                                 कृपया {phaseDate && `${moment(phaseDate.toDate()).format('Do MMM YYYY')}`} को मतदान
                                 केंद्र पर जाकर अपना वोट अवश्य डालें। यह आपका राष्ट्रीय कर्तव्य है।
                              </strong>
                           )}
                           {phaseDate && (
                              <strong>
                                 कृपया मतदान केंद्र पर जाकर अपना वोट अवश्य डालें। यह आपका राष्ट्रीय कर्तव्य है।
                              </strong>
                           )}
                        </DialogContentText>
                     )}
                  </DialogContent>
                  <DialogActions>
                     <Button onClick={handleCloseDialogThanks}>Close</Button>
                  </DialogActions>
               </Dialog>
            </Box>
         </div>
      </LocalizationProvider>
   )
}

ElecCandiBasicInfo.propTypes = {
   consti: PropTypes.object.isRequired,
   candi: PropTypes.object.isRequired,
   elec: PropTypes.object.isRequired,
   candisList: PropTypes.array.isRequired,
   sortCandisList: PropTypes.func.isRequired,
}

export default ElecCandiBasicInfo
