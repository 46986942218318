import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import PropTypes from 'prop-types'
import MyLoaderCircularGradient from '../../../Utils/Loaders/MyLoaderCircularGradient'
import { auth } from '../../../FirebaseConfig'
import { useSelector } from 'react-redux'
import { selectUsers } from '../../../redux/reducers/users-slice'

function AddElectionPhase({
   lastPhase,
   uploading,
   uploaded,
   uploadingFailed,
   dataCase,
   selectedPhase,
   handleAddPhase,
}) {
   /// /
   const user = useSelector(selectUsers)
   const ip = user.ip

   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         if (selectedPhase !== '' && dataCase === 'Changed') {
            setPhaseDetails({
               ...phaseDetails,
               PhaseNum: selectedPhase.PhaseNum,
               ScheduleNum: selectedPhase.ScheduleNum,
            })
         }
         setFirstLoadStatus('success')
      }
   }, [])
   /// /
   const [phaseDetails, setPhaseDetails] = useState({
      PhaseNum: '',
      ScheduleNum: '',
      DateOfNotification: '',
      DateOfNotificationOld: [],
      DateOfNominationStart: '',
      DateOfNominationStartOld: [],
      DateOfNominationEnd: '',
      DateOfNominationEndOld: [],
      DateOfNominationScrutiny: '',
      DateOfNominationScrutinyOld: [],
      DateOfWithdrawal: '',
      DateOfWithdrawalOld: [],
      DateOfPublicCanvassingEnd: '',
      DateOfPublicCanvassingEndOld: [],
      DateOfDoorCanvassingEnd: '',
      DateOfDoorCanvassingEndOld: [],
      DateOfPoll: '',
      DateOfPollOld: [],
      DateOfPollEnd: '',
      DateOfPollEndOld: [],
      DateOfCounting: '',
      DateOfCountingOld: [],
      DateOfCompletion: '',
      DateOfCompletionOld: [],
      Description: [],
      IsDeleted: false,
      IsDeletedRecord: {},
      IsCancelled: false,
      IsCancelledRecord: {},
   })

   useEffect(() => {
      // console.log('phase details on change: ', phaseDetails)
   }, [phaseDetails])

   function handlePhaseDetails(e) {
      setPhaseDetails({ ...phaseDetails, [e.target.name]: e.target.value })
   }

   function handlePhaseNum(e) {
      if (e.target.value !== '') {
         const newValue = parseInt(e.target.value, 10)

         newValue && !isNaN(newValue)
            ? setPhaseDetails({ ...phaseDetails, [e.target.name]: newValue })
            : setPhaseDetails({ ...phaseDetails, [e.target.name]: '' })
      } else {
         setPhaseDetails({ ...phaseDetails, [e.target.name]: '' })
      }
   }

   const [dateOfNotification, setDateOfNotification] = useState('')
   useEffect(() => {
      if (dateOfNotification) {
         setPhaseDetails({ ...phaseDetails, DateOfNotification: dateOfNotification.$d })
      }
   }, [dateOfNotification])

   const [dateOfNominationStart, setDateOfNominationStart] = useState('')
   useEffect(() => {
      if (dateOfNominationStart) {
         setPhaseDetails({ ...phaseDetails, DateOfNominationStart: dateOfNominationStart.$d })
      }
   }, [dateOfNominationStart])

   const [dateOfNominationEnd, setDateOfNominationEnd] = useState('')
   useEffect(() => {
      if (dateOfNominationEnd) {
         setPhaseDetails({ ...phaseDetails, DateOfNominationEnd: dateOfNominationEnd.$d })
      }
   }, [dateOfNominationEnd])

   const [dateOfNominationScrutiny, setDateOfNominationScrutiny] = useState('')
   useEffect(() => {
      if (dateOfNominationScrutiny) {
         setPhaseDetails({ ...phaseDetails, DateOfNominationScrutiny: dateOfNominationScrutiny.$d })
      }
   }, [dateOfNominationScrutiny])

   const [dateOfWithdrawal, setDateOfWithdrawal] = useState('')
   useEffect(() => {
      if (dateOfWithdrawal) {
         setPhaseDetails({ ...phaseDetails, DateOfWithdrawal: dateOfWithdrawal.$d })
      }
   }, [dateOfWithdrawal])

   const [dateOfPublicCanvassingEnd, setDateOfPublicCanvassingEnd] = useState('')
   useEffect(() => {
      if (dateOfPublicCanvassingEnd) {
         setPhaseDetails({ ...phaseDetails, DateOfPublicCanvassingEnd: dateOfPublicCanvassingEnd.$d })
      }
   }, [dateOfPublicCanvassingEnd])

   const [dateOfDoorCanvassingEnd, setDateOfDoorCanvassingEnd] = useState('')
   useEffect(() => {
      if (dateOfDoorCanvassingEnd) {
         setPhaseDetails({ ...phaseDetails, DateOfDoorCanvassingEnd: dateOfDoorCanvassingEnd.$d })
      }
   }, [dateOfDoorCanvassingEnd])

   const [dateOfPoll, setDateOfPoll] = useState('')
   useEffect(() => {
      if (dateOfPoll) {
         setPhaseDetails({ ...phaseDetails, DateOfPoll: dateOfPoll.$d, DateOfPollEnd: dateOfPoll.$d })
      }
   }, [dateOfPoll])

   const [dateOfCounting, setDateOfCounting] = useState('')
   useEffect(() => {
      if (dateOfCounting) {
         setPhaseDetails({ ...phaseDetails, DateOfCounting: dateOfCounting.$d })
      }
   }, [dateOfCounting])

   const [dateOfCompletion, setDateOfCompletion] = useState('')
   useEffect(() => {
      if (dateOfCompletion) {
         setPhaseDetails({ ...phaseDetails, DateOfCompletion: dateOfCompletion.$d })
      }
   }, [dateOfCompletion])

   const [description, setDescription] = useState('')
   const handleDescription = (e) => {
      setDescription(e.target.value)
   }

   const handleSubmitPhase = async (e) => {
      e.preventDefault()
      if (
         phaseDetails.PhaseNum !== '' &&
         !isNaN(phaseDetails.PhaseNum) &&
         phaseDetails.PhaseNum > 0 &&
         phaseDetails.DateOfNotification !== '' &&
         phaseDetails.DateOfNominationStart !== '' &&
         phaseDetails.DateOfNominationScrutiny !== '' &&
         phaseDetails.DateOfWithdrawal !== ''
      ) {
         if (description !== '') {
            phaseDetails.Description.push({
               Text: description,
               Deleted: false,
               CrBy: auth.currentUser.uid,
               CrDt: new Date(),
               CrByIP: ip.data.ip,
            })
         }
         // console.log('phaseDetails before submit: ', phaseDetails)
         handleAddPhase(phaseDetails)
         setPhaseDetails({
            PhaseNum: '',
            ScheduleNum: '',
            DateOfNotification: '',
            DateOfNotificationOld: [],
            DateOfNominationStart: '',
            DateOfNominationStartOld: [],
            DateOfNominationEnd: '',
            DateOfNominationEndOld: [],
            DateOfNominationScrutiny: '',
            DateOfNominationScrutinyOld: [],
            DateOfWithdrawal: '',
            DateOfWithdrawalOld: [],
            DateOfPublicCanvassingEnd: '',
            DateOfPublicCanvassingEndOld: [],
            DateOfDoorCanvassingEnd: '',
            DateOfDoorCanvassingEndOld: [],
            DateOfPoll: '',
            DateOfPollOld: [],
            DateOfPollEnd: '',
            DateOfPollEndOld: [],
            DateOfCounting: '',
            DateOfCountingOld: [],
            DateOfCompletion: '',
            DateOfCompletionOld: [],
            Description: [],
            IsDeleted: false,
            IsDeletedRecord: {},
            IsCancelled: false,
            IsCancelledRecord: {},
         })
      }
   }
   return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
         <Box
            sx={{
               //    mb: 1,
               mx: 1,
               pb: 1,
               borderRadius: 2,
               display: 'flex',
               flexDirection: 'column',
               '& .MuiButton-root': { width: '15ch', mt: 1 },
               '& .MuiTextField-root': { width: '50ch', mb: 0.5 },
               // '& .MuiInputBase-root': { mb: 2 },
               '& .MuiFormControl-root': { width: '50ch', mb: 1 },
               justifyContent: 'center',
               alignItems: 'center',
            }}
         >
            <div>
               <TextField
                  onBlur={(event) => event}
                  onChange={(e) => {
                     handlePhaseNum(e)
                  }}
                  value={phaseDetails.PhaseNum}
                  name="PhaseNum"
                  id="phaseNum"
                  label="Phase Number"
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  size="small"
                  disabled={dataCase === 'Changed'}
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               ></TextField>
            </div>
            <div>
               <TextField
                  onBlur={(event) => event}
                  onChange={(e) => {
                     handlePhaseDetails(e)
                  }}
                  value={phaseDetails.ScheduleNum}
                  name="ScheduleNum"
                  id="scheduleNum"
                  label="Schedule Number"
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  size="small"
                  disabled={dataCase === 'Changed'}
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               ></TextField>
            </div>
            <div>
               <DatePicker
                  label="Notification Date"
                  name="DateOfNotification"
                  value={phaseDetails.DateOfNotification != null ? dayjs(phaseDetails.DateOfNotification) : null}
                  format="DD-MMM-YYYY"
                  onChange={(newValue) => setDateOfNotification(newValue)}
                  views={['year', 'month', 'day']}
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               />
            </div>
            <div>
               <DatePicker
                  label="Nomination Start Date"
                  name="DateOfNominationStart"
                  value={phaseDetails.DateOfNominationStart != null ? dayjs(phaseDetails.DateOfNominationStart) : null}
                  format="DD-MMM-YYYY"
                  onChange={(newValue) => setDateOfNominationStart(newValue)}
                  views={['year', 'month', 'day']}
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               />
            </div>
            <div>
               <DatePicker
                  label="Nomination End Date"
                  name="DateOfNominationEnd"
                  value={phaseDetails.DateOfNominationEnd != null ? dayjs(phaseDetails.DateOfNominationEnd) : null}
                  format="DD-MMM-YYYY"
                  onChange={(newValue) => setDateOfNominationEnd(newValue)}
                  views={['year', 'month', 'day']}
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               />
            </div>
            <div>
               <DatePicker
                  label="Nomination Scrutiny Date"
                  name="DateOfNominationScrutiny"
                  value={
                     phaseDetails.DateOfNominationScrutiny != null ? dayjs(phaseDetails.DateOfNominationScrutiny) : null
                  }
                  format="DD-MMM-YYYY"
                  onChange={(newValue) => setDateOfNominationScrutiny(newValue)}
                  views={['year', 'month', 'day']}
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               />
            </div>
            <div>
               <DatePicker
                  label="Withdrawal Date"
                  name="DateOfWithdrawal"
                  value={phaseDetails.DateOfWithdrawal != null ? dayjs(phaseDetails.DateOfWithdrawal) : null}
                  format="DD-MMM-YYYY"
                  onChange={(newValue) => setDateOfWithdrawal(newValue)}
                  views={['year', 'month', 'day']}
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               />
            </div>
            <div>
               <DatePicker
                  label="Public Canvassing End Date"
                  name="DateOfPublicCanvassingEnd"
                  value={
                     phaseDetails.DateOfPublicCanvassingEnd != null
                        ? dayjs(phaseDetails.DateOfPublicCanvassingEnd)
                        : null
                  }
                  format="DD-MMM-YYYY"
                  onChange={(newValue) => setDateOfPublicCanvassingEnd(newValue)}
                  views={['year', 'month', 'day']}
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               />
            </div>
            <div>
               <DatePicker
                  label="Door Canvassing End Date"
                  name="DateOfDoorCanvassingEnd"
                  value={
                     phaseDetails.DateOfDoorCanvassingEnd != null ? dayjs(phaseDetails.DateOfDoorCanvassingEnd) : null
                  }
                  format="DD-MMM-YYYY"
                  onChange={(newValue) => setDateOfDoorCanvassingEnd(newValue)}
                  views={['year', 'month', 'day']}
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               />
            </div>
            <div>
               <DatePicker
                  label="Poll Date"
                  name="DateOfPoll"
                  value={phaseDetails.DateOfPoll != null ? dayjs(phaseDetails.DateOfPoll) : null}
                  format="DD-MMM-YYYY"
                  onChange={(newValue) => setDateOfPoll(newValue)}
                  views={['year', 'month', 'day']}
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               />
            </div>
            <div>
               <DatePicker
                  label="Counting Date"
                  name="DateOfCounting"
                  value={phaseDetails.DateOfCounting != null ? dayjs(phaseDetails.DateOfCounting) : null}
                  format="DD-MMM-YYYY"
                  onChange={(newValue) => setDateOfCounting(newValue)}
                  views={['year', 'month', 'day']}
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               />
            </div>
            <div>
               <DatePicker
                  label="Completion Date"
                  name="DateOfCompletion"
                  value={phaseDetails.DateOfCompletion != null ? dayjs(phaseDetails.DateOfCompletion) : null}
                  format="DD-MMM-YYYY"
                  onChange={(newValue) => setDateOfCompletion(newValue)}
                  views={['year', 'month', 'day']}
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               />
            </div>
            <div>
               <TextField
                  id="Description"
                  label="Description"
                  multiline
                  rows={4}
                  onChange={(e) => {
                     handleDescription(e)
                  }}
                  // disabled={dataCase === 'Changed'}
               />
            </div>
            <Box>
               {uploading && <MyLoaderCircularGradient title={'Listing the phase ...'}></MyLoaderCircularGradient>}
               {uploaded && (
                  <Alert variant="outlined" severity="success">
                     <strong>{lastPhase}</strong> listed successfully.
                  </Alert>
               )}
               {uploadingFailed && (
                  <Alert variant="outlined" severity="error">
                     Error occured! <strong>{lastPhase}</strong> could not be listed.
                  </Alert>
               )}
            </Box>
            <div>
               <Button
                  type="submit"
                  align="center"
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={(e) => {
                     handleSubmitPhase(e)
                  }}
                  disabled={
                     phaseDetails.PhaseNum === '' ||
                     isNaN(phaseDetails.PhaseNum) ||
                     phaseDetails.PhaseNum < 1 ||
                     phaseDetails.DateOfNotification === '' ||
                     phaseDetails.DateOfNominationStart === '' ||
                     phaseDetails.DateOfNominationScrutiny === '' ||
                     phaseDetails.DateOfWithdrawal === ''
                  }
               >
                  Submit
               </Button>
            </div>
         </Box>
      </LocalizationProvider>
   )
}

AddElectionPhase.propTypes = {
   selectedPhase: PropTypes.object,
   dataCase: PropTypes.string.isRequired,
   lastPhase: PropTypes.string.isRequired,
   handleAddPhase: PropTypes.func.isRequired,
   uploading: PropTypes.bool.isRequired,
   uploaded: PropTypes.bool.isRequired,
   uploadingFailed: PropTypes.bool.isRequired,
}

export default AddElectionPhase
