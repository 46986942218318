import { doc, getDoc } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { db } from '../../../FirebaseConfig'
import { Avatar, Badge, Box, Card, CardContent, CardMedia, Tabs, Typography, useTheme } from '@mui/material'
// import { styled } from '@mui/material/styles'

import { TabContext } from '@mui/lab'
import { a11yProps, StyledTab1, TabPanel2 } from '../../../Components/TabPanelSystem'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import SwipeableViews from 'react-swipeable-views'
import NetaBasicInfo from '../NetaBasicInfo/NetaBasicInfo'
import NetaCred from '../NetaCred/NetaCred'
import NetaWorks from '../NetaWorks/NetaWorks'
import {
   SmallAvatar,
   stringAvatar150,
   stringAvatar150Single,
   stringAvatar50,
   stringAvatar50Single,
} from '../../../Utils/MUITheme/MUITheme'
import { countWordsUsingReplace } from '../../../Utils/Conversions/CaseConversion'
import NetaInteract from '../NetaInteract/NetaInteract'
import { Helmet } from 'react-helmet'
import NetaStatements from '../NetaStatements/NetaStatements'

function Neta() {
   const { netaProfileId, netaName } = useParams()
   const navigate = useNavigate()

   // const theme = useTheme()
   const [value, setValue] = useState(0)
   const handleChangeNavTab = (event, newValue) => {
      setValue(newValue)
   }
   const handleChangeNavTabIndex = (index) => {
      setValue(index)
   }

   const [initialState, setInitialState] = useState('idle')
   const [neta, setNeta] = useState('')
   const [credentials, setCredentials] = useState('')
   const [netaConstituencies, setNetaConstituencies] = useState([])

   useEffect(() => {
      if (initialState === 'idle') {
         console.log('reached first blank use effect of Neta')
         fetchNeta(netaProfileId)
         fetchCredentials()
         setInitialState('success')
      }
   }, [])

   const fetchNeta = async (netaId) => {
      try {
         const docRefNeta = doc(db, 'NetaProfiles', netaId)
         const docSnapNeta = await getDoc(docRefNeta)
         console.log('reached fetching neta')
         if (docSnapNeta.exists()) {
            setNeta({ ...docSnapNeta.data(), id: docSnapNeta.id })
            console.log('fetched neta: ', docSnapNeta.data())

            // fetch core list subcollection of neta profile for attached constituencies
            const docRefNetaSubCollectionConstituencies = doc(
               db,
               'NetaProfiles',
               netaId,
               'CoreLists',
               'Constituencies-001',
            )
            const docSnapNetaSubCollectionConstituencies = await getDoc(docRefNetaSubCollectionConstituencies)
            if (docSnapNetaSubCollectionConstituencies.exists()) {
               setNetaConstituencies(docSnapNetaSubCollectionConstituencies.data().Constituencies)
            } else {
               setNetaConstituencies([])
            }
         } else {
            navigate('/', { replace: true })
         }
      } catch (err) {
         console.log('error', err)
      }
   }

   const fetchCredentials = async (netaId) => {
      try {
         const docRef = doc(db, 'NetaProfiles', netaId, 'CoreLists', 'Credentials')
         const docSnap = await getDoc(docRef)
         console.log('reached fetching credentials')
         if (docSnap.exists()) {
            setCredentials({ ...docSnap.data() })
            console.log('fetched credentials: ', docSnap.data())
         } else {
            setCredentials('')
         }
      } catch (err) {
         console.log('error', err)
      }
   }
   const theme = useTheme()

   //
   return (
      <Box sx={{ minHeight: 600, px: 0.5, pb: 20 }}>
         <div>
            <Helmet>
               <title>{`Track Democracy - Leader - Neta - ${netaName}`}</title>
            </Helmet>
         </div>
         <Card
            sx={{
               my: 1,
               py: 1,
               px: 2,
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               // backgroundColor: '#000000',
               backgroundImage: 'linear-gradient(to right, #545159, #797580, #545159)',
            }}
         >
            <Box sx={{ display: 'flex', flexDirection: 'column', px: 2 }}>
               <CardContent sx={{ flex: '1 0 auto' }}>
                  {neta && (
                     <div>
                        {neta.NameLocal && (
                           <Typography
                              component="div"
                              variant="h5"
                              sx={{ fontWeight: 700, fontSize: 22, fontFamily: 'Poppins' }}
                              color="#FFFFFF"
                           >
                              {neta.NameLocal}
                           </Typography>
                        )}
                        {neta.NameHindi && neta.NameLocal === '' && (
                           <Typography
                              component="div"
                              variant="h5"
                              sx={{ fontWeight: 700, fontSize: 22, fontFamily: 'Poppins' }}
                              color="#FFFFFF"
                           >
                              {neta.NameHindi}
                           </Typography>
                        )}
                        {neta.NameHindi && neta.NameLocal !== '' && (
                           <Typography
                              component="div"
                              sx={{ fontWeight: 700, fontSize: 16, fontFamily: 'Poppins' }}
                              color="#FFFFFF"
                           >
                              {neta.NameHindi}
                           </Typography>
                        )}
                        {neta.NameLocal === '' && (
                           <Typography
                              component="div"
                              variant="h5"
                              sx={{ fontWeight: 700, fontSize: 22, fontFamily: 'Poppins' }}
                              color="#FFFFFF"
                           >
                              {neta.Name}
                           </Typography>
                        )}
                        {neta.NameLocal !== '' && (
                           <Typography
                              component="div"
                              sx={{ fontWeight: 700, fontSize: 16, fontFamily: 'Poppins' }}
                              color="#FFFFFF"
                           >
                              {neta.Name}
                           </Typography>
                        )}
                        {/* <Box sx={{ display: 'flex', direction: 'row' }}>
                           <Box
                              sx={{
                                 display: 'inline',
                                 mx: 0.5,
                                 fontSize: 14,
                                 fontWeight: 400,
                                 color: '#D0D3D5',
                              }}
                           >
                              <HowToVoteIcon sx={{ ml: 1 }} />{' '}
                           </Box>
                           <Box
                              sx={{
                                 display: 'inline',
                                 mx: 0.5,
                                 fontSize: 14,
                              }}
                           >
                              {neta.ElectionNameLocal !== '' && (
                                 <Box>
                                    <Link
                                       sx={{ color: '#ffffff', fontWeight: 700, fontFamily: 'Poppins' }}
                                       href={`/election/${neta.ElectionName}/${neta.Election_id}`}
                                       rel="noreferrer"
                                       target="_blank"
                                    >
                                       {neta.ElectionNameLocal}
                                    </Link>
                                 </Box>
                              )}
                              <Box>
                                 <Link
                                    sx={{ color: '#ffffff', fontWeight: 700, fontFamily: 'Poppins' }}
                                    href={`/election/${neta.ElectionName}/${neta.Election_id}`}
                                    rel="noreferrer"
                                    target="_blank"
                                 >
                                    {neta.ElectionNameHindi}
                                 </Link>
                              </Box>
                              <Box>
                                 <Link
                                    sx={{ color: '#ffffff', fontWeight: 700, fontFamily: 'Poppins' }}
                                    href={`/election/${neta.ElectionName}/${neta.Election_id}`}
                                    rel="noreferrer"
                                    target="_blank"
                                 >
                                    {neta.ElectionName}{' '}
                                 </Link>
                              </Box>
                              {elec && elec.DateStart && (
                                 <Box
                                    sx={{
                                       display: 'inline',
                                       mx: 0.5,
                                       fontSize: 14,
                                       fontWeight: 400,
                                       color: '#ffffff',
                                    }}
                                 >
                                    {moment(elec.DateStart.toDate()).format('Do MMM YYYY')}

                                    {moment(elec.DateStart.toDate()).format('Do MMM YYYY') !==
                                       moment(elec.DateFinish.toDate()).format('Do MMM YYYY') && (
                                       <> - {moment(elec.DateFinish.toDate()).format('Do MMM YYYY')}</>
                                    )}
                                 </Box>
                              )}
                           </Box>
                        </Box> */}
                        {/* <Box>
                           <Box
                              sx={{
                                 display: 'inline',
                                 mx: 0.5,
                                 fontSize: 14,
                                 color: '#b3bdbd',
                              }}
                           >
                              Poll end:
                           </Box>
                           <Box
                              sx={{
                                 display: 'inline',
                                 mx: 0.5,
                                 fontSize: 14,
                                 color: '#ffffff',
                              }}
                           >
                              {moment(elec.DateFinish.toDate()).format('Do MMMM YYYY')}
                           </Box>
                        </Box> */}
                     </div>
                  )}
               </CardContent>
            </Box>
            <CardMedia
            // component="img"
            // sx={{ width: 151, borderRadius: 1, mr: 1 }}
            // image="https://images.unsplash.com/photo-1622993288089-18298ec89b78?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            // alt="Live from space album cover"
            >
               {neta.Name && (
                  <Badge
                     overlap="circular"
                     anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                     badgeContent={
                        countWordsUsingReplace(neta.Name) > 1 ? (
                           <SmallAvatar
                              {...stringAvatar50(neta.Name)}
                              alt={neta.Name}
                              src={neta.Party.PartyFlag ? neta.Party.PartyFlag : neta.AllottedSymbol}
                           />
                        ) : (
                           <SmallAvatar
                              {...stringAvatar50Single(neta.Name)}
                              src={neta.Party.PartyFlag ? neta.Party.PartyFlag : neta.AllottedSymbol}
                           />
                        )
                     }
                  >
                     {countWordsUsingReplace(neta.Name) > 1 ? (
                        <Avatar {...stringAvatar150(neta.Name)} alt="Travis Howard" src={neta.Image.Image} />
                     ) : (
                        <Avatar {...stringAvatar150Single(neta.Name)} alt="Travis Howard" src={neta.Image.Image} />
                     )}
                  </Badge>
               )}

               {/* <Avatar alt="An apple" sx={{ height: 150, width: 150 }} src={neta.Image} /> */}
            </CardMedia>
         </Card>
         <TabContext value={value}>
            <div>
               <Tabs
                  value={value}
                  onChange={handleChangeNavTab}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="full width tabs example"
                  centered
                  // variant="scrollable"
                  // scrollButtons="auto"
                  sx={{
                     marginBottom: 1,
                     py: 0.5,
                     minHeight: 25,
                     backgroundColor: '#e7e9bb',
                  }}
               >
                  <StyledTab1 label={`Basic info \n मूल सूचना`} {...a11yProps(0)} />
                  <StyledTab1 label={`Credentials \n प्रामाणिकताएँ`} {...a11yProps(1)} />
                  <StyledTab1 label={`Statements \n कथन`} {...a11yProps(2)} />
                  <StyledTab1 label={`Works \n कार्य`} {...a11yProps(3)} />
                  <StyledTab1 label={`Interact \n संवाद करें`} {...a11yProps(4)} />
               </Tabs>
            </div>
            <Grid2 container sx={{ mt: 2 }} id="vBoxGridContainer">
               <Grid2 item xs={8} id="vboxGrid1">
                  <Box>
                     <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeNavTabIndex}
                     >
                        <TabPanel2 value={value} index={0} dir={theme.direction}>
                           {neta && <NetaBasicInfo neta={neta} netaConstituencies={netaConstituencies} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={1} dir={theme.direction}>
                           {neta && <NetaCred neta={neta} credentials={credentials} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={2} dir={theme.direction}>
                           {neta && <NetaStatements neta={neta} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={3} dir={theme.direction}>
                           {neta && <NetaWorks neta={neta} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={4} dir={theme.direction}>
                           {neta && <NetaInteract neta={neta} />}
                        </TabPanel2>
                     </SwipeableViews>
                  </Box>
               </Grid2>
               <Grid2 item xs={4}></Grid2>
            </Grid2>
         </TabContext>
      </Box>
   )
}

export default Neta
