import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'
import {
   Avatar,
   Box,
   Divider,
   IconButton,
   Link,
   ListItem,
   ListItemAvatar,
   ListItemText,
   Typography,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'

import { stringAvatar80, stringAvatar80Single } from '../../Utils/MUITheme/MUITheme'
import { countWordsUsingReplace } from '../../Utils/Conversions/CaseConversion'
import styles from './ElecCandiComp.module.css'
import ReadMore from '../ReadMore/ReadMore'

import NOTA from '../../Images/NOTA.png'
import winner from '../../Images/winner.png'

function ElecCandiCompAccepted({
   item,
   handleDelete,
   handleOpenModalStatus3,
   isDataEditor,
   isSuper,
   index,
   handleSendToLegis,
   handleSendToNeta,
   handleOpenModalConstiTransfer,
   handleOpenModalWinner,
   handleOpenModalVotes,
   elec,
}) {
   return (
      <ListItem
         sx={{ pl: 0, ml: 0 }}
         key={item.Candidate_id}
         secondaryAction={
            <Box sx={{ ml: 'auto' }}>
               {isSuper && (
                  <IconButton
                     edge="end"
                     aria-label="delete"
                     onClick={(e) => {
                        handleDelete(e, item)
                     }}
                     sx={{ mr: 1, height: '25px', width: '25px' }}
                  >
                     <DeleteIcon fontSize="small" />
                  </IconButton>
               )}
            </Box>
         }
      >
         <Link
            href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
            sx={{ textDecoration: 'none !important' }}
            target="_blank"
            rel="noopener"
         >
            <ListItemAvatar sx={{ display: 'flex', flexDirection: 'column' }}>
               <Typography
                  component="span"
                  variant="body2"
                  sx={{
                     color: 'text.primary',
                     display: 'inline',
                     fontWeight: 600,
                     mb: 1,
                  }}
               >
                  {index + 1}.
               </Typography>
               {item.Name &&
                  (countWordsUsingReplace(item.Name) > 1 ? (
                     <Avatar {...stringAvatar80(item.Name)} alt="Travis Howard" src={item.Image} />
                  ) : item.Name !== 'Zznota' ? (
                     <Avatar {...stringAvatar80Single(item.Name)} alt="Travis Howard" src={item.Image} />
                  ) : (
                     <Avatar
                        sx={{
                           width: 80,
                           height: 80,
                           backgroundColor: 'Yellow',
                        }}
                        src={NOTA}
                     />
                  ))}
            </ListItemAvatar>
         </Link>

         <ListItemText
            sx={{ ml: 1 }}
            secondaryTypographyProps={{ align: 'left', whiteSpace: 'pre-line' }}
            primaryTypographyProps={{ whiteSpace: 'pre-line' }}
            primary={
               <>
                  {item.Name !== 'Zznota' && (
                     <>
                        <Link
                           // color="inherit"
                           underline="hover"
                           href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                           sx={{ textDecoration: 'none !important' }}
                           target="_blank"
                           rel="noopener"
                        >
                           <strong>{item.Name.toUpperCase()}</strong>
                        </Link>{' '}
                        -{' '}
                        {item.NameLocal && (
                           <Link
                              href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                              className={styles.overrideUnderline}
                              sx={{ textDecoration: 'none !important' }}
                              target="_blank"
                              rel="noopener"
                           >
                              {item.NameLocal}
                           </Link>
                        )}
                        {'  '}-{' '}
                        <Link
                           underline="none"
                           href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                           color="warning"
                           sx={{ textDecoration: 'none !important' }}
                           target="_blank"
                           rel="noopener"
                        >
                           {/* <FiberManualRecordIcon fontSize="12px" sx={{ color: '#888889' }} />{' '} */}
                           {item.NameHindi}
                           {'  '}
                        </Link>
                        ({item.Age}y)
                     </>
                  )}
                  {item.Name === 'Zznota' && (
                     <>
                        <strong>
                           NOTA
                           <br />
                           {item.NameLocal}
                           <br />
                           {item.NameHindi}
                        </strong>
                     </>
                  )}
               </>
            }
            component={'span'}
            secondary={
               <>
                  <>{<strong> Constituency: </strong>}</>
                  {item.ConstituencyName} {`- ${item.ConstituencyNameLocal} - ${item.ConstituencyNameHindi}`}
                  <br />
                  <>{<strong> Constituency No.: </strong>}</>
                  {item.ConstituencyNumber}
                  <br />
                  <>
                     {item.PartyFlag && (
                        <Link
                           href={`/political-party/${item.PartyName}/${item.Party_id}`}
                           target="_blank"
                           rel="noopener"
                        >
                           <img src={item.PartyFlag} className={styles.imgX2} />
                        </Link>
                     )}

                     {item.PartySymbol && <img src={item.PartySymbol} className={styles.imgX3} />}
                     {item.AllottedSymbol && <img src={item.AllottedSymbol} className={styles.imgX3} />}
                  </>
                  <>
                     {item.Name !== 'Zznota' && (
                        <>
                           {<strong> Party: </strong>}
                           {item.PartyName !== 'Independent' && (
                              <Typography
                                 sx={{ display: 'inline' }}
                                 component="span"
                                 variant="body2"
                                 color="text.primary"
                              >
                                 <Link
                                    href={`/political-party/${item.PartyName}/${item.Party_id}`}
                                    underline="none"
                                    sx={{ textDecoration: 'none !important' }}
                                    target="_blank"
                                    rel="noopener"
                                 >
                                    {item.PartyName}
                                 </Link>
                              </Typography>
                           )}
                           {item.PartyName === 'Independent' && (
                              <Typography
                                 sx={{ display: 'inline' }}
                                 component="span"
                                 variant="body2"
                                 color="text.primary"
                              >
                                 {item.PartyName}
                              </Typography>
                           )}
                           <Box sx={{ flexWrap: 'wrap', wordWrap: 'break-word' }}>
                              {<strong> Alliances: </strong>}
                              {item.Alliances.map((itemAl, index) => (
                                 <Fragment key={itemAl.Alliance_id}>
                                    {index + 1}.
                                    <Link
                                       key={itemAl.Alliance_id}
                                       href={`/alliance/${itemAl.Name}/${itemAl.Alliance_id}`}
                                       sx={{
                                          mr: 1,
                                          ml: 0.5,
                                          textDecoration: 'none !important',
                                       }}
                                       rel="noreferrer"
                                       target="_blank"
                                    >
                                       {itemAl.Abbreviation}
                                    </Link>
                                 </Fragment>
                              ))}
                           </Box>
                        </>
                     )}
                     <>
                        {<strong> Votes: </strong>}
                        <Typography
                           sx={
                              ({
                                 display: 'inline',
                              },
                              item.Winner ? { color: '#4CAF50' } : { color: '#DC143C' })
                           }
                           component="span"
                           variant="body2"
                        >
                           <strong>{item.Votes}</strong>
                        </Typography>

                        {(isDataEditor || isSuper) && (
                           <IconButton
                              edge="end"
                              aria-label="edit"
                              onClick={(e) => {
                                 handleOpenModalVotes(e, item)
                              }}
                              sx={{ ml: 1, height: '15px', width: '15px' }}
                           >
                              <EditIcon sx={{ height: '15px', width: '15px' }} />
                           </IconButton>
                        )}
                     </>
                     {item.Name !== 'Zznota' && (
                        <Box>
                           {item.Winner === 'true' && <img src={winner} className={styles.imgXX} />}
                           {(isDataEditor || isSuper) && (
                              <>
                                 Declare Winner
                                 <IconButton
                                    edge="end"
                                    aria-label="edit"
                                    onClick={(e) => {
                                       handleOpenModalWinner(e, item)
                                    }}
                                    sx={{ ml: 1, height: '15px', width: '15px' }}
                                 >
                                    <EditIcon sx={{ height: '15px', width: '15px' }} />
                                 </IconButton>
                              </>
                           )}
                        </Box>
                     )}
                     {item.Winner === 'true' && (isDataEditor || isSuper) && (
                        <Box>
                           {<strong> Send to legislature: </strong>}

                           <IconButton
                              edge="end"
                              aria-label="sendToLegis"
                              onClick={(e) => {
                                 handleSendToLegis(e, item)
                              }}
                              sx={{ ml: 1, height: '20px', width: '20px' }}
                           >
                              <DoubleArrowIcon
                                 sx={{
                                    height: '20px',
                                    width: '20px',
                                    color: '#03a9f4',
                                 }}
                              />
                           </IconButton>
                        </Box>
                     )}
                     {item.Name !== 'Zznota' && elec.ResultsDeclared && (isDataEditor || isSuper) && (
                        <Box>
                           {<strong> Send to constituency as leader: </strong>}

                           <IconButton
                              edge="end"
                              aria-label="sendToNeta"
                              onClick={(e) => {
                                 handleSendToNeta(e, item)
                              }}
                              sx={{ ml: 1, height: '30px', width: '30px' }}
                           >
                              <DoubleArrowIcon
                                 sx={{
                                    height: '20px',
                                    width: '20px',
                                    color: '#03a9f4',
                                 }}
                              />
                           </IconButton>
                        </Box>
                     )}
                     {item.Name !== 'Zznota' && (isDataEditor || isSuper) && (
                        <Box>
                           {<strong> Transfer to another constituency: </strong>}

                           <IconButton
                              edge="end"
                              aria-label="edit"
                              onClick={(e) => {
                                 handleOpenModalConstiTransfer(e, item)
                              }}
                              sx={{ ml: 1, height: '15px', width: '15px' }}
                           >
                              <EditIcon sx={{ height: '15px', width: '15px' }} />
                           </IconButton>
                        </Box>
                     )}
                     {item.Name === 'Zznota' && (
                        <Box
                           sx={{
                              border: 1,
                              borderColor: 'Crimson',
                              color: 'Crimson',
                              fontSize: 12,
                              p: 1,
                           }}
                        >
                           <ReadMore>
                              NOTA vote has no role in the loss / win of any real candidate. If a real candidate gets
                              1000 votes as highest among the real candidates and NOTA gets 1111 votes, the real
                              candidate with 1000 votes is declared winner.
                           </ReadMore>
                           <Divider sx={{ my: 0.5 }} />
                           <ReadMore>
                              NOTA वोट की किसी भी वास्तविक उम्मीदवार की हार / जीत में कोई भूमिका नहीं होती। यदि किसी
                              वास्तविक उम्मीदवार को वास्तविक उम्मीदवारों में सबसे अधिक 1000 वोट मिलते हैं और NOTA को
                              1111 वोट मिलते हैं, तो 1000 वोट पाने वाले वास्तविक उम्मीदवार को विजेता घोषित किया जाता है।
                           </ReadMore>
                        </Box>
                     )}
                  </>
                  {item.Name !== 'Zznota' && (
                     <Fragment>
                        {<strong> Status3: </strong>}
                        {item.Announced && item.Announced !== '' && (
                           <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="#03a9f4">
                              <ArrowCircleRightIcon sx={{ fontSize: 14 }} />
                              {item.AnnouncedDate && item.AnnouncedDate !== ''
                                 ? `Announced by party on ${moment(item.AnnouncedDate.toDate()).format('Do MMMM YYYY')} `
                                 : `Announced by party. `}
                           </Typography>
                        )}
                        {item.Filed !== '' && (
                           <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="#ba68c8">
                              <ArrowCircleRightIcon sx={{ fontSize: 14 }} />
                              {item.FiledDate && item.FiledDate !== ''
                                 ? `Candidature filed on ${moment(item.FiledDate.toDate()).format('Do MMMM YYYY')} `
                                 : `Candidature filed. `}
                           </Typography>
                        )}
                        {item.Accepted !== '' && (
                           <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="#4caf50">
                              <ArrowCircleRightIcon sx={{ fontSize: 14 }} />
                              {item.AcceptedDate && item.AcceptedDate !== ''
                                 ? `Candidature accepted by ECI on ${moment(item.AcceptedDate.toDate()).format('Do MMMM YYYY')} `
                                 : `Candidature accepted by ECI. `}
                           </Typography>
                        )}
                        {item.Rejected !== '' && (
                           <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="#d32f2f">
                              <ArrowCircleRightIcon sx={{ fontSize: 14 }} />
                              {item.RejectedDate && item.RejectedDate !== ''
                                 ? `Candidature rejected by ECI on ${moment(item.RejectedDate.toDate()).format('Do MMMM YYYY')} `
                                 : `Candidature rejected by ECI. `}
                           </Typography>
                        )}
                        {item.Withdrawn !== '' && (
                           <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="#ff9800">
                              <ArrowCircleRightIcon sx={{ fontSize: 14 }} />
                              {item.WithdrawnDate && item.WithdrawnDate !== ''
                                 ? `Candidature Withdrawn by candidate on ${moment(item.WithdrawnDate.toDate()).format('Do MMMM YYYY')} `
                                 : `Candidature Withdrawn by candidate. `}
                           </Typography>
                        )}
                        {item.AnnounceCancelled && item.AnnounceCancelled !== '' && (
                           <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="#d32f2f">
                              <ArrowCircleRightIcon sx={{ fontSize: 14 }} />
                              {item.AnnounceCancelledDate && item.AnnounceCancelledDate !== ''
                                 ? `Announcement cancelled by party on ${moment(item.AnnounceCancelledDate.toDate()).format('Do MMMM YYYY')} `
                                 : `Announcement cancelled by party. `}
                           </Typography>
                        )}
                        {item.Refused && item.Refused !== '' && (
                           <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="#d32f2f">
                              <ArrowCircleRightIcon sx={{ fontSize: 14 }} />
                              {item.RefusedDate && item.RefusedDate !== ''
                                 ? `Refused by candidate on ${moment(item.RefusedDate.toDate()).format('Do MMMM YYYY')} `
                                 : `Refused by candidate. `}
                           </Typography>
                        )}

                        {(isDataEditor || isSuper) && (
                           <IconButton
                              edge="end"
                              aria-label="edit"
                              onClick={(e) => {
                                 handleOpenModalStatus3(e, item.Candidate_id, item.Name)
                              }}
                              sx={{ ml: 1, height: '15px', width: '15px' }}
                           >
                              <EditIcon sx={{ height: '15px', width: '15px' }} />
                           </IconButton>
                        )}
                     </Fragment>
                  )}
               </>
            }
         />
      </ListItem>
   )
}

ElecCandiCompAccepted.propTypes = {
   index: PropTypes.number.isRequired,
   isDataEditor: PropTypes.bool.isRequired,
   isSuper: PropTypes.bool.isRequired,
   item: PropTypes.object.isRequired,
   handleOpenModalStatus3: PropTypes.func.isRequired,
   handleDelete: PropTypes.func.isRequired,

   handleSendToLegis: PropTypes.func.isRequired,
   handleSendToNeta: PropTypes.func.isRequired,
   handleOpenModalConstiTransfer: PropTypes.func.isRequired,
   handleOpenModalWinner: PropTypes.func.isRequired,
   handleOpenModalVotes: PropTypes.func.isRequired,
   elec: PropTypes.object.isRequired,
}

export default ElecCandiCompAccepted
