import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Alert, Box, Button, Divider, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'

import MyLoaderCircularGradient from '../../../Utils/Loaders/MyLoaderCircularGradient'
import Switch from '@mui/material/Switch'

function SupportPPartyNational({
   partiesList,
   uploading,
   uploadingFailed,
   uploaded,
   handleAddSupport,
   handleCancelSupport,
}) {
   const [supportDetails, setSupportDetails] = useState({
      Party_id: '',
      PartyName: '',
      PartyNameHindi: '',
      PartyNameLocal: '',
      PartyFlag: '',
   })
   const [selectedParty, setSelectedParty] = useState('')
   const [submitted, setSubmitted] = useState(false)
   const focusRef = useRef(null)

   useEffect(() => {
      console.log('on line vote details on change: ', supportDetails)
   }, [supportDetails])

   function handleOnlineSupportDetails(e, item) {
      e.preventDefault()
      setSupportDetails({
         Party_id: item.Party_id,
         PartyName: item.Name,
         PartyNameHindi: item.NameHindi,
         PartyNameLocal: item.NameLocal,
         PartyFlag: item.Flag,
      })
      setSelectedParty(item)
   }

   useEffect(() => {
      focusRef.current?.scrollIntoView({ behavior: 'smooth' })
      // focusRef.current?.scrollIntoView({ block: 'start', inline: 'nearest' })
   }, [selectedParty])

   const handleSubmitOnlineSupport = async (e) => {
      e.preventDefault()
      setSubmitted(true)

      if (supportDetails.Party_id !== '') {
         console.log('status Details before submit: ', supportDetails)

         handleAddSupport(supportDetails)
         setSupportDetails({ Party_id: '', PartyName: '', PartyNameHindi: '', PartyNameLocal: '', PartyFlag: '' })
         setSelectedParty('')
      }
   }

   const handleSubmitCancelOnlineSupport = async (e) => {
      e.preventDefault()

      setSupportDetails({ Party_id: '', PartyName: '', PartyNameHindi: '', PartyNameLocal: '', PartyFlag: '' })
      setSelectedParty('')
      handleCancelSupport()
   }
   return (
      <Box
         sx={{
            //    mb: 1,
            m: 1,
            pb: 1,
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            // '& .MuiButton-root': { width: '15ch', mt: 1 },
            '& .MuiTextField-root': { width: '50ch', mb: 0.5, mt: 0 },
            // '& .MuiInputBase-root': { mb: 2 },
            '& .MuiFormControl-root': { width: '50ch', mb: 0.5 },
            justifyContent: 'center',
            alignItems: 'center',
         }}
      >
         <List dense>
            {partiesList &&
               partiesList.length > 0 &&
               partiesList.map((item, index) => (
                  <div key={item.Party_id}>
                     <ListItem
                        sx={{ pl: 0, ml: 0 }}
                        key={item.Party_id}
                        secondaryAction={
                           <Box sx={{ ml: 'auto' }}>
                              <Switch
                                 checked={item.Party_id === supportDetails.Party_id}
                                 onChange={(e) => {
                                    handleOnlineSupportDetails(e, item)
                                 }}
                                 inputProps={{ 'aria-label': 'controlled' }}
                              />
                           </Box>
                        }
                     >
                        <ListItemIcon sx={{ minWidth: '50px !important' }}>
                           {item.Flag && (
                              <Box component="img" src={item.Flag} alt={item.Name} sx={{ height: '25px' }} />
                           )}
                        </ListItemIcon>

                        <ListItemText
                           sx={{ m: 0.5 }}
                           primaryTypographyProps={{ fontSize: 12 }}
                           secondaryTypographyProps={{ align: 'left', fontSize: 12 }}
                           primary={item.Name}
                           secondary={item.NameHindi}
                        />
                     </ListItem>
                     <Divider />
                  </div>
               ))}
         </List>
         {selectedParty !== '' && (
            <Box ref={focusRef} sx={{ mb: 1 }}>
               <Alert variant="outlined" severity="warning">
                  You have chosen to support <strong>{selectedParty.Name}</strong>.
                  <br />
                  आपने <strong>{selectedParty.NameHindi}</strong> का समर्थन करना चुना है |
               </Alert>
            </Box>
         )}

         <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Button
               type="submit"
               align="center"
               variant="contained"
               color="primary"
               size="small"
               onClick={(e) => {
                  handleSubmitOnlineSupport(e)
               }}
               disabled={!supportDetails.Party_id || submitted === true}
               sx={{ fontSize: 12, whiteSpace: 'pre-line', lineHeight: 1.2 }}
            >
               Submit Support {'\n'} समर्थन प्रस्तुत करें
            </Button>
            <Button
               type="submit"
               align="center"
               variant="contained"
               color="error"
               size="small"
               onClick={(e) => {
                  handleSubmitCancelOnlineSupport(e)
               }}
               sx={{ fontSize: 12, whiteSpace: 'pre-line', lineHeight: 1.2 }}
            >
               Cancel {'\n'} निरस्त करें
            </Button>
         </Box>
         <Box>
            {uploading && (
               <MyLoaderCircularGradient title={'Uploading your support vote ...'}></MyLoaderCircularGradient>
            )}
            {uploaded && (
               <Alert variant="outlined" severity="success">
                  Your support updated successfully.
               </Alert>
            )}
            {uploadingFailed && (
               <Alert variant="outlined" severity="error">
                  Error occured! Your support could not be updated.
               </Alert>
            )}
         </Box>
      </Box>
   )
}

SupportPPartyNational.propTypes = {
   partiesList: PropTypes.array.isRequired,
   handleAddSupport: PropTypes.func.isRequired,
   handleCancelSupport: PropTypes.func.isRequired,
   uploading: PropTypes.bool.isRequired,
   uploaded: PropTypes.bool.isRequired,
   uploadingFailed: PropTypes.bool.isRequired,
}

export default SupportPPartyNational
