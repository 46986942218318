import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { db } from '../../FirebaseConfig'
import PropTypes from 'prop-types'

// import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
// import { DatePicker } from '@mui/x-date-pickers/DatePicker'

function AddAddress({ countryId, handleAddAddress }) {
   const [statesList, setStatesList] = useState('')
   const [selectedState, setSelectedState] = useState('')

   const [districtsList, setDistrictsList] = useState('')
   const [selectedDistrict, setSelectedDistrict] = useState('')

   // const [pinCodesList, setPinCodesList] = useState('')
   // const [selectedPinCode, setSelectedPinCode] = useState('')

   const [fetchStatus, setFetchStatus] = useState('idle')

   useEffect(() => {
      if (fetchStatus === 'idle') {
         fetchStates(countryId)
         setFetchStatus('success')
      }
   }, [])

   const [addressDetails, setAddressDetails] = useState({
      ValueRadio: 'Current address',
      State_id: '',
      StateName: '',
      District_id: '',
      DistrictName: '',
      PinCode: '',
      Address: '',
      RecordedDate: '',
      IsActive: true,
   })

   useEffect(() => {
      console.log('party address Details on change: ', addressDetails)
   }, [addressDetails])

   function handleAddressDetails(event) {
      setAddressDetails({ ...addressDetails, [event.target.name]: event.target.value })
   }

   // related to states

   const fetchStates = async (countryId) => {
      try {
         const q = query(collection(db, 'States'), where('Country_id', '==', countryId), orderBy('Name', 'asc'))
         const querySnapshot = await getDocs(q)
         console.log('States fetched querySnapshot: ', querySnapshot)
         const statesFetched = []
         querySnapshot.forEach((doc) => {
            statesFetched.push({ id: doc.id, ...doc.data() })
         })
         setStatesList(statesFetched)
      } catch (err) {
         console.log('error fetching states: ', err)
      }
   }

   const handleStateChange = (e) => {
      e.preventDefault()
      setSelectedState(e.target.value)
      const stateComplete = statesList.find((item) => item.id === e.target.value)
      setAddressDetails({
         ...addressDetails,
         State_id: e.target.value,
         StateName: stateComplete.Name,
      })
      console.log('State name selected: ', stateComplete.Name)
   }

   useEffect(() => {
      console.log('selected state name from address details: ', addressDetails.StateName)
      fetchDistrictsList(selectedState)
   }, [selectedState])

   // related to districts

   const fetchDistrictsList = async (stateId) => {
      try {
         const q = query(collection(db, 'Districts'), where('State_id', '==', stateId), orderBy('Name', 'asc'))
         const querySnapshot = await getDocs(q)
         const districtsListFetched = []
         querySnapshot.forEach((doc) => {
            districtsListFetched.push({ id: doc.id, ...doc.data() })
         })
         setDistrictsList(districtsListFetched)
         console.log('districts list fetched by state ', districtsListFetched)
      } catch (err) {
         console.log('error fetching districts list: ', err)
      }
   }

   const handleDistrictChange = (e) => {
      e.preventDefault()
      setSelectedDistrict(e.target.value)
      const districtComplete = districtsList.find((item) => item.id === e.target.value)
      setAddressDetails({
         ...addressDetails,
         District_id: e.target.value,
         DistrictName: districtComplete.Name,
      })
      console.log('District name selected: ', districtComplete.Name)
   }

   // useEffect(() => {
   //    console.log('selected district name : ', addressDetails.DistrictName)
   //    // fetch districts
   //    fetchPinCodeList(selectedDistrict)
   // }, [selectedDistrict])

   // related to pin code

   // const fetchPinCodeList = async (districtId) => {
   //    try {
   //       const q = query(collection(db, 'PinCodes'), where('District_id', '==', districtId), orderBy('Name', 'asc'))
   //       const querySnapshot = await getDocs(q)
   //       const pinCodesListFetched = []
   //       querySnapshot.forEach((doc) => {
   //          pinCodesListFetched.push({ id: doc.id, ...doc.data() })
   //       })
   //       setPinCodesList(pinCodesListFetched)
   //       console.log('PinCodes list fetched by district ', pinCodesListFetched)
   //    } catch (err) {
   //       console.log('error fetching PinCodes list: ', err)
   //    }
   // }

   // const handlePinCodeChange = (e) => {
   //    e.preventDefault()
   //    setSelectedPinCode(e.target.value)
   //    setAddressDetails({
   //       ...addressDetails,
   //       PinCode: e.target.value,
   //    })
   // }

   // related to radio button

   // const handleChangeRadio = (e) => {
   //    e.preventDefault()
   //    setAddressDetails({
   //       ...addressDetails,
   //       ValueRadio: e.target.value,
   //    })
   // }

   // related to form filling and submit

   function handleAddressSubmit(e) {
      e.preventDefault()
      console.log('stage 1 submit')
      handleAddAddress(addressDetails)
      console.log('stage 2 submit')
      reset()
      console.log('stage 3 submit')
   }

   function reset() {
      setAddressDetails({
         ValueRadio: 'Current Address',
         State_id: '',
         StateName: '',
         District_id: '',
         DistrictName: '',
         PinCode: '',
         Address: '',
         RecordedDate: '',
         IsActive: true,
      })
      setSelectedState('')
      setSelectedDistrict('')
      // setSelectedPinCode('')
      setDistrictsList('')
   }

   return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
         <Box sx={{ display: 'flex', flexDirection: 'column', m: 2 }}>
            {/* <FormControl sx={{ border: 1, borderRadius: 1, borderColor: 'text.disabled', px: 2, mt: 1 }}>
                  <FormLabel id="demo-row-radio-buttons-group-label">Relevance*</FormLabel>
                  <RadioGroup
                     row
                     aria-labelledby="demo-row-radio-buttons-group-label"
                     name="row-radio-buttons-group"
                     value={addressDetails.ValueRadio}
                     onChange={(e) => {
                        handleChangeRadio(e)
                     }}
                  >
                     <FormControlLabel
                        value="Current address"
                        control={<Radio size="small" />}
                        label="Current address"
                     />
                     <FormControlLabel value="Old address" control={<Radio size="small" />} label="Old address" />
                  </RadioGroup>
               </FormControl> */}
            <FormControl sx={{ mt: 1, width: 300 }} size="small">
               <InputLabel id="state-label">State</InputLabel>
               <Select
                  labelId="state-label"
                  id="state"
                  value={selectedState}
                  label="State"
                  onChange={(e) => {
                     handleStateChange(e)
                  }}
                  sx={{ backgroundColor: '#FFFFFF' }}
               >
                  <MenuItem value="">
                     <em>None</em>
                  </MenuItem>
                  {statesList &&
                     statesList.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                           {item.Name}
                        </MenuItem>
                     ))}
               </Select>
            </FormControl>
            <FormControl sx={{ mt: 1, width: 300 }} size="small">
               <InputLabel id="district-label">District</InputLabel>
               <Select
                  labelId="district-label"
                  id="district"
                  value={selectedDistrict}
                  label="District"
                  onChange={(e) => {
                     handleDistrictChange(e)
                  }}
                  sx={{ backgroundColor: '#FFFFFF' }}
               >
                  <MenuItem value="">
                     <em>None</em>
                  </MenuItem>
                  {districtsList &&
                     districtsList.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                           {item.Name}
                        </MenuItem>
                     ))}
               </Select>
            </FormControl>
            {/* <Box sx={{ mt: 1 }}>
                  <FormControl sx={{ my: 1, width: 300 }} size="small">
                     <InputLabel id="pin-label">Postal code</InputLabel>
                     <Select
                        labelId="pin-label"
                        id="pin"
                        value={selectedPinCode}
                        label="Postal code"
                        onChange={(e) => {
                           handlePinCodeChange(e)
                        }}
                        sx={{ backgroundColor: '#FFFFFF' }}
                     >
                        <MenuItem value="">
                           <em>None</em>
                        </MenuItem>
                        {pinCodesList &&
                           pinCodesList.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                 {item.Name}
                              </MenuItem>
                           ))}
                     </Select>
                  </FormControl>
               </Box> */}

            <TextField
               onBlur={(event) => event}
               onChange={handleAddressDetails}
               value={addressDetails.PinCode}
               name="PinCode"
               id="pinCode"
               label="Postal code"
               size="small"
               margin="dense"
               sx={{ paddingBottom: 0, backgroundColor: '#ffffff', mt: 1 }}
            />

            <TextField
               onBlur={(event) => event}
               onChange={handleAddressDetails}
               value={addressDetails.Address}
               name="Address"
               id="address"
               label="Address"
               multiline
               rows={4}
               // defaultValue="the address"
               size="small"
               margin="dense"
               sx={{ paddingBottom: 0, backgroundColor: '#ffffff', mt: 1 }}
            />

            {/* <Box sx={{ mt: 1 }}>
                  <DatePicker
                     label="Recorded Date"
                     name="RecordedDate"
                     value={addressDetails.RecordedDate != null ? dayjs(addressDetails.RecordedDate) : null}
                     format="DD-MMM-YYYY"
                     //  onChange={(newValue) => setDateRecognition(newValue)}
                     onChange={(newValue) =>
                        setAddressDetails({
                           ...addressDetails,
                           RecordedDate: new Date(newValue),
                        })
                     }
                     views={['year', 'month', 'day']}
                     sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
                  />
               </Box> */}
            <Box sx={{ textAlign: 'center', my: 1 }}>
               <Button
                  type="submit"
                  align="center"
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={(e) => {
                     handleAddressSubmit(e)
                  }}
                  disabled={
                     addressDetails.State_id.length < 3 ||
                     // addressDetails.District_id.length < 3 ||
                     addressDetails.Address.length < 10 ||
                     addressDetails.ValueRadio === ''
                  }
               >
                  Submit
               </Button>
            </Box>
         </Box>
      </LocalizationProvider>
   )
}

AddAddress.propTypes = {
   countryId: PropTypes.string.isRequired,
   handleAddAddress: PropTypes.func.isRequired,
}

export default AddAddress
