import { Box, Button, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import myStyles from './Edits.module.css'
import PropTypes from 'prop-types'

function AddTextMultiline({ prevText, labelName, textLength, handleAddTextMultiline }) {
   const [textDetails, setTextDetails] = useState({
      Text: '',
   })

   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')
   const [tLength, setTLength] = useState(0)
   console.log('text length: ', textLength)

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         //
         if (prevText !== '')
            setTextDetails({
               Text: prevText,
            })
         setFirstLoadStatus('success')
      }
      console.log('Multiline text details on change of its value: ', textDetails)
   }, [])

   useEffect(() => {
      console.log('Multiline text details on change of its value: ', textDetails)
   }, [textDetails])

   function handleTextDetails(event) {
      try {
         if (typeof event.target.value !== 'undefined') {
            const data = event.target.value
            if (data.length <= textLength) {
               setTextDetails({ ...textDetails, [event.target.name]: data })
               setTLength(data.length)
            } else if (data.length > textLength) {
               const newData = data.slice(0, textLength)
               setTextDetails({ ...textDetails, [event.target.name]: newData })
               setTLength(newData.length)
            }
         } else {
            setTextDetails({ Text: '' })
            return false
         }
      } catch (error) {
         console.log(error)
      }
   }

   const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
         handleSubmitText(event)
      }
   }

   function handleSubmitText(e) {
      e.preventDefault()

      if (textDetails.Text === '') {
         alert('Please add some text.')
         return
      }

      handleAddTextMultiline(textDetails)

      reset()
   }

   function reset() {
      setTextDetails({
         Text: '',
      })
   }

   return (
      <Box sx={{ m: 2 }}>
         <div>
            <TextField
               onBlur={(event) => event}
               onChange={handleTextDetails}
               slotProps={{ htmlInput: { maxLength: textLength } }}
               value={textDetails.Text}
               name="Text"
               id="text"
               label={labelName}
               variant="outlined"
               margin="dense"
               required
               multiline
               rows={4}
               size="small"
               autoComplete="off"
               autoFocus={true}
               onKeyDown={handleKeyDown}
               sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1, width: '50ch' }}
            ></TextField>
         </div>
         <Box>
            Length of intro: {tLength} / {textLength}
         </Box>
         <div className={myStyles.text_align_center}>
            <Button
               type="submit"
               align="center"
               variant="contained"
               color="primary"
               size="small"
               margin="dense"
               sx={{ my: 1 }}
               onClick={(e) => {
                  handleSubmitText(e)
               }}
               disabled={tLength < 10}
            >
               Submit
            </Button>
         </div>
      </Box>
   )
}

AddTextMultiline.propTypes = {
   prevText: PropTypes.string.isRequired,
   textLength: PropTypes.number.isRequired,
   labelName: PropTypes.string.isRequired,
   handleAddTextMultiline: PropTypes.func.isRequired,
}

export default AddTextMultiline
