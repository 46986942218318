import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Alert, Box, Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material'

import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'

// import { LocalizationProvider } from '@mui/x-date-pickers'
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import MyLoaderCircularGradient from '../../../Utils/Loaders/MyLoaderCircularGradient'

function AddCandiStatus3({ uploadingStatus, uploadingFailedStatus, uploadedStatus, handleAddStatus3 }) {
   const [statusDetails, setStatusDetails] = useState({
      StatusType: '',
      StatusDate: '',
   })
   const [actionIsValid, setActionIsValid] = useState(false)
   const [date, setDate] = useState('')

   function handleChangeStatus(e) {
      e.preventDefault()

      if (e.target.value !== '') {
         setStatusDetails({ ...statusDetails, StatusType: e.target.value })
      } else {
         setStatusDetails({ ...statusDetails, StatusType: '' })
      }
   }

   useEffect(() => {
      if (date !== '') {
         setStatusDetails({
            ...statusDetails,
            StatusDate: date.$d,
         })
      } else {
         setStatusDetails({
            StatusDate: '',
         })
      }
   }, [date])

   const handleSubmitStatus = async (e) => {
      e.preventDefault()

      if (actionIsValid) {
         // console.log('status Details before submit: ', statusDetails)

         handleAddStatus3(statusDetails, actionIsValid)
      }
   }

   useEffect(() => {
      // console.log('status details on change: ', statusDetails)
      if (statusDetails.StatusType === '') {
         setActionIsValid(false)
      } else {
         setActionIsValid(true)
      }
   }, [statusDetails])

   return (
      //   <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
         sx={{
            //    mb: 1,
            mx: 1,
            pb: 1,
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            '& .MuiButton-root': { width: '15ch', mt: 1 },
            '& .MuiTextField-root': { width: '50ch', mb: 0.5, mt: 0 },
            // '& .MuiInputBase-root': { mb: 2 },
            '& .MuiFormControl-root': { width: '50ch', mb: 0.5 },
            justifyContent: 'center',
            alignItems: 'center',
         }}
      >
         <Box>
            <FormControl sx={{ m: 1 }} size="small">
               <InputLabel>Status</InputLabel>
               <Select
                  value={statusDetails.StatusType}
                  label="Party"
                  onChange={(e) => {
                     handleChangeStatus(e)
                  }}
                  sx={{ backgroundColor: '#ffffff' }}
               >
                  <MenuItem value="">
                     <em>None</em>
                  </MenuItem>
                  <MenuItem value="Announced">Announced by Party</MenuItem>
                  <MenuItem value="AnnounceCancelled">Announcement cancelled by Party</MenuItem>
                  <MenuItem value="Filed">Filed</MenuItem>
                  <MenuItem value="Accepted">Accepted</MenuItem>
                  <MenuItem value="Rejected">Rejected</MenuItem>
                  <MenuItem value="Refused">Refused by Candidate</MenuItem>
                  <MenuItem value="Withdrawn">Withdrawn</MenuItem>
               </Select>
            </FormControl>
         </Box>
         <Box>
            <DatePicker
               label="Date"
               name="Date"
               value={date != null ? dayjs(date) : null}
               format="DD-MMM-YYYY"
               onChange={(newValue) => setDate(newValue)}
               views={['year', 'month', 'day']}
               sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1, mt: 1 }}
            />
         </Box>

         <Box>
            <Button
               type="submit"
               align="center"
               variant="contained"
               color="primary"
               size="small"
               onClick={(e) => {
                  handleSubmitStatus(e)
               }}
               disabled={!actionIsValid}
            >
               Submit
            </Button>
         </Box>
         <Box>
            {uploadingStatus && <MyLoaderCircularGradient title={'Uploading status ...'}></MyLoaderCircularGradient>}
            {uploadedStatus && (
               <Alert variant="outlined" severity="success">
                  Status updated successfully.
               </Alert>
            )}
            {uploadingFailedStatus && (
               <Alert variant="outlined" severity="error">
                  Error occured! Status could not be updated.
               </Alert>
            )}
         </Box>
      </Box>
      //   </LocalizationProvider>
   )
}

AddCandiStatus3.propTypes = {
   handleAddStatus3: PropTypes.func.isRequired,
   uploadingStatus: PropTypes.bool.isRequired,
   uploadedStatus: PropTypes.bool.isRequired,
   uploadingFailedStatus: PropTypes.bool.isRequired,
}

export default AddCandiStatus3
