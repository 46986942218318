import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './ElecCandis.module.css'
import {
   Alert,
   Avatar,
   Box,
   Button,
   Divider,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   Typography,
} from '@mui/material'

import { countWordsUsingReplace } from '../../../Utils/Conversions/CaseConversion'
import { stringAvatar80, stringAvatar80Single } from '../../../Utils/MUITheme/MUITheme'
import MyLoaderCircularGradient from '../../../Utils/Loaders/MyLoaderCircularGradient'
import Switch from '@mui/material/Switch'

import NOTA from '../../../Images/NOTA.png'

function AddCandiOnlineSupport({
   actionForSupport,
   candidatesListForOnlineSupport,
   uploadingOnlineSupport,
   uploadingFailedOnlineSupport,
   uploadedOnlineSupport,
   addOnlineSupport,
   cancelOnlineSupport,
}) {
   const [onlineSupportDetails, setOnlineSupportDetails] = useState({
      Candidate_id: '',
      CandidateName: '',
      CandidateNameHindi: '',
      CandidateNameLocal: '',
      PartyName: '',
      PartyNameHindi: '',
      PartyNameLocal: '',
      PartyId: '',
      Alliances: '',
      CoreListId: '',
   })
   const [disableButtons, setDisableButtons] = useState(false)
   const [selectedCandi, setSelectedCandi] = useState('')
   const focusRef = useRef(null)

   useEffect(() => {
      // console.log('on line vote details on change: ', onlineSupportDetails)
   }, [onlineSupportDetails])

   function handleOnlineSupportDetails(e, item) {
      e.preventDefault()

      setOnlineSupportDetails({
         Candidate_id: item.Candidate_id,
         CandidateName: item.Name,
         CandidateNameHindi: item.NameHindi,
         CandidateNameLocal: item.NameLocal,
         PartyName: item.PartyName,
         PartyNameHindi: item.PartyNameHindi,
         PartyNameLocal: item.PartyNameLocal,
         Party_id: item.Party_id,
         Alliances: item.Alliances,
         CoreListId: item.CoreListId,
      })
      setSelectedCandi(item)
   }

   useEffect(() => {
      focusRef.current?.scrollIntoView({ behavior: 'smooth' })
      // focusRef.current?.scrollIntoView({ block: 'start', inline: 'nearest' })
   }, [selectedCandi])

   const handleSubmitOnlineSupport = async (e) => {
      e.preventDefault()

      if (onlineSupportDetails.Candidate_id !== '') {
         // console.log('status Details before submit: ', onlineSupportDetails)
         setDisableButtons(true)

         addOnlineSupport(onlineSupportDetails)
      }
   }

   const handleSubmitCancelOnlineSupport = async (e) => {
      e.preventDefault()

      setOnlineSupportDetails({
         Candidate_id: '',
         CandidateName: '',
         CandidateNameHindi: '',
         CandidateNameLocal: '',
         PartyName: '',
         PartyId: '',
         Alliances: '',
         CoreListId: '',
      })
      cancelOnlineSupport()
   }
   return (
      <Box
         sx={{
            //    mb: 1,
            m: 1,
            pb: 1,
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            // '& .MuiButton-root': { width: '15ch', mt: 1 },
            '& .MuiTextField-root': { width: '50ch', mb: 0.5, mt: 0 },
            // '& .MuiInputBase-root': { mb: 2 },
            '& .MuiFormControl-root': { width: '50ch', mb: 0.5 },
            justifyContent: 'center',
            alignItems: 'center',
         }}
      >
         <List dense>
            {candidatesListForOnlineSupport &&
               candidatesListForOnlineSupport.length > 0 &&
               candidatesListForOnlineSupport.map((item, index) => (
                  <div key={item.Candidate_id}>
                     <ListItem
                        sx={{ pl: 0, ml: 0 }}
                        key={item.Candidate_id}
                        secondaryAction={
                           <Box sx={{ ml: 'auto' }}>
                              <Switch
                                 checked={item.Candidate_id === onlineSupportDetails.Candidate_id}
                                 onChange={(e) => {
                                    handleOnlineSupportDetails(e, item)
                                 }}
                                 inputProps={{ 'aria-label': 'controlled' }}
                              />
                           </Box>
                        }
                     >
                        <ListItemAvatar>
                           <Typography
                              component="span"
                              variant="body2"
                              sx={{
                                 color: 'text.primary',
                                 display: 'inline',
                                 fontWeight: 600,
                                 mb: 1,
                              }}
                           >
                              {index + 1}.
                           </Typography>
                           {item.Name &&
                              (countWordsUsingReplace(item.Name) > 1 ? (
                                 <Avatar {...stringAvatar80(item.Name)} alt="Travis Howard" src={item.Image} />
                              ) : item.Name !== 'Zznota' ? (
                                 <Avatar {...stringAvatar80Single(item.Name)} alt="Travis Howard" src={item.Image} />
                              ) : (
                                 <Avatar
                                    sx={{
                                       width: 80,
                                       height: 80,
                                       backgroundColor: 'Yellow',
                                    }}
                                    src={NOTA}
                                 />
                              ))}
                        </ListItemAvatar>

                        <ListItemText
                           sx={{ ml: 1 }}
                           secondaryTypographyProps={{ align: 'left', whiteSpace: 'pre-line' }}
                           primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                           primary={
                              <>
                                 {item.Name !== 'Zznota' ? (
                                    <strong>{item.Name.toUpperCase()}</strong>
                                 ) : (
                                    <strong>NOTA</strong>
                                 )}

                                 {item.NameLocal && (
                                    <>
                                       <br />
                                       {item.NameLocal}
                                    </>
                                 )}
                                 {!item.NameLocal && (
                                    <>
                                       <br />
                                       {item.NameHindi}
                                    </>
                                 )}
                              </>
                           }
                           component={'span'}
                           secondary={
                              <>
                                 <>
                                    {item.PartyFlag && <img src={item.PartyFlag} className={styles.imgX2} />}

                                    {item.PartySymbol && <img src={item.PartySymbol} className={styles.imgX3} />}
                                    {item.AllottedSymbol && <img src={item.AllottedSymbol} className={styles.imgX3} />}
                                 </>
                                 <>
                                    {<strong> Party: </strong>}
                                    <Typography
                                       sx={{ display: 'inline' }}
                                       component="span"
                                       variant="body2"
                                       color="text.primary"
                                    >
                                       {item.PartyName}
                                    </Typography>
                                 </>
                              </>
                           }
                        />
                     </ListItem>
                     <Divider />
                  </div>
               ))}
         </List>
         {selectedCandi !== '' && (
            <Box ref={focusRef} sx={{ mb: 1 }}>
               <Alert variant="outlined" severity="warning">
                  You have chosen to support <strong>{selectedCandi.Name}</strong>.
                  <br />
                  आपने <strong>{selectedCandi.NameHindi}</strong> का समर्थन करना चुना है |
               </Alert>
            </Box>
         )}
         {actionForSupport === 'attach and then accept the support' && (
            <Alert variant="outlined" severity="error" sx={{ color: 'Crimson' }}>
               <Typography sx={{ lineHeight: 1.1 }} variant="body1">
                  Please express your support inside <strong>your constituency strictly</strong>.
               </Typography>
               <Typography sx={{ color: '#4caf50', lineHeight: 1.1 }}>
                  Your user_id will be automatically attached to this constituency for next three years.
               </Typography>
               <br />
               <Typography>
                  कृपया <strong>अपने निर्वाचन क्षेत्र</strong> के अंदर ही अपना समर्थन प्रदर्शित करें।
               </Typography>
               <Typography sx={{ color: '#4caf50' }}>
                  आपकी user_id अगले तीन वर्षों के लिए स्वचालित रूप से इस निर्वाचन क्षेत्र से जुड़ जाएगी।
               </Typography>
            </Alert>
         )}

         <Box sx={{ mt: 1 }}>
            <Button
               type="submit"
               align="center"
               variant="contained"
               color="primary"
               size="small"
               onClick={(e) => {
                  handleSubmitOnlineSupport(e)
               }}
               disabled={!onlineSupportDetails.Candidate_id || disableButtons}
               sx={{ fontSize: 12, whiteSpace: 'pre-line', lineHeight: 1.2, mr: 3 }}
            >
               Submit Support {'\n'} समर्थन प्रस्तुत करें
            </Button>
            <Button
               type="submit"
               align="center"
               variant="contained"
               color="error"
               size="small"
               onClick={(e) => {
                  handleSubmitCancelOnlineSupport(e)
               }}
               sx={{ fontSize: 12, whiteSpace: 'pre-line', lineHeight: 1.2 }}
               disabled={disableButtons}
            >
               Cancel {'\n'} निरस्त करें
            </Button>
         </Box>
         <Box>
            {uploadingOnlineSupport && (
               <MyLoaderCircularGradient title={'Uploading your support vote ...'}></MyLoaderCircularGradient>
            )}
            {uploadedOnlineSupport && (
               <Alert variant="outlined" severity="success">
                  Your support updated successfully.
               </Alert>
            )}
            {uploadingFailedOnlineSupport && (
               <Alert variant="outlined" severity="error">
                  Error occured! Your support could not be updated.
               </Alert>
            )}
         </Box>
      </Box>
   )
}

AddCandiOnlineSupport.propTypes = {
   actionForSupport: PropTypes.string.isRequired,
   candidatesListForOnlineSupport: PropTypes.object.isRequired,
   addOnlineSupport: PropTypes.func.isRequired,
   cancelOnlineSupport: PropTypes.func.isRequired,
   uploadingOnlineSupport: PropTypes.bool.isRequired,
   uploadedOnlineSupport: PropTypes.bool.isRequired,
   uploadingFailedOnlineSupport: PropTypes.bool.isRequired,
}

export default AddCandiOnlineSupport
