import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
   Alert,
   AlertTitle,
   Avatar,
   Box,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogContentText,
   DialogTitle,
   Divider,
   FormControl,
   IconButton,
   InputLabel,
   Link,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   MenuItem,
   Modal,
   Select,
   Slide,
   Typography,
} from '@mui/material'

import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
// import InfoSharpIcon from '@mui/icons-material/InfoSharp'
import HowToVoteIcon from '@mui/icons-material/HowToVote'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { v4 } from 'uuid'

import styles from './ElecCandis.module.css'

import { useDispatch, useSelector } from 'react-redux'
import { selectUserProfile, selectUsers, setUserProfile } from '../../../redux/reducers/users-slice'

import { useNavigate } from 'react-router-dom'
import { auth, db, dbStorage } from '../../../FirebaseConfig'
import {
   addDoc,
   arrayRemove,
   arrayUnion,
   collection,
   deleteDoc,
   doc,
   getDoc,
   getDocs,
   increment,
   query,
   setDoc,
   updateDoc,
   where,
} from 'firebase/firestore'

import AddCandis from './AddCandis'
import moment from 'moment'
import {
   stringAvatar80,
   stringAvatar80Single,
   styleModalBox,
   styleModalTypography,
} from '../../../Utils/MUITheme/MUITheme'
import { countWordsUsingReplace, sentenceCase } from '../../../Utils/Conversions/CaseConversion'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import CloudOffIcon from '@mui/icons-material/CloudOff'
import AddCandiOnlineSupport from './AddCandiOnlineSupport'
import AddWinner from './AddWinner'
import AddNumber from '../../../Components/Edits/AddNumber'
import { Helmet } from 'react-helmet'
import ElecCandiConstiTransfer from './ElecCandiConstiTransfer'
import { selectPParties } from '../../../redux/reducers/pParties-slice'
import ElecCandiComp from '../../../Components/ElecCandiComp/ElecCandiComp'
import ElecCandiCompAccepted from '../../../Components/ElecCandiComp/ElecCandiCompAccepted'
// import ElecCandiCompWaiting from '../../../Components/ElecCandiComp/ElecCandiCompWaiting'
import ElecCandiCompWithdrawn from '../../../Components/ElecCandiComp/ElecCandiCompWithdrawn'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
// import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt'

import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import AddCandiStatus3 from './AddCandiStatus3'

const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction="up" ref={ref} {...props} />
})

function ElecCandis({
   legis,
   elec,
   constituencyId,
   constisListFromElec,
   candidatesListRoot,
   handleCandidatesListRootUpdate,
}) {
   // console.log('value from props from inside Elec Candis page: ', elec)

   const user = useSelector(selectUsers)
   const ip = user.ip
   const dispatch = useDispatch()
   const pParties = useSelector(selectPParties)

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')
   const [nameOfUser, setNameOfUser] = useState('')
   const [attachedConstiName, setAttachedConstiName] = useState('')

   useEffect(() => {
      // console.log('ElecCandis - first use effect entered')

      if (user.currentUser !== null) {
         // console.log('ElecCandis - found user state not-null')
         setThisUser(user.currentUser.id)
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
         } else {
            setIsSuper(false)
            setIsDataEditor(false)
         }
      } else {
         // console.log('ElecCandis - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   const userProfile = useSelector(selectUserProfile)
   // console.log('user profile: ', userProfile)

   useEffect(() => {
      if (userProfile && userProfile !== '') {
         const name = userProfile.NameFirst + ' ' + userProfile.NameMiddle + ' ' + userProfile.NameLast
         setNameOfUser(name)
         // console.log('userProfile use state: ', userProfile)
         switch (legis.Strata) {
            case 'Central':
               getLastSupportCenter()
               if (userProfile.AtCentralConstituency && userProfile.AtCentralConstituency.ConstituencyNameLocal) {
                  setAttachedConstiName(
                     userProfile.AtCentralConstituency.ConstituencyName +
                        ' (' +
                        userProfile.AtCentralConstituency.ConstituencyNumber +
                        ') ' +
                        userProfile.AtCentralConstituency.ConstituencyNameLocal,
                  )
               } else {
                  setAttachedConstiName(
                     userProfile.AtCentralConstituency.ConstituencyName +
                        ' (' +
                        userProfile.AtCentralConstituency.ConstituencyNumber +
                        ') ' +
                        userProfile.AtCentralConstituency.ConstituencyNameHindi,
                  )
               }
               break
            case 'State':
               getLastSupportState()
               if (userProfile.AtStateConstituency && userProfile.AtStateConstituency.ConstituencyNameLocal) {
                  setAttachedConstiName(
                     userProfile.AtStateConstituency.ConstituencyName +
                        ' (' +
                        userProfile.AtStateConstituency.ConstituencyNumber +
                        ') ' +
                        userProfile.AtStateConstituency.ConstituencyNameLocal,
                  )
               } else {
                  setAttachedConstiName(
                     userProfile.AtStateConstituency.ConstituencyName +
                        ' (' +
                        userProfile.AtStateConstituency.ConstituencyNumber +
                        ') ' +
                        userProfile.AtStateConstituency.ConstituencyNameHindi,
                  )
               }
               break
            case 'Division':
               //
               break
            case 'District':
               //
               break
            case 'SubDistrict':
               //
               break
            case 'Block':
               //
               break
            case 'Municipality':
               //
               break
            case 'Village':
               //
               break
         }
      }
   }, [userProfile])

   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')
   const [constisList, setConstisList] = useState([])

   const [selectedConstiId, setSelectedConstiId] = useState('')
   const [selectedConsti, setSelectedConsti] = useState('')

   const [selectedConstiPhaseDate, setSelectedConstiPhaseDate] = useState('')

   const [candidatesListRootLocal, setCandidatesListRootLocal] = useState([])
   const [candidatesListRejected, setCandidatesListRejected] = useState([])
   const [candidatesListWithdrawn, setCandidatesListWithdrawn] = useState([])
   const [candidatesListWaiting, setCandidatesListWaiting] = useState([])
   const [candidatesListAccepted, setCandidatesListAccepted] = useState([])
   const [candidatesListForOnlineSupport, setCandidatesListForOnlineSupport] = useState([])
   const [partiesList, setPartiesList] = useState([])

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         setPartiesList(pParties.pPartiesList)

         setCandidatesListRootLocal(candidatesListRoot)

         const sortedConstisList = [...constisListFromElec].sort((a, b) => {
            if (b.Name.trim() > a.Name.trim()) {
               return -1
            } else if (b.Name.trim() < a.Name.trim()) {
               return 1
            } else {
               return 0
            }
         })
         setConstisList(sortedConstisList)

         setFirstLoadStatus('success')
         // console.log('ElecCandis - first use effect blank')
      }
   }, [])

   useEffect(() => {
      if (constisList && constisList.length > 0) {
         setSelectedConstiId(constituencyId)
      } else {
         // console.log('ElecCandis - use effect of candis list root: no list')
      }
   }, [constisList])

   const handleChangeConsti = async (e) => {
      e.preventDefault()

      setSelectedConstiId(e.target.value)
      // console.log('ElecCandis - handle change constis')
   }

   useEffect(() => {
      // console.log('ElecCandis - use effect of selected consti - start - out')
      if (selectedConstiId && selectedConstiId !== '') {
         // console.log('ElecCandis - use effect of selected consti - start - in - 1', selectedConstiId)
         const consti = constisListFromElec.find((item) => item.Constituency_id === selectedConstiId)

         setSelectedConsti(consti)

         const phase = elec.Phases.find((item) => item.PhaseNum === consti.PhaseNum)
         if (phase && phase !== '') {
            setSelectedConstiPhaseDate(phase.DateOfPoll)
         }

         const list = candidatesListRootLocal.filter((item) => item.Constituency_id === selectedConstiId)
         const listAccepted = list.filter((item) => item.Accepted === 'Accepted' && item.Withdrawn === '')
         const listRejected = list.filter(
            (item) =>
               item.Rejected === 'Rejected' ||
               item.Refused === 'Refused' ||
               item.AnnounceCancelled === 'AnnounceCancelled',
         )
         const listWithdrawn = list.filter((item) => item.Withdrawn === 'Withdrawn')
         // const listAccepted = list.filter((item) => item.Accepted === 'Accepted')
         const listWaiting = list.filter(
            (item) =>
               item.Accepted !== 'Accepted' &&
               item.Rejected !== 'Rejected' &&
               item.Refused !== 'Refused' &&
               item.Withdrawn !== 'Withdrawn' &&
               item.AnnounceCancelled !== 'AnnounceCancelled',
         )

         const listForOnlineSupport = [...listAccepted, ...listWaiting]

         // console.log('list: ', list)

         let sortedListForOnlineSupport = []
         if (!consti.OnlineSupportStarted || consti.OnlineSupportStarted === false) {
            // console.log('star')
            sortedListForOnlineSupport = [...listForOnlineSupport].sort((a, b) => {
               if (b.Name.trim() > a.Name.trim()) {
                  return -1
               } else if (b.Name.trim() < a.Name.trim()) {
                  return 1
               } else {
                  return 0
               }
            })
         } else {
            // console.log('moon')
            sortedListForOnlineSupport = [...listForOnlineSupport].sort((a, b) => b.OnlineSupport - a.OnlineSupport)
         }
         setCandidatesListForOnlineSupport(sortedListForOnlineSupport)

         let sortedListWaiting = []
         sortedListWaiting = [...listWaiting].sort((a, b) => {
            if (b.Name.trim() > a.Name.trim()) {
               return -1
            } else if (b.Name.trim() < a.Name.trim()) {
               return 1
            } else {
               return 0
            }
         })
         setCandidatesListWaiting(sortedListWaiting)

         let sortedListAccepted = []
         if (!elec.ResultsDeclared) {
            sortedListAccepted = [...listAccepted].sort((a, b) => {
               if (b.Name.trim() > a.Name.trim()) {
                  return -1
               } else if (b.Name.trim() < a.Name.trim()) {
                  return 1
               } else {
                  return 0
               }
            })
            // console.log('ElecCandis - use effect of selected consti - start - in - 3')
         } else {
            sortedListAccepted = [...listAccepted].sort((a, b) => b.Votes - a.Votes)
         }

         setCandidatesListAccepted(sortedListAccepted)
         // console.log('ElecCandis - use effect of selected consti - end: sortedListAccepted: ', sortedListAccepted)

         let sortedListRejected = []
         sortedListRejected = [...listRejected].sort((a, b) => {
            if (b.Name.trim() > a.Name.trim()) {
               return -1
            } else if (b.Name.trim() < a.Name.trim()) {
               return 1
            } else {
               return 0
            }
         })
         setCandidatesListRejected(sortedListRejected)

         let sortedListWithdrawn = []
         sortedListWithdrawn = [...listWithdrawn].sort((a, b) => {
            if (b.Name.trim() > a.Name.trim()) {
               return -1
            } else if (b.Name.trim() < a.Name.trim()) {
               return 1
            } else {
               return 0
            }
         })
         setCandidatesListWithdrawn(sortedListWithdrawn)
      } else {
         setCandidatesListWaiting([])
         setCandidatesListAccepted([])
         setCandidatesListRejected([])
         setCandidatesListWithdrawn([])
         setCandidatesListForOnlineSupport([])
      }
   }, [selectedConstiId])

   // related to form filling and submission of candis

   const [openModalAddCandis, setOpenModalAddCandis] = useState(false)

   const handleOpenModalAddCandis = async (e) => {
      e.preventDefault()

      setOpenModalAddCandis(true)
   }

   const handleCloseModalAddCandis = () => setOpenModalAddCandis(false)

   const [lastCandi, setLastCandi] = useState('')
   const [uploadingCandis, setUploadingCandis] = useState(false)
   const [uploadedCandis, setUploadedCandis] = useState(false)
   const [uploadingFailedCandis, setUploadingFailedCandis] = useState(false)

   const handleAddCandis = async (candiDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (candiDetails.Name.length > 3) {
               // console.log('reached add')
               setUploadingCandis(true)

               candiDetails.CrBy = auth.currentUser.uid
               candiDetails.CrDt = new Date()
               candiDetails.CrIP = ip.data.ip

               candiDetails.Country_id = legis.Country_id
               candiDetails.CountryName = legis.CountryName
               candiDetails.State_id = legis.State_id
               candiDetails.StateName = legis.StateName
               candiDetails.Division_id = legis.Division_id
               candiDetails.DivisionName = legis.DivisionName
               candiDetails.District_id = legis.District_id
               candiDetails.DistrictName = legis.DistrictName
               candiDetails.SubDistrict_id = legis.SubDistrict_id
               candiDetails.SubDistrictName = legis.SubDistrictName
               candiDetails.Block_id = legis.Block_id
               candiDetails.BlockName = legis.BlockName
               candiDetails.Village_id = legis.Village_id
               candiDetails.VillageName = legis.VillageName

               candiDetails.Name = sentenceCase(candiDetails.Name).trim()
               candiDetails.NameHindi = candiDetails.NameHindi.trim()
               candiDetails.NameLocal = candiDetails.NameLocal.trim()

               candiDetails.NameOld = [
                  {
                     Name: candiDetails.Name,
                     CrBy: auth.currentUser.uid,
                     CrDt: new Date(),
                     CrIP: ip.data.ip,
                  },
               ]
               if (candiDetails.NameHindi !== '') {
                  candiDetails.NameHindiOld = [
                     {
                        Name: candiDetails.NameHindi.trim(),
                        CrBy: auth.currentUser.uid,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     },
                  ]
               }
               if (candiDetails.NameLocal !== '') {
                  candiDetails.NameLocalOld = [
                     {
                        Name: candiDetails.NameLocal.trim(),
                        CrBy: auth.currentUser.uid,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     },
                  ]
               }
               candiDetails.DocType = 'Candidate'
               candiDetails.Legislature_id = legis.id
               candiDetails.LegislatureName = legis.Name
               candiDetails.LegislatureNameHindi = legis.NameHindi
               candiDetails.LegislatureNameLocal = legis.NameLocal

               candiDetails.Election_id = elec.id
               candiDetails.ElectionName = elec.Name
               candiDetails.ElectionNameHindi = elec.NameHindi
               candiDetails.ElectionNameLocal = elec.NameLocal

               candiDetails.PhaseNum = selectedConsti.PhaseNum
               candiDetails.Constituency_id = selectedConstiId
               candiDetails.ConstituencyName = selectedConsti.Name
               candiDetails.ConstituencyNameHindi = selectedConsti.NameHindi
               candiDetails.ConstituencyNameLocal = selectedConsti.NameLocal
               candiDetails.ConstituencyNumber = selectedConsti.ConstituencyNumber
               candiDetails.SeatType = selectedConsti.SeatType

               const queryAlliance = query(
                  collection(db, 'PPAlliances'),
                  where('Party_id_s', 'array-contains', candiDetails.Party_id),
               )
               const querySnapshotAlliance = await getDocs(queryAlliance)
               querySnapshotAlliance.forEach(async (docSnap) => {
                  candiDetails.Alliances.push({
                     Alliance_id: docSnap.id,
                     Name: docSnap.data().Name,
                     Abbreviation: docSnap.data().Abbreviation,
                  })
                  candiDetails.AlliancesForMainDoc.push({
                     Alliance_id: docSnap.id,
                     Name: docSnap.data().Name,
                     Abbreviation: docSnap.data().Abbreviation,
                     Parties: docSnap.data().Parties,
                  })
               })

               // console.log('candiDetails 1: ', candiDetails)

               setLastCandi(candiDetails.Name)
               // console.log('candiDetails 2: ', candiDetails, ' uploading: ', uploadingCandis)

               let coreListId = ''
               let candidatesCount = 0

               // we are fetching docElecFresh so that we get the real count of candidates just before the entry of new candidate
               const docRefElecFresh = doc(db, 'Elections', elec.id)
               const docSnapElecFresh = await getDoc(docRefElecFresh)
               candidatesCount = docSnapElecFresh.data().CandidatesCount

               // console.log('local Candidates Count before core list: ', candidatesCount)

               const slab = 500
               const quotient = Math.floor(candidatesCount / slab)
               // const remainder = candidatesCount % slab
               const num = quotient + 1
               const numStr = String(num).padStart(4, '0')

               // coreListId = 'CandiCoreList-' + numStr
               coreListId = 'CandiCoreList-' + numStr

               candiDetails.CoreListId = coreListId
               // console.log('coreListId: ', coreListId)

               const record = {
                  Candidate_id: '',
                  Name: candiDetails.Name,
                  NameHindi: candiDetails.NameHindi,
                  NameLocal: candiDetails.NameLocal,
                  PhaseNum: selectedConsti.PhaseNum,
                  Constituency_id: selectedConstiId,
                  ConstituencyName: selectedConsti.Name,
                  ConstituencyNameHindi: selectedConsti.NameHindi,
                  ConstituencyNameLocal: selectedConsti.NameLocal,
                  ConstituencyNumber: selectedConsti.ConstituencyNumber,
                  Age: candiDetails.Age,
                  DoB: candiDetails.DoB,
                  Gender: candiDetails.Gender,

                  State_id: candiDetails.State_id,
                  StateName: candiDetails.StateName,
                  Division_id: candiDetails.Division_id,
                  DivisionName: candiDetails.DivisionName,
                  District_id: candiDetails.District_id,
                  DistrictName: candiDetails.DistrictName,
                  SubDistrict_id: candiDetails.SubDistrict_id,
                  SubDistrictName: candiDetails.SubDistrictName,
                  Block_id: candiDetails.Block_id,
                  BlockName: candiDetails.BlockName,
                  Village_id: candiDetails.Village_id,
                  VillageName: candiDetails.VillageName,

                  Party_id: candiDetails.Party_id,
                  PartyName: candiDetails.PartyName,
                  PartyNameHindi: candiDetails.PartyNameHindi,
                  PartyNameLocal: candiDetails.PartyNameLocal,
                  PartyAbbreviation: candiDetails.PartyAbbreviation,
                  PartyFlag: candiDetails.PartyFlag,
                  PartySymbol: candiDetails.PartySymbol,
                  AllottedSymbol: candiDetails.AllottedSymbol,
                  Alliances: candiDetails.Alliances,

                  Image: '',
                  CoreListId: coreListId,
                  Announced: candiDetails.Announced,
                  AnnouncedDate: candiDetails.AnnouncedDate,
                  AnnounceCancelled: candiDetails.AnnounceCancelled,
                  AnnounceCancelledDate: candiDetails.AnnounceCancelledDate,
                  Filed: candiDetails.Filed,
                  FiledDate: candiDetails.FiledDate,
                  Accepted: candiDetails.Accepted,
                  AcceptedDate: candiDetails.AcceptedDate,
                  Rejected: candiDetails.Rejected,
                  RejectedDate: candiDetails.RejectedDate,
                  Refused: candiDetails.Refused,
                  RefusedDate: candiDetails.RefusedDate,
                  Withdrawn: candiDetails.Withdrawn,
                  WithdrawnDate: candiDetails.WithdrawnDate,
                  Votes: candiDetails.Votes,
                  Winner: candiDetails.Winner,

                  Caste: candiDetails.Caste,
                  Religion: candiDetails.Religion,

                  OnlineSupport: 0,
                  OnlineSupportCancelled: 0,
                  OnlineSwingSupport: 0,
                  ExitSupport: 0,
               }

               try {
                  let recordNew = {}

                  const docRefElecSubColCandiCoreList = doc(db, 'Elections', elec.id, 'CoreLists', coreListId)
                  const docSnapSubColElec = await getDoc(docRefElecSubColCandiCoreList)

                  if (candiDetails.Image !== '') {
                     const imageRef = ref(dbStorage, `ElectionCandidates/${elec.id}/${candiDetails.Image.name + v4()}`)
                     await uploadBytes(imageRef, candiDetails.Image).then((snapshot) => {
                        getDownloadURL(snapshot.ref).then(async (url) => {
                           candiDetails = {
                              ...candiDetails,
                              Image: url,
                              ImagesOld: [
                                 {
                                    Image: url,
                                    CrBy: auth.currentUser.uid,
                                    CrDt: new Date(),
                                    CrIP: ip.data.ip,
                                 },
                              ],
                           }

                           // 1. create the main doc of Candidate
                           const docRef = await addDoc(collection(db, 'ElectionCandidates'), candiDetails)
                           candiDetails.id = docRef.id

                           const docRefCandi = doc(db, 'ElectionCandidates', docRef.id)
                           const docSnapCandi = await getDoc(docRefCandi)

                           recordNew = {
                              ...record,
                              Candidate_id: docSnapCandi.id,
                              Image: url,
                              AnnouncedDate: docSnapCandi.data().AnnouncedDate,
                              AnnounceCancelledDate: docSnapCandi.data().AnnounceCancelledDate,
                              FiledDate: docSnapCandi.data().FiledDate,
                              AcceptedDate: docSnapCandi.data().AcceptedDate,
                              RejectedDate: docSnapCandi.data().RejectedDate,
                              RefusedDate: docSnapCandi.data().RefusedDate,
                              WithdrawnDate: docSnapCandi.data().WithdrawnDate,
                           }

                           // 2. Add the candidate record to the coreList of Election
                           if (docSnapSubColElec.exists()) {
                              // 2.1 means at least one record has been inserted erliear for this subcollection
                              // update subcollection 'CoreLists' of legislature with new values
                              await updateDoc(docRefElecSubColCandiCoreList, {
                                 Candidates: arrayUnion(recordNew),
                                 DocType: 'CandidatesCoreList',
                              })
                           } else {
                              // 2.2 means no record has been inserted for this subcollection
                              // create the subcollection CoreLists with new values
                              setDoc(
                                 docRefElecSubColCandiCoreList,
                                 { Candidates: [recordNew], DocType: 'CandidatesCoreList' },
                                 { merge: true },
                              )
                           }

                           // 3. update local state
                           setCandidatesListRootLocal([...candidatesListRootLocal, recordNew])
                           handleCandidatesListRootUpdate([...candidatesListRootLocal, recordNew])

                           if (candiDetails.Withdrawn === 'Withdrawn') {
                              setCandidatesListWithdrawn([...candidatesListWithdrawn, recordNew])
                           } else if (
                              candiDetails.Rejected === 'Rejected' ||
                              candiDetails.AnnounceCancelled === 'AnnounceCancelled' ||
                              candiDetails.Refused === 'Refused'
                           ) {
                              setCandidatesListRejected([...candidatesListRejected, recordNew])
                           } else if (candiDetails.Accepted === 'Accepted') {
                              setCandidatesListAccepted([...candidatesListAccepted, recordNew])
                           } else if (candiDetails.Filed === 'Filed' || candiDetails.Announced === 'Announced') {
                              setCandidatesListWaiting([...candidatesListWaiting, recordNew])
                           }
                        })
                     })
                  } else {
                     // 1. create the main doc
                     const docRef = await addDoc(collection(db, 'ElectionCandidates'), candiDetails)
                     candiDetails.id = docRef.id

                     const docRefCandi = doc(db, 'ElectionCandidates', docRef.id)
                     const docSnapCandi = await getDoc(docRefCandi)

                     recordNew = {
                        ...record,
                        Candidate_id: docSnapCandi.id,
                        AnnouncedDate: docSnapCandi.data().AnnouncedDate,
                        AnnounceCancelledDate: docSnapCandi.data().AnnounceCancelledDate,
                        FiledDate: docSnapCandi.data().FiledDate,
                        AcceptedDate: docSnapCandi.data().AcceptedDate,
                        RejectedDate: docSnapCandi.data().RejectedDate,
                        RefusedDate: docSnapCandi.data().RefusedDate,
                        WithdrawnDate: docSnapCandi.data().WithdrawnDate,
                     }

                     // 2. add record to subcollection Candidates of Election
                     if (docSnapSubColElec.exists()) {
                        // 2.1 means at least one record has been inserted erliear for this subcollection
                        // update subcollection 'CoreLists' of legislature with new values
                        await updateDoc(docRefElecSubColCandiCoreList, {
                           Candidates: arrayUnion(recordNew),
                        })
                     } else {
                        // 2.2 means no record has been inserted for this subcollection
                        // create the subcollection CoreLists with new values
                        setDoc(
                           docRefElecSubColCandiCoreList,
                           { Candidates: [recordNew], DocType: 'CandidatesCoreList' },
                           { merge: true },
                        )
                     }

                     // 3. update local state
                     setCandidatesListRootLocal([...candidatesListRootLocal, recordNew])
                     handleCandidatesListRootUpdate([...candidatesListRootLocal, recordNew])

                     if (candiDetails.Withdrawn === 'Withdrawn') {
                        setCandidatesListWithdrawn([...candidatesListWithdrawn, recordNew])
                     } else if (
                        candiDetails.Rejected === 'Rejected' ||
                        candiDetails.AnnounceCancelled === 'AnnounceCancelled' ||
                        candiDetails.Refused === 'Refused'
                     ) {
                        setCandidatesListRejected([...candidatesListRejected, recordNew])
                     } else if (candiDetails.Accepted === 'Accepted') {
                        setCandidatesListAccepted([...candidatesListAccepted, recordNew])
                     } else if (candiDetails.Filed === 'Filed' || candiDetails.Announced === 'Announced') {
                        setCandidatesListWaiting([...candidatesListWaiting, recordNew])
                     }
                  }

                  // Note:
                  // increment the CandidatesCount and CandidatesCoreLists in Elections
                  const docRefUpdateElection = doc(db, 'Elections', elec.id)
                  await updateDoc(docRefUpdateElection, {
                     CandidatesCount: increment(1),
                  })

                  setLastCandi(candiDetails.Name)

                  setUploadingCandis(false)
                  setUploadedCandis(true)
                  setUploadingFailedCandis(false)
               } catch (error) {
                  alert('Error adding candidate: ', error.message)
                  // console.log('Error adding candidate: ', error)
                  setUploadingCandis(false)
                  setUploadedCandis(false)
                  setUploadingFailedCandis(true)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleDelete = async (e, item) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isSuper) {
            if (confirm('Are you sure you want to remove this candidate?')) {
               try {
                  // console.log('value to remove: ', item)

                  // 1. Delete the main doc
                  if (item.Name !== 'Zznota') {
                     await deleteDoc(doc(db, 'ElectionCandidates', item.Candidate_id))
                  }

                  // 1. Get reference of subcollection
                  const docRefElecSubColCandiCoreList = doc(db, 'Elections', elec.id, 'CoreLists', item.CoreListId)
                  // 2. Delete from sub collection
                  await updateDoc(docRefElecSubColCandiCoreList, {
                     Candidates: arrayRemove(item),
                  })
                  // 3. Decrement the count in Election
                  if (item.Name !== 'Zznota') {
                     const docRefUpdateElection = doc(db, 'Elections', elec.id)
                     await updateDoc(docRefUpdateElection, {
                        CandidatesCount: increment(-1),
                     })
                  }

                  setCandidatesListWaiting(
                     candidatesListWaiting.filter((thisItem) => thisItem.Candidate_id !== item.Candidate_id),
                  )
                  setCandidatesListAccepted(
                     candidatesListAccepted.filter((thisItem) => thisItem.Candidate_id !== item.Candidate_id),
                  )
                  setCandidatesListRejected(
                     candidatesListRejected.filter((thisItem) => thisItem.Candidate_id !== item.Candidate_id),
                  )
                  setCandidatesListWithdrawn(
                     candidatesListWithdrawn.filter((thisItem) => thisItem.Candidate_id !== item.Candidate_id),
                  )
                  setCandidatesListRootLocal(
                     candidatesListRootLocal.filter((thisItem) => thisItem.Candidate_id !== item.Candidate_id),
                  )
                  handleCandidatesListRootUpdate(
                     candidatesListRootLocal.filter((thisItem) => thisItem.Candidate_id !== item.Candidate_id),
                  )
               } catch (error) {
                  alert('Error deleting district')
                  // console.log(error)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // status of candidates

   const [openModalStatus3, setOpenModalStatus3] = useState(false)
   const [uploadingStatus, setUploadingStatus] = useState(false)
   const [uploadedStatus, setUploadedStatus] = useState(false)
   const [uploadingFailedStatus, setUploadingFailedStatus] = useState(false)
   const [selectedCandidate, setSelectedCandidate] = useState('')
   const [selectedCandidateId, setSelectedCandidateId] = useState('')
   const [selectedCandidateName, setSelectedCandidateName] = useState('')

   const handleOpenModalStatus3 = async (e, CandiId, CandiName) => {
      e.preventDefault()

      setSelectedCandidateId(CandiId)
      setSelectedCandidateName(CandiName)
      // console.log('selected candidate id for status: ', CandiId, CandiName)

      setOpenModalStatus3(true)
   }

   const handleCloseModalStatus3 = () => setOpenModalStatus3(false)

   // const handleAddStatus = async (statusDetails, actionIsValid) => {
   //    if (auth.currentUser && thisUser === auth.currentUser.uid) {
   //       if (isDataEditor || isSuper) {
   //          if (actionIsValid) {
   //             setUploadingStatus(true)
   //             const newRecord = { ...selectedCandidate, ...statusDetails }

   //             try {
   //                console.log('value to add: ', newRecord)

   //                // get referneces of documents to be updated
   //                const docRefElectionCandidate = doc(db, 'ElectionCandidates', selectedCandidate.Candidate_id)
   //                const docRefElectionsSubCollCoreList = doc(
   //                   db,
   //                   'Elections',
   //                   elec.id,
   //                   'CoreLists',
   //                   selectedCandidate.CoreListId,
   //                )

   //                // update the main Candidate doc
   //                await updateDoc(docRefElectionCandidate, {
   //                   Announced: statusDetails.Announced,
   //                   AnnouncedDate: statusDetails.AnnouncedDate,
   //                   AnnounceCancelled: statusDetails.AnnounceCancelled,
   //                   AnnounceCancelledDate: statusDetails.AnnounceCancelledDate,
   //                   Filed: statusDetails.Filed,
   //                   FiledDate: statusDetails.FiledDate,
   //                   Accepted: statusDetails.Accepted,
   //                   AcceptedDate: statusDetails.AcceptedDate,
   //                   Rejected: statusDetails.Rejected,
   //                   RejectedDate: statusDetails.RejectedDate,
   //                   Refused: statusDetails.Refused,
   //                   RefusedDate: statusDetails.RefusedDate,
   //                   Withdrawn: statusDetails.Withdrawn,
   //                   WithdrawnDate: statusDetails.WithdrawnDate,
   //                })

   //                // update the candidate corelist inside election
   //                await updateDoc(docRefElectionsSubCollCoreList, {
   //                   Candidates: arrayRemove(selectedCandidate),
   //                })
   //                await updateDoc(docRefElectionsSubCollCoreList, {
   //                   Candidates: arrayUnion(newRecord),
   //                })

   //                // manage the local state

   //                const docSnapCandi = await getDoc(docRefElectionCandidate)

   //                const localNew = {
   //                   ...selectedCandidate,
   //                   Candidate_id: docSnapCandi.id,
   //                   Announced: docSnapCandi.data().Announced,
   //                   AnnouncedDate: docSnapCandi.data().AnnouncedDate,
   //                   AnnounceCancelled: docSnapCandi.data().AnnounceCancelled,
   //                   AnnounceCancelledDate: docSnapCandi.data().AnnounceCancelledDate,
   //                   Filed: docSnapCandi.data().Filed,
   //                   FiledDate: docSnapCandi.data().FiledDate,
   //                   Accepted: docSnapCandi.data().Accepted,
   //                   AcceptedDate: docSnapCandi.data().AcceptedDate,
   //                   Rejected: docSnapCandi.data().Rejected,
   //                   RejectedDate: docSnapCandi.data().RejectedDate,
   //                   Refused: docSnapCandi.data().Refused,
   //                   RefusedDate: docSnapCandi.data().RefusedDate,
   //                   Withdrawn: docSnapCandi.data().Withdrawn,
   //                   WithdrawnDate: docSnapCandi.data().WithdrawnDate,
   //                }

   //                const root = candidatesListRootLocal.filter(
   //                   (item) => item.Candidate_id !== selectedCandidate.Candidate_id,
   //                )

   //                root.push(localNew)

   //                setCandidatesListRootLocal(root)
   //                handleCandidatesListRootUpdate(root)

   //                const list = root.filter((item) => item.Constituency_id === selectedConstiId)

   //                const listWaiting = list.filter(
   //                   (item) =>
   //                      item.Accepted !== 'Accepted' &&
   //                      item.Rejected !== 'Rejected' &&
   //                      item.Refused !== 'Refused' &&
   //                      item.Withdrawn !== 'Withdrawn' &&
   //                      item.AnnounceCancelled !== 'AnnounceCancelled',
   //                )

   //                let sortedListWaiting = []
   //                sortedListWaiting = [...listWaiting].sort((a, b) => {
   //                   if (b.Name.trim() > a.Name.trim()) {
   //                      return -1
   //                   } else if (b.Name.trim() < a.Name.trim()) {
   //                      return 1
   //                   } else {
   //                      return 0
   //                   }
   //                })
   //                console.log('sorted list waiting after status update: ', sortedListWaiting)

   //                setCandidatesListWaiting(sortedListWaiting)

   //                const listAccepted = list.filter((item) => item.Accepted === 'Accepted' && item.Withdrawn === '')

   //                let sortedListAccepted = []
   //                if (!elec.ResultsDeclared) {
   //                   sortedListAccepted = [...listAccepted].sort((a, b) => {
   //                      if (b.Name.trim() > a.Name.trim()) {
   //                         return -1
   //                      } else if (b.Name.trim() < a.Name.trim()) {
   //                         return 1
   //                      } else {
   //                         return 0
   //                      }
   //                   })
   //                } else {
   //                   sortedListAccepted = [...listAccepted].sort((a, b) => b.Votes - a.Votes)
   //                }
   //                console.log('sorted list accepted after status update: ', sortedListAccepted)
   //                setCandidatesListAccepted(sortedListAccepted)

   //                const listRejected = list.filter(
   //                   (item) =>
   //                      item.Rejected === 'Rejected' ||
   //                      item.AnnounceCancelled === 'AnnounceCancelled' ||
   //                      item.Refused === 'Refused',
   //                )

   //                let sortedListRejected = []
   //                sortedListRejected = [...listRejected].sort((a, b) => {
   //                   if (b.Name.trim() > a.Name.trim()) {
   //                      return -1
   //                   } else if (b.Name.trim() < a.Name.trim()) {
   //                      return 1
   //                   } else {
   //                      return 0
   //                   }
   //                })
   //                console.log('sorted list rejected after status update: ', sortedListRejected)
   //                setCandidatesListRejected(sortedListRejected)

   //                const listWithdrawn = list.filter((item) => item.Withdrawn === 'Withdrawn')
   //                let sortedListWithdrawn = []
   //                sortedListWithdrawn = [...listWithdrawn].sort((a, b) => {
   //                   if (b.Name.trim() > a.Name.trim()) {
   //                      return -1
   //                   } else if (b.Name.trim() < a.Name.trim()) {
   //                      return 1
   //                   } else {
   //                      return 0
   //                   }
   //                })
   //                console.log('sorted list withdrawn after status update: ', sortedListWithdrawn)
   //                setCandidatesListWithdrawn(sortedListWithdrawn)

   //                setUploadingStatus(false)
   //                setUploadedStatus(true)
   //                setUploadingFailedStatus(false)

   //                handleCloseModalStatus()
   //             } catch (error) {
   //                alert(`Error adding candidate's status: `, error.message)
   //                console.log(`Error adding candidate's status: `, error)
   //                setUploadingStatus(false)
   //                setUploadedStatus(false)
   //                setUploadingFailedStatus(true)
   //             }
   //          }
   //       } else {
   //          navigate('/', { replace: true })
   //       }
   //    } else {
   //       navigate('/', { replace: true })
   //    }
   // }

   const handleAddStatus3 = async (statusDetails, actionIsValid) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (statusDetails.StatusType !== '') {
               //
               setUploadingStatus(true)

               const thisCandi = candidatesListRootLocal.find((item) => item.Candidate_id === selectedCandidateId)
               let newRecord = {}
               // console.log('Stage 111: ', thisCandi)

               // if (statusDetails.Announced !== '') {
               //    newRecord = {
               //       ...selectedCandidate,
               //       Announced: statusDetails.Announced,
               //       AnnouncedDate: statusDetails.AnnouncedDate,
               //    }
               // } else if (statusDetails.AnnounceCancelled !== '') {
               //    newRecord = {
               //       ...selectedCandidate,
               //       AnnounceCancelled: statusDetails.AnnounceCancelled,
               //       AnnounceCancelledDate: statusDetails.AnnounceCancelledDate,
               //    }
               // } else if (statusDetails.Filed !== '') {
               //    newRecord = {
               //       ...selectedCandidate,
               //       Filed: statusDetails.Filed,
               //       FiledDate: statusDetails.FiledDate,
               //    }
               // } else if (statusDetails.Accepted !== '') {
               //    newRecord = {
               //       ...selectedCandidate,
               //       Accepted: statusDetails.Accepted,
               //       AcceptedDate: statusDetails.AcceptedDate,
               //    }
               // } else if (statusDetails.Rejected !== '') {
               //    newRecord = {
               //       ...selectedCandidate,
               //       Rejected: statusDetails.Rejected,
               //       RejectedDate: statusDetails.RejectedDate,
               //    }
               // } else if (statusDetails.Refused !== '') {
               //    newRecord = {
               //       ...selectedCandidate,
               //       Refused: statusDetails.Refused,
               //       RefusedDate: statusDetails.RefusedDate,
               //    }
               // } else if (statusDetails.Withdrawn !== '') {
               //    newRecord = {
               //       ...selectedCandidate,
               //       Withdrawn: statusDetails.Withdrawn,
               //       WithdrawnDate: statusDetails.WithdrawnDate,
               //    }
               // }

               // switch (statusDetails.StatusType) {
               //    case 'Announced':
               //       newRecord = {
               //          ...selectedCandidate,
               //          Announced: 'Announced',
               //          AnnouncedDate: statusDetails.StatusDate,
               //       }
               //       return
               //    case 'AnnounceCancelled':
               //       newRecord = {
               //          ...selectedCandidate,
               //          AnnounceCancelled: 'AnnounceCancelled',
               //          AnnounceCancelledDate: statusDetails.StatusDate,
               //       }
               //       return
               //    case 'Filed':
               //       newRecord = {
               //          ...selectedCandidate,
               //          Filed: 'Filed',
               //          FiledDate: statusDetails.StatusDate,
               //       }
               //       return
               //    case 'Accepted':
               //       newRecord = {
               //          ...selectedCandidate,
               //          Accepted: 'Accepted',
               //          AcceptedDate: statusDetails.StatusDate,
               //       }
               //       return
               //    case 'Rejected':
               //       newRecord = {
               //          ...selectedCandidate,
               //          Rejected: 'Rejected',
               //          RejectedDate: statusDetails.StatusDate,
               //       }
               //       return
               //    case 'Refused':
               //       newRecord = {
               //          ...selectedCandidate,
               //          Refused: 'Refused',
               //          RefusedDate: statusDetails.StatusDate,
               //       }
               //       return
               //    case 'Withdrawn':
               //       newRecord = {
               //          ...selectedCandidate,
               //          Withdrawn: 'Withdrawn',
               //          WithdrawnDate: statusDetails.StatusDate,
               //       }
               // }

               try {
                  // console.log('value to add: ', newRecord)

                  // get referneces of documents to be updated
                  const docRefElectionCandidate = doc(db, 'ElectionCandidates', thisCandi.Candidate_id)
                  const docRefElectionsSubCollCoreList = doc(
                     db,
                     'Elections',
                     elec.id,
                     'CoreLists',
                     thisCandi.CoreListId,
                  )

                  // update the main Candidate doc

                  if (statusDetails.StatusType === 'Announced') {
                     newRecord = {
                        ...thisCandi,
                        Announced: 'Announced',
                        AnnounceDate: statusDetails.StatusDate,
                     }
                     await updateDoc(docRefElectionCandidate, {
                        Announced: 'Announced',
                        AnnouncedDate: statusDetails.StatusDate,
                     })
                  } else if (statusDetails.StatusType === 'AnnounceCancelled') {
                     newRecord = {
                        ...thisCandi,
                        AnnounceCancelled: 'AnnounceCancelled',
                        AnnounceCancelledDate: statusDetails.StatusDate,
                     }
                     await updateDoc(docRefElectionCandidate, {
                        AnnounceCancelled: 'AnnounceCancelled',
                        AnnounceCancelledDate: statusDetails.StatusDate,
                     })
                  } else if (statusDetails.StatusType === 'Filed') {
                     // console.log('Stage 222: ')
                     newRecord = {
                        ...thisCandi,
                        Filed: 'Filed',
                        FiledDate: statusDetails.StatusDate,
                     }
                     // console.log('Stage 333: ', newRecord)
                     await updateDoc(docRefElectionCandidate, {
                        Filed: 'Filed',
                        FiledDate: statusDetails.StatusDate,
                     })
                     // console.log('Stage 333 A: ', newRecord)
                  } else if (statusDetails.StatusType === 'Accepted') {
                     newRecord = {
                        ...thisCandi,
                        Accepted: 'Accepted',
                        AcceptedDate: statusDetails.StatusDate,
                     }
                     await updateDoc(docRefElectionCandidate, {
                        Accepted: 'Accepted',
                        AcceptedDate: statusDetails.StatusDate,
                     })
                  } else if (statusDetails.StatusType === 'Rejected') {
                     newRecord = {
                        ...thisCandi,
                        Rejected: 'Rejected',
                        RejectedDate: statusDetails.StatusDate,
                     }
                     await updateDoc(docRefElectionCandidate, {
                        Rejected: 'Rejected',
                        RejectedDate: statusDetails.StatusDate,
                     })
                  } else if (statusDetails.StatusType === 'Refused') {
                     newRecord = {
                        ...thisCandi,
                        Refused: 'Refused',
                        RefusedDate: statusDetails.StatusDate,
                     }
                     await updateDoc(docRefElectionCandidate, {
                        Refused: 'Refused',
                        RefusedDate: statusDetails.StatusDate,
                     })
                  } else if (statusDetails.StatusType === 'Withdrawn') {
                     newRecord = {
                        ...thisCandi,
                        Withdrawn: 'Withdrawn',
                        WithdrawnDate: statusDetails.StatusDate,
                     }
                     await updateDoc(docRefElectionCandidate, {
                        Withdrawn: 'Withdrawn',
                        WithdrawnDate: statusDetails.StatusDate,
                     })
                  }

                  // if (statusDetails.Announced !== '') {
                  //    await updateDoc(docRefElectionCandidate, {
                  //       Announced: statusDetails.Announced,
                  //       AnnouncedDate: statusDetails.AnnouncedDate,
                  //    })
                  // } else if (statusDetails.AnnounceCancelled !== '') {
                  //    await updateDoc(docRefElectionCandidate, {
                  //       AnnounceCancelled: statusDetails.AnnounceCancelled,
                  //       AnnounceCancelledDate: statusDetails.AnnounceCancelledDate,
                  //    })
                  // } else if (statusDetails.Filed !== '') {
                  //    await updateDoc(docRefElectionCandidate, {
                  //       Filed: statusDetails.Filed,
                  //       FiledDate: statusDetails.FiledDate,
                  //    })
                  // } else if (statusDetails.Accepted !== '') {
                  //    await updateDoc(docRefElectionCandidate, {
                  //       Accepted: statusDetails.Accepted,
                  //       AcceptedDate: statusDetails.AcceptedDate,
                  //    })
                  // } else if (statusDetails.Rejected !== '') {
                  //    await updateDoc(docRefElectionCandidate, {
                  //       Rejected: statusDetails.Rejected,
                  //       RejectedDate: statusDetails.RejectedDate,
                  //    })
                  // } else if (statusDetails.Refused !== '') {
                  //    await updateDoc(docRefElectionCandidate, {
                  //       Refused: statusDetails.Refused,
                  //       RefusedDate: statusDetails.RefusedDate,
                  //    })
                  // } else if (statusDetails.Withdrawn !== '') {
                  //    await updateDoc(docRefElectionCandidate, {
                  //       Withdrawn: statusDetails.Withdrawn,
                  //       WithdrawnDate: statusDetails.WithdrawnDate,
                  //    })
                  // }
                  // console.log('Satge 555 ')
                  // update the candidate corelist inside election
                  await updateDoc(docRefElectionsSubCollCoreList, {
                     Candidates: arrayRemove(thisCandi),
                  })
                  await updateDoc(docRefElectionsSubCollCoreList, {
                     Candidates: arrayUnion(newRecord),
                  })
                  // console.log('Satge 555 A ')
                  // manage the local state

                  const docSnapCandi = await getDoc(docRefElectionCandidate)

                  const localNew = {
                     ...thisCandi,
                     Candidate_id: docSnapCandi.id,
                     Announced: docSnapCandi.data().Announced,
                     AnnouncedDate: docSnapCandi.data().AnnouncedDate,
                     AnnounceCancelled: docSnapCandi.data().AnnounceCancelled,
                     AnnounceCancelledDate: docSnapCandi.data().AnnounceCancelledDate,
                     Filed: docSnapCandi.data().Filed,
                     FiledDate: docSnapCandi.data().FiledDate,
                     Accepted: docSnapCandi.data().Accepted,
                     AcceptedDate: docSnapCandi.data().AcceptedDate,
                     Rejected: docSnapCandi.data().Rejected,
                     RejectedDate: docSnapCandi.data().RejectedDate,
                     Refused: docSnapCandi.data().Refused,
                     RefusedDate: docSnapCandi.data().RefusedDate,
                     Withdrawn: docSnapCandi.data().Withdrawn,
                     WithdrawnDate: docSnapCandi.data().WithdrawnDate,
                  }

                  // console.log('Satge 666; ', localNew)
                  const root = candidatesListRootLocal.filter((item) => item.Candidate_id !== selectedCandidateId)

                  root.push(localNew)

                  setCandidatesListRootLocal(root)
                  handleCandidatesListRootUpdate(root)

                  const list = root.filter((item) => item.Constituency_id === selectedConstiId)

                  const listWaiting = list.filter(
                     (item) =>
                        item.Accepted !== 'Accepted' &&
                        item.Rejected !== 'Rejected' &&
                        item.Refused !== 'Refused' &&
                        item.Withdrawn !== 'Withdrawn' &&
                        item.AnnounceCancelled !== 'AnnounceCancelled',
                  )

                  let sortedListWaiting = []
                  sortedListWaiting = [...listWaiting].sort((a, b) => {
                     if (b.Name.trim() > a.Name.trim()) {
                        return -1
                     } else if (b.Name.trim() < a.Name.trim()) {
                        return 1
                     } else {
                        return 0
                     }
                  })
                  // console.log('sorted list waiting after status update: ', sortedListWaiting)

                  setCandidatesListWaiting(sortedListWaiting)

                  const listAccepted = list.filter((item) => item.Accepted === 'Accepted' && item.Withdrawn === '')

                  let sortedListAccepted = []
                  if (!elec.ResultsDeclared) {
                     sortedListAccepted = [...listAccepted].sort((a, b) => {
                        if (b.Name.trim() > a.Name.trim()) {
                           return -1
                        } else if (b.Name.trim() < a.Name.trim()) {
                           return 1
                        } else {
                           return 0
                        }
                     })
                  } else {
                     sortedListAccepted = [...listAccepted].sort((a, b) => b.Votes - a.Votes)
                  }
                  // console.log('sorted list accepted after status update: ', sortedListAccepted)
                  setCandidatesListAccepted(sortedListAccepted)

                  const listRejected = list.filter(
                     (item) =>
                        item.Rejected === 'Rejected' ||
                        item.AnnounceCancelled === 'AnnounceCancelled' ||
                        item.Refused === 'Refused',
                  )

                  let sortedListRejected = []
                  sortedListRejected = [...listRejected].sort((a, b) => {
                     if (b.Name.trim() > a.Name.trim()) {
                        return -1
                     } else if (b.Name.trim() < a.Name.trim()) {
                        return 1
                     } else {
                        return 0
                     }
                  })
                  // console.log('sorted list rejected after status update: ', sortedListRejected)
                  setCandidatesListRejected(sortedListRejected)

                  const listWithdrawn = list.filter((item) => item.Withdrawn === 'Withdrawn')
                  let sortedListWithdrawn = []
                  sortedListWithdrawn = [...listWithdrawn].sort((a, b) => {
                     if (b.Name.trim() > a.Name.trim()) {
                        return -1
                     } else if (b.Name.trim() < a.Name.trim()) {
                        return 1
                     } else {
                        return 0
                     }
                  })
                  // console.log('sorted list withdrawn after status update: ', sortedListWithdrawn)
                  setCandidatesListWithdrawn(sortedListWithdrawn)

                  setUploadingStatus(false)
                  setUploadedStatus(true)
                  setUploadingFailedStatus(false)

                  handleCloseModalStatus3()
               } catch (error) {
                  alert(`Error adding candidate's status: `, error.message)
                  // console.log(`Error adding candidate's status: `, error)
                  setUploadingStatus(false)
                  setUploadedStatus(false)
                  setUploadingFailedStatus(true)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // transfer of constituency of candidates

   const [openModalConstiTransfer, setOpenModalConstiTransfer] = useState(false)
   const [uploadingConstiTransfer, setUploadingConstiTransfer] = useState(false)
   const [uploadedConstiTransfer, setUploadedConstiTransfer] = useState(false)
   const [uploadingFailedConstiTransfer, setUploadingFailedConstiTransfer] = useState(false)

   const handleOpenModalConstiTransfer = async (e, item) => {
      e.preventDefault()

      setSelectedCandidate(item)

      setOpenModalConstiTransfer(true)
   }

   const handleCloseModalConstiTransfer = () => setOpenModalConstiTransfer(false)

   const handleConstiTransfer = async (record) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (record.Constituency_id !== '') {
               setUploadingConstiTransfer(true)
               const newRecord = { ...selectedCandidate, ...record }

               try {
                  // console.log('value to add: ', record)

                  const docRefCandidate = doc(db, 'ElectionCandidates', selectedCandidate.Candidate_id)
                  const docRefElectionSubCollCandiCoreList = doc(
                     db,
                     'Elections',
                     elec.id,
                     'CoreLists',
                     selectedCandidate.CoreListId,
                  )

                  await updateDoc(docRefCandidate, {
                     Constituency_id: record.Constituency_id,
                     ConstituencyName: record.ConstituencyName,
                     ConstituencyNameHindi: record.ConstituencyNameHindi,
                     ConstituencyNameLocal: record.ConstituencyNameLocal,
                  })

                  await updateDoc(docRefElectionSubCollCandiCoreList, {
                     Candidates: arrayRemove(selectedCandidate),
                  })
                  await updateDoc(docRefElectionSubCollCandiCoreList, {
                     Candidates: arrayUnion(newRecord),
                  })

                  const root = candidatesListRootLocal.filter(
                     (item) => item.Candidate_id !== selectedCandidate.Candidate_id,
                  )
                  // console.log('root: ', root)
                  root.push(newRecord)
                  // console.log('root 2: ', root)
                  setCandidatesListRootLocal(root)
                  handleCandidatesListRootUpdate(root)

                  const list = root.filter((item) => item.Constituency_id === selectedConstiId)

                  const listWaiting = list.filter(
                     (item) =>
                        item.Accepted !== 'Accepted' &&
                        item.Rejected !== 'Rejected' &&
                        item.Refused !== 'Refused' &&
                        item.Withdrawn !== 'Withdrawn' &&
                        item.AnnounceCancelled !== 'AnnounceCancelled',
                  )

                  let sortedListWaiting = []
                  sortedListWaiting = [...listWaiting].sort((a, b) => {
                     if (b.Name.trim() > a.Name.trim()) {
                        return -1
                     } else if (b.Name.trim() < a.Name.trim()) {
                        return 1
                     } else {
                        return 0
                     }
                  })
                  // console.log('sorted list waiting after transfer update: ', sortedListWaiting)

                  setCandidatesListWaiting(sortedListWaiting)

                  const listAccepted = list.filter((item) => item.Accepted === 'Accepted' && item.Withdrawn === '')

                  let sortedListAccepted = []
                  if (!elec.ResultsDeclared) {
                     sortedListAccepted = [...listAccepted].sort((a, b) => {
                        if (b.Name.trim() > a.Name.trim()) {
                           return -1
                        } else if (b.Name.trim() < a.Name.trim()) {
                           return 1
                        } else {
                           return 0
                        }
                     })
                  } else {
                     sortedListAccepted = [...listAccepted].sort((a, b) => b.Votes - a.Votes)
                  }
                  // console.log('sorted list accepted after transfer update: ', sortedListAccepted)
                  setCandidatesListAccepted(sortedListAccepted)

                  const listRejected = list.filter(
                     (item) =>
                        item.Rejected === 'Rejected' ||
                        item.Refused === 'Refused' ||
                        item.AnnounceCancelled === 'AnnounceCancelled',
                  )

                  let sortedListRejected = []
                  sortedListRejected = [...listRejected].sort((a, b) => {
                     if (b.Name.trim() > a.Name.trim()) {
                        return -1
                     } else if (b.Name.trim() < a.Name.trim()) {
                        return 1
                     } else {
                        return 0
                     }
                  })
                  // console.log('sorted list rejected after transfer update: ', sortedListRejected)
                  setCandidatesListRejected(sortedListRejected)

                  const listWithdrawn = list.filter((item) => item.Withdrawn === 'Withdrawn')
                  let sortedListWithdrawn = []
                  sortedListWithdrawn = [...listWithdrawn].sort((a, b) => {
                     if (b.Name.trim() > a.Name.trim()) {
                        return -1
                     } else if (b.Name.trim() < a.Name.trim()) {
                        return 1
                     } else {
                        return 0
                     }
                  })
                  // console.log('sorted list withdrawn after transfer update: ', sortedListWithdrawn)
                  setCandidatesListWithdrawn(sortedListWithdrawn)

                  setUploadingConstiTransfer(false)
                  setUploadedConstiTransfer(true)
                  setUploadingFailedConstiTransfer(false)

                  handleCloseModalConstiTransfer()
               } catch (error) {
                  alert(`Error adding candidate's transfer: `, error.message)
                  // console.log(`Error adding candidate's transfer: `, error)
                  setUploadingConstiTransfer(false)
                  setUploadedConstiTransfer(false)
                  setUploadingFailedConstiTransfer(true)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // online support: related to last support

   const [hoursCenter, setHoursCenter] = useState('')
   const [lastCandiSupported, setLastCandiSupported] = useState('')
   const [hoursState, setHoursState] = useState('')

   const getLastSupportCenter = async () => {
      // console.log('getLastSupportCenter : enetered')

      const lastSupportedCenter = userProfile.LS_Candi_CenterConstituency
      // console.log('lastSupportedCenter: ', lastSupportedCenter)

      if (lastSupportedCenter) {
         if (lastSupportedCenter.Election_id === elec.id) {
            // find the difference in hours
            const lastDateCenter = new Date(lastSupportedCenter.CrDt.toDate())
            const date1 = moment(lastDateCenter)
            const date2 = moment(new Date())
            const resHousrs = date2.diff(date1, 'hours')
            setHoursCenter(resHousrs)
            setLastCandiSupported(lastSupportedCenter)
         } else {
            setHoursCenter('')
            setLastCandiSupported('')
         }
      } else {
         setHoursCenter('')
         setLastCandiSupported('')
      }
   }

   useEffect(() => {
      if (hoursCenter !== '') {
         // console.log('hours Center: ', hoursCenter)
      }
   }, [hoursCenter])

   const getLastSupportState = async () => {
      // console.log('getLastSupportState : enetered')

      const lastSupportedState = userProfile.LS_Candi_StateConstituency
      // console.log('lastSupportedState: ', lastSupportedState)

      if (lastSupportedState) {
         if (lastSupportedState.Election_id === elec.id) {
            // find the difference in hours
            const lastDateState = new Date(lastSupportedState.CrDt.toDate())
            const date1 = moment(lastDateState)
            const date2 = moment(new Date())
            const resHousrs = date2.diff(date1, 'hours')
            // console.log('resHousrs state: ', resHousrs)
            setHoursState(resHousrs)
            setLastCandiSupported(lastSupportedState)
         } else {
            setHoursState('')
            setLastCandiSupported('')
         }
      } else {
         setHoursState('')
         setLastCandiSupported('')
      }
   }

   useEffect(() => {
      if (hoursState !== '') {
         // console.log('hours State: ', hoursState)
      }
   }, [hoursState])

   // online support: opening the modal

   const [openModalOnlineSupport, setOpenModalOnlineSupport] = useState(false)
   const [uploadingOnlineSupport, setUploadingOnlineSupport] = useState(false)
   const [uploadedOnlineSupport, setUploadedOnlineSupport] = useState(false)
   const [uploadingFailedOnlineSupport, setUploadingFailedOnlineSupport] = useState(false)
   const [actionForSupport, setActionForSupport] = useState('')

   const handleOpenModalOnlineSupport = async (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         // console.log(
         //    'reached stage prior to open modal for support - you are logged in.',
         //    new Date().toDateString(),
         //    ' phase date: ',
         //    new Date(selectedConstiPhaseDate.toDate()).toDateString(),
         // )

         if (new Date() > new Date(selectedConstiPhaseDate.toDate())) {
            // console.log(
            //    'today date ',
            //    new Date(),
            //    ' is greater than election date ',
            //    new Date(selectedConstiPhaseDate.toDate()),
            // )
            handleOpenDialogDatePassed()
         } else {
            switch (legis.Strata) {
               case 'Central':
                  if (userProfile.AtCentralConstituency === '') {
                     // means he is new and fresh at Central level, so no need of any calculation
                     // proceed to vote and prompt the user at submit that he will be automatically
                     // attached to certain constituencies
                     setActionForSupport('attach and then accept the support')

                     setUploadingOnlineSupport(false)
                     setUploadedOnlineSupport(false)
                     setUploadingFailedOnlineSupport(false)

                     setOpenModalOnlineSupport(true)
                  } else {
                     // means (1) he is NOT fresh at Central level (2) he is already attached to some constituency at Central level
                     // Now, find if he is attached to the selected constituency
                     if (userProfile.AtCentralConstituency.Constituency_id === selectedConstiId) {
                        // means he is attached to this constituency, so proceed further

                        // fetch last support center
                        let hoursCenterHere = ''
                        // let lastCandiSupportedHere = ''

                        const lastSupportedCenter = userProfile.LS_Candi_CenterConstituency
                        console.log('lastSupportedCenter: ', lastSupportedCenter)

                        if (lastSupportedCenter) {
                           if (lastSupportedCenter.Election_id === elec.id) {
                              // find the difference in hours
                              // console.log('center check')
                              const lastDateCenter = new Date(lastSupportedCenter.CrDt.toDate())
                              // console.log('center check 2')
                              const date1 = moment(lastDateCenter)
                              const date2 = moment(new Date())
                              const resHousrs = date2.diff(date1, 'hours')
                              setHoursCenter(resHousrs)
                              hoursCenterHere = resHousrs
                              setLastCandiSupported(lastSupportedCenter)
                              // lastCandiSupportedHere = lastSupportedCenter
                           } else {
                              setHoursCenter('')
                              setLastCandiSupported('')
                           }
                        } else {
                           setHoursCenter('')
                           setLastCandiSupported('')
                        }

                        if (hoursCenterHere !== '') {
                           // means (1) he is attached to this constituency (2) he has already supported a candidate in this election
                           if (parseInt(hoursCenterHere) < 24) {
                              // prompt the user that (1) he has supported within last 24 hours (2) he can support another one after 24 hours
                              setOpenModalOnlineSupport(false)
                              handleOpenDialogHours()
                           } else {
                              // he is past 24 hours and now he can support another candidate
                              setActionForSupport('accept the support if another candidate')
                              setOpenModalOnlineSupport(true)
                           }
                        } else {
                           // means (1) he is attached to this constituency (2) he has NOT yet supported any candidate for this election
                           // proceed to vote and accept the vote
                           setActionForSupport('accept the support')

                           setUploadingOnlineSupport(false)
                           setUploadedOnlineSupport(false)
                           setUploadingFailedOnlineSupport(false)

                           setOpenModalOnlineSupport(true)
                        }
                     } else {
                        // means (1) he is NOT attached to this constituency
                        // means (2) he is ATTACHED to another constituency
                        // means (3) he is trying to support a candidate here
                        // prompt the user that he is attached to some other constituency at this level
                        setActionForSupport('attached to other consti')

                        handleOpenDialogAtOtherConsti()
                     }
                  }
                  break
               case 'State':
                  if (userProfile.AtStateConstituency === '') {
                     // means he is new and fresh at State level, so no need of any calculation
                     // proceed to vote and prompt the user at submit that he will be automatically
                     // attached to certain constituencies
                     // console.log('prior to open support modal - state level - attached consti state absent')
                     setActionForSupport('attach and then accept the support')

                     setUploadingOnlineSupport(false)
                     setUploadedOnlineSupport(false)
                     setUploadingFailedOnlineSupport(false)

                     setOpenModalOnlineSupport(true)
                  } else {
                     // means (1) he is NOT fresh at State level (2) he is already attached to some constituency at State level
                     // Now, find if he is attached to the selected constituency
                     if (userProfile.AtStateConstituency.Constituency_id === selectedConstiId) {
                        // console.log(
                        //    'prior to open support modal - state level - attached consti - ',
                        //    userProfile.AtStateConstituency.Constituency_id,
                        //    ' selected consti - ',
                        //    selectedConstiId,
                        // )
                        // means he is attached to this constituency, so proceed further

                        // fetch last support state
                        let hoursStateHere = ''
                        // let lastCandiSupportedHere = ''

                        const lastSupportedState = userProfile.LS_Candi_StateConstituency
                        console.log('lastSupportedState: ', lastSupportedState)

                        if (lastSupportedState) {
                           if (lastSupportedState.Election_id === elec.id) {
                              // find the difference in hours
                              // console.log('center check')
                              const lastDateState = new Date(lastSupportedState.CrDt.toDate())
                              // console.log('center check 2')
                              const date1 = moment(lastDateState)
                              const date2 = moment(new Date())
                              const resHousrs = date2.diff(date1, 'hours')
                              setHoursState(resHousrs)
                              hoursStateHere = resHousrs
                              setLastCandiSupported(lastSupportedState)
                              // lastCandiSupportedHere = lastSupportedState
                           } else {
                              setHoursState('')
                              setLastCandiSupported('')
                           }
                        } else {
                           setHoursState('')
                           setLastCandiSupported('')
                        }

                        if (hoursStateHere !== '') {
                           // // means (1) he is attached to this constituency (2) he has already supported a candidate in this election
                           if (parseInt(hoursStateHere) < 24) {
                              // prompt the user that (1) he has supported within last 24 hours (2) he can support another one after 24 hours
                              setOpenModalOnlineSupport(false)
                              handleOpenDialogHours()
                           } else {
                              // he is past 24 hours and now he can support another candidate
                              setActionForSupport('accept the support if another candidate')
                              setOpenModalOnlineSupport(true)
                           }
                        } else {
                           // means (1) he is attached to this constituency (2) he has NOT yet supported any candidate for this election
                           // proceed to vote and accept the vote
                           setActionForSupport('accept the support')

                           setUploadingOnlineSupport(false)
                           setUploadedOnlineSupport(false)
                           setUploadingFailedOnlineSupport(false)

                           setOpenModalOnlineSupport(true)
                        }
                     } else {
                        // means (1) he is NOT attached to this constituency
                        // means (2) he is trying to support a candidate here
                        // prompt the user that he is attached to some other constituency at this level
                        // console.log(
                        //    'prior to open support modal - state level - attached consti - ',
                        //    userProfile.AtStateConstituency.Constituency_id,
                        //    ' selected consti - ',
                        //    selectedConstiId,
                        // )
                        setActionForSupport('attached to other consti')

                        handleOpenDialogAtOtherConsti()
                     }
                  }
                  break
               case 'Division':
                  //
                  break
               case 'District':
                  //
                  break
               case 'SubDistrict':
                  //
                  break
               case 'Block':
                  //
                  break
               case 'Village':
                  //
                  break
            }
         }
      } else {
         // console.log('reached stage prior to open modal for support - You are NOT logged in.')
         handleOpenDialogNotLoggedIn()
      }
   }

   const closeModalOnlineSupport = () => setOpenModalOnlineSupport(false)

   // online support: final record

   async function attachUserToConsti() {
      try {
         // 1. First get the count of attached users from the Constituency and finalize corelist id

         const docRefConsti = doc(db, 'LegisConstituencies', selectedConstiId)
         const docSnapConsti = await getDoc(docRefConsti)
         const slab = 5000
         const count = docSnapConsti.data().AttachedUsersCount
         const quotient = Math.floor(count / slab)
         const num = quotient + 1
         const numStr = String(num).padStart(6, '0')
         console.log('attaching user stage 1: ')
         // The above is for now. Later when people start changing constis after 2 years, the code need to be revised

         // 2. Now attach the user inside Constituency-AttachedUsersLists-CoreListId

         const docRefConstiSubCollAttached = doc(
            db,
            'LegisConstituencies',
            selectedConstiId,
            'AttachedUsersLists',
            numStr,
         )
         const docSnapConstiSubCollAttached = await getDoc(docRefConstiSubCollAttached)

         if (docSnapConstiSubCollAttached.exists()) {
            await updateDoc(docRefConstiSubCollAttached, {
               Users: arrayUnion({
                  Name: nameOfUser,
                  UserProfile_id: userProfile.id,
                  User_id: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
                  ModDt: '',
               }),
            })
            console.log('attaching user stage 2.1: ')
         } else {
            console.log('attaching user stage 2.2: ')
            setDoc(
               docRefConstiSubCollAttached,
               {
                  Users: [
                     {
                        Name: nameOfUser,
                        UserProfile_id: userProfile.id,
                        User_id: auth.currentUser.uid,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                        ModDt: '',
                     },
                  ],
               },
               { merge: true },
            )
            console.log('attaching user stage 2.2.1: ')
         }

         // 3. Now, Increment count inside Constituency

         await updateDoc(docRefConsti, {
            AttachedUsersCount: increment(1),
         })
         console.log('attaching user stage 3: ')
         // 4. Append the coreList id to the object

         const constiObjForUser = {
            Constituency_id: selectedConstiId,
            ConstituencyNumber: selectedConsti.ConstituencyNumber,
            ConstituencyName: selectedConsti.Name,
            ConstituencyNameHindi: selectedConsti.NameHindi,
            ConstituencyNameLocal: selectedConsti.NameLocal,
            LegislatureName: selectedConsti.LegislatureName,
            Legislature_id: selectedConsti.Legislature_id,

            StateName: selectedConsti.StateName,
            State_id: selectedConsti.State_id,
            CrDt: new Date(),
            CrIP: ip.data.ip,
            ModDt: '',

            CoreListId: numStr,
         }
         console.log('attaching user stage 4: ', constiObjForUser)
         const constiObjForUserCentral = { ...constiObjForUser, Level: 'Central' }
         const constiObjForUserState = { ...constiObjForUser, Level: 'State' }

         // 5. Add record to user profile
         console.log('attaching user stage 5: ')
         const docRefUserProfile = doc(db, 'UserProfiles', userProfile.id)
         const docRefUserProfileSubCollAttachedConstis = doc(
            db,
            'UserProfiles',
            userProfile.id,
            'CoreLists',
            'AttachedConstituencies',
         )
         const docSnapUserProfileSubCollAttachedConstis = await getDoc(docRefUserProfileSubCollAttachedConstis)

         switch (legis.Strata) {
            case 'Central':
               console.log('attaching user stage 5.C: ')
               await updateDoc(docRefUserProfile, {
                  AtCentralConstituency: constiObjForUserCentral,
               })
               console.log('attaching user stage 5.C.1: ')
               if (docSnapUserProfileSubCollAttachedConstis.exists()) {
                  await updateDoc(docRefUserProfileSubCollAttachedConstis, {
                     AttachedConstituencies: arrayUnion(constiObjForUserCentral),
                  })
                  console.log('attaching user stage 5.C.2: ', constiObjForUser)
               } else {
                  setDoc(
                     docRefUserProfileSubCollAttachedConstis,
                     {
                        AttachedConstituencies: [constiObjForUserCentral],
                     },
                     { merge: true },
                  )
                  console.log('attaching user stage 5.C.3: ')
               }
               break
            case 'State':
               console.log('attaching user stage 5.S: ')
               await updateDoc(docRefUserProfile, {
                  AtStateConstituency: constiObjForUserState,
               })
               console.log('attaching user stage 5.S.1: ')
               if (docSnapUserProfileSubCollAttachedConstis.exists()) {
                  await updateDoc(docRefUserProfileSubCollAttachedConstis, {
                     AttachedConstituencies: arrayUnion(constiObjForUserState),
                  })
                  console.log('attaching user stage 5.S.2: ')
               } else {
                  setDoc(
                     docRefUserProfileSubCollAttachedConstis,
                     {
                        AttachedConstituencies: [constiObjForUserState],
                     },
                     { merge: true },
                  )
                  console.log('attaching user stage 5.S.3: ')
               }
               break
         }
         console.log('attaching user stage END: ')
      } catch (error) {
         console.log('error attaching user', error)
      }
   }

   const addOnlineSupport = async (onlineSupportDetails) => {
      console.log('reached adding stage of vote.')
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         console.log('reached adding stage of vote - you are logged in.')
         try {
            if (onlineSupportDetails.Candidate_id !== '') {
               setUploadingOnlineSupport(true)

               try {
                  if (new Date() > new Date(selectedConstiPhaseDate.toDate())) {
                     console.log('Add - stage - 1')
                     // means the date is over
                     // this condition was already checked befor opening the add modal. So reaching this stage means fraud.
                     navigate('/', { replace: true })
                  } else {
                     // means the date is NOT over
                     console.log('Add - stage - 2')
                     switch (legis.Strata) {
                        case 'Central':
                           console.log('Add - stage - 2.1')
                           if (!userProfile.AtCentralConstituency || userProfile.AtCentralConstituency === '') {
                              // 0. The user is NOT YET attached to any constituency at central level
                              // 1. fetch constituency - in this case the present selected consti is applicable
                              // 2. attach the user to constituency
                              attachUserToConsti()
                              // 3. proceed to record the support vote
                              recordOnlineSupport(onlineSupportDetails, 'positive')
                           } else {
                              if (userProfile.AtCentralConstituency.Constituency_id === selectedConstiId) {
                                 // means he is attached to this constituency, so proceed further

                                 // fetch last support state
                                 let hoursCenterHere = ''
                                 // let lastCandiSupportedHere = ''

                                 const lastSupportedCenter = userProfile.LS_Candi_CenterConstituency
                                 console.log('lastSupportedCenter: ', lastSupportedCenter)

                                 if (lastSupportedCenter) {
                                    if (lastSupportedCenter.Election_id === elec.id) {
                                       // find the difference in hours
                                       // console.log('center check')
                                       const lastDateCenter = new Date(lastSupportedCenter.CrDt.toDate())
                                       // console.log('center check 2')
                                       const date1 = moment(lastDateCenter)
                                       const date2 = moment(new Date())
                                       const resHousrs = date2.diff(date1, 'hours')
                                       setHoursState(resHousrs)
                                       hoursCenterHere = resHousrs
                                       setLastCandiSupported(lastSupportedCenter)
                                       // lastCandiSupportedHere = lastSupportedCenter
                                    } else {
                                       setHoursCenter('')
                                       setLastCandiSupported('')
                                    }
                                 } else {
                                    setHoursCenter('')
                                    setLastCandiSupported('')
                                 }

                                 if (hoursCenterHere !== '') {
                                    // means (1) he is attached to this constituency (2) he has already supported a candidate in this election
                                    if (parseInt(hoursCenterHere) < 24) {
                                       // it is less than 24 hrs, how did he reach here?
                                       navigate('/', { replace: true })
                                    } else {
                                       // he is past 24 hours and now he can support another candidate
                                       // now check if he is again supporting the last supported candidate
                                       if (lastCandiSupported.Candidate_id === onlineSupportDetails.Candidate_id) {
                                          setOpenModalOnlineSupport(false)
                                          handleOpenDialogSameCandi()
                                          setUploadingOnlineSupport(false)
                                       } else {
                                          recordOnlineSupport(onlineSupportDetails, 'negative')
                                       }
                                    }
                                 } else {
                                    // means (1) he is attached to this constituency (2) he has NOT yet supported any candidate for this election
                                    // proceed to record and accept the vote
                                    recordOnlineSupport(onlineSupportDetails, 'positive')
                                 }
                              } else {
                                 // 0. The user is NOT attached to this consti. How did he reach here?
                                 // 1. Prompt the user that he is attached to some other constituency at this level.
                                 // 2. This condition was already checked befor opening the add modal. So reaching this stage means fraud.
                                 navigate('/', { replace: true })
                              }
                           }
                           break
                        case 'State':
                           console.log('Add - stage - 2.2')
                           // 1. fetch constituency
                           // 2. fetch parent constituency
                           // 2. attach the user to constituency and the parent constituency
                           // 3. record the support vote
                           if (userProfile.AtStateConstituency === '') {
                              console.log('Add - stage - 2.2.1')
                              // 0. The user is FRESH and NOT not attached to any constituency at state level.
                              // 1. &  2. fetch constituency and parent constituency - in this case the present selected consti is applicable
                              // 3. attach the user to constituency
                              attachUserToConsti()
                              // 3. proceed to record the support vote
                              recordOnlineSupport(onlineSupportDetails, 'positive')
                           } else {
                              console.log('Add - stage - 2.2.2')
                              // the user is attached to some constituency at this level
                              // hence:
                              if (userProfile.AtStateConstituency.Constituency_id === selectedConstiId) {
                                 console.log('Add - stage - 2.2.2.1')
                                 // means he is attached to this constituency, so proceed further

                                 // fetch last support state
                                 let hoursStateHere = ''
                                 // let lastCandiSupportedHere = ''

                                 const lastSupportedState = userProfile.LS_Candi_StateConstituency
                                 console.log('lastSupportedState: ', lastSupportedState)

                                 if (lastSupportedState) {
                                    if (lastSupportedState.Election_id === elec.id) {
                                       // find the difference in hours
                                       // console.log('state check')
                                       const lastDateState = new Date(lastSupportedState.CrDt.toDate())
                                       // console.log('center check 2')
                                       const date1 = moment(lastDateState)
                                       const date2 = moment(new Date())
                                       const resHousrs = date2.diff(date1, 'hours')
                                       setHoursState(resHousrs)
                                       hoursStateHere = resHousrs
                                       setLastCandiSupported(lastSupportedState)
                                       // lastCandiSupportedHere = lastSupportedState
                                    } else {
                                       setHoursState('')
                                       setLastCandiSupported('')
                                    }
                                 } else {
                                    setHoursState('')
                                    setLastCandiSupported('')
                                 }

                                 if (hoursStateHere !== '') {
                                    console.log('Add - stage - 2.2.2.1.1')
                                    // means (1) he is attached to this constituency (2) he has already supported a candidate in this election
                                    if (parseInt(hoursStateHere) < 24) {
                                       // it is less than 24 hrs, how did he reach here?
                                       navigate('/', { replace: true })
                                    } else {
                                       // he is past 24 hours and now he can support another candidate
                                       // now check if he is again supporting the last supported candidate
                                       if (lastCandiSupported.Candidate_id === onlineSupportDetails.Candidate_id) {
                                          setOpenModalOnlineSupport(false)
                                          handleOpenDialogSameCandi()
                                          setUploadingOnlineSupport(false)
                                       } else {
                                          recordOnlineSupport(onlineSupportDetails, 'negative')
                                       }
                                    }
                                 } else {
                                    console.log('Add - stage - 2.2.2.1.2')
                                    // means (1) he is attached to this constituency (2) he has NOT yet supported any candidate for this election
                                    // proceed to vote and accept the vote
                                    recordOnlineSupport(onlineSupportDetails, 'positive')
                                 }
                              } else {
                                 console.log('Add - stage - 2.2.2.2')
                                 // 0. The user is NOT attached to this consti. How did he reach here?
                                 // 1. Prompt the user that he is attached to some other constituency at this level
                                 // 2. This condition was already checked befor opening the add modal. So reaching this stage means fraud.
                                 navigate('/', { replace: true })
                              }
                           }
                           break
                        case 'Division':
                           //
                           break
                        case 'District':
                           //
                           break
                        case 'SubDistrict':
                           //
                           break
                        case 'Block':
                           //
                           break
                        case 'Village':
                           //
                           break
                     }
                  }

                  // closeModalOnlineSupport()
                  // handleOpenDialogThanks()

                  // closeModalOnlineSupport()

                  // setUploadingOnlineSupport(false)
                  // setUploadedOnlineSupport(true)
                  // setUploadingFailedOnlineSupport(false)
               } catch (error) {
                  alert(`Error adding public vote: `, error.message)
                  console.log(`Error adding public vote: `, error)
                  setUploadingOnlineSupport(false)
                  setUploadedOnlineSupport(false)
                  setUploadingFailedOnlineSupport(true)
               }
            } else {
               alert('Please select a candidate to proceed. \n कृपया आगे बढ़ने के लिए एक प्रत्याशी का चयन करें।')
            }
         } catch (error) {
            console.log(error)
         }
      } else {
         console.log('reached adding stage of vote - You are NOT logged in.')
         // this condition was already checked befor opening the add modal. So reaching this stage means fraud.
         navigate('/', { replace: true })
      }
   }

   const recordOnlineSupport = async (onlineSupportDetails, type) => {
      try {
         const supportObj4Candi = {
            CrBy: auth.currentUser.uid,
            CrDt: new Date(),
            CrIP: ip.data.ip,
            ModDt: '',
            UserProfile_id: userProfile.id,
            Name: nameOfUser,
         }
         // console.log('recordOnlineSupport stage: 1')

         if (!selectedConsti.OnlineSupportStarted) {
            // console.log('recordOnlineSupport stage: 2')
            const docRefConsti = doc(db, 'LegisConstituencies', selectedConstiId)
            await updateDoc(docRefConsti, { OnlineSupportStarted: true })

            const docRefElecSubCollConsti = doc(db, 'Elections', elec.id, 'CoreLists', 'ConstituencyCoreList')
            const docSnapElecSubCollConsti = await getDoc(docRefElecSubCollConsti)
            const consti = docSnapElecSubCollConsti
               .data()
               .Constituencies.find((item) => item.Constituency_id === selectedConstiId)
            const constiNew = { ...consti, OnlineSupportStarted: true }
            await updateDoc(docRefElecSubCollConsti, {
               Constituencies: arrayRemove(consti),
            })
            await updateDoc(docRefElecSubCollConsti, {
               Constituencies: arrayUnion(constiNew),
            })
         }
         // console.log('recordOnlineSupport stage: 3')
         const docRefCandi = doc(db, 'ElectionCandidates', onlineSupportDetails.Candidate_id)
         const docSnapCandi = await getDoc(docRefCandi)
         // console.log('recordOnlineSupport stage: 3.3')

         // console.log('recordOnlineSupport stage: 4')
         //
         const slab = 7500
         const supportCount = docSnapCandi.data().OnlineSupports + docSnapCandi.data().OnlineSupportsCancelled
         const quotient = Math.floor(supportCount / slab)
         const num = quotient + 1
         const numStr = String(num).padStart(6, '0')
         // console.log('recordOnlineSupport stage: 5')
         const supportObj4UserProfile = {
            Election_id: elec.id,
            ElectionName: elec.Name,
            ElectionNameHindi: elec.NameHindi,
            ElectionNameLocal: elec.NameLocal,
            Constituency_id: selectedConstiId,
            ConstituencyName: selectedConsti.Name,
            ConstituencyNameHindi: selectedConsti.NameHindi,
            ConstituencyNameLocal: selectedConsti.NameLocal,
            Candidate_id: onlineSupportDetails.Candidate_id,
            CandidateName: onlineSupportDetails.CandidateName,
            CandidateNameHindi: onlineSupportDetails.CandidateNameHindi,
            CandidateNameLocal: onlineSupportDetails.CandidateNameLocal,
            PartyName: onlineSupportDetails.PartyName,
            PartyNameHindi: onlineSupportDetails.PartyNameHindi,
            PartyNameLocal: onlineSupportDetails.PartyNameLocal,
            PartyId: onlineSupportDetails.Party_id,
            Alliances: onlineSupportDetails.Alliances,
            CrDt: new Date(),
            CrIP: ip.data.ip,
            CoreListId_InCandidate: numStr,
            CoreListId_InElection: onlineSupportDetails.CoreListId,
            Type: 'CandiSupport',
         }
         // console.log('supportObj4UserProfile: ', supportObj4UserProfile)
         // console.log('recordOnlineSupport stage: 6')

         // update the support count in main candidate doc

         if (type === 'negative') {
            const docRefCandiPrev = doc(db, 'ElectionCandidates', lastCandiSupported.Candidate_id)
            await updateDoc(docRefCandiPrev, {
               OnlineSupports: increment(-1),
               OnlineSupportsCancelled: increment(1),
            })
         }
         // console.log('recordOnlineSupport stage: 7')
         await updateDoc(docRefCandi, { OnlineSupports: increment(1) })

         // add fresh record to candidate's subcollection named "OnlineSupports"
         // console.log('recordOnlineSupport stage: 8')
         if (type === 'negative') {
            // console.log('recordOnlineSupport stage: 9')
            const docRefCandiSubCollectionOnlineSupportsPrev = doc(
               db,
               'ElectionCandidates',
               lastCandiSupported.Candidate_id,
               'OnlineSupports',
               lastCandiSupported.CoreListId_InCandidate,
            )
            // console.log('XX XX XX')
            const docSnapCandiSubCollectionOnlineSupportsPrev = await getDoc(docRefCandiSubCollectionOnlineSupportsPrev)
            const oldRecord = docSnapCandiSubCollectionOnlineSupportsPrev
               .data()
               .Supports.find((item) => item.CrBy === auth.currentUser.uid && item.ModDt === '')

            // console.log('XX XX: ', oldRecord)

            if (oldRecord) {
               // console.log('recordOnlineSupport stage: 10')
               const oldRecordModified = { ...oldRecord, ModDt: new Date() }

               await updateDoc(docRefCandiSubCollectionOnlineSupportsPrev, {
                  Supports: arrayRemove(oldRecord),
               })
               await updateDoc(docRefCandiSubCollectionOnlineSupportsPrev, {
                  Supports: arrayUnion(oldRecordModified),
               })
            }
         }
         // console.log('recordOnlineSupport stage: 11')
         const docRefCandiSubCollectionOnlineSupports = doc(
            db,
            'ElectionCandidates',
            onlineSupportDetails.Candidate_id,
            'OnlineSupports',
            numStr,
         )
         const docSnapCandiSubCollectionOnlineSupports = await getDoc(docRefCandiSubCollectionOnlineSupports)
         // console.log('recordOnlineSupport stage: 12')
         if (docSnapCandiSubCollectionOnlineSupports.exists()) {
            // console.log('recordOnlineSupport stage: 13')
            await updateDoc(docRefCandiSubCollectionOnlineSupports, {
               Supports: arrayUnion(supportObj4Candi),
            })
         } else {
            // console.log('recordOnlineSupport stage: 14')
            setDoc(docRefCandiSubCollectionOnlineSupports, { Supports: [supportObj4Candi] }, { merge: true })
         }

         // add record to user profile main doc
         // console.log('recordOnlineSupport stage: 15')
         const docRefUserProfile = doc(db, 'UserProfiles', userProfile.id)
         switch (legis.Strata) {
            case 'Central':
               await updateDoc(docRefUserProfile, {
                  LS_Candi_CenterConstituency: supportObj4UserProfile,
                  SupportedCount: increment(1),
               })
               break
            case 'State':
               await updateDoc(docRefUserProfile, {
                  LS_Candi_StateConstituency: supportObj4UserProfile,
                  SupportedCount: increment(1),
               })
               break
         }

         // add record to user profile subCollection
         // console.log('recordOnlineSupport stage: 16')
         const docRefUserSubCollectionCandiadtesSupported = doc(
            db,
            'UserProfiles',
            userProfile.id,
            'CandidatesSupported',
            '000001',
         )
         const docSnapUserSubCollectionCandiadtesSupported = await getDoc(docRefUserSubCollectionCandiadtesSupported)
         // console.log('recordOnlineSupport stage: 17')
         if (docSnapUserSubCollectionCandiadtesSupported.exists()) {
            // console.log('recordOnlineSupport stage: 18')
            await updateDoc(docRefUserSubCollectionCandiadtesSupported, {
               SupportedList: arrayUnion(supportObj4UserProfile),
            })
         } else {
            // console.log('recordOnlineSupport stage: 19')
            setDoc(
               docRefUserSubCollectionCandiadtesSupported,
               { SupportedList: [supportObj4UserProfile] },
               { merge: true },
            )
         }
         // console.log('recordOnlineSupport stage: 20')
         // update the support count in candidates core list inside Elections
         if (type === 'negative') {
            // console.log('recordOnlineSupport stage: 21')
            const docRefElectionCandiCoreListPrev = doc(
               db,
               'Elections',
               elec.id,
               'CoreLists',
               lastCandiSupported.CoreListId_InElection,
            )
            const docSnapElectionCandiCoreListPrev = await getDoc(docRefElectionCandiCoreListPrev)
            const candiRecordNegative = docSnapElectionCandiCoreListPrev
               .data()
               .Candidates.find((item) => item.Candidate_id === lastCandiSupported.Candidate_id)

            const candiRecordNegativeNew = {
               ...candiRecordNegative,
               OnlineSupport: candiRecordNegative.OnlineSupport - 1,
               OnlineSupportCancelled: candiRecordNegative.OnlineSupportCancelled + 1,
            }

            await updateDoc(docRefElectionCandiCoreListPrev, {
               Candidates: arrayRemove(candiRecordNegative),
            })
            await updateDoc(docRefElectionCandiCoreListPrev, {
               Candidates: arrayUnion(candiRecordNegativeNew),
            })
         }
         // console.log('recordOnlineSupport stage: 22')
         const docRefElectionCandiCoreList = doc(db, 'Elections', elec.id, 'CoreLists', onlineSupportDetails.CoreListId)
         const docSnapElectionCandiCoreList = await getDoc(docRefElectionCandiCoreList)

         const candiRecordPositive = docSnapElectionCandiCoreList
            .data()
            .Candidates.find((item) => item.Candidate_id === onlineSupportDetails.Candidate_id)

         const candiRecordPositiveNew = { ...candiRecordPositive, OnlineSupport: candiRecordPositive.OnlineSupport + 1 }
         // console.log('recordOnlineSupport stage: 23')
         await updateDoc(docRefElectionCandiCoreList, {
            Candidates: arrayRemove(candiRecordPositive),
         })
         await updateDoc(docRefElectionCandiCoreList, {
            Candidates: arrayUnion(candiRecordPositiveNew),
         })

         // update the support in local state
         // console.log('recordOnlineSupport stage: 24')
         const candiRecordInWaiting = candidatesListWaiting.find(
            (item) => item.Candidate_id === onlineSupportDetails.Candidate_id,
         )
         if (candiRecordInWaiting) {
            candiRecordInWaiting.OnlineSupport = candiRecordInWaiting.OnlineSupport + 1
         }

         const candiRecordInAccepted = candidatesListAccepted.find(
            (item) => item.Candidate_id === onlineSupportDetails.Candidate_id,
         )
         if (candiRecordInAccepted) {
            candiRecordInAccepted.OnlineSupport = candiRecordInAccepted.OnlineSupport + 1
         }

         if (type === 'negative') {
            const candiRecordInWaitingPrev = candidatesListWaiting.find(
               (item) => item.Candidate_id === lastCandiSupported.Candidate_id,
            )
            if (candiRecordInWaitingPrev) {
               candiRecordInWaitingPrev.OnlineSupport = candiRecordInWaitingPrev.OnlineSupport - 1
               candiRecordInWaitingPrev.OnlineSupportCancelled = candiRecordInWaitingPrev.OnlineSupportCancelled + 1
            }

            const candiRecordInAcceptedPrev = candidatesListAccepted.find(
               (item) => item.Candidate_id === lastCandiSupported.Candidate_id,
            )
            if (candiRecordInAcceptedPrev) {
               candiRecordInAcceptedPrev.OnlineSupport = candiRecordInAcceptedPrev.OnlineSupport - 1
               candiRecordInAcceptedPrev.OnlineSupportCancelled = candiRecordInAcceptedPrev.OnlineSupportCancelled + 1
            }
         }

         const sortedListForOnlineSupport = [...candidatesListForOnlineSupport].sort(
            (a, b) => b.OnlineSupport - a.OnlineSupport,
         )
         setCandidatesListForOnlineSupport(sortedListForOnlineSupport)

         // set the state of userProfile afresh
         const querryUser = query(collection(db, 'UserProfiles'), where('User_id', '==', auth.currentUser.uid))
         const querySnapshot = await getDocs(querryUser)
         let userFetched = ''
         querySnapshot.forEach((docSnap) => {
            userFetched = { id: docSnap.id, ...docSnap.data() }
         })
         dispatch(setUserProfile(userFetched))

         handleOpenDialogThanks()
         setUploadingOnlineSupport(false)
         setOpenModalOnlineSupport(false)
      } catch (error) {
         console.log('error recording support', error)
         setUploadingOnlineSupport(false)
      }
   }

   const cancelOnlineSupport = async () => {
      setOpenModalOnlineSupport(false)
      setUploadingOnlineSupport(false)
   }

   // winner

   const [openModalWinner, setOpenModalWinner] = useState(false)

   const handleOpenModalWinner = async (e, item) => {
      e.preventDefault()

      setSelectedCandidate(item)

      setOpenModalWinner(true)
   }

   const handleCloseModalWinner = () => setOpenModalWinner(false)

   const handleAddWinner = async (record) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (record.Winner !== '') {
               const newRecord = { ...selectedCandidate, ...record }

               try {
                  // console.log('value to add: ', record)

                  const docRefCandidate = doc(db, 'ElectionCandidates', selectedCandidate.Candidate_id)
                  const docRefElectionSubCollCoreLists = doc(
                     db,
                     'Elections',
                     elec.id,
                     'CoreLists',
                     selectedCandidate.CoreListId,
                  )

                  await updateDoc(docRefCandidate, {
                     Winner: record.Winner,
                  })

                  await updateDoc(docRefElectionSubCollCoreLists, {
                     Candidates: arrayRemove(selectedCandidate),
                  })
                  await updateDoc(docRefElectionSubCollCoreLists, {
                     Candidates: arrayUnion(newRecord),
                  })

                  const root = candidatesListRootLocal.filter(
                     (item) => item.Candidate_id !== selectedCandidate.Candidate_id,
                  )
                  // console.log('root: ', root)
                  root.push(newRecord)
                  // console.log('root 2: ', root)
                  setCandidatesListRootLocal(root)
                  handleCandidatesListRootUpdate(root)

                  const list = root.filter((item) => item.Constituency_id === selectedConstiId)

                  const listAccepted = list.filter((item) => item.Accepted === 'Accepted' && item.Withdrawn === '')

                  let sortedListAccepted = []
                  if (!elec.ResultsDeclared) {
                     sortedListAccepted = [...listAccepted].sort((a, b) => {
                        if (b.Name.trim() > a.Name.trim()) {
                           return -1
                        } else if (b.Name.trim() < a.Name.trim()) {
                           return 1
                        } else {
                           return 0
                        }
                     })
                  } else {
                     sortedListAccepted = [...listAccepted].sort((a, b) => b.Votes - a.Votes)
                  }
                  // console.log('sorted list accepted after status update: ', sortedListAccepted)
                  setCandidatesListAccepted(sortedListAccepted)

                  handleCloseModalWinner()
               } catch (error) {
                  alert(`Error adding candidate's winner: `, error.message)
                  console.log(`Error adding candidate's winner: `, error)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // Votes

   const [openModalVotes, setOpenModalVotes] = useState(false)

   const handleOpenModalVotes = async (e, item) => {
      e.preventDefault()

      setSelectedCandidate(item)

      setOpenModalVotes(true)
   }

   const handleCloseModalVotes = () => setOpenModalVotes(false)

   const handleAddVotes = async (numberDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (numberDetails.Number !== '') {
               const newRecord = { ...selectedCandidate, Votes: numberDetails.Number }

               try {
                  // console.log('value to add: ', newRecord)

                  const docRefElectionSubCollCandiCoreList = doc(
                     db,
                     'Elections',
                     elec.id,
                     'CoreLists',
                     selectedCandidate.CoreListId,
                  )

                  // 1. update Candidate main doc
                  if (selectedCandidate.Name !== 'Zznota') {
                     const docRefCandidate = doc(db, 'ElectionCandidates', selectedCandidate.Candidate_id)
                     await updateDoc(docRefCandidate, {
                        Votes: numberDetails.Number,
                     })
                  }

                  // 2. update the candi core list of election
                  await updateDoc(docRefElectionSubCollCandiCoreList, {
                     Candidates: arrayRemove(selectedCandidate),
                  })
                  await updateDoc(docRefElectionSubCollCandiCoreList, {
                     Candidates: arrayUnion(newRecord),
                  })

                  const root = candidatesListRootLocal.filter(
                     (item) => item.Candidate_id !== selectedCandidate.Candidate_id,
                  )
                  // console.log('root: ', root)
                  root.push(newRecord)
                  // console.log('root 2: ', root)
                  setCandidatesListRootLocal(root)
                  handleCandidatesListRootUpdate(root)

                  const list = root.filter((item) => item.Constituency_id === selectedConstiId)

                  const listAccepted = list.filter((item) => item.Accepted === 'Accepted' && item.Withdrawn === '')

                  let sortedListAccepted = []
                  if (!elec.ResultsDeclared) {
                     sortedListAccepted = [...listAccepted].sort((a, b) => {
                        if (b.Name.trim() > a.Name.trim()) {
                           return -1
                        } else if (b.Name.trim() < a.Name.trim()) {
                           return 1
                        } else {
                           return 0
                        }
                     })
                  } else {
                     sortedListAccepted = [...listAccepted].sort((a, b) => b.Votes - a.Votes)
                  }
                  // console.log('sorted list accepted after status update: ', sortedListAccepted)
                  setCandidatesListAccepted(sortedListAccepted)

                  handleCloseModalVotes()
               } catch (error) {
                  alert(`Error adding public vote: `, error.message)
                  console.log(`Error adding public vote: `, error)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // not logged in

   const [openDialogNotLoggedIn, setOpenDialogNotLoggedIn] = useState(false)

   const handleOpenDialogNotLoggedIn = async () => {
      setOpenDialogNotLoggedIn(true)
   }

   const handleCloseDialogNotLoggedIn = () => setOpenDialogNotLoggedIn(false)

   // date passed

   const [openDialogDatePassed, setOpenDialogDatePassed] = useState(false)

   const handleOpenDialogDatePassed = async () => {
      setOpenDialogDatePassed(true)
   }

   const handleCloseDialogDatePassed = () => setOpenDialogDatePassed(false)

   // hours remaining

   const [openDialogHours, setOpenDialogHours] = useState(false)

   const handleOpenDialogHours = async () => {
      setOpenDialogHours(true)
   }

   const handleCloseDialogHours = () => setOpenDialogHours(false)

   // attached to other consti at the same level

   const [openDialogAtOtherConsti, setOpenDialogAtOtherConsti] = useState(false)

   const handleOpenDialogAtOtherConsti = async () => {
      setOpenDialogAtOtherConsti(true)
   }

   const handleCloseDialogAtOtherConsti = () => setOpenDialogAtOtherConsti(false)

   // supporting same candidate

   const [openDialogSameCandi, setOpenDialogSameCandi] = useState(false)

   const handleOpenDialogSameCandi = async () => {
      setOpenDialogSameCandi(true)
   }

   const handleCloseDialogSameCandi = () => setOpenDialogSameCandi(false)

   // thanks for support

   const [openDialogThanks, setOpenDialogThanks] = useState(false)

   const handleOpenDialogThanks = async () => {
      setOpenDialogThanks(true)
   }

   const handleCloseDialogThanks = () => setOpenDialogThanks(false)

   // send candidate to legislature
   const handleSendToLegis = async (e, thisItem) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isSuper) {
            if (confirm('Are you sure you want to send this candidate to members list?')) {
               try {
                  const docRefCandi = doc(db, 'ElectionCandidates', thisItem.Candidate_id)
                  const docSnapCandi = await getDoc(docRefCandi)
                  const address = docSnapCandi.data().Address
                  const addressOld = docSnapCandi.data().AddressOld
                  // this const 'LegisMemberDetails' is for creating main doc of LegisMem
                  const LegisMemberDetails = {
                     Candidate_id: thisItem.Candidate_id,
                     NameAsCandidate: thisItem.Name,
                     Name: thisItem.Name,
                     NameOld: [],
                     NameHindi: thisItem.NameHindi,
                     NameHindiOld: [],
                     NameLocal: thisItem.NameLocal,
                     NameLocalOld: [],

                     CoreListId: '',

                     Age: thisItem.Age,
                     DoB: thisItem.DoB,
                     DoBOld: [],
                     Gender: thisItem.Gender,
                     GenderOld: [],

                     Constituency_id: thisItem.Constituency_id,
                     ConstituencyName: thisItem.ConstituencyName,
                     ConstituencyNameHindi: thisItem.ConstituencyNameHindi,
                     ConstituencyNameLocal: thisItem.ConstituencyNameLocal,
                     ConstituencyNumber: selectedConsti.ConstituencyNumber,
                     SeatType: selectedConsti.SeatType,

                     VillageName: selectedConsti.VillageName,
                     Village_id: selectedConsti.Village_id,
                     BlockName: selectedConsti.BlockName,
                     Block_id: selectedConsti.Block_id,
                     SubDistrictName: selectedConsti.SubDistrictName,
                     SubDistrict_id: selectedConsti.SubDistrict_id,
                     DistrictName: selectedConsti.DistrictName,
                     District_id: selectedConsti.District_id,
                     DivisionName: selectedConsti.DivisionName,
                     Division_id: selectedConsti.Division_id,
                     StateName: selectedConsti.StateName,
                     State_id: selectedConsti.State_id,

                     Image: thisItem.Image,

                     Party_id: thisItem.Party_id,
                     PartyName: thisItem.PartyName,
                     PartyNameHindi: thisItem.PartyNameHindi,
                     PartyNameLocal: thisItem.PartyNameLocal,
                     PartyAbbreviation: thisItem.PartyAbbreviation,
                     PartyFlag: thisItem.PartyFlag,
                     Alliances: thisItem.Alliances,
                     AlliancesNow: [],

                     Votes: thisItem.Votes,
                     OnlineSupport: thisItem.OnlineSupport ? thisItem.OnlineSupport : 0,
                     OnlineSupportCancelled: thisItem.OnlineSupportCancelled ? thisItem.OnlineSupportCancelled : 0,
                     OnlineSwingSupport: thisItem.OnlineSwingSupport ? thisItem.OnlineSwingSupport : 0,
                     ExitSupport: thisItem.ExitSupport ? thisItem.ExitSupport : 0,

                     Election_id: elec.id,
                     ElectionName: elec.Name,
                     ElectionNameHindi: elec.NameHindi,
                     ElectionNameLocal: elec.NameLocal,
                     ElectionType: elec.ElectionType,

                     Legislature_id: elec.Legislature_id,
                     LegislatureName: elec.LegislatureName,
                     LegislatureNameHindi: elec.LegislatureNameHindi,
                     LegislatureNameLocal: elec.LegislatureNameLocal,

                     HouseNumber: elec.HouseNumber,
                     FromDate: elec.DateCompletion,
                     ToDate: '',
                     ToDateReason: '',

                     Oath: '',

                     Address: address,
                     AddressOld: addressOld,
                     Phones: [],
                     PhonesOld: [],
                     WebLinks: [],
                     WebLinksOld: [],
                     Emails: [],
                     EmailsOld: [],

                     User_id: '',
                     Helpers: [],
                     HelpersOld: [],
                     Religion: '',
                     ReligionOld: [],
                     Caste: '',
                     CasteOld: [],
                     NetaProfile_id: '',

                     CrBy: auth.currentUser.uid,
                     CrDt: new Date(),
                     CrIP: ip.data.ip,
                  }
                  // console.log('Send candi to legis - LegisMemberDetails: ', LegisMemberDetails)

                  // this const 'record' is for cross-reference entries in legislature and constituenciy
                  const record = {
                     LegisMem_id: '',
                     Candidate_id: thisItem.Candidate_id,
                     NameAsCandidate: thisItem.Name,
                     Name: thisItem.Name,
                     NameHindi: thisItem.NameHindi,
                     NameLocal: thisItem.NameLocal,

                     Age: thisItem.Age,
                     DoB: thisItem.DoB,
                     Gender: thisItem.Gender,

                     CoreListId: '',

                     Constituency_id: thisItem.Constituency_id,
                     ConstituencyName: thisItem.ConstituencyName,
                     ConstituencyNameHindi: thisItem.ConstituencyNameHindi,
                     ConstituencyNameLocal: thisItem.ConstituencyNameLocal,
                     ConstituencyNumber: selectedConsti.ConstituencyNumber,
                     SeatType: selectedConsti.SeatType,

                     VillageName: selectedConsti.VillageName,
                     Village_id: selectedConsti.Village_id,
                     BlockName: selectedConsti.BlockName,
                     Block_id: selectedConsti.Block_id,
                     SubDistrictName: selectedConsti.SubDistrictName,
                     SubDistrict_id: selectedConsti.SubDistrict_id,
                     DistrictName: selectedConsti.DistrictName,
                     District_id: selectedConsti.District_id,
                     DivisionName: selectedConsti.DivisionName,
                     Division_id: selectedConsti.Division_id,
                     StateName: selectedConsti.StateName,
                     State_id: selectedConsti.State_id,

                     Image: thisItem.Image,

                     Party_id: thisItem.Party_id,
                     PartyName: thisItem.PartyName,
                     PartyNameHindi: thisItem.PartyNameHindi,
                     PartyNameLocal: thisItem.PartyNameLocal,
                     PartyAbbreviation: thisItem.PartyAbbreviation,
                     PartyFlag: thisItem.PartyFlag,

                     Alliances: thisItem.Alliances,
                     AlliancesNow: [],

                     Votes: thisItem.Votes,
                     OnlineSupport: thisItem.OnlineSupport ? thisItem.OnlineSupport : '',
                     OnlineSwingSupport: thisItem.OnlineSwingSupport ? thisItem.OnlineSwingSupport : '',
                     ExitSupport: thisItem.ExitSupport ? thisItem.ExitSupport : '',

                     Election_id: elec.id,
                     ElectionName: elec.Name,
                     ElectionNameHindi: elec.NameHindi,
                     ElectionNameLocal: elec.NameLocal,
                     ElectionType: elec.ElectionType,

                     Legislature_id: elec.Legislature_id,
                     HouseNumber: elec.HouseNumber,
                     FromDate: elec.DateCompletion,
                     ToDate: '',
                     ToDateReason: '',
                     Oath: '',

                     User_id: '',
                     Religion: '',
                     Caste: '',
                     NetaProfile_id: '',

                     CrBy: auth.currentUser.uid,
                     CrDt: new Date(),
                     CrIP: ip.data.ip,
                  }
                  // console.log('Send candi to legis - record: ', record)

                  // prepare ground for subCollection 'MemCoreLists' inside the legislature
                  // we are fetching docLegisFresh so that we get the real count of members just before the entry of new member
                  const docRefLegisFresh = doc(db, 'Legislatures', elec.Legislature_id)
                  const docSnapLegisFresh = await getDoc(docRefLegisFresh)
                  const housesList = docSnapLegisFresh.data().Houses
                  const thisHouse = housesList.find((item) => item.HouseNumber === elec.HouseNumber)
                  const memCount = thisHouse.MemCount
                  const thisHouseNew = { ...thisHouse, MemCount: memCount + 1 }

                  // console.log('local members Count before core list: ', memCount)

                  const slab = 300
                  const quotient = Math.floor(memCount / slab)
                  // const remainder = candidatesCount % slab
                  const num = quotient + 1
                  const coreListId = String(num).padStart(3, '0')

                  // append corelistId to legisMemDetail and record
                  LegisMemberDetails.CoreListId = coreListId
                  record.CoreListId = coreListId

                  // get ref of the subCollection 'MemCoreLists' inside the LEGISLATURE
                  const memCoreListCollection = 'MemCoreList-' + elec.HouseNumber
                  const docRefLegisSubCollectionMemCoreLists = doc(
                     db,
                     'Legislatures',
                     elec.Legislature_id,
                     memCoreListCollection,
                     coreListId,
                  )
                  const docRefLegisSubCollectionMemCoreListsForCandidateIds = doc(
                     db,
                     'Legislatures',
                     elec.Legislature_id,
                     memCoreListCollection,
                     'CandidateIdList',
                  )
                  // const docSnapLegisSubCollectionMemCoreLists = await getDoc(docRefLegisSubCollectionMemCoreLists)
                  const docSnapLegisSubCollectionMemCoreListsForCandidateIds = await getDoc(
                     docRefLegisSubCollectionMemCoreListsForCandidateIds,
                  )

                  // get ref of the subcollection 'CoreLists / MemCoreList' inside the CONSTITUENCY
                  const docRefConstiSubCollectionMemCoreList = doc(
                     db,
                     'LegisConstituencies',
                     thisItem.Constituency_id,
                     'CoreLists',
                     'MemCoreList',
                  )
                  const docSnapConstiSubCollectionMemCoreList = await getDoc(docRefConstiSubCollectionMemCoreList)

                  // console.log('Stage 2')

                  // start the data writing
                  if (docSnapLegisSubCollectionMemCoreListsForCandidateIds.exists()) {
                     // means one or more candidates were earlier added for this election.
                     // now find if the present candidate was added as member to this legilsature
                     if (
                        docSnapLegisSubCollectionMemCoreListsForCandidateIds
                           .data()
                           .CandidateIdsList.includes(thisItem.Candidate_id)
                     ) {
                        // means this candidate was already added earlier
                        alert('This candidate is already in the members list of legislature!')
                     } else {
                        // means this candidate was NOT added earlier
                        // 1. Create the main doc in collection LegisMembers
                        const docRef = await addDoc(collection(db, 'LegisMembers'), LegisMemberDetails)
                        record.LegisMem_id = docRef.id

                        // 2. update subcollection 'MemCoreLists' of legislature for member record
                        await updateDoc(docRefLegisSubCollectionMemCoreLists, {
                           Members: arrayUnion(record),
                        })

                        // 3. update subcollection 'MemCoreLists' of legislature for CandidateIds List
                        await updateDoc(docRefLegisSubCollectionMemCoreListsForCandidateIds, {
                           CandidateIdsList: arrayUnion(thisItem.Candidate_id),
                        })

                        // 4. update subcollection 'MemCoreList' of Constituencies
                        if (docSnapConstiSubCollectionMemCoreList.exists()) {
                           await updateDoc(docRefConstiSubCollectionMemCoreList, {
                              Members: arrayUnion(record),
                              CandidateIdsList: arrayUnion(thisItem.Candidate_id),
                           })
                        } else {
                           setDoc(
                              docRefConstiSubCollectionMemCoreList,
                              {
                                 Members: [record],
                                 CandidateIdsList: [thisItem.Candidate_id],
                              },
                              { merge: true },
                           )
                        }

                        // 5. Update Legislature for member count
                        await updateDoc(docRefLegisFresh, {
                           Houses: arrayRemove(thisHouse),
                        })
                        await updateDoc(docRefLegisFresh, {
                           Houses: arrayUnion(thisHouseNew),
                        })
                     }
                  } else {
                     // means NO candidate was earlier added for this election.
                     // 1. Create the main doc in collection LegisMembers
                     const docRef = await addDoc(collection(db, 'LegisMembers'), LegisMemberDetails)
                     record.LegisMem_id = docRef.id

                     // 2. update subcollection 'MemCoreLists' of legislature for member record
                     setDoc(
                        docRefLegisSubCollectionMemCoreLists,
                        {
                           Members: [record],
                        },
                        { merge: true },
                     )

                     // 3. update subcollection 'MemCoreLists' of legislature for CandidateIds List
                     setDoc(
                        docRefLegisSubCollectionMemCoreListsForCandidateIds,
                        {
                           CandidateIdsList: [thisItem.Candidate_id],
                        },
                        { merge: true },
                     )

                     // 4. update subcollection 'MemCoreList' of constituencies
                     if (docSnapConstiSubCollectionMemCoreList.exists()) {
                        await updateDoc(docRefConstiSubCollectionMemCoreList, {
                           Members: arrayUnion(record),
                           CandidateIdsList: arrayUnion(thisItem.Candidate_id),
                        })
                     } else {
                        setDoc(
                           docRefConstiSubCollectionMemCoreList,
                           {
                              Members: [record],
                              CandidateIdsList: [thisItem.Candidate_id],
                           },
                           { merge: true },
                        )
                     }
                  }

                  // 5. Update Legislature for member count
                  await updateDoc(docRefLegisFresh, {
                     Houses: arrayRemove(thisHouse),
                  })
                  await updateDoc(docRefLegisFresh, {
                     Houses: arrayUnion(thisHouseNew),
                  })

                  // if (docSnapLegisSubCollectionMemCoreLists.exists()) {
                  //    // means one or more candidates were earlier added for this election.
                  //    // now find if the present candidate was added as member to thie legisature
                  //    if (
                  //       docSnapLegisSubCollectionMemCoreLists.data().CandidateIdsList.includes(thisItem.Candidate_id)
                  //    ) {
                  //       // means this candidate was already added earlier
                  //       alert('This candidate is already in the members list of legislature!')
                  //    } else {
                  //       // means this candidate was NOT added earlier

                  //       // 1. Create the main doc in collection LegisMembers
                  //       const docRef = await addDoc(collection(db, 'LegisMembers'), LegisMemberDetails)
                  //       record.LegisMem_id = docRef.id

                  //       // 2. update subcollection 'MemCoreLists' of legislature with new values
                  //       await updateDoc(docRefLegisSubCollectionMemCoreLists, {
                  //          Members: arrayUnion(record),
                  //          CandidateIdsList: arrayUnion(thisItem.Candidate_id),
                  //       })

                  //       // 3. update Legislatures with following values
                  //       // const docRefLegis = doc(db, 'Legislatures', elec.Legislature_id)

                  //       // if (elec.ElectionType === 'General Election') {
                  //       //    // in case of general election
                  //       //    await updateDoc(docRefLegis, {
                  //       //       Houses: arrayUnion({
                  //       //          HouseNumber: elec.HouseNumber,
                  //       //          FromDate: elec.DateCompletion,
                  //       //          ToDate: '',
                  //       //          ToDateReason: '',
                  //       //       }),
                  //       //       HousesOld: arrayUnion({
                  //       //          HouseNumber: elec.HouseNumber,
                  //       //          FromDate: elec.DateCompletion,
                  //       //          ToDate: '',
                  //       //          ToDateReason: '',
                  //       //       }),
                  //       //       HouseNumbers: arrayUnion(elec.HouseNumber),
                  //       //    })
                  //       // } else {
                  //       //    // in case of bye-election
                  //       //    await updateDoc(docRefLegis, {
                  //       //       HouseNumbers: arrayUnion(elec.HouseNumber),
                  //       //    })
                  //       // }

                  //       // 4. update subcollection 'MemCoreList' of Constituencies
                  //       if (docSnapConstiSubCollectionMemCoreList.exists()) {
                  //          await updateDoc(docRefConstiSubCollectionMemCoreList, {
                  //             Members: arrayUnion(record),
                  //             CandidateIdsList: arrayUnion(thisItem.Candidate_id),
                  //          })
                  //       } else {
                  //          setDoc(
                  //             docRefConstiSubCollectionMemCoreList,
                  //             {
                  //                Members: [record],
                  //                CandidateIdsList: [thisItem.Candidate_id],
                  //             },
                  //             { merge: true },
                  //          )
                  //       }
                  //    }
                  // } else {
                  //    // means NO candidate was earlier added for this election.

                  //    // 1. Create the main doc in collection LegisMembers
                  //    const docRef = await addDoc(collection(db, 'LegisMembers'), LegisMemberDetails)
                  //    record.LegisMem_id = docRef.id

                  //    // 2. create the subcollection MemCoreLists with new values
                  //    setDoc(
                  //       docRefLegisSubCollectionMemCoreLists,
                  //       {
                  //          Members: [record],
                  //          CandidateIdsList: [thisItem.Candidate_id],
                  //          HouseNumber: elec.HouseNumber,
                  //       },
                  //       { merge: true },
                  //    )

                  //    // 3. update Legislatures with following values
                  //    // const docRefLegis = doc(db, 'Legislatures', elec.Legislature_id)

                  //    // if (elec.ElectionType === 'General Election') {
                  //    //    // in case of general election
                  //    //    await updateDoc(docRefLegis, {
                  //    //       Houses: arrayUnion({
                  //    //          HouseNumber: elec.HouseNumber,
                  //    //          FromDate: elec.DateCompletion,
                  //    //          ToDate: '',
                  //    //          ToDateReason: '',
                  //    //       }),
                  //    //       HousesOld: arrayUnion({
                  //    //          HouseNumber: elec.HouseNumber,
                  //    //          FromDate: elec.DateCompletion,
                  //    //          ToDate: '',
                  //    //          ToDateReason: '',
                  //    //       }),
                  //    //       HouseNumbers: arrayUnion(elec.HouseNumber),
                  //    //    })
                  //    // } else {
                  //    //    // in case of bye-election
                  //    //    await updateDoc(docRefLegis, {
                  //    //       HouseNumbers: arrayUnion(elec.HouseNumber),
                  //    //    })
                  //    // }

                  //    // 4. update subcollection 'MemCoreList' of constituencies
                  //    if (docSnapConstiSubCollectionMemCoreList.exists()) {
                  //       await updateDoc(docRefConstiSubCollectionMemCoreList, {
                  //          Members: arrayUnion(record),
                  //          CandidateIdsList: arrayUnion(thisItem.Candidate_id),
                  //       })
                  //    } else {
                  //       setDoc(
                  //          docRefConstiSubCollectionMemCoreList,
                  //          {
                  //             Members: [record],
                  //             CandidateIdsList: [thisItem.Candidate_id],
                  //          },
                  //          { merge: true },
                  //       )
                  //    }
                  // }
               } catch (error) {
                  alert('Error adding member')
                  console.log(error)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // send candidate to neta
   const handleSendToNeta = async (e, thisItem) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isSuper) {
            if (confirm('Are you sure you want to send this candidate to neta list?')) {
               try {
                  const docRefCandi = doc(db, 'ElectionCandidates', thisItem.Candidate_id)
                  const docSnapCandi = await getDoc(docRefCandi)
                  const address = docSnapCandi.data().Address
                  const addressOld = docSnapCandi.data().AddressOld
                  // this const 'data' is for main doc of Neta
                  // Constituency_id is used only here for sending candidates as netas.
                  const netaDetails = {
                     Candidate_id: thisItem.Candidate_id,
                     Name: thisItem.Name,
                     NameOld: [thisItem.Name],
                     NameHindi: thisItem.NameHindi,
                     NameHindiOld: [thisItem.NameHindi],
                     NameLocal: thisItem.NameLocal,
                     NameLocalOld: [thisItem.NameLocal],

                     NameOthers: [],
                     NameOthersOld: [],
                     NameOthersHindi: [],
                     NameOthersHindiOld: [],
                     NameOthersLocal: [],
                     NameOthersLocalOld: [],

                     DoB: thisItem.DoB,
                     DoBOld: [],
                     Gender: thisItem.Gender,
                     GenderOld: [],

                     Caste: '',
                     CasteOld: [],
                     Religion: '',
                     ReligionOld: [],
                     ReservationCat: '',
                     ReservationCatOld: [],
                     Address: address,
                     AddressOld: addressOld,
                     AddressOffice: [],
                     AddressOfficeOld: [],
                     Party: {
                        Party_id: thisItem.Party_id,
                        PartyName: thisItem.PartyName,
                        PartyNameHindi: thisItem.PartyNameHindi,
                        PartyNameLocal: thisItem.PartyNameLocal,
                        PartyAbbreviation: thisItem.PartyAbbreviation,
                        PartyFlag: thisItem.PartyFlag,
                        PartySymbol: thisItem.PartySymbol,
                     },
                     PartiesOld: [
                        {
                           Party_id: thisItem.Party_id,
                           PartyName: thisItem.PartyName,
                           PartyNameHindi: thisItem.PartyNameHindi,
                           PartyNameLocal: thisItem.PartyNameLocal,
                           PartyAbbreviation: thisItem.PartyAbbreviation,
                           PartyFlag: thisItem.PartyFlag,
                           PartySymbol: thisItem.PartySymbol,
                           CrDt: new Date(),
                           CrBy: '',
                           CrIP: '',
                        },
                     ],
                     Image: {
                        Image: thisItem.Image,
                        CrDt: new Date(),
                        CrBy: '',
                        CrIP: '',
                     },
                     Phones: [],
                     PhonesOld: [],
                     WebLinks: [],
                     WebLinksOld: [],
                     Emails: [],
                     EmailsOld: [],
                     Constituencies: [],
                     Helpers: [],

                     Votes: thisItem.Votes,
                     OnlineSupport: thisItem.OnlineSupport ? thisItem.OnlineSupport : 0,
                     OnlineSupportCancelled: thisItem.OnlineSupportCancelled ? thisItem.OnlineSupportCancelled : 0,
                     OnlineSwingSupport: thisItem.OnlineSwingSupport ? thisItem.OnlineSwingSupport : 0,
                     ExitSupport: thisItem.ExitSupport ? thisItem.ExitSupport : 0,

                     IsActive: true,
                     CrBy: '',
                     CrDt: new Date(),
                     CrIP: '',
                     User_id: '',
                     UserProfile_id: '',
                     IsBlocked: false,
                     IsSuspended: false,
                     IsSubscriptionExpired: false,
                     Parking: 'Open',
                  }
                  // console.log('Send To Neta - netaDetails: ', netaDetails)

                  // this const 'electionObj' is for subcolection 'Elections' inside NetaProfies

                  const electionObj = {
                     Candidate_id: thisItem.Candidate_id,
                     CandidateName: thisItem.Name,
                     Constituency_id: thisItem.Constituency_id,
                     ConstituencyName: thisItem.ConstituencyName,
                     ConstituencyNameHindi: thisItem.ConstituencyNameHindi,
                     ConstituencyNameLocal: thisItem.ConstituencyNameLocal,
                     ConstituencyNumber: selectedConsti.ConstituencyNumber,
                     SeatType: selectedConsti.SeatType,

                     Party_id: thisItem.Party_id,
                     PartyName: thisItem.PartyName,
                     PartyNameHindi: thisItem.PartyNameHindi,
                     PartyNameLocal: thisItem.PartyNameLocal,
                     PartyAbbreviation: thisItem.PartyAbbreviation,
                     PartyFlag: thisItem.PartyFlag,
                     Alliances: thisItem.Alliances,
                     AlliancesNow: [],

                     Votes: thisItem.Votes,
                     OnlineSupport: thisItem.OnlineSupport,
                     OnlineSwingSupport: thisItem.OnlineSwingSupport,
                     ExitSupport: thisItem.ExitSupport,
                     Winner: thisItem.Winner,

                     Election_id: elec.id,
                     ElectionName: elec.Name,
                     ElectionNameHindi: elec.NameHindi,
                     ElectionNameLocal: elec.NameLocal,

                     Legislature_id: elec.Legislature_id,
                     LegislatureName: elec.LegislatureName,
                     LegislatureNameHindi: elec.LegislatureNameHindi,
                     LegislatureNameLocal: elec.LegislatureNameLocal,
                     HouseNumber: elec.HouseNumber,
                  }
                  // console.log('Send To Neta - electionObj: ', electionObj)

                  // this const 'netaObj' is for crossreference entry in constituencies.
                  const netaObj = {
                     NetaProfile_id: '',
                     Candidate_id: thisItem.Candidate_id,
                     Party: {
                        Party_id: thisItem.Party_id,
                        PartyName: thisItem.PartyName,
                        PartyNameHindi: thisItem.PartyNameHindi,
                        PartyNameLocal: thisItem.PartyNameLocal,
                        PartyAbbreviation: thisItem.PartyAbbreviation,
                        PartyFlag: thisItem.PartyFlag,
                        PartySymbol: thisItem.PartySymbol,
                     },
                     Name: thisItem.Name,
                     NameHindi: thisItem.NameHindi,
                     NameLocal: thisItem.NameLocal,
                     Image: thisItem.Image,
                     DoB: thisItem.DoB,
                     Gender: thisItem.Gender,
                     Caste: '',
                     Religion: '',
                     IsActive: true,
                     DateAttached: new Date(),
                     DateDetached: '',
                     CoreListId: 'Netas-000001',
                     CrBy: auth.currentUser.uid,
                     CrIP: ip.data.ip,
                     ModBy: '',
                     ModIP: '',
                  }
                  // console.log('Send To Neta - netaObj: ', netaObj)

                  // this const 'constiObj' is for crossreference entry in constituencies.
                  const constiObj = {
                     ...selectedConsti,
                     ConstituencyType: 'Public',
                     IsActive: true,
                     DateAttached: new Date(),
                     DateDetached: '',
                     CoreListId: 'Constituencies-001',
                     CrBy: auth.currentUser.uid,
                     CrIP: ip.data.ip,
                     ModBy: '',
                     ModIP: '',
                  }
                  // console.log('Send To Neta - constiObj: ', constiObj)

                  if (netaDetails.User_id !== '' && netaObj.NetaProfile_id !== '') {
                     // means there is already a neta profile claimed and playing in this context
                     // 1. add the electionObj to the 'Elections' subcollection of 'NetaProfiles'
                     // 2. add the constiObj to the 'Constituencies' subcollection of 'NetaProfiles'
                     // 3. add the netaObj to the 'Netas' subcollection of 'Constituencies'
                     // HOWEVER, all these can be done when the neta profile claims the candi profile, which will be more easy
                  } else {
                     // means no existing neta profile has claimed it
                     // 1. Check if neta profile for this candidate exists
                     const querryProfile = query(
                        collection(db, 'NetaProfiles'),
                        where('Candidate_id', '==', thisItem.Candidate_id),
                     )
                     const querySnapshot = await getDocs(querryProfile)
                     if (!querySnapshot.empty) {
                        // alert
                        alert('Candidate already added to Neta Profiles.')
                     } else {
                        // 1. Create a leader profile doc
                        const docRef = await addDoc(collection(db, 'NetaProfiles'), netaDetails)
                        netaObj.NetaProfile_id = docRef.id
                        // console.log('Send To Neta - profie created.')

                        // 2. add the electionObj to the 'Elections' subcollection of 'NetaProfiles'
                        const docRefNetaProfilesSubCollectionElections = doc(
                           db,
                           'NetaProfiles',
                           docRef.id,
                           'CoreLists',
                           'Elections-001',
                        )
                        setDoc(docRefNetaProfilesSubCollectionElections, { Elections: [electionObj] }, { merge: true })

                        // 3. add the constiObj to the 'Constituencies' subcollection of 'NetaProfiles'
                        const docRefNetaProfilesSubCollectionConstituencies = doc(
                           db,
                           'NetaProfiles',
                           docRef.id,
                           'CoreLists',
                           'Constituencies-001',
                        )
                        setDoc(
                           docRefNetaProfilesSubCollectionConstituencies,
                           { Constituencies: [constiObj] },
                           { merge: true },
                        )

                        // 4. add the netaObj to the 'Netas' subcollection of 'Constituencies'

                        // we are fetching docRefConstituencies so that we get the real count of netas just before the entry of new candidate
                        const docRefConstituencies = doc(db, 'LegisConstituencies', thisItem.Constituency_id)
                        const docSnapConstituencies = await getDoc(docRefConstituencies)
                        const netasCount = docSnapConstituencies.data().NetasCount

                        // console.log('netas Count before core list: ', netasCount)

                        const slab = 500
                        const quotient = Math.floor(netasCount / slab)
                        // const remainder = candidatesCount % slab
                        const num = quotient + 1
                        const numStr = String(num).padStart(6, '0')

                        // coreListId = 'CandiCoreList-' + numStr
                        const coreListId = 'Netas-' + numStr

                        const docRefConstituenciesSubCollectionNetas = doc(
                           db,
                           'LegisConstituencies',
                           thisItem.Constituency_id,
                           'CoreLists',
                           coreListId,
                        )
                        const docSnapConstituenciesSubCollectionNetas = await getDoc(
                           docRefConstituenciesSubCollectionNetas,
                        )

                        if (docSnapConstituenciesSubCollectionNetas.exists()) {
                           // update
                           await updateDoc(docRefConstituenciesSubCollectionNetas, {
                              Netas: arrayUnion(netaObj),
                           })
                        } else {
                           // set
                           setDoc(docRefConstituenciesSubCollectionNetas, { Netas: [netaObj] }, { merge: true })
                        }

                        await updateDoc(docRefConstituencies, {
                           NetasCount: increment(1),
                        })
                     }
                  }
               } catch (error) {
                  alert('Error adding neta')
                  console.log('Error adding neta: ', error)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
         <Box>
            <div>
               <Helmet>
                  <title>{`Track Democracy - Candidates for ${elec.Name}`}</title>
               </Helmet>
            </div>
            {!navigator.onLine && (
               <div>
                  <Alert
                     variant="outlined"
                     severity="error"
                     sx={{ margin: 5, justifyContent: 'center', alignItems: 'center' }}
                  >
                     <AlertTitle>
                        You are offline !
                        <CloudOffIcon sx={{ ml: 5 }} />
                     </AlertTitle>
                  </Alert>
               </div>
            )}
            {navigator.onLine && (
               <Box sx={{ px: 1 }}>
                  <Box
                     sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        px: 1,
                        mt: 0.5,
                        '& .MuiFormControl-root': { maxWidth: '50ch', minWidth: '35ch' },
                     }}
                  >
                     <Box sx={{ pt: 1 }}>
                        <FormControl
                           sx={{
                              ml: 1,
                           }}
                           size="small"
                        >
                           <InputLabel>Constituency / निर्वाचन क्षेत्र</InputLabel>
                           <Select
                              value={selectedConstiId}
                              label="Constituency / निर्वाचन क्षेत्र"
                              onChange={(e) => {
                                 handleChangeConsti(e)
                              }}
                              MenuProps={{
                                 sx: {
                                    '&& .Mui-selected': {
                                       color: '#FFFFFF',
                                       background: '#1565c0',
                                    },
                                 },
                              }}
                           >
                              <MenuItem value="">
                                 <em>None</em>
                              </MenuItem>
                              {constisList &&
                                 constisList.length > 0 &&
                                 constisList.map((item) => (
                                    <MenuItem
                                       key={item.Constituency_id}
                                       value={item.Constituency_id}
                                       sx={{ fontSize: 13 }}
                                    >
                                       <strong style={{ mr: 1 }}>
                                          {item.Name} ({item.ConstituencyNumber})
                                          {item.NameLocal !== ''
                                             ? ` ${item.NameLocal}`
                                             : item.NameHindi !== ''
                                               ? ` ${item.NameHindi}`
                                               : null}
                                       </strong>
                                       {legis.Strata === 'Central' ? ` - ${item.StateName}` : null}
                                    </MenuItem>
                                 ))}
                           </Select>
                        </FormControl>
                     </Box>
                  </Box>
                  <Box>
                     <Box
                        sx={{
                           p: 1,
                           mt: 0.5,
                           backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
                           color: '#ffffff',
                        }}
                     >
                        {selectedConstiId === '' && <Box>Candidates for this election:</Box>}
                        {selectedConstiId === '' && <Box>इस चुनाव के लिए प्रत्याशी:</Box>}
                        {constisList &&
                           constisList.length > 0 &&
                           selectedConstiId !== '' &&
                           selectedConstiPhaseDate && (
                              <Box>
                                 Phase: <strong>{selectedConsti.PhaseNum}</strong>
                                 <HowToVoteIcon sx={{ ml: 1 }} />{' '}
                                 {selectedConstiPhaseDate &&
                                    moment(selectedConstiPhaseDate.toDate()).format('Do MMM YYYY')}
                              </Box>
                           )}
                        {constisList && constisList.length > 0 && selectedConstiId !== '' && (
                           <Box>
                              Candidates for constituency{' '}
                              <strong>
                                 <u>
                                    {selectedConsti.Name} ({selectedConsti.ConstituencyNumber})
                                 </u>
                              </strong>
                              :
                           </Box>
                        )}
                        {constisList && constisList.length > 0 && selectedConstiId !== '' && (
                           <Box>
                              {selectedConsti.NameLocal !== '' && (
                                 <>
                                    निर्वाचन क्षेत्र{' '}
                                    <strong>
                                       {selectedConsti.NameLocal} - {selectedConsti.NameHindi} (
                                       {selectedConsti.ConstituencyNumber})
                                    </strong>{' '}
                                    के लिए प्रत्याशी:
                                 </>
                              )}
                              {selectedConsti.NameLocal === '' && (
                                 <>
                                    निर्वाचन क्षेत्र{' '}
                                    <strong>
                                       {selectedConsti.NameHindi} ({selectedConsti.ConstituencyNumber})
                                    </strong>{' '}
                                    के लिए प्रत्याशी:
                                 </>
                              )}
                              <Box>
                                 Seat type: <strong>{selectedConsti.SeatType}</strong>
                              </Box>
                           </Box>
                        )}
                     </Box>
                     {selectedConstiId !== '' && constisList && constisList.length < 1 && (
                        <Box>
                           <Alert
                              variant="outlined"
                              severity="warning"
                              sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
                           >
                              <AlertTitle sx={{ fontSize: 12 }}>
                                 To be available soon. <br /> शीघ्र उपलब्ध होगा |
                              </AlertTitle>
                           </Alert>
                        </Box>
                     )}
                     {selectedConstiId === '' && (
                        <Box>
                           <Alert
                              variant="outlined"
                              severity="warning"
                              sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
                           >
                              <AlertTitle>
                                 Please select a constituency. <br /> कृपया एक निर्वाचन क्षेत्र चुनें |
                              </AlertTitle>
                           </Alert>
                        </Box>
                     )}

                     {(isSuper || isDataEditor) && constisList && constisList.length > 0 && selectedConstiId !== '' && (
                        <Box>
                           <Button
                              sx={{ py: 0, px: 1, minWidth: 0 }}
                              onClick={(e) => {
                                 handleOpenModalAddCandis(e)
                              }}
                           >
                              Add Candidates
                           </Button>
                        </Box>
                     )}

                     <Box>
                        <Grid2 container sx={{ mt: 2 }} id="gridContainer">
                           <Grid2 item xs={8} id="gridItem1">
                              <Box sx={{ mr: 2 }}>
                                 <List dense>
                                    {candidatesListWaiting &&
                                       candidatesListWaiting.length > 0 &&
                                       candidatesListWaiting.map((item, index) => (
                                          <div key={item.Candidate_id}>
                                             {/* <ElecCandiCompWaiting
                                             item={item}
                                             handleDelete={handleDelete}
                                             handleOpenModalStatus3={handleOpenModalStatus3}
                                             isDataEditor={isDataEditor}
                                             isSuper={isSuper}
                                             index={index}
                                             handleOpenModalConstiTransfer={handleOpenModalConstiTransfer}
                                          ></ElecCandiCompWaiting> */}
                                             <ListItem
                                                sx={{ pl: 0, ml: 0 }}
                                                key={item.Candidate_id}
                                                secondaryAction={
                                                   <Box sx={{ ml: 'auto' }}>
                                                      {isSuper && (
                                                         <IconButton
                                                            edge="end"
                                                            aria-label="delete"
                                                            onClick={(e) => {
                                                               handleDelete(e, item)
                                                            }}
                                                            sx={{ mr: 1, height: '25px', width: '25px' }}
                                                         >
                                                            <DeleteIcon fontSize="small" />
                                                         </IconButton>
                                                      )}
                                                   </Box>
                                                }
                                             >
                                                <Link
                                                   href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                                                   sx={{ textDecoration: 'none !important' }}
                                                   target="_blank"
                                                   rel="noopener"
                                                >
                                                   <ListItemAvatar sx={{ display: 'flex', flexDirection: 'column' }}>
                                                      <Typography
                                                         component="span"
                                                         variant="body2"
                                                         sx={{
                                                            color: 'text.primary',
                                                            display: 'inline',
                                                            fontWeight: 600,
                                                            mb: 1,
                                                         }}
                                                      >
                                                         {index + 1}.
                                                      </Typography>
                                                      {item.Name &&
                                                         (countWordsUsingReplace(item.Name) > 1 ? (
                                                            <Avatar
                                                               {...stringAvatar80(item.Name)}
                                                               alt="Travis Howard"
                                                               src={item.Image}
                                                            />
                                                         ) : (
                                                            <Avatar
                                                               {...stringAvatar80Single(item.Name)}
                                                               alt="Travis Howard"
                                                               src={item.Image}
                                                            />
                                                         ))}
                                                   </ListItemAvatar>
                                                </Link>

                                                <ListItemText
                                                   sx={{ ml: 1 }}
                                                   secondaryTypographyProps={{ align: 'left', whiteSpace: 'pre-line' }}
                                                   primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                                                   primary={
                                                      <>
                                                         {item.Name !== 'Zznota' && (
                                                            <>
                                                               <Link
                                                                  underline="hover"
                                                                  href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                                                                  sx={{ textDecoration: 'none !important' }}
                                                                  target="_blank"
                                                                  rel="noopener"
                                                               >
                                                                  <strong>{item.Name.toUpperCase()}</strong>
                                                               </Link>{' '}
                                                               -{' '}
                                                               {item.NameLocal && (
                                                                  <Link
                                                                     href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                                                                     className={styles.overrideUnderline}
                                                                     sx={{ textDecoration: 'none !important' }}
                                                                     target="_blank"
                                                                     rel="noopener"
                                                                  >
                                                                     {item.NameLocal}
                                                                  </Link>
                                                               )}
                                                               {'  '}-{' '}
                                                               <Link
                                                                  underline="none"
                                                                  href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                                                                  color="warning"
                                                                  sx={{ textDecoration: 'none !important' }}
                                                                  target="_blank"
                                                                  rel="noopener"
                                                               >
                                                                  {item.NameHindi}
                                                                  {'  '}
                                                               </Link>
                                                               ({item.Age}y)
                                                            </>
                                                         )}
                                                         {item.Name === 'Zznota' && (
                                                            <>
                                                               <strong>
                                                                  NOTA
                                                                  <br />
                                                                  {item.NameLocal}
                                                                  <br />
                                                                  {item.NameHindi}
                                                               </strong>
                                                            </>
                                                         )}
                                                      </>
                                                   }
                                                   component={'span'}
                                                   secondary={
                                                      <>
                                                         <>{<strong> Constituency: </strong>}</>
                                                         {item.ConstituencyNameLocal
                                                            ? `${item.ConstituencyNameLocal} (${item.ConstituencyNumber}) - ${item.ConstituencyName} - ${item.ConstituencyNameHindi}`
                                                            : `${item.ConstituencyName} (${item.ConstituencyNumber}) - ${item.ConstituencyNameHindi}`}
                                                         <br />
                                                         <>{<strong> Constituency No.: </strong>}</>
                                                         {item.ConstituencyNumber}
                                                         <br />
                                                         <>
                                                            {item.PartyFlag && (
                                                               <Link
                                                                  href={`/political-party/${item.PartyName}/${item.Party_id}`}
                                                                  target="_blank"
                                                                  rel="noopener"
                                                               >
                                                                  <img src={item.PartyFlag} className={styles.imgX2} />
                                                               </Link>
                                                            )}

                                                            {item.PartySymbol && (
                                                               <img src={item.PartySymbol} className={styles.imgX3} />
                                                            )}
                                                            {item.AllottedSymbol && (
                                                               <img
                                                                  src={item.AllottedSymbol}
                                                                  className={styles.imgX3}
                                                               />
                                                            )}
                                                         </>
                                                         <>
                                                            {<strong> Party: </strong>}
                                                            {item.PartyName !== 'Independent' && (
                                                               <Typography
                                                                  sx={{ display: 'inline' }}
                                                                  component="span"
                                                                  variant="body2"
                                                                  color="text.primary"
                                                               >
                                                                  <Link
                                                                     href={`/political-party/${item.PartyName}/${item.Party_id}`}
                                                                     underline="none"
                                                                     sx={{ textDecoration: 'none !important' }}
                                                                     target="_blank"
                                                                     rel="noopener"
                                                                  >
                                                                     {item.PartyName}
                                                                  </Link>
                                                               </Typography>
                                                            )}
                                                            {item.PartyName === 'Independent' && (
                                                               <Typography
                                                                  sx={{ display: 'inline' }}
                                                                  component="span"
                                                                  variant="body2"
                                                                  color="text.primary"
                                                               >
                                                                  {item.PartyName}
                                                               </Typography>
                                                            )}
                                                            <>
                                                               <br />
                                                               {<strong> Alliances: </strong>}
                                                               {item.Alliances.map((itemAl, index) => (
                                                                  <Fragment key={itemAl.Alliance_id}>
                                                                     {index + 1}.
                                                                     <Link
                                                                        key={itemAl.Alliance_id}
                                                                        href={`/alliance/${itemAl.Name}/${itemAl.Alliance_id}`}
                                                                        sx={{
                                                                           mr: 1,
                                                                           ml: 0.5,
                                                                           textDecoration: 'none !important',
                                                                        }}
                                                                        rel="noreferrer"
                                                                        target="_blank"
                                                                     >
                                                                        {itemAl.Abbreviation}
                                                                     </Link>
                                                                  </Fragment>
                                                               ))}
                                                            </>
                                                            <>
                                                               <br />
                                                               {<strong> Status: </strong>}
                                                               {item.Announced !== '' && (
                                                                  <Typography
                                                                     sx={{ display: 'inline' }}
                                                                     component="span"
                                                                     variant="body2"
                                                                     color="#03a9f4"
                                                                  >
                                                                     {item.AnnouncedDate !== ''
                                                                        ? `Announced by party on ${moment(item.AnnouncedDate.toDate()).format('Do MMMM YYYY')} `
                                                                        : `Announced by party. `}
                                                                  </Typography>
                                                               )}
                                                               {item.Filed !== '' && (
                                                                  <Typography
                                                                     sx={{ display: 'inline' }}
                                                                     component="span"
                                                                     variant="body2"
                                                                     color="#ba68c8"
                                                                  >
                                                                     {item.FiledDate !== ''
                                                                        ? `Candidature filed on ${moment(item.FiledDate.toDate()).format('Do MMMM YYYY')} `
                                                                        : `Candidature filed. `}
                                                                  </Typography>
                                                               )}
                                                               {item.Accepted !== '' && (
                                                                  <Typography
                                                                     sx={{ display: 'inline' }}
                                                                     component="span"
                                                                     variant="body2"
                                                                     color="#4caf50"
                                                                  >
                                                                     {item.AcceptedDate !== ''
                                                                        ? `Candidature accepted by ECI on ${moment(item.AcceptedDate.toDate()).format('Do MMMM YYYY')} `
                                                                        : `Candidature accepted by ECI. `}
                                                                  </Typography>
                                                               )}
                                                               {item.Rejected !== '' && (
                                                                  <Typography
                                                                     sx={{ display: 'inline' }}
                                                                     component="span"
                                                                     variant="body2"
                                                                     color="#d32f2f"
                                                                  >
                                                                     {item.RejectedDate !== ''
                                                                        ? `Candidature rejected by ECI on ${moment(item.RejectedDate.toDate()).format('Do MMMM YYYY')} `
                                                                        : `Candidature rejected by ECI. `}
                                                                  </Typography>
                                                               )}
                                                               {item.Withdrawn !== '' && (
                                                                  <Typography
                                                                     sx={{ display: 'inline' }}
                                                                     component="span"
                                                                     variant="body2"
                                                                     color="#ff9800"
                                                                  >
                                                                     {item.WithdrawnDate !== ''
                                                                        ? `Candidature Withdrawn by candidate on ${moment(item.WithdrawnDate.toDate()).format('Do MMMM YYYY')} `
                                                                        : `Candidature Withdrawn by candidate. `}
                                                                  </Typography>
                                                               )}
                                                               {item.AnnounceCancelled !== '' && (
                                                                  <Typography
                                                                     sx={{ display: 'inline' }}
                                                                     component="span"
                                                                     variant="body2"
                                                                     color="#d32f2f"
                                                                  >
                                                                     {item.AnnounceCancelledDate !== ''
                                                                        ? `Announcement cancelled by party on ${moment(item.AnnounceCancelledDate.toDate()).format('Do MMMM YYYY')} `
                                                                        : `Announcement cancelled by party. `}
                                                                  </Typography>
                                                               )}
                                                               {(isDataEditor || isSuper) && (
                                                                  <IconButton
                                                                     edge="end"
                                                                     aria-label="edit"
                                                                     onClick={(e) => {
                                                                        handleOpenModalStatus3(
                                                                           e,
                                                                           item.Candidate_id,
                                                                           item.Name,
                                                                        )
                                                                     }}
                                                                     sx={{ ml: 1, height: '15px', width: '15px' }}
                                                                  >
                                                                     <EditIcon sx={{ height: '15px', width: '15px' }} />
                                                                  </IconButton>
                                                               )}
                                                            </>
                                                            {(isDataEditor || isSuper) && (
                                                               <>
                                                                  <br />
                                                                  {<strong> Transfer to another constituency: </strong>}

                                                                  <IconButton
                                                                     edge="end"
                                                                     aria-label="edit"
                                                                     onClick={(e) => {
                                                                        handleOpenModalConstiTransfer(e, item)
                                                                     }}
                                                                     sx={{ ml: 1, height: '15px', width: '15px' }}
                                                                  >
                                                                     <EditIcon sx={{ height: '15px', width: '15px' }} />
                                                                  </IconButton>
                                                               </>
                                                            )}
                                                         </>
                                                      </>
                                                   }
                                                />
                                             </ListItem>
                                             <Divider />
                                          </div>
                                       ))}
                                 </List>
                                 {candidatesListWaiting &&
                                    candidatesListWaiting.length < 1 &&
                                    selectedConstiId !== '' &&
                                    candidatesListAccepted.length < 1 &&
                                    candidatesListWithdrawn.length < 1 &&
                                    candidatesListRejected.length < 1 && (
                                       <Box>
                                          <Alert
                                             variant="outlined"
                                             severity="warning"
                                             sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
                                          >
                                             <AlertTitle sx={{ fontSize: 12 }}>
                                                List of candidates for{' '}
                                                <strong>
                                                   <u>{selectedConsti.Name}</u>
                                                </strong>{' '}
                                                to be available soon. <br />{' '}
                                                <strong>
                                                   <u>{selectedConsti.NameHindi}</u>
                                                </strong>{' '}
                                                के लिए प्रत्याशियों की सूची शीघ्र ही उपलब्ध होगी |
                                             </AlertTitle>
                                          </Alert>
                                       </Box>
                                    )}
                              </Box>
                              <Box
                                 sx={{
                                    p: 1,
                                    color: '#ffffff',
                                    fontWeight: 700,
                                    backgroundColor: '#4caf50',
                                    mr: 1,
                                 }}
                              >
                                 Accepted:
                              </Box>
                              <Box sx={{ mr: 2 }}>
                                 <List dense>
                                    {candidatesListAccepted &&
                                       candidatesListAccepted.length > 0 &&
                                       candidatesListAccepted.map((item, index) => (
                                          <div key={item.Candidate_id}>
                                             <ElecCandiCompAccepted
                                                item={item}
                                                handleDelete={handleDelete}
                                                handleOpenModalStatus3={handleOpenModalStatus3}
                                                isDataEditor={isDataEditor}
                                                isSuper={isSuper}
                                                index={index}
                                                handleSendToLegis={handleSendToLegis}
                                                handleSendToNeta={handleSendToNeta}
                                                handleOpenModalConstiTransfer={handleOpenModalConstiTransfer}
                                                handleOpenModalWinner={handleOpenModalWinner}
                                                handleOpenModalVotes={handleOpenModalVotes}
                                                elec={elec}
                                             ></ElecCandiCompAccepted>
                                             {/* <ListItem
                                                sx={{ pl: 0, ml: 0 }}
                                                key={item.Candidate_id}
                                                secondaryAction={
                                                   <Box sx={{ ml: 'auto' }}>
                                                      {isSuper && (
                                                         <IconButton
                                                            edge="end"
                                                            aria-label="delete"
                                                            onClick={(e) => {
                                                               handleDelete(e, item)
                                                            }}
                                                            sx={{ mr: 1, height: '25px', width: '25px' }}
                                                         >
                                                            <DeleteIcon fontSize="small" />
                                                         </IconButton>
                                                      )}
                                                   </Box>
                                                }
                                             >
                                                <Link
                                                   href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                                                   sx={{ textDecoration: 'none !important' }}
                                                   target="_blank"
                                                   rel="noopener"
                                                >
                                                   <ListItemAvatar sx={{ display: 'flex', flexDirection: 'column' }}>
                                                      <Typography
                                                         component="span"
                                                         variant="body2"
                                                         sx={{
                                                            color: 'text.primary',
                                                            display: 'inline',
                                                            fontWeight: 600,
                                                            mb: 1,
                                                         }}
                                                      >
                                                         {index + 1}.
                                                      </Typography>
                                                      {item.Name &&
                                                         (countWordsUsingReplace(item.Name) > 1 ? (
                                                            <Avatar
                                                               {...stringAvatar80(item.Name)}
                                                               alt="Travis Howard"
                                                               src={item.Image}
                                                            />
                                                         ) : item.Name !== 'Zznota' ? (
                                                            <Avatar
                                                               {...stringAvatar80Single(item.Name)}
                                                               alt="Travis Howard"
                                                               src={item.Image}
                                                            />
                                                         ) : (
                                                            <Avatar
                                                               sx={{
                                                                  width: 80,
                                                                  height: 80,
                                                                  backgroundColor: 'Yellow',
                                                               }}
                                                               src={NOTA}
                                                            />
                                                         ))}
                                                   </ListItemAvatar>
                                                </Link>

                                                <ListItemText
                                                   sx={{ ml: 1 }}
                                                   secondaryTypographyProps={{ align: 'left', whiteSpace: 'pre-line' }}
                                                   primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                                                   primary={
                                                      <>
                                                         {item.Name !== 'Zznota' && (
                                                            <>
                                                               <Link
                                                                  underline="hover"
                                                                  href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                                                                  sx={{ textDecoration: 'none !important' }}
                                                                  target="_blank"
                                                                  rel="noopener"
                                                               >
                                                                  <strong>{item.Name.toUpperCase()}</strong>
                                                               </Link>{' '}
                                                               -{' '}
                                                               {item.NameLocal && (
                                                                  <Link
                                                                     href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                                                                     className={styles.overrideUnderline}
                                                                     sx={{ textDecoration: 'none !important' }}
                                                                     target="_blank"
                                                                     rel="noopener"
                                                                  >
                                                                     {item.NameLocal}
                                                                  </Link>
                                                               )}
                                                               {'  '}-{' '}
                                                               <Link
                                                                  underline="none"
                                                                  href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                                                                  color="warning"
                                                                  sx={{ textDecoration: 'none !important' }}
                                                                  target="_blank"
                                                                  rel="noopener"
                                                               >
                                                                  {item.NameHindi}
                                                                  {'  '}
                                                               </Link>
                                                               ({item.Age}y)
                                                            </>
                                                         )}
                                                         {item.Name === 'Zznota' && (
                                                            <>
                                                               <strong>
                                                                  NOTA
                                                                  <br />
                                                                  {item.NameLocal}
                                                                  <br />
                                                                  {item.NameHindi}
                                                               </strong>
                                                            </>
                                                         )}
                                                      </>
                                                   }
                                                   secondary={
                                                      <>
                                                         <>
                                                            {item.PartyFlag && (
                                                               <Link
                                                                  href={`/political-party/${item.PartyName}/${item.Party_id}`}
                                                                  target="_blank"
                                                                  rel="noopener"
                                                               >
                                                                  <img src={item.PartyFlag} className={styles.imgX2} />
                                                               </Link>
                                                            )}

                                                            {item.PartySymbol && (
                                                               <img src={item.PartySymbol} className={styles.imgX3} />
                                                            )}
                                                            {item.AllottedSymbol && (
                                                               <img
                                                                  src={item.AllottedSymbol}
                                                                  className={styles.imgX3}
                                                               />
                                                            )}
                                                         </>
                                                         <>
                                                            {item.Name !== 'Zznota' && (
                                                               <>
                                                                  {<strong> Party: </strong>}
                                                                  {item.PartyName !== 'Independent' && (
                                                                     <Typography
                                                                        sx={{ display: 'inline' }}
                                                                        component="span"
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                     >
                                                                        <Link
                                                                           href={`/political-party/${item.PartyName}/${item.Party_id}`}
                                                                           underline="none"
                                                                           sx={{ textDecoration: 'none !important' }}
                                                                           target="_blank"
                                                                           rel="noopener"
                                                                        >
                                                                           {item.PartyName}
                                                                        </Link>
                                                                     </Typography>
                                                                  )}
                                                                  {item.PartyName === 'Independent' && (
                                                                     <Typography
                                                                        sx={{ display: 'inline' }}
                                                                        component="span"
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                     >
                                                                        {item.PartyName}
                                                                     </Typography>
                                                                  )}
                                                                  <Box
                                                                     sx={{ flexWrap: 'wrap', wordWrap: 'break-word' }}
                                                                  >
                                                                     {<strong> Alliances: </strong>}
                                                                     {item.Alliances.map((itemAl, index) => (
                                                                        <Fragment key={itemAl.Alliance_id}>
                                                                           {index + 1}.
                                                                           <Link
                                                                              key={itemAl.Alliance_id}
                                                                              href={`/alliance/${itemAl.Name}/${itemAl.Alliance_id}`}
                                                                              sx={{
                                                                                 mr: 1,
                                                                                 ml: 0.5,
                                                                                 textDecoration: 'none !important',
                                                                              }}
                                                                              rel="noreferrer"
                                                                              target="_blank"
                                                                           >
                                                                              {itemAl.Abbreviation}
                                                                           </Link>
                                                                        </Fragment>
                                                                     ))}
                                                                  </Box>

                                                                  <>
                                                                     {<strong> Status: </strong>}
                                                                     {item.Announced !== '' && (
                                                                        <Typography
                                                                           sx={{ display: 'inline' }}
                                                                           component="span"
                                                                           variant="body2"
                                                                           color="#03a9f4"
                                                                        >
                                                                           {item.AnnouncedDate !== ''
                                                                              ? `Announced by party on ${moment(item.AnnouncedDate.toDate()).format('Do MMMM YYYY')} `
                                                                              : `Announced by party. `}
                                                                        </Typography>
                                                                     )}
                                                                     {item.Filed !== '' && (
                                                                        <Typography
                                                                           sx={{ display: 'inline' }}
                                                                           component="span"
                                                                           variant="body2"
                                                                           color="#ba68c8"
                                                                        >
                                                                           {item.FiledDate !== ''
                                                                              ? `Candidature filed on ${moment(item.FiledDate.toDate()).format('Do MMMM YYYY')} `
                                                                              : `Candidature filed. `}
                                                                        </Typography>
                                                                     )}
                                                                     {item.Accepted !== '' && (
                                                                        <Typography
                                                                           sx={{ display: 'inline' }}
                                                                           component="span"
                                                                           variant="body2"
                                                                           color="#4caf50"
                                                                        >
                                                                           {item.AcceptedDate !== ''
                                                                              ? `Candidature accepted by ECI on ${moment(item.AcceptedDate.toDate()).format('Do MMMM YYYY')} `
                                                                              : `Candidature accepted by ECI. `}
                                                                        </Typography>
                                                                     )}
                                                                     {item.Rejected !== '' && (
                                                                        <Typography
                                                                           sx={{ display: 'inline' }}
                                                                           component="span"
                                                                           variant="body2"
                                                                           color="#d32f2f"
                                                                        >
                                                                           {item.RejectedDate !== ''
                                                                              ? `Candidature rejected by ECI on ${moment(item.RejectedDate.toDate()).format('Do MMMM YYYY')} `
                                                                              : `Candidature rejected by ECI. `}
                                                                        </Typography>
                                                                     )}
                                                                     {item.Withdrawn !== '' && (
                                                                        <Typography
                                                                           sx={{ display: 'inline' }}
                                                                           component="span"
                                                                           variant="body2"
                                                                           color="#ff9800"
                                                                        >
                                                                           {item.WithdrawnDate !== ''
                                                                              ? `Candidature Withdrawn by candidate on ${moment(item.WithdrawnDate.toDate()).format('Do MMMM YYYY')} `
                                                                              : `Candidature Withdrawn by candidate. `}
                                                                        </Typography>
                                                                     )}
                                                                     {item.AnnounceCancelled !== '' && (
                                                                        <Typography
                                                                           sx={{ display: 'inline' }}
                                                                           component="span"
                                                                           variant="body2"
                                                                           color="#d32f2f"
                                                                        >
                                                                           {item.AnnounceCancelledDate !== ''
                                                                              ? `Announcement cancelled by party on ${moment(item.AnnounceCancelledDate.toDate()).format('Do MMMM YYYY')} `
                                                                              : `Announcement cancelled by party. `}
                                                                        </Typography>
                                                                     )}

                                                                     {(isDataEditor || isSuper) && (
                                                                        <IconButton
                                                                           edge="end"
                                                                           aria-label="edit"
                                                                           onClick={(e) => {
                                                                              handleOpenModalStatus3(e, item)
                                                                           }}
                                                                           sx={{
                                                                              ml: 1,
                                                                              height: '15px',
                                                                              width: '15px',
                                                                           }}
                                                                        >
                                                                           <EditIcon
                                                                              sx={{ height: '15px', width: '15px' }}
                                                                           />
                                                                        </IconButton>
                                                                     )}
                                                                     <br />
                                                                  </>
                                                               </>
                                                            )}
                                                            <>
                                                               {<strong> Votes: </strong>}
                                                               <Typography
                                                                  sx={
                                                                     ({
                                                                        display: 'inline',
                                                                     },
                                                                     item.Winner
                                                                        ? { color: '#4CAF50' }
                                                                        : { color: '#DC143C' })
                                                                  }
                                                                  component="span"
                                                                  variant="body2"
                                                               >
                                                                  <strong>{item.Votes}</strong>
                                                               </Typography>

                                                               {(isDataEditor || isSuper) && (
                                                                  <IconButton
                                                                     edge="end"
                                                                     aria-label="edit"
                                                                     onClick={(e) => {
                                                                        handleOpenModalVotes(e, item)
                                                                     }}
                                                                     sx={{ ml: 1, height: '15px', width: '15px' }}
                                                                  >
                                                                     <EditIcon sx={{ height: '15px', width: '15px' }} />
                                                                  </IconButton>
                                                               )}
                                                               <br />
                                                            </>
                                                            {item.Name !== 'Zznota' && (
                                                               <>
                                                                  {item.Winner === 'true' && (
                                                                     <img src={winner} className={styles.imgXX} />
                                                                  )}
                                                                  {(isDataEditor || isSuper) && (
                                                                     <>
                                                                        Declare Winner
                                                                        <IconButton
                                                                           edge="end"
                                                                           aria-label="edit"
                                                                           onClick={(e) => {
                                                                              handleOpenModalWinner(e, item)
                                                                           }}
                                                                           sx={{ ml: 1, height: '15px', width: '15px' }}
                                                                        >
                                                                           <EditIcon
                                                                              sx={{ height: '15px', width: '15px' }}
                                                                           />
                                                                        </IconButton>
                                                                     </>
                                                                  )}
                                                                  <br />
                                                               </>
                                                            )}
                                                            {item.Winner === 'true' && (isDataEditor || isSuper) && (
                                                               <>
                                                                  {<strong> Send to legislature: </strong>}

                                                                  <IconButton
                                                                     edge="end"
                                                                     aria-label="sendToLegis"
                                                                     onClick={(e) => {
                                                                        handleSendToLegis(e, item)
                                                                     }}
                                                                     sx={{ ml: 1, height: '20px', width: '20px' }}
                                                                  >
                                                                     <DoubleArrowIcon
                                                                        sx={{
                                                                           height: '20px',
                                                                           width: '20px',
                                                                           color: '#03a9f4',
                                                                        }}
                                                                     />
                                                                  </IconButton>

                                                                  <br />
                                                               </>
                                                            )}
                                                            {item.Name !== 'Zznota' &&
                                                               elec.ResultsDeclared &&
                                                               (isDataEditor || isSuper) && (
                                                                  <>
                                                                     {
                                                                        <strong>
                                                                           {' '}
                                                                           Send to constituency as leader:{' '}
                                                                        </strong>
                                                                     }

                                                                     <IconButton
                                                                        edge="end"
                                                                        aria-label="sendToNeta"
                                                                        onClick={(e) => {
                                                                           handleSendToNeta(e, item)
                                                                        }}
                                                                        sx={{ ml: 1, height: '30px', width: '30px' }}
                                                                     >
                                                                        <DoubleArrowIcon
                                                                           sx={{
                                                                              height: '20px',
                                                                              width: '20px',
                                                                              color: '#03a9f4',
                                                                           }}
                                                                        />
                                                                     </IconButton>

                                                                     <br />
                                                                  </>
                                                               )}
                                                            {item.Name !== 'Zznota' && (isDataEditor || isSuper) && (
                                                               <>
                                                                  <br />
                                                                  {<strong> Transfer to another constituency: </strong>}

                                                                  <IconButton
                                                                     edge="end"
                                                                     aria-label="edit"
                                                                     onClick={(e) => {
                                                                        handleOpenModalConstiTransfer(e, item)
                                                                     }}
                                                                     sx={{ ml: 1, height: '15px', width: '15px' }}
                                                                  >
                                                                     <EditIcon sx={{ height: '15px', width: '15px' }} />
                                                                  </IconButton>
                                                               </>
                                                            )}
                                                            {item.Name === 'Zznota' && (
                                                               <Box
                                                                  sx={{
                                                                     border: 1,
                                                                     borderColor: 'Crimson',
                                                                     color: 'Crimson',
                                                                     fontSize: 12,
                                                                     p: 1,
                                                                  }}
                                                               >
                                                                  <ReadMore>
                                                                     NOTA vote has no role in the loss / win of any real
                                                                     candidate. If a real candidate gets 1000 votes as
                                                                     highest among the real candidates and NOTA gets
                                                                     1111 votes, the real candidate with 1000 votes is
                                                                     declared winner.
                                                                  </ReadMore>
                                                                  <Divider sx={{ my: 0.5 }} />
                                                                  <ReadMore>
                                                                     NOTA वोट की किसी भी वास्तविक उम्मीदवार की हार / जीत
                                                                     में कोई भूमिका नहीं होती। यदि किसी वास्तविक
                                                                     उम्मीदवार को वास्तविक उम्मीदवारों में सबसे अधिक
                                                                     1000 वोट मिलते हैं और NOTA को 1111 वोट मिलते हैं,
                                                                     तो 1000 वोट पाने वाले वास्तविक उम्मीदवार को विजेता
                                                                     घोषित किया जाता है।
                                                                  </ReadMore>
                                                               </Box>
                                                            )}
                                                         </>
                                                      </>
                                                   }
                                                />
                                             </ListItem> */}
                                             <Divider />
                                          </div>
                                       ))}
                                 </List>
                              </Box>
                              <Box
                                 sx={{
                                    p: 1,
                                    mt: 0.5,
                                    color: '#ffffff',
                                    fontWeight: 700,
                                    backgroundColor: '#ff9800',
                                    mr: 1,
                                 }}
                              >
                                 Withdrawn:
                              </Box>
                              <Box sx={{ mr: 2 }}>
                                 <List dense>
                                    {candidatesListWithdrawn &&
                                       candidatesListWithdrawn.length > 0 &&
                                       candidatesListWithdrawn.map((item, index) => (
                                          <div key={item.Candidate_id}>
                                             <ElecCandiCompWithdrawn
                                                item={item}
                                                handleDelete={handleDelete}
                                                handleOpenModalStatus3={handleOpenModalStatus3}
                                                isDataEditor={isDataEditor}
                                                isSuper={isSuper}
                                                index={index}
                                                handleSendToNeta={handleSendToNeta}
                                                handleOpenModalConstiTransfer={handleOpenModalConstiTransfer}
                                                elec={elec}
                                             ></ElecCandiCompWithdrawn>
                                             {/* <ListItem
                                                sx={{ pl: 0, ml: 0 }}
                                                key={item.Candidate_id}
                                                secondaryAction={
                                                   <Box sx={{ ml: 'auto' }}>
                                                      {isSuper && (
                                                         <IconButton
                                                            edge="end"
                                                            aria-label="delete"
                                                            onClick={(e) => {
                                                               handleDelete(e, item)
                                                            }}
                                                            sx={{ mr: 1, height: '25px', width: '25px' }}
                                                         >
                                                            <DeleteIcon fontSize="small" />
                                                         </IconButton>
                                                      )}
                                                   </Box>
                                                }
                                             >
                                                <Link
                                                   href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                                                   sx={{ textDecoration: 'none !important' }}
                                                   target="_blank"
                                                   rel="noopener"
                                                >
                                                   <ListItemAvatar sx={{ display: 'flex', flexDirection: 'column' }}>
                                                      <Typography
                                                         component="span"
                                                         variant="body2"
                                                         sx={{
                                                            color: 'text.primary',
                                                            display: 'inline',
                                                            fontWeight: 600,
                                                            mb: 1,
                                                         }}
                                                      >
                                                         {index + 1}.
                                                      </Typography>
                                                      {item.Name &&
                                                         (countWordsUsingReplace(item.Name) > 1 ? (
                                                            <Avatar
                                                               {...stringAvatar80(item.Name)}
                                                               alt="Travis Howard"
                                                               src={item.Image}
                                                            />
                                                         ) : (
                                                            <Avatar
                                                               {...stringAvatar80Single(item.Name)}
                                                               alt="Travis Howard"
                                                               src={item.Image}
                                                            />
                                                         ))}
                                                   </ListItemAvatar>
                                                </Link>

                                                <ListItemText
                                                   sx={{ ml: 1 }}
                                                   secondaryTypographyProps={{ align: 'left', whiteSpace: 'pre-line' }}
                                                   primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                                                   primary={
                                                      <>
                                                         <Link
                                                            underline="hover"
                                                            href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                                                            sx={{ textDecoration: 'none !important' }}
                                                            target="_blank"
                                                            rel="noopener"
                                                         >
                                                            <strong>{item.Name.toUpperCase()}</strong>
                                                         </Link>{' '}
                                                         -{' '}
                                                         {item.NameLocal && (
                                                            <Link
                                                               href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                                                               className={styles.overrideUnderline}
                                                               sx={{ textDecoration: 'none !important' }}
                                                               target="_blank"
                                                               rel="noopener"
                                                            >
                                                               {item.NameLocal}
                                                            </Link>
                                                         )}
                                                         {'  '}-{' '}
                                                         <Link
                                                            underline="none"
                                                            href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                                                            color="warning"
                                                            sx={{ textDecoration: 'none !important' }}
                                                            target="_blank"
                                                            rel="noopener"
                                                         >
                                                            {item.NameHindi}
                                                            {'  '}
                                                         </Link>
                                                         ({item.Age}y)
                                                      </>
                                                   }
                                                   component={'span'}
                                                   secondary={
                                                      <>
                                                         <>
                                                            <Link
                                                               href={`/political-party/${item.PartyName}/${item.Party_id}`}
                                                               target="_blank"
                                                               rel="noopener"
                                                            >
                                                               <img src={item.PartyFlag} className={styles.imgX2} />
                                                            </Link>
                                                            {item.PartySymbol ? (
                                                               <img src={item.PartySymbol} className={styles.imgX3} />
                                                            ) : (
                                                               <img
                                                                  src={item.AllottedSymbol}
                                                                  className={styles.imgX3}
                                                               />
                                                            )}
                                                         </>
                                                         <>
                                                            {<strong> Party: </strong>}
                                                            {item.PartyName !== 'Independent' && (
                                                               <Typography
                                                                  sx={{ display: 'inline' }}
                                                                  component="span"
                                                                  variant="body2"
                                                                  color="text.primary"
                                                               >
                                                                  <Link
                                                                     href={`/political-party/${item.PartyName}/${item.Party_id}`}
                                                                     underline="none"
                                                                     sx={{ textDecoration: 'none !important' }}
                                                                     target="_blank"
                                                                     rel="noopener"
                                                                  >
                                                                     {item.PartyName}
                                                                  </Link>
                                                               </Typography>
                                                            )}
                                                            {item.PartyName === 'Independent' && (
                                                               <Typography
                                                                  sx={{ display: 'inline' }}
                                                                  component="span"
                                                                  variant="body2"
                                                                  color="text.primary"
                                                               >
                                                                  {item.PartyName}
                                                               </Typography>
                                                            )}
                                                            <>
                                                               <br />
                                                               {<strong> Alliances: </strong>}
                                                               {item.Alliances.map((itemAl, index) => (
                                                                  <Fragment key={itemAl.Alliance_id}>
                                                                     {index + 1}.
                                                                     <Link
                                                                        key={itemAl.Alliance_id}
                                                                        href={`/alliance/${itemAl.Name}/${itemAl.Alliance_id}`}
                                                                        sx={{
                                                                           mr: 1,
                                                                           ml: 0.5,
                                                                           textDecoration: 'none !important',
                                                                        }}
                                                                        rel="noreferrer"
                                                                        target="_blank"
                                                                     >
                                                                        {itemAl.Abbreviation}
                                                                     </Link>
                                                                  </Fragment>
                                                               ))}
                                                            </>
                                                            <>
                                                               <br />
                                                               {<strong> Status: </strong>}
                                                               {item.Announced !== '' && (
                                                                  <Typography
                                                                     sx={{ display: 'inline' }}
                                                                     component="span"
                                                                     variant="body2"
                                                                     color="#03a9f4"
                                                                  >
                                                                     {item.AnnouncedDate !== ''
                                                                        ? `Announced by party on ${moment(item.AnnouncedDate.toDate()).format('Do MMMM YYYY')} `
                                                                        : `Announced by party. `}
                                                                  </Typography>
                                                               )}
                                                               {item.Filed !== '' && (
                                                                  <Typography
                                                                     sx={{ display: 'inline' }}
                                                                     component="span"
                                                                     variant="body2"
                                                                     color="#ba68c8"
                                                                  >
                                                                     {item.FiledDate !== ''
                                                                        ? `Candidature filed on ${moment(item.FiledDate.toDate()).format('Do MMMM YYYY')} `
                                                                        : `Candidature filed. `}
                                                                  </Typography>
                                                               )}
                                                               {item.Accepted !== '' && (
                                                                  <Typography
                                                                     sx={{ display: 'inline' }}
                                                                     component="span"
                                                                     variant="body2"
                                                                     color="#4caf50"
                                                                  >
                                                                     {item.AcceptedDate !== ''
                                                                        ? `Candidature accepted by ECI on ${moment(item.AcceptedDate.toDate()).format('Do MMMM YYYY')} `
                                                                        : `Candidature accepted by ECI. `}
                                                                  </Typography>
                                                               )}
                                                               {item.Rejected !== '' && (
                                                                  <Typography
                                                                     sx={{ display: 'inline' }}
                                                                     component="span"
                                                                     variant="body2"
                                                                     color="#d32f2f"
                                                                  >
                                                                     {item.RejectedDate !== ''
                                                                        ? `Candidature rejected by ECI on ${moment(item.RejectedDate.toDate()).format('Do MMMM YYYY')} `
                                                                        : `Candidature rejected by ECI. `}
                                                                  </Typography>
                                                               )}
                                                               {item.Withdrawn !== '' && (
                                                                  <Typography
                                                                     sx={{ display: 'inline' }}
                                                                     component="span"
                                                                     variant="body2"
                                                                     color="#ff9800"
                                                                  >
                                                                     {item.WithdrawnDate !== ''
                                                                        ? `Candidature Withdrawn by candidate on ${moment(item.WithdrawnDate.toDate()).format('Do MMMM YYYY')} `
                                                                        : `Candidature Withdrawn by candidate. `}
                                                                  </Typography>
                                                               )}
                                                               {item.AnnounceCancelled !== '' && (
                                                                  <Typography
                                                                     sx={{ display: 'inline' }}
                                                                     component="span"
                                                                     variant="body2"
                                                                     color="#d32f2f"
                                                                  >
                                                                     {item.AnnounceCancelledDate !== ''
                                                                        ? `Announcement cancelled by party on ${moment(item.AnnounceCancelledDate.toDate()).format('Do MMMM YYYY')} `
                                                                        : `Announcement cancelled by party. `}
                                                                  </Typography>
                                                               )}

                                                               {(isDataEditor || isSuper) && (
                                                                  <IconButton
                                                                     edge="end"
                                                                     aria-label="edit"
                                                                     onClick={(e) => {
                                                                        handleOpenModalStatus3(e, item)
                                                                     }}
                                                                     sx={{ ml: 1, height: '15px', width: '15px' }}
                                                                  >
                                                                     <EditIcon sx={{ height: '15px', width: '15px' }} />
                                                                  </IconButton>
                                                               )}
                                                               <br />
                                                            </>
                                                            {(isDataEditor || isSuper) && (
                                                               <>
                                                                  <br />
                                                                  {<strong> Transfer to another constituency: </strong>}

                                                                  <IconButton
                                                                     edge="end"
                                                                     aria-label="edit"
                                                                     onClick={(e) => {
                                                                        handleOpenModalConstiTransfer(e, item)
                                                                     }}
                                                                     sx={{ ml: 1, height: '15px', width: '15px' }}
                                                                  >
                                                                     <EditIcon sx={{ height: '15px', width: '15px' }} />
                                                                  </IconButton>
                                                               </>
                                                            )}
                                                         </>
                                                      </>
                                                   }
                                                />
                                             </ListItem> */}
                                             <Divider />
                                          </div>
                                       ))}
                                 </List>
                              </Box>
                              <Box
                                 sx={{
                                    p: 1,
                                    mt: 0.5,
                                    backgroundColor: '#f44336',
                                    color: '#ffffff',
                                    fontWeight: 700,
                                    mr: 1,
                                 }}
                              >
                                 Rejected / Refused / Announcement Cancelled:
                              </Box>
                              <Box sx={{ mr: 2 }}>
                                 <List dense>
                                    {candidatesListRejected &&
                                       candidatesListRejected.length > 0 &&
                                       candidatesListRejected.map((item, index) => (
                                          <div key={item.Candidate_id}>
                                             <ElecCandiComp
                                                item={item}
                                                handleDelete={handleDelete}
                                                handleOpenModalStatus3={handleOpenModalStatus3}
                                                isDataEditor={isDataEditor}
                                                isSuper={isSuper}
                                                index={index}
                                             ></ElecCandiComp>
                                             {/* <ListItem
                                                sx={{ pl: 0, ml: 0 }}
                                                key={item.Candidate_id}
                                                secondaryAction={
                                                   <Box sx={{ ml: 'auto' }}>
                                                      {isSuper && (
                                                         <IconButton
                                                            edge="end"
                                                            aria-label="delete"
                                                            onClick={(e) => {
                                                               handleDelete(e, item)
                                                            }}
                                                            sx={{ mr: 1, height: '25px', width: '25px' }}
                                                         >
                                                            <DeleteIcon fontSize="small" />
                                                         </IconButton>
                                                      )}
                                                   </Box>
                                                }
                                             >
                                                <Link
                                                   href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                                                   sx={{ textDecoration: 'none !important' }}
                                                   target="_blank"
                                                   rel="noopener"
                                                >
                                                   <ListItemAvatar sx={{ display: 'flex', flexDirection: 'column' }}>
                                                      <Typography
                                                         component="span"
                                                         variant="body2"
                                                         sx={{
                                                            color: 'text.primary',
                                                            display: 'inline',
                                                            fontWeight: 600,
                                                            mb: 1,
                                                         }}
                                                      >
                                                         {index + 1}.
                                                      </Typography>
                                                      {item.Name &&
                                                         (countWordsUsingReplace(item.Name) > 1 ? (
                                                            <Avatar
                                                               {...stringAvatar80(item.Name)}
                                                               alt="Travis Howard"
                                                               src={item.Image}
                                                            />
                                                         ) : (
                                                            <Avatar
                                                               {...stringAvatar80Single(item.Name)}
                                                               alt="Travis Howard"
                                                               src={item.Image}
                                                            />
                                                         ))}
                                                   </ListItemAvatar>
                                                </Link>

                                                <ListItemText
                                                   sx={{ ml: 1 }}
                                                   secondaryTypographyProps={{ align: 'left', whiteSpace: 'pre-line' }}
                                                   primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                                                   primary={
                                                      <>
                                                         <Link
                                                            underline="hover"
                                                            href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                                                            sx={{ textDecoration: 'none !important' }}
                                                            target="_blank"
                                                            rel="noopener"
                                                         >
                                                            <strong>{item.Name.toUpperCase()}</strong>
                                                         </Link>{' '}
                                                         -{' '}
                                                         {item.NameLocal && (
                                                            <Link
                                                               href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                                                               className={styles.overrideUnderline}
                                                               sx={{ textDecoration: 'none !important' }}
                                                               target="_blank"
                                                               rel="noopener"
                                                            >
                                                               {item.NameLocal}
                                                            </Link>
                                                         )}
                                                         {'  '}-{' '}
                                                         <Link
                                                            underline="none"
                                                            href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                                                            color="warning"
                                                            sx={{ textDecoration: 'none !important' }}
                                                            target="_blank"
                                                            rel="noopener"
                                                         >
                                                            {item.NameHindi}
                                                            {'  '}
                                                         </Link>
                                                         ({item.Age}y)
                                                      </>
                                                   }
                                                   component={'span'}
                                                   secondary={
                                                      <>
                                                         <>
                                                            <Link
                                                               href={`/political-party/${item.PartyName}/${item.Party_id}`}
                                                               target="_blank"
                                                               rel="noopener"
                                                            >
                                                               <img src={item.PartyFlag} className={styles.imgX2} />
                                                            </Link>
                                                            {item.PartySymbol ? (
                                                               <img src={item.PartySymbol} className={styles.imgX3} />
                                                            ) : (
                                                               <img
                                                                  src={item.AllottedSymbol}
                                                                  className={styles.imgX3}
                                                               />
                                                            )}
                                                         </>
                                                         <>
                                                            {<strong> Party: </strong>}
                                                            {item.PartyName !== 'Independent' && (
                                                               <Typography
                                                                  sx={{ display: 'inline' }}
                                                                  component="span"
                                                                  variant="body2"
                                                                  color="text.primary"
                                                               >
                                                                  <Link
                                                                     href={`/political-party/${item.PartyName}/${item.Party_id}`}
                                                                     underline="none"
                                                                     sx={{ textDecoration: 'none !important' }}
                                                                     target="_blank"
                                                                     rel="noopener"
                                                                  >
                                                                     {item.PartyName}
                                                                  </Link>
                                                               </Typography>
                                                            )}
                                                            {item.PartyName === 'Independent' && (
                                                               <Typography
                                                                  sx={{ display: 'inline' }}
                                                                  component="span"
                                                                  variant="body2"
                                                                  color="text.primary"
                                                               >
                                                                  {item.PartyName}
                                                               </Typography>
                                                            )}
                                                            <>
                                                               <br />
                                                               {<strong> Alliances: </strong>}
                                                               {item.Alliances.map((itemAl, index) => (
                                                                  <Fragment key={itemAl.Alliance_id}>
                                                                     {index + 1}.
                                                                     <Link
                                                                        key={itemAl.Alliance_id}
                                                                        href={`/alliance/${itemAl.Name}/${itemAl.Alliance_id}`}
                                                                        sx={{
                                                                           mr: 1,
                                                                           ml: 0.5,
                                                                           textDecoration: 'none !important',
                                                                        }}
                                                                        rel="noreferrer"
                                                                        target="_blank"
                                                                     >
                                                                        {itemAl.Abbreviation}
                                                                     </Link>
                                                                  </Fragment>
                                                               ))}
                                                            </>
                                                            <>
                                                               <br />
                                                               {<strong> Status: </strong>}
                                                               {item.Announced !== '' && (
                                                                  <Typography
                                                                     sx={{ display: 'inline' }}
                                                                     component="span"
                                                                     variant="body2"
                                                                     color="#03a9f4"
                                                                  >
                                                                     <ArrowCircleRightIcon sx={{ fontSize: 14 }} />
                                                                     {item.AnnouncedDate !== ''
                                                                        ? `Announced by party on ${moment(item.AnnouncedDate.toDate()).format('Do MMMM YYYY')} `
                                                                        : `Announced by party. `}
                                                                  </Typography>
                                                               )}
                                                               {item.Filed !== '' && (
                                                                  <Typography
                                                                     sx={{ display: 'inline' }}
                                                                     component="span"
                                                                     variant="body2"
                                                                     color="#ba68c8"
                                                                  >
                                                                     <ArrowCircleRightIcon sx={{ fontSize: 14 }} />
                                                                     {item.FiledDate !== ''
                                                                        ? `Candidature filed on ${moment(item.FiledDate.toDate()).format('Do MMMM YYYY')} `
                                                                        : `Candidature filed. `}
                                                                  </Typography>
                                                               )}
                                                               {item.Accepted !== '' && (
                                                                  <Typography
                                                                     sx={{ display: 'inline' }}
                                                                     component="span"
                                                                     variant="body2"
                                                                     color="#4caf50"
                                                                  >
                                                                     <ArrowCircleRightIcon sx={{ fontSize: 14 }} />
                                                                     {item.AcceptedDate !== ''
                                                                        ? `Candidature accepted by ECI on ${moment(item.AcceptedDate.toDate()).format('Do MMMM YYYY')} `
                                                                        : `Candidature accepted by ECI. `}
                                                                  </Typography>
                                                               )}
                                                               {item.Rejected !== '' && (
                                                                  <Typography
                                                                     sx={{ display: 'inline' }}
                                                                     component="span"
                                                                     variant="body2"
                                                                     color="#d32f2f"
                                                                  >
                                                                     <ArrowCircleRightIcon sx={{ fontSize: 14 }} />
                                                                     {item.RejectedDate !== ''
                                                                        ? `Candidature rejected by ECI on ${moment(item.RejectedDate.toDate()).format('Do MMMM YYYY')} `
                                                                        : `Candidature rejected by ECI. `}
                                                                  </Typography>
                                                               )}
                                                               {item.Withdrawn !== '' && (
                                                                  <Typography
                                                                     sx={{ display: 'inline' }}
                                                                     component="span"
                                                                     variant="body2"
                                                                     color="#ff9800"
                                                                  >
                                                                     <ArrowCircleRightIcon sx={{ fontSize: 14 }} />
                                                                     {item.WithdrawnDate !== ''
                                                                        ? `Candidature Withdrawn by candidate on ${moment(item.WithdrawnDate.toDate()).format('Do MMMM YYYY')} `
                                                                        : `Candidature Withdrawn by candidate. `}
                                                                  </Typography>
                                                               )}
                                                               {item.AnnounceCancelled !== '' && (
                                                                  <Typography
                                                                     sx={{ display: 'inline' }}
                                                                     component="span"
                                                                     variant="body2"
                                                                     color="#d32f2f"
                                                                  >
                                                                     <ArrowCircleRightIcon sx={{ fontSize: 14 }} />
                                                                     {item.AnnounceCancelledDate !== ''
                                                                        ? `Announcement cancelled by party on ${moment(item.AnnounceCancelledDate.toDate()).format('Do MMMM YYYY')} `
                                                                        : `Announcement cancelled by party. `}
                                                                  </Typography>
                                                               )}
                                                               {item.Refused !== '' && (
                                                                  <Typography
                                                                     sx={{ display: 'inline' }}
                                                                     component="span"
                                                                     variant="body2"
                                                                     color="#d32f2f"
                                                                  >
                                                                     <ArrowCircleRightIcon sx={{ fontSize: 14 }} />
                                                                     {item.RefusedDate !== ''
                                                                        ? `Refused by candidate on ${moment(item.RefusedDate.toDate()).format('Do MMMM YYYY')} `
                                                                        : `Refused by candidate. `}
                                                                  </Typography>
                                                               )}

                                                               {(isDataEditor || isSuper) && (
                                                                  <IconButton
                                                                     edge="end"
                                                                     aria-label="edit"
                                                                     onClick={(e) => {
                                                                        handleOpenModalStatus3(e, item)
                                                                     }}
                                                                     sx={{ ml: 1, height: '15px', width: '15px' }}
                                                                  >
                                                                     <EditIcon sx={{ height: '15px', width: '15px' }} />
                                                                  </IconButton>
                                                               )}
                                                               <br />
                                                            </>
                                                         </>
                                                      </>
                                                   }
                                                />
                                             </ListItem> */}
                                             <Divider />
                                          </div>
                                       ))}
                                 </List>
                              </Box>
                           </Grid2>
                           <Grid2 item xs={4} id="gridItem2">
                              {candidatesListForOnlineSupport && candidatesListForOnlineSupport.length > 0 && (
                                 <Box sx={{ borderRadius: 1, border: 1, borderColor: '#03a9f4' }}>
                                    <Box
                                       sx={{
                                          backgroundColor: '#03a9f4',
                                          whiteSpace: 'pre-line',
                                          textAlign: 'center',
                                          fontWeight: 700,
                                          color: '#FFFFFF',
                                          mt: 1,
                                          cursor: 'pointer',
                                          '&:hover': { backgroundColor: '#01579b' },
                                       }}
                                       onClick={(e) => {
                                          handleOpenModalOnlineSupport(e)
                                       }}
                                    >
                                       Support Online {'\n'} ऑनलाइन समर्थन करें
                                    </Box>
                                    <Box sx={{ display: 'flex', pt: 0.5, pr: 2 }}>
                                       {/* <Button
                                       variant="contained"
                                       sx={{ py: 0, px: 1, minWidth: 0, ml: 'auto', textTransform: 'none' }}
                                       onClick={(e) => {
                                          handleOpenModalOnlineSupport(e)
                                       }}
                                       endIcon={<HowToVoteIcon />}
                                    >
                                       Your Support ...
                                    </Button> */}
                                       <IconButton
                                          edge="end"
                                          aria-label="onlineSupport"
                                          onClick={(e) => {
                                             handleOpenModalOnlineSupport(e)
                                          }}
                                          sx={{
                                             height: '35px',
                                             width: '35px',
                                             backgroundColor: '#03a9f4',
                                             color: '#FFFFFF',
                                             '&:hover': { backgroundColor: '#01579b' },
                                             ml: 'auto',
                                          }}
                                       >
                                          <HowToVoteIcon fontSize="small" />
                                       </IconButton>
                                    </Box>
                                    <Box>
                                       <List dense sx={{ py: 0.5 }}>
                                          {candidatesListForOnlineSupport &&
                                             candidatesListForOnlineSupport.length > 0 &&
                                             candidatesListForOnlineSupport.map((item, index) => (
                                                <Box key={item.Candidate_id}>
                                                   <ListItem sx={{ p: 0, ml: 0 }} key={item.Candidate_id}>
                                                      <ListItemText
                                                         sx={{ ml: 1, fontSize: 13, p: 0 }}
                                                         primaryTypographyProps={{
                                                            whiteSpace: 'pre-line',
                                                            fontSize: 12,
                                                         }}
                                                         secondaryTypographyProps={{
                                                            align: 'left',
                                                         }}
                                                         primary={
                                                            <>
                                                               {index + 1}.{' '}
                                                               {item.Name !== 'Zznota' ? (
                                                                  <strong>{item.Name.toUpperCase()}</strong>
                                                               ) : (
                                                                  <strong>NOTA</strong>
                                                               )}
                                                            </>
                                                         }
                                                         secondary={
                                                            <>
                                                               <>
                                                                  <Typography
                                                                     sx={{ display: 'inline', fontSize: 14, mr: 5 }}
                                                                     component="span"
                                                                     variant="body2"
                                                                     color="text.primary"
                                                                  >
                                                                     {item.NameLocal && <>{item.NameLocal}</>}
                                                                     {!item.NameLocal && <>{item.NameHindi}</>}
                                                                  </Typography>
                                                                  <br />
                                                               </>
                                                               <>
                                                                  <ThumbUpAltIcon
                                                                     sx={{
                                                                        fontSize: 20,
                                                                        verticalAlign: 'middle',
                                                                        mr: 1,
                                                                        borderRadius: 1,
                                                                        cursor: 'pointer',
                                                                        color: '#4caf50',
                                                                        '&:hover': {
                                                                           backgroundColor: '#01579b',
                                                                           color: '#ffffff',
                                                                        },
                                                                     }}
                                                                     onClick={(e) => {
                                                                        handleOpenModalOnlineSupport(e)
                                                                     }}
                                                                  />
                                                                  <Typography
                                                                     sx={{ display: 'inline', mr: 3, color: '#4caf50' }}
                                                                     component="span"
                                                                     variant="body2"
                                                                  >
                                                                     {item.OnlineSupport}
                                                                  </Typography>
                                                                  {/* <ThumbDownAltIcon
                                                                  sx={{
                                                                     fontSize: 20,
                                                                     verticalAlign: 'middle',
                                                                     mr: 1,
                                                                     color: 'Crimson',
                                                                  }}
                                                               />
                                                               <Typography
                                                                  sx={{ display: 'inline', mr: 5, color: 'Crimson' }}
                                                                  component="span"
                                                                  variant="body2"
                                                               >
                                                                  {item.OnlineSupportCancelled}
                                                               </Typography>
                                                               <br /> */}
                                                               </>
                                                            </>
                                                         }
                                                      />
                                                   </ListItem>
                                                   <Divider />
                                                </Box>
                                             ))}
                                       </List>
                                    </Box>
                                 </Box>
                              )}
                           </Grid2>
                        </Grid2>
                     </Box>
                  </Box>
               </Box>
            )}
            <Box id="boxModals">
               <Modal
                  open={openModalAddCandis}
                  onClose={handleCloseModalAddCandis}
                  aria-labelledby="modal-modal-title-addCandis"
                  aria-describedby="modal-modal-description-addCandis"
                  disableScrollLock
                  autoFocus={false}
               >
                  <Box sx={styleModalBox}>
                     <Typography
                        id="modal-modal-title-addCandis"
                        variant="h6"
                        component="h6"
                        align="center"
                        sx={styleModalTypography}
                     >
                        Add candidates for {'\n'} {selectedConsti.Name}
                     </Typography>
                     <AddCandis
                        partiesList={partiesList}
                        lastCandi={lastCandi}
                        uploadingCandis={uploadingCandis}
                        uploadedCandis={uploadedCandis}
                        uploadingFailedCandis={uploadingFailedCandis}
                        handleAddCandis={handleAddCandis}
                     ></AddCandis>
                  </Box>
               </Modal>
               <Modal
                  open={openModalStatus3}
                  onClose={handleCloseModalStatus3}
                  aria-labelledby="modal-modal-title-addStatus3"
                  aria-describedby="modal-modal-description-addStatus3"
                  disableScrollLock
               >
                  <Box sx={styleModalBox}>
                     <Typography
                        id="modal-modal-title-addStatus3"
                        variant="h6"
                        component="h6"
                        align="center"
                        sx={styleModalTypography}
                     >
                        {selectedCandidateName !== '' && `Update status of \n ${selectedCandidateName.toUpperCase()}`}
                     </Typography>
                     <AddCandiStatus3
                        // selectedCandidate={selectedCandidate}
                        uploadingStatus={uploadingStatus}
                        uploadingFailedStatus={uploadingFailedStatus}
                        uploadedStatus={uploadedStatus}
                        handleAddStatus3={handleAddStatus3}
                     ></AddCandiStatus3>
                  </Box>
               </Modal>
               <Modal
                  open={openModalConstiTransfer}
                  onClose={handleCloseModalConstiTransfer}
                  aria-labelledby="modal-modal-title-constiTransfer"
                  aria-describedby="modal-modal-description-constiTransfer"
                  disableScrollLock
               >
                  <Box sx={styleModalBox}>
                     <Typography
                        id="modal-modal-title-addStatus"
                        variant="h6"
                        component="h6"
                        align="center"
                        sx={styleModalTypography}
                     >
                        {selectedCandidate !== '' &&
                           `Transfer ${selectedCandidate.Name.toUpperCase()} \n to another constituency`}
                     </Typography>
                     <ElecCandiConstiTransfer
                        selectedCandidate={selectedCandidate}
                        uploadingConstiTransfer={uploadingConstiTransfer}
                        uploadingFailedConstiTransfer={uploadingFailedConstiTransfer}
                        uploadedConstiTransfer={uploadedConstiTransfer}
                        handleConstiTransfer={handleConstiTransfer}
                        constisList={constisList}
                     ></ElecCandiConstiTransfer>
                  </Box>
               </Modal>

               <Modal
                  open={openModalOnlineSupport}
                  onClose={closeModalOnlineSupport}
                  aria-labelledby="modal-modal-title-addOnlineSupport"
                  aria-describedby="modal-modal-description-addOnlineSupport"
                  disableScrollLock
               >
                  <Box sx={styleModalBox}>
                     {selectedConsti && selectedConsti.Name !== '' && (
                        <Typography
                           id="modal-modal-title-addOnlineSupport"
                           variant="h6"
                           component="h6"
                           align="center"
                           sx={styleModalTypography}
                        >
                           Support a candidate for{' '}
                           <strong>
                              {selectedConsti.Name.toUpperCase()} ({selectedConsti.ConstituencyNumber})
                           </strong>
                           {'\n'}{' '}
                           <strong>
                              {selectedConsti.NameHindi} ({selectedConsti.ConstituencyNumber})
                           </strong>{' '}
                           के लिए एक प्रत्याशी का समर्थन करें
                        </Typography>
                     )}
                     <AddCandiOnlineSupport
                        actionForSupport={actionForSupport}
                        candidatesListForOnlineSupport={candidatesListForOnlineSupport}
                        uploadingOnlineSupport={uploadingOnlineSupport}
                        uploadingFailedOnlineSupport={uploadingFailedOnlineSupport}
                        uploadedOnlineSupport={uploadedOnlineSupport}
                        addOnlineSupport={addOnlineSupport}
                        cancelOnlineSupport={cancelOnlineSupport}
                     ></AddCandiOnlineSupport>
                  </Box>
               </Modal>
               <Dialog
                  open={openDialogNotLoggedIn}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleCloseDialogNotLoggedIn}
                  aria-describedby="alert-dialog-slide-notLoggedIn"
               >
                  <DialogTitle
                     sx={{
                        whiteSpace: 'pre-line',
                        textAlign: 'center',
                     }}
                  >{`Please log in. ${'\n'} कृपया लॉगिन करें।`}</DialogTitle>
                  <DialogContent>
                     <DialogContentText
                        id="alert-dialog-slide-notLoggedIn"
                        sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}
                     >
                        You are not logged-in.
                        <br /> <br />
                        आपने लॉग - इन नहीं किया है।
                     </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                     <Button onClick={handleCloseDialogNotLoggedIn}>Close</Button>
                  </DialogActions>
               </Dialog>
               <Dialog
                  open={openDialogDatePassed}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleCloseDialogDatePassed}
                  aria-describedby="alert-dialog-slide-datePassed"
               >
                  <DialogTitle
                     sx={{
                        whiteSpace: 'pre-line',
                        textAlign: 'center',
                     }}
                  >{`Online Support ${'\n'} ऑनलाइन समर्थन`}</DialogTitle>
                  <DialogContent>
                     <DialogContentText
                        id="alert-dialog-slide-datePassed"
                        sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}
                     >
                        The date for <strong>phase {selectedConsti.PhaseNum}</strong> of <strong>{elec.Name}</strong> is
                        over for this facility.
                        <br /> <br />
                        इस सुविधा के लिए <strong>{elec.NameHindi}</strong> के{' '}
                        <strong>चरण {selectedConsti.PhaseNum}</strong> की तिथि समाप्त हो गई है।
                     </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                     <Button onClick={handleCloseDialogDatePassed}>Close</Button>
                  </DialogActions>
               </Dialog>
               <Dialog
                  open={openDialogHours}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleCloseDialogHours}
                  aria-describedby="alert-dialog-slide-hours"
               >
                  <DialogTitle
                     sx={{
                        whiteSpace: 'pre-line',
                        textAlign: 'center',
                     }}
                  >{`Online Support ${'\n'} ऑनलाइन समर्थन`}</DialogTitle>
                  <DialogContent>
                     <DialogContentText id="alert-dialog-slide-hours" sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}>
                        You supported{' '}
                        {userProfile &&
                           userProfile.LS_Candi_StateConstituency &&
                           `${userProfile.LS_Candi_StateConstituency.CandidateName}`}{' '}
                        on{' '}
                        {userProfile &&
                           userProfile.LS_Candi_StateConstituency &&
                           userProfile.LS_Candi_StateConstituency.CrDt &&
                           `${moment(userProfile.LS_Candi_StateConstituency.CrDt.toDate()).format('Do MMMM YYYY')}`}
                        . You can change your support after expiry of 24 hours.
                        <br />
                        <br />
                        आपने{' '}
                        {userProfile &&
                           userProfile.LS_Candi_StateConstituency &&
                           userProfile.LS_Candi_StateConstituency.CrDt &&
                           `${moment(userProfile.LS_Candi_StateConstituency.CrDt.toDate()).format('Do MMMM YYYY')}`}{' '}
                        को{' '}
                        {userProfile &&
                           userProfile.LS_Candi_StateConstituency &&
                           `${userProfile.LS_Candi_StateConstituency.CandidateNameHindi}`}{' '}
                        का समर्थन किया था| आप 24 घंटे की समाप्ति के बाद अपना समर्थन बदल सकते हैं|
                     </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                     <Button onClick={handleCloseDialogHours}>Close</Button>
                  </DialogActions>
               </Dialog>
               <Dialog
                  open={openDialogSameCandi}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleCloseDialogSameCandi}
                  aria-describedby="alert-dialog-slide-sameCandi"
               >
                  <DialogTitle
                     sx={{
                        whiteSpace: 'pre-line',
                        textAlign: 'center',
                     }}
                  >{`Online Support ${'\n'} ऑनलाइन समर्थन`}</DialogTitle>
                  <DialogContent>
                     <DialogContentText id="alert-dialog-slide-hours" sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}>
                        <Typography sx={{ color: '#4caf50' }}>
                           Thanks for showing support to your favourite candidate.
                        </Typography>
                        <Typography sx={{ color: '#ff9800' }}>
                           You have supported the same candidate you supported last time. Both will be counted as one.
                        </Typography>
                        <strong>
                           Please make sure to go to the polling booth on{' '}
                           {selectedConstiPhaseDate &&
                              `${moment(selectedConstiPhaseDate.toDate()).format('Do MMM YYYY')}`}
                           to caste your vote. This is your national duty.
                        </strong>
                        <br />
                        <br />
                        <Typography sx={{ color: '#4caf50' }}>
                           अपने पसंदीदा प्रत्याशी को समर्थन दिखाने के लिए धन्यवाद।
                        </Typography>
                        <Typography sx={{ color: '#ff9800' }}>
                           आपने उसी प्रत्याशी का समर्थन किया है जिसका आपने पिछली बार समर्थन किया था। दोनों को एक ही माना
                           जाएगा।
                        </Typography>
                        <strong>
                           कृपया{' '}
                           {selectedConstiPhaseDate &&
                              `${moment(selectedConstiPhaseDate.toDate()).format('Do MMM YYYY')}`}{' '}
                           को मतदान केंद्र पर जाकर अपना वोट अवश्य डालें। यह आपका राष्ट्रीय कर्तव्य है।
                        </strong>
                     </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                     <Button onClick={handleCloseDialogSameCandi}>Close</Button>
                  </DialogActions>
               </Dialog>

               <Dialog
                  id="atOtherConsti"
                  open={openDialogAtOtherConsti}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleCloseDialogAtOtherConsti}
                  aria-describedby="alert-dialog-slide-atOtherConsti"
               >
                  <DialogTitle
                     sx={{
                        whiteSpace: 'pre-line',
                        textAlign: 'center',
                     }}
                  >{`Online Support ${'\n'} ऑनलाइन समर्थन`}</DialogTitle>
                  <DialogContent>
                     <DialogContentText
                        id="alert-dialog-slide-atOtherConsti"
                        sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}
                     >
                        <strong>
                           You are attached to {attachedConstiName} constituency at the same level. Hence you cant
                           support candidate of this constituency.
                        </strong>
                        <br />
                        <br />
                        आप इस स्तर पर {attachedConstiName} निर्वाचन क्षेत्र से जुड़े हुए हैं। इसलिए आप इस निर्वाचन
                        क्षेत्र के प्रत्याशी का समर्थन नहीं कर सकते।
                     </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                     <Button onClick={handleCloseDialogAtOtherConsti}>Close</Button>
                  </DialogActions>
               </Dialog>

               <Dialog
                  open={openDialogThanks}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleCloseDialogThanks}
                  aria-describedby="alert-dialog-slide-Thanks"
               >
                  <DialogTitle
                     sx={{
                        whiteSpace: 'pre-line',
                        textAlign: 'center',
                     }}
                  >{`Online Support ${'\n'} ऑनलाइन समर्थन`}</DialogTitle>
                  <DialogContent>
                     {selectedConstiPhaseDate && (
                        <DialogContentText id="alert-dialog-slide-su" sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}>
                           <Typography sx={{ color: '#4caf50' }}>
                              Thanks for showing support to your favourite candidate.
                           </Typography>
                           <strong>
                              Please make sure to go to the polling booth on{' '}
                              {selectedConstiPhaseDate &&
                                 `${moment(selectedConstiPhaseDate.toDate()).format('Do MMM YYYY')}`}{' '}
                              to caste your vote. This is your national duty.
                           </strong>
                           <br /> <br />
                           <Typography sx={{ color: '#4caf50' }}>
                              अपने पसंदीदा प्रत्याशी को समर्थन दिखाने के लिए धन्यवाद।
                           </Typography>
                           {selectedConstiPhaseDate && (
                              <strong>
                                 कृपया{' '}
                                 {selectedConstiPhaseDate &&
                                    `${moment(selectedConstiPhaseDate.toDate()).format('Do MMM YYYY')}`}{' '}
                                 को मतदान केंद्र पर जाकर अपना वोट अवश्य डालें। यह आपका राष्ट्रीय कर्तव्य है।
                              </strong>
                           )}
                           {selectedConstiPhaseDate && (
                              <strong>
                                 कृपया मतदान केंद्र पर जाकर अपना वोट अवश्य डालें। यह आपका राष्ट्रीय कर्तव्य है।
                              </strong>
                           )}
                        </DialogContentText>
                     )}
                  </DialogContent>
                  <DialogActions>
                     <Button onClick={handleCloseDialogThanks}>Close</Button>
                  </DialogActions>
               </Dialog>

               <Modal
                  open={openModalVotes}
                  onClose={handleCloseModalVotes}
                  aria-labelledby="modal-modal-title-addVotes"
                  aria-describedby="modal-modal-description-addVotes"
                  disableScrollLock
                  autoFocus={false}
               >
                  <Box sx={styleModalBox}>
                     <Typography
                        id="modal-modal-title-addVotes"
                        variant="h6"
                        component="h6"
                        align="center"
                        sx={styleModalTypography}
                     >
                        {selectedCandidate !== '' && `Update Votes of \n ${selectedCandidate.Name.toUpperCase()}`}
                     </Typography>
                     <AddNumber handleAddNumber={handleAddVotes}></AddNumber>
                  </Box>
               </Modal>
               <Modal
                  open={openModalWinner}
                  onClose={handleCloseModalWinner}
                  aria-labelledby="modal-modal-title-addWinner"
                  aria-describedby="modal-modal-description-addWinner"
                  disableScrollLock
                  autoFocus={false}
                  sx={{ overflow: 'hidden' }}
               >
                  <Box sx={styleModalBox}>
                     <Typography
                        id="modal-modal-title-addWinner"
                        variant="h6"
                        component="h6"
                        align="center"
                        sx={styleModalTypography}
                     >
                        {selectedCandidate !== '' &&
                           `Update WINNER status of \n ${selectedCandidate.Name.toUpperCase()}`}
                     </Typography>
                     <AddWinner handleAddWinner={handleAddWinner}></AddWinner>
                  </Box>
               </Modal>
            </Box>
         </Box>
      </LocalizationProvider>
   )
}

ElecCandis.propTypes = {
   legis: PropTypes.object.isRequired,
   elec: PropTypes.object.isRequired,
   constituencyId: PropTypes.string.isRequired,
   constisListFromElec: PropTypes.array.isRequired,
   candidatesListRoot: PropTypes.array.isRequired,
   handleCandidatesListRootUpdate: PropTypes.func.isRequired,
}

export default ElecCandis
