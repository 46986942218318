import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import {
   Alert,
   Avatar,
   Badge,
   Box,
   Button,
   Checkbox,
   Divider,
   FormControl,
   FormControlLabel,
   FormLabel,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   Radio,
   RadioGroup,
   Typography,
} from '@mui/material'
import MyLoaderCircularGradient from '../../../Utils/Loaders/MyLoaderCircularGradient'

function AddConstisToPhase({
   constisListAvailable,
   phasesList,
   handleAddConstisToPhase,
   uploadingConstis,
   uploadedConstis,
   uploadingFailedConstis,
}) {
   const [checkedList, setCheckedList] = useState([])
   const [phase, setPhase] = useState('')

   const handleCheckList = (e) => {
      //
      if (e.target.checked) {
         if (!checkedList.includes(e.target.value)) {
            setCheckedList([...checkedList, e.target.value])
            // Note:
            // alternative code as follows:
            // setCheckedList((checkedList) => [...checkedList, constiId])
         }
      } else {
         setCheckedList((checkedList) => [...checkedList.filter((consti) => consti !== e.target.value)])
      }
   }

   const handlePhase = (e) => {
      //
      setPhase(e.target.value)
   }

   useEffect(() => {
      if (checkedList) {
         console.log('checked list:', checkedList)
      }
   }, [checkedList])

   const handleSubmitSelectedConsti = async (e) => {
      e.preventDefault()
      if (checkedList.length > 0 && phase !== '') {
         // console.log('constiDetails before submit: ', checkedList)
         handleAddConstisToPhase(checkedList, phase)
         setCheckedList([])
         setPhase('')
      }
   }
   return (
      <Box>
         <Box sx={{ border: 1, borderRadius: 1, m: 2, p: 1 }}>
            <FormControl>
               <FormLabel id="demo-controlled-radio-buttons-group">Select Phase:</FormLabel>
               <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="SortField"
                  value={phase}
                  // style={{ display: 'flex', gap: '2rem' }}
                  onChange={(e) => {
                     handlePhase(e)
                  }}
                  sx={{
                     backgroundColor: '#ffffff',
                     borderRadius: 1,
                     py: 0,
                     '& .MuiSvgIcon-root': {
                        fontSize: 15,
                     },
                     '& .MuiButtonBase-root': {
                        py: 0,
                     },
                     '& MuiFormControlLabel-label': {
                        fontSize: 13,
                        lineHeight: 16,
                        py: 0,
                     },
                  }}
               >
                  <FormControlLabel
                     value="Any"
                     control={<Radio size="small" />}
                     label="Any / Not known"
                     sx={{ py: 0, fontSize: 15 }}
                  />
                  {phasesList &&
                     phasesList.length > 0 &&
                     phasesList.map((item) => (
                        <FormControlLabel
                           key={item}
                           value={item}
                           control={<Radio size="small" />}
                           label={item}
                           sx={{ py: 0, fontSize: 15 }}
                        />
                     ))}
               </RadioGroup>
            </FormControl>
         </Box>
         <Box sx={{ width: '45ch', pl: 2 }}>
            <List dense>
               {constisListAvailable.map((item, index) => (
                  <div key={item.Constituency_id}>
                     <ListItem
                        key={item.Constituency_id}
                        secondaryAction={
                           <Checkbox
                              edge="end"
                              onChange={(e) => {
                                 handleCheckList(e)
                              }}
                              value={item.Constituency_id}
                              // checked={checkedList.includes(item.Constituency_id)}
                              inputProps={{ 'aria-labelledby': item.Name }}
                           />
                        }
                        disablePadding
                     >
                        <ListItemAvatar>
                           <Avatar sx={{ height: '30px', width: '30px' }}>
                              <Badge badgeContent={index + 1} color="secondary" max={999}></Badge>
                           </Avatar>
                        </ListItemAvatar>

                        <ListItemText
                           secondaryTypographyProps={{ align: 'left', whiteSpace: 'pre-line' }}
                           primaryTypographyProps={{
                              whiteSpace: 'pre-line',
                              justifyContent: 'center',
                              alignItems: 'center',
                           }}
                           primary={`${item.Name} (${item.ConstituencyNumber})`}
                           component={'span'}
                           secondary={
                              <>
                                 {item.Strata === 'Central' && (
                                    <>
                                       <br />
                                       {' State / UT: '}
                                       <Typography
                                          sx={{ display: 'inline' }}
                                          component="span"
                                          variant="body2"
                                          color="text.primary"
                                       >
                                          {item.StateName}
                                       </Typography>
                                    </>
                                 )}
                              </>
                           }
                        />
                     </ListItem>
                     <Divider />
                  </div>
               ))}
            </List>
         </Box>
         <Box sx={{ mb: 4 }}>
            {uploadingConstis && (
               <MyLoaderCircularGradient title={'Listing the selected constituencies ...'}></MyLoaderCircularGradient>
            )}
            {uploadedConstis && (
               <Alert variant="outlined" severity="success">
                  <strong>selected constituencies</strong> listed successfully.
               </Alert>
            )}
            {uploadingFailedConstis && (
               <Alert variant="outlined" severity="error">
                  Error occured! <strong>selected constituencies</strong> could not be listed.
               </Alert>
            )}
         </Box>
         <Box sx={{ pl: 2 }}>
            <Button
               type="submit"
               align="center"
               variant="contained"
               color="primary"
               size="small"
               onClick={(e) => {
                  handleSubmitSelectedConsti(e)
               }}
               disabled={checkedList.length < 1 || phase === '' || uploadingConstis}
            >
               Submit
            </Button>
         </Box>
      </Box>
   )
}

AddConstisToPhase.propTypes = {
   constisListAvailable: PropTypes.array,
   phasesList: PropTypes.array,
   handleAddConstisToPhase: PropTypes.func,
   uploadingConstis: PropTypes.bool,
   uploadedConstis: PropTypes.bool,
   uploadingFailedConstis: PropTypes.bool,
}

export default AddConstisToPhase
