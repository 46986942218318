import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
   Alert,
   AlertTitle,
   Avatar,
   Badge,
   Box,
   Button,
   Divider,
   FormControl,
   FormControlLabel,
   FormLabel,
   Grid,
   IconButton,
   InputLabel,
   Link,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   MenuItem,
   Modal,
   Radio,
   RadioGroup,
   Select,
   Typography,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
// import InfoSharpIcon from '@mui/icons-material/InfoSharp'
import PeopleIcon from '@mui/icons-material/People'
import CloudOffIcon from '@mui/icons-material/CloudOff'

import { useSelector } from 'react-redux'
import { selectUsers } from '../../../redux/reducers/users-slice'
import { useNavigate } from 'react-router-dom'
import { auth, db } from '../../../FirebaseConfig'
import { arrayRemove, arrayUnion, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore'

import AddConstisToPhase from './AddConstisToPhase'
import EditSeat from './EditSeat'
import { styleModalBox, styleModalTypography } from '../../../Utils/MUITheme/MUITheme'
import { Helmet } from 'react-helmet'
import MyLoaderCircularGradient from '../../../Utils/Loaders/MyLoaderCircularGradient'
import { selectCountries } from '../../../redux/reducers/countries-slice'
import ChangePhaseOfConsti from './ChangePhaseOfConsti'

function ElecConstis({
   elec,
   legis,
   handleConstituencyCandidatesLink,
   constisListFromElec,
   constisListFromLegis,
   handleConstisListRootUpdate,
   candidatesListRoot,
   handleCandidatesListRootUpdateForConsti,
}) {
   // console.log('value from props from inside Elec Constis page: ', elec)

   const user = useSelector(selectUsers)
   const ip = user.ip
   const countries = useSelector(selectCountries).countriesList

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   useEffect(() => {
      // console.log('states - first use effect entered')

      if (user.currentUser !== null) {
         // console.log('states - found user state not-null')
         setThisUser(user.currentUser.id)
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
         } else {
            setIsSuper(false)
            setIsDataEditor(false)
         }
      } else {
         // console.log('states - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')
   const [phasesList, setPhasesList] = useState([])

   const [statesList, setStatesList] = useState([])
   const [selectedState, setSelectedState] = useState('All')
   const [selectedStateName, setSelectedStateName] = useState('All')

   const [constisListAvailable, setConstisListAvailable] = useState([])

   const [constisList, setConstisList] = useState([])
   const [constisListRootLocal, setConstisListRootLocal] = useState([])

   const [selectedPhase, setSelectedPhase] = useState('')

   const [fetchingConstis, setFetchingConstis] = useState(true)

   const [openModalEditSeat, setOpenModalEditSeat] = useState(false)
   const [uploadingSeat, setUploadingSeat] = useState(false)
   const [uploadedSeat, setUploadedSeat] = useState(false)
   const [uploadingFailedSeat, setUploadingFailedSeat] = useState(false)

   const [openModalChangePhaseOfConsti, setOpenModalChangePhaseOfConsti] = useState(false)
   const [uploadingPhaseChangeOfConsti, setUploadingPhaseChangeOfConsti] = useState(false)
   const [uploadedPhaseChangeOfConsti, setUploadedPhaseChangeOfConsti] = useState(false)
   const [uploadingFailedPhaseChangeOfConsti, setUploadingFailedPhaseChangeOfConsti] = useState(false)

   const [selectedConstiObj, setSelectedConstiObj] = useState({})
   const [selectedConsti, setSelectedConsti] = useState('')
   const [selectedConstiName, setSelectedConstiName] = useState('')
   const [constiCount, setConstiCount] = useState('')

   const [sortField, setSortField] = useState('Name')

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         //

         setConstisListRootLocal(constisListFromElec)

         if (elec.Phases && elec.Phases.length > 0) {
            const array = []
            elec.Phases.forEach(async (item) => {
               array.push(item.PhaseNum)
            })
            const uniqueArray = [...new Set(array)]
            const sortedPhases = [...uniqueArray].sort((a, b) => a - b)
            setPhasesList(sortedPhases)
            // console.log('sorted phases: ', sortedPhases)
         }

         fetchStatesList()

         // const sortedPhases = [...elec.Phases].sort((a, b) => a.PhaseNum - b.PhaseNum)
         // setPhasesList(sortedPhases)
         setSelectedPhase('All')

         setFirstLoadStatus('success')
      }
   }, [])

   function sortConstisList(list) {
      let sortedList = []
      switch (sortField) {
         case 'Name':
            sortedList = [...list].sort((a, b) => {
               if (b.Name.trim() > a.Name.trim()) {
                  return -1
               } else if (b.Name.trim() < a.Name.trim()) {
                  return 1
               } else {
                  return 0
               }
            })
            break
         case 'ConstituencyNumber':
            sortedList = [...list].sort((a, b) => a.ConstituencyNumber - b.ConstituencyNumber)
            break
      }
      setConstisList(sortedList)
   }

   // states list
   const fetchStatesList = async () => {
      try {
         const selectedCountryComplete = countries.find((item) => item.id === legis.Country_id)
         // console.log('selectedCountryComplete: ', selectedCountryComplete)

         const sortedList = [...selectedCountryComplete.States].sort((a, b) => {
            if (b.Name.trim() > a.Name.trim()) {
               return -1
            } else if (b.Name.trim() < a.Name.trim()) {
               return 1
            } else {
               return 0
            }
         })

         setStatesList(sortedList)
         // console.log('states list raw: ', selectedCountryComplete.States)
         // console.log('states list sorted: ', sortedList)
      } catch (err) {
         console.log('error fetching states list: ', err)
      }
   }

   function handleChangeState(e) {
      e.preventDefault()
      setFetchingConstis(true)
      setSelectedState(e.target.value)
      if (e.target.value !== 'All') {
         const thisState = statesList.find((item) => item.State_id === e.target.value)
         setSelectedStateName(thisState.Name)
      } else {
         setSelectedStateName('All')
      }
      setConstiCount('')
   }

   const handleRemoveState = async (e) => {
      e.preventDefault()
      setFetchingConstis(true)
      setSelectedState('All')
      setSelectedStateName('All')
      setConstiCount('')
   }

   useEffect(() => {
      if (selectedState !== 'All') {
         if (selectedPhase === 'All') {
            const list = constisListRootLocal.filter((item) => item.State_id === selectedState)
            sortConstisList(list)
         } else {
            const list = constisListRootLocal.filter(
               (item) => item.PhaseNum === selectedPhase && item.State_id === selectedState,
            )
            sortConstisList(list)
         }
      } else {
         if (selectedPhase === 'All') {
            sortConstisList(constisListRootLocal)
         } else {
            const list = constisListRootLocal.filter((item) => item.PhaseNum === selectedPhase)
            sortConstisList(list)
         }
      }
   }, [selectedState])

   // phases

   // useEffect(() => {
   //    // the phase list will change only once on page load, hence ...
   //    if (phasesList && phasesList.length > 0) {
   //       const item = phasesList[0]
   //       setSelectedPhase(item)
   //    }
   // }, [phasesList])

   const handleChangePhase = async (e) => {
      e.preventDefault()

      setFetchingConstis(true)

      setSelectedPhase(e.target.value)
      setConstiCount('')
   }

   useEffect(() => {
      if (selectedPhase) {
         setFetchingConstis(true)
         setConstiCount('')
         if (selectedPhase === 'All') {
            if (selectedState !== 'All') {
               const list = constisListRootLocal.filter((item) => item.State_id === selectedState)
               sortConstisList(list)
            } else {
               sortConstisList(constisListRootLocal)
            }
         } else {
            if (selectedState !== 'All') {
               const list = constisListRootLocal.filter(
                  (item) => item.PhaseNum === selectedPhase && item.State_id === selectedState,
               )
               sortConstisList(list)
            } else {
               const list = constisListRootLocal.filter((item) => item.PhaseNum === selectedPhase)
               sortConstisList(list)
            }
         }
      }
   }, [selectedPhase])

   useEffect(() => {
      if (constisList) {
         setConstiCount(constisList.length)
         setFetchingConstis(false)
      }
   }, [constisList])

   function handleSortField(e) {
      e.preventDefault()
      setSortField(e.target.value)
   }

   useEffect(() => {
      if (sortField) {
         setFetchingConstis(true)
         sortConstisList(constisList)
      }
   }, [sortField])

   // related to form filling and submission of constis

   const [openModalAddConstisToPhase, setOpenModalAddConstisToPhase] = useState(false)

   const handleOpenModalAddConstisToPhase = (e) => {
      e.preventDefault()

      if (constisListFromLegis && constisListRootLocal.length > 0) {
         // Note: the following code collects all the Constituency_is from the constisListRootLocal
         const filterIds = new Set(constisListRootLocal.map((item) => item.Constituency_id))

         // Note: the following code filters out all the filterIds from the constisListFromLegis
         const filteredArray = constisListFromLegis.filter((item) => !filterIds.has(item.Constituency_id))

         setConstisListAvailable(filteredArray)
      } else {
         setConstisListAvailable(constisListFromLegis)
      }

      setOpenModalAddConstisToPhase(true)
   }

   const handleCloseModalAddConstisToPhase = () => setOpenModalAddConstisToPhase(false)

   const [uploadingConstis, setUploadingConstis] = useState(false)
   const [uploadedConstis, setUploadedConstis] = useState(false)
   const [uploadingFailedConstis, setUploadingFailedConstis] = useState(false)

   const handleAddConstisToPhase = async (constisListSelected, phase) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (constisListSelected.length > 0) {
               try {
                  setUploadingConstis(true)
                  const tempListRoot = constisListRootLocal
                  const tempListConsti = constisList

                  // send each selected constituency to the Constituencies array of Election

                  const docRefElecSubCollConstis = doc(db, 'Elections', elec.id, 'CoreLists', 'ConstituencyCoreList')
                  const docSnapElecSubCollConstis = await getDoc(docRefElecSubCollConstis)

                  constisListSelected.forEach(async (item) => {
                     const thisConstituency = constisListFromLegis.find(
                        (itemConsti) => itemConsti.Constituency_id === item,
                     )

                     thisConstituency.CrBy = auth.currentUser.uid
                     thisConstituency.CrDt = new Date()
                     thisConstituency.CrIP = ip.data.ip
                     thisConstituency.IsDeleted = false
                     thisConstituency.PhaseNum = phase === 'Any' ? '' : phase

                     thisConstituency.RemovedFromPhase = false
                     thisConstituency.RemovedFromPhaseDate = ''
                     thisConstituency.RemovedFromPhaseBy = ''
                     thisConstituency.RemovedFromPhaseByDate = ''

                     thisConstituency.SeatType = ''
                     thisConstituency.SeatTypeChanged = false
                     thisConstituency.SeatTypeChangedDate = ''
                     thisConstituency.SeatTypeChangedBy = ''
                     thisConstituency.SeatTypeChangedByDate = ''

                     if (docSnapElecSubCollConstis.exists()) {
                        await updateDoc(docRefElecSubCollConstis, {
                           Constituencies: arrayUnion(thisConstituency),
                        })
                        // console.log('test 1:')
                     } else {
                        setDoc(docRefElecSubCollConstis, { Constituencies: [thisConstituency] }, { merge: true })
                        // console.log('test 2:')
                     }

                     tempListRoot.push(thisConstituency)
                     // console.log('temp list root:', tempListRoot)

                     tempListConsti.push(thisConstituency)
                     // console.log('temp list consti:', tempListConsti)

                     // send the election record to the ElecCoreList subcollection of the constituency for cross reference
                     const recordOfElectionForConstituencyCoreList = {
                        Election_id: elec.id,
                        Legislature_id: elec.Legislature_id,
                        LegislatureName: elec.LegislatureName,
                        LegislatureNameHindi: elec.LegislatureNameHindi,
                        LegislatureNameLocal: elec.LegislatureNameLocal,
                        Name: elec.Name,
                        NameHindi: elec.NameHindi,
                        NameLocal: elec.NameLocal,
                        NameAlt: elec.NameAlt,
                        DateStart: elec.DateStart,
                        DateFinish: elec.DateFinish,
                        HouseNumber: elec.HouseNumber,
                        IsSpeculated: elec.IsSpeculated,
                        PhaseNum: phase === 'Any' ? '' : phase,
                        SeatType: '',
                     }

                     const docRefConstiSubCollElec = doc(db, 'LegisConstituencies', item, 'CoreLists', 'ElecCoreList')
                     const docSnapConstiSubCollElec = await getDoc(docRefConstiSubCollElec)

                     if (docSnapConstiSubCollElec.exists()) {
                        const eleclist = [...docSnapConstiSubCollElec.data().ElectionIdsList]
                        if (!eleclist.includes(elec.id)) {
                           await updateDoc(docRefConstiSubCollElec, {
                              Elections: arrayUnion(recordOfElectionForConstituencyCoreList),
                              ElectionIdsList: arrayUnion(elec.id),
                           })
                        }
                        // console.log('test 1:')
                     } else {
                        setDoc(
                           docRefConstiSubCollElec,
                           { Elections: [recordOfElectionForConstituencyCoreList], ElectionIdsList: [elec.id] },
                           { merge: true },
                        )
                        // console.log('test 2:')
                     }
                     // console.log('test 3:')
                  })

                  handleConstisListRootUpdate(tempListRoot)
                  // console.log('consti list root 2:', tempListRoot)
                  // since the constis from legis are being added inside a selected phase only ...
                  setFetchingConstis(true)
                  setConstiCount('')
                  setConstisList(tempListConsti)
                  // console.log('consti list consti 2:', tempListConsti)

                  setUploadingConstis(false)
                  setUploadedConstis(true)
                  setUploadingFailedConstis(false)

                  setFetchingConstis(false)

                  setOpenModalAddConstisToPhase(false)
               } catch (error) {
                  alert('Error adding election 22: ', error.message)
                  // console.log('Error adding electione', error)
                  setUploadingConstis(false)
                  setUploadedConstis(false)
                  setUploadingFailedConstis(true)

                  setOpenModalAddConstisToPhase(false)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleDelete = async (e, item) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isSuper) {
            if (confirm('Are you sure you want to remove this constitency?')) {
               try {
                  // delete record from Elections
                  const docRefUpdate = doc(db, 'Elections', elec.id, 'CoreLists', 'ConstituencyCoreList')
                  await updateDoc(docRefUpdate, {
                     Constituencies: arrayRemove(item),
                  })

                  // delete record of election from Constituency
                  const docRefConstiSubColElection = doc(
                     db,
                     'LegisConstituencies',
                     item.Constituency_id,
                     'CoreLists',
                     'ElecCoreList',
                  )
                  const docSnap = await getDoc(docRefConstiSubColElection)
                  const list = [...docSnap.data().Elections]
                  list.forEach(async (thisItem) => {
                     if (thisItem.Election_id === elec.id) {
                        await updateDoc(docRefConstiSubColElection, {
                           Elections: arrayRemove(thisItem),
                           ElectionIdsList: arrayRemove(elec.id),
                        })
                     }
                  })

                  // manage local state
                  setFetchingConstis(true)
                  setConstiCount('')
                  const constisListFiltered = constisList.filter(
                     (itemFitered) => itemFitered.Constituency_id !== item.Constituency_id,
                  )
                  const constisListRootLocalFiltered = constisListRootLocal.filter(
                     (itemFiteredRoot) => itemFiteredRoot.Constituency_id !== item.Constituency_id,
                  )
                  setConstisList(constisListFiltered)
                  setFetchingConstis(false)
                  handleConstisListRootUpdate(constisListRootLocalFiltered)
               } catch (error) {
                  alert('Error deleting district')
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // realted to seat reservation

   const handleOpenModalEditSeat = (e, ConstituencyId, Name, Number) => {
      e.preventDefault()

      setSelectedConsti(ConstituencyId)
      setSelectedConstiName(Name.toUpperCase() + ' (' + Number + ')')

      setOpenModalEditSeat(true)
   }

   const handleCloseModalEditSeat = () => setOpenModalEditSeat(false)

   const handleEditSeat = async (seatType) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (seatType.length !== '') {
               try {
                  setUploadingSeat(true)

                  const consti = constisList.find((item) => item.Constituency_id === selectedConsti)

                  const recordOld = {
                     Constituency_id: consti.Constituency_id,
                     Legislature_id: consti.Legislature_id,
                     LegislatureName: consti.LegislatureName,
                     ConstituencyNumber: consti.ConstituencyNumber,
                     Name: consti.Name,
                     NameHindi: consti.NameHindi,
                     NameLocal: consti.NameLocal,
                     NameAlt: consti.NameAlt,
                     StateName: consti.StateName,
                     State_id: consti.State_id,
                     DivisionName: consti.DivisionName,
                     Division_id: consti.Division_id,
                     DistrictName: consti.DistrictName,
                     District_id: consti.District_id,
                     SubDistrict_id: consti.SubDistrict_id,
                     SubDistrictName: consti.SubDistrictName,
                     Block_id: consti.Block_id,
                     BlockName: consti.BlockName,
                     Village_id: consti.Village_id,
                     VillageName: consti.VillageName,
                     ParentConsti_id: consti.ParentConsti_id,
                     ParentConstiName: consti.ParentConstiName,
                     IsNominated: consti.IsNominated,
                     Strata: consti.Strata,
                     IsDeleted: consti.IsDeleted,
                     PhaseNum: consti.PhaseNum,
                     CrBy: consti.CrBy,
                     CrDt: consti.CrDt,
                     CrIP: consti.CrIP,

                     RemovedFromPhase: consti.RemovedFromPhase,
                     RemovedFromPhaseDate: consti.RemovedFromPhaseDate,
                     RemovedFromPhaseBy: consti.RemovedFromPhaseBy,
                     RemovedFromPhaseByDate: consti.RemovedFromPhaseByDate,

                     SeatType: consti.SeatType,
                     SeatTypeChanged: consti.SeatTypeChanged,
                     SeatTypeChangedDate: consti.SeatTypeChangedDate,
                     SeatTypeChangedBy: consti.SeatTypeChangedBy,
                     SeatTypeChangedByDate: consti.SeatTypeChangedByDate,
                  }
                  // console.log(recordOld)
                  const recordNew = {
                     Constituency_id: consti.Constituency_id,
                     Legislature_id: consti.Legislature_id,
                     LegislatureName: consti.LegislatureName,
                     ConstituencyNumber: consti.ConstituencyNumber,
                     Name: consti.Name,
                     NameHindi: consti.NameHindi,
                     NameLocal: consti.NameLocal,
                     NameAlt: consti.NameAlt,
                     StateName: consti.StateName,
                     State_id: consti.State_id,
                     DivisionName: consti.DivisionName,
                     Division_id: consti.Division_id,
                     DistrictName: consti.DistrictName,
                     District_id: consti.District_id,
                     SubDistrict_id: consti.SubDistrict_id,
                     SubDistrictName: consti.SubDistrictName,
                     Block_id: consti.Block_id,
                     BlockName: consti.BlockName,
                     Village_id: consti.Village_id,
                     VillageName: consti.VillageName,
                     ParentConsti_id: consti.ParentConsti_id,
                     ParentConstiName: consti.ParentConstiName,
                     IsNominated: consti.IsNominated,
                     Strata: consti.Strata,
                     IsDeleted: consti.IsDeleted,
                     PhaseNum: consti.PhaseNum,
                     CrBy: consti.CrBy,
                     CrDt: consti.CrDt,
                     CrIP: consti.CrIP,

                     RemovedFromPhase: consti.RemovedFromPhase,
                     RemovedFromPhaseDate: consti.RemovedFromPhaseDate,
                     RemovedFromPhaseBy: consti.RemovedFromPhaseBy,
                     RemovedFromPhaseByDate: consti.RemovedFromPhaseByDate,

                     SeatType: seatType,
                     SeatTypeChanged: true,
                     SeatTypeChangedDate: '',
                     SeatTypeChangedBy: auth.currentUser.uid,
                     SeatTypeChangedByDate: new Date(),
                  }
                  // console.log(recordNew)

                  const docRefUpdate = doc(db, 'Elections', elec.id, 'CoreLists', 'ConstituencyCoreList')
                  await updateDoc(docRefUpdate, {
                     Constituencies: arrayRemove(recordOld),
                  })
                  await updateDoc(docRefUpdate, {
                     Constituencies: arrayUnion(recordNew),
                  })

                  const docRefUpdateConstituency = doc(db, 'LegisConstituencies', consti.Constituency_id)
                  await updateDoc(docRefUpdateConstituency, {
                     SeatTypeHistory: arrayUnion({
                        SeatType: seatType,
                        Election_id: elec.id,
                        ElectionName: elec.Name,
                        ElectionNameHindi: elec.NameHindi,
                        ElectionNameLocal: elec.NameLocal,
                     }),
                  })

                  const newConstisListFiltered = constisList.filter((item) => item.Constituency_id !== selectedConsti)

                  const newConstisListAppended = [...newConstisListFiltered, recordNew]
                  let newConstisListSorted = []
                  switch (sortField) {
                     case 'Name':
                        newConstisListSorted = [...newConstisListAppended].sort((a, b) => {
                           if (b.Name.trim() > a.Name.trim()) {
                              return -1
                           } else if (b.Name.trim() < a.Name.trim()) {
                              return 1
                           } else {
                              return 0
                           }
                        })
                        break
                     case 'ConstituencyNumber':
                        newConstisListSorted = [...newConstisListAppended].sort(
                           (a, b) => a.ConstituencyNumber - b.ConstituencyNumber,
                        )
                        break
                  }

                  const newConstisListRootFiltered = constisListRootLocal.filter(
                     (item) => item.Constituency_id !== selectedConsti,
                  )
                  // console.log('newConstisList Root Filtered: ', newConstisListRootFiltered)
                  const newConstisListRootAppended = [...newConstisListRootFiltered, recordNew]
                  // console.log('newConstisList Root Appended: ', newConstisListRootAppended)

                  let newConstisListRootSorted = []
                  switch (sortField) {
                     case 'Name':
                        newConstisListRootSorted = [...newConstisListRootAppended].sort((a, b) => {
                           if (b.Name.trim() > a.Name.trim()) {
                              return -1
                           } else if (b.Name.trim() < a.Name.trim()) {
                              return 1
                           } else {
                              return 0
                           }
                        })
                        break
                     case 'ConstituencyNumber':
                        newConstisListRootSorted = [...newConstisListRootAppended].sort(
                           (a, b) => a.ConstituencyNumber - b.ConstituencyNumber,
                        )
                        break
                  }
                  // console.log('newConstisList Root Sorted: ', newConstisListRootSorted)
                  setFetchingConstis(true)
                  setConstiCount('')
                  setConstisList(newConstisListSorted)
                  setConstisListRootLocal(newConstisListRootSorted)
                  handleConstisListRootUpdate(newConstisListRootSorted)

                  setUploadingSeat(false)
                  setUploadedSeat(true)
                  setUploadingFailedSeat(false)

                  setOpenModalEditSeat(false)
               } catch (error) {
                  alert('Error editing seat: ', error.message)
                  console.log('Error editing seat', error)
                  setUploadingSeat(false)
                  setUploadedSeat(false)
                  setUploadingFailedSeat(true)

                  setOpenModalEditSeat(false)
               }
            } else {
               setSelectedConsti('')
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // realted to phase change of consti

   const handleOpenModalChangePhaseOfConsti = (e, item) => {
      e.preventDefault()

      setSelectedConstiObj(item)
      setSelectedConsti(item.Constituency_id)
      setSelectedConstiName(item.Name.toUpperCase() + ' (' + Number + ')')

      setOpenModalChangePhaseOfConsti(true)
   }

   const handleCloseModalChangePhaseOfConsti = () => setOpenModalChangePhaseOfConsti(false)

   const handleChangePhaseOfConsti = async (newPhase) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (newPhase !== '' && newPhase !== selectedConstiObj.PhaseNum) {
               try {
                  setUploadingPhaseChangeOfConsti(true)
                  // console.log('selectedConstiObj 1: ', selectedConstiObj)
                  // find the consti from this election
                  const thisConstituency = constisListFromElec.find(
                     (item) => item.Constituency_id === selectedConstiObj.Constituency_id,
                  )
                  // console.log('thisConstituency: ', thisConstituency)
                  // create the new consti object with new phaseNum
                  const thisConstituencyNew = { ...thisConstituency, PhaseNum: newPhase }
                  // console.log('thisConstituency New: ', thisConstituencyNew)
                  // update the Election with new values
                  const docRef = doc(db, 'Elections', elec.id, 'CoreLists', 'ConstituencyCoreList')
                  await updateDoc(docRef, {
                     Constituencies: arrayRemove(thisConstituency),
                  })
                  await updateDoc(docRef, {
                     Constituencies: arrayUnion(thisConstituencyNew),
                  })
                  // console.log('selectedConstiObj 2: ', selectedConstiObj)
                  // find election from Constituency / ElectionCoreList, remove from array and add to array
                  const docRefConstiSubCollElec = doc(
                     db,
                     'LegisConstituencies',
                     selectedConstiObj.Constituency_id,
                     'CoreLists',
                     'ElecCoreList',
                  )
                  const docSnapConstiSubCollElec = await getDoc(docRefConstiSubCollElec)

                  const elecListInsideConstituency = docSnapConstiSubCollElec.data().Elections
                  elecListInsideConstituency.forEach(async (item) => {
                     if (item.Election_id === elec.id) {
                        const newElec = { ...item, PhaseNum: newPhase }
                        await updateDoc(docRefConstiSubCollElec, {
                           Elections: arrayRemove(item),
                        })
                        await updateDoc(docRefConstiSubCollElec, {
                           Elections: arrayUnion(newElec),
                        })
                     }
                  })
                  // console.log('elecListInsideConstituency: ', elecListInsideConstituency)
                  // update local state for constituencies of selected phase
                  const newConstisListFiltered = constisList.filter(
                     (item) => item.Constituency_id !== thisConstituency.Constituency_id,
                  )
                  const newConstisListAppended = [...newConstisListFiltered, thisConstituencyNew]
                  let newConstisListSorted = []
                  switch (sortField) {
                     case 'Name':
                        newConstisListSorted = [...newConstisListAppended].sort((a, b) => {
                           if (b.Name.trim() > a.Name.trim()) {
                              return -1
                           } else if (b.Name.trim() < a.Name.trim()) {
                              return 1
                           } else {
                              return 0
                           }
                        })
                        break
                     case 'ConstituencyNumber':
                        newConstisListSorted = [...newConstisListAppended].sort(
                           (a, b) => a.ConstituencyNumber - b.ConstituencyNumber,
                        )
                        break
                  }
                  // const newConstisListSorted = [...newConstisListAppended].sort((a, b) => {
                  //    if (b.Name.trim() > a.Name.trim()) {
                  //       return -1
                  //    } else if (b.Name.trim() < a.Name.trim()) {
                  //       return 1
                  //    } else {
                  //       return 0
                  //    }
                  // })
                  setFetchingConstis(true)
                  setConstiCount('')
                  setConstisList(newConstisListSorted)

                  // update local state for constituencies of root
                  const newConstisListRootFiltered = constisListRootLocal.filter(
                     (item) => item.Constituency_id !== thisConstituency.Constituency_id,
                  )
                  const newConstisListRootAppended = [...newConstisListRootFiltered, thisConstituencyNew]
                  setConstisListRootLocal(newConstisListRootAppended)

                  // let newConstisListRootSorted
                  // switch (sortField) {
                  //    case 'Name':
                  //       newConstisListRootSorted = [...newConstisListRootAppended].sort((a, b) => {
                  //          if (b.Name.trim() > a.Name.trim()) {
                  //             return -1
                  //          } else if (b.Name.trim() < a.Name.trim()) {
                  //             return 1
                  //          } else {
                  //             return 0
                  //          }
                  //       })
                  //       break
                  //    case 'ConstituencyNumber':
                  //       newConstisListRootSorted = [...newConstisListRootAppended].sort(
                  //          (a, b) => a.ConstituencyNumber - b.ConstituencyNumber,
                  //       )
                  //       break
                  // }

                  // setConstisListRootLocal(newConstisListRootSorted)
                  // handleConstisListRootUpdate(newConstisListRootSorted)

                  // find all candidates already listed for this constituency
                  const list = candidatesListRoot.filter((item) => item.Constituency_id === selectedConsti)
                  list.forEach(async (item) => {
                     // update the candidate main doc
                     const docRef = doc(db, 'ElectionCandidates', item.Candidate_id)
                     await updateDoc(docRef, {
                        PhaseNum: newPhase,
                     })
                     // update the candidate inside Election/CandiCoreList
                     const oldCandidate = item
                     const newCandidate = { ...item, PhaseNum: newPhase }
                     const docRefElecSubColCandiCoreList = doc(db, 'Elections', elec.id, 'CoreLists', item.CoreListId)

                     await updateDoc(docRefElecSubColCandiCoreList, {
                        Candidates: arrayRemove(oldCandidate),
                     })
                     await updateDoc(docRefElecSubColCandiCoreList, {
                        Candidates: arrayUnion(newCandidate),
                     })

                     // update the local state for candidate
                     handleCandidatesListRootUpdateForConsti(oldCandidate, newCandidate)
                  })

                  setUploadingPhaseChangeOfConsti(false)
                  setOpenModalChangePhaseOfConsti(false)
               } catch (error) {
                  alert('Error changing phase: ', error.message)
                  console.log('Error changing phase', error)
                  setUploadingPhaseChangeOfConsti(false)
                  setUploadedPhaseChangeOfConsti(false)
                  setUploadingFailedPhaseChangeOfConsti(true)

                  setOpenModalChangePhaseOfConsti(false)
               }
            }
            setSelectedConsti('')
            setSelectedConstiObj({})
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // candidate link

   const handleCandidateLink = async (e, constituencyId) => {
      e.preventDefault()

      handleConstituencyCandidatesLink(constituencyId)
   }

   return (
      <Box>
         <div>
            <Helmet>
               <title>{`Track Democracy - Constituencies for ${elec.Name}`}</title>
            </Helmet>
         </div>
         {!navigator.onLine && (
            <div>
               <Alert
                  variant="outlined"
                  severity="error"
                  sx={{ margin: 5, justifyContent: 'center', alignItems: 'center' }}
               >
                  <AlertTitle>
                     You are offline !
                     <CloudOffIcon sx={{ ml: 5 }} />
                  </AlertTitle>
               </Alert>
            </div>
         )}
         {navigator.onLine && (
            <Box sx={{ px: 1 }}>
               <Grid id="home_grid_container" container spacing={0} sx={{ my: 0.25, justifyContent: 'space-between' }}>
                  <Grid
                     item
                     id="home_grid_Container_phase"
                     xs={3}
                     sm={3}
                     md={2}
                     order={{ xs: 1, sm: 1, md: 1 }}
                     sx={{
                        backgroundColor: '#F1F1F1',
                        py: 1,
                        px: 1.5,
                        mt: 0.5,
                        display: 'flex',
                        flexDirection: 'row',
                        border: 2,
                        borderColor: '#ffffff',
                        justifyContent: 'center',
                        alignItems: 'center',
                     }}
                  >
                     <FormControl size="small" sx={{ width: '100%', backgroundColor: '#F1F1F1' }}>
                        <InputLabel>Phase / चरण</InputLabel>
                        <Select
                           value={selectedPhase}
                           label="Phase / चरण"
                           onChange={(e) => {
                              handleChangePhase(e)
                           }}
                           sx={{ backgroundColor: '#FFFFFF' }}
                        >
                           <MenuItem value="All">
                              <em>All</em>
                           </MenuItem>
                           {phasesList &&
                              phasesList.length > 0 &&
                              phasesList.map((item) => (
                                 <MenuItem key={item} value={item}>
                                    {item}
                                 </MenuItem>
                              ))}
                        </Select>
                     </FormControl>
                  </Grid>
                  {legis.Strata === 'Central' && (
                     <Grid
                        item
                        id="home_grid_Container_state"
                        xs={4}
                        sm={4}
                        md={4}
                        order={{ xs: 2, sm: 2, md: 2 }}
                        sx={{
                           backgroundColor: '#F1F1F1',
                           py: 1,
                           px: 1.5,
                           mt: 0.5,
                           display: 'flex',
                           flexDirection: 'row',
                           border: 2,
                           borderColor: '#ffffff',
                           justifyContent: 'center',
                           alignItems: 'center',
                        }}
                     >
                        <FormControl size="small" sx={{ width: '100%', backgroundColor: '#F1F1F1' }}>
                           <InputLabel>State / राज्य</InputLabel>
                           <Select
                              value={selectedState}
                              label="State / राज्य"
                              onChange={handleChangeState}
                              MenuProps={{
                                 sx: {
                                    '&& .Mui-selected': {
                                       color: '#FFFFFF',
                                       background: '#1565c0',
                                    },
                                 },
                              }}
                              sx={{ backgroundColor: '#ffffff' }}
                           >
                              <MenuItem value="All">
                                 <MenuItem>All</MenuItem>
                              </MenuItem>
                              {statesList &&
                                 statesList.map((item) => (
                                    <MenuItem key={item.State_id} value={item.State_id}>
                                       {item.Name}
                                    </MenuItem>
                                 ))}
                           </Select>
                        </FormControl>
                        <IconButton
                           edge="end"
                           aria-label="delete"
                           onClick={(e) => {
                              handleRemoveState(e)
                           }}
                           sx={{ height: '25px', width: '25px' }}
                           disabled={selectedState === 'All'}
                        >
                           <DeleteIcon fontSize="small" />
                        </IconButton>
                     </Grid>
                  )}
                  <Grid
                     item
                     id="home_grid_Container_Sort"
                     xs={5}
                     sm={5}
                     md={5}
                     order={{ xs: 3, sm: 3, md: 3 }}
                     sx={{
                        backgroundColor: '#F1F1F1',
                        py: 1,
                        px: 1.5,
                        mt: 0.5,
                        display: 'flex',
                        flexDirection: 'row',
                        border: 2,
                        borderColor: '#ffffff',
                     }}
                  >
                     <FormControl
                        sx={{
                           px: 0,
                        }}
                     >
                        <FormLabel id="demo-controlled-radio-buttons-group">Sort by:</FormLabel>
                        <RadioGroup
                           row
                           aria-labelledby="demo-controlled-radio-buttons-group"
                           name="SortField"
                           value={sortField}
                           // style={{ display: 'flex', gap: '2rem' }}
                           onChange={(e) => {
                              handleSortField(e)
                           }}
                           sx={{
                              backgroundColor: '#ffffff',
                              borderRadius: 1,
                              border: 0.5,
                              py: 0,
                              px: 0.5,
                              '& .MuiSvgIcon-root': {
                                 fontSize: 15,
                              },
                              '& .MuiButtonBase-root': {
                                 py: 0,
                              },
                              '& MuiFormControlLabel-label': {
                                 fontSize: 13,
                                 lineHeight: 16,
                                 py: 0,
                              },
                           }}
                        >
                           <FormControlLabel
                              value="Name"
                              control={<Radio size="small" />}
                              label="Name"
                              sx={{ py: 0, fontSize: 15 }}
                           />
                           <FormControlLabel
                              value="ConstituencyNumber"
                              control={<Radio size="small" />}
                              label="Constituency number"
                              sx={{ py: 0, fontSize: 15 }}
                           />
                        </RadioGroup>
                     </FormControl>
                  </Grid>
               </Grid>
               <Box>
                  <Box
                     sx={{
                        p: 1,
                        mt: 0.5,
                        backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
                        color: '#ffffff',
                     }}
                  >
                     {phasesList && phasesList.length < 1 && <Box>Constituencies for this election:</Box>}
                     {phasesList && phasesList.length < 1 && <Box>इस चुनाव के लिए निर्वाचन क्षेत्र:</Box>}
                     {phasesList && phasesList.length > 0 && (
                        <Box>
                           {selectedPhase !== 'All'
                              ? `Constituencies under phase ${selectedPhase}`
                              : `Constituencies under all phases`}
                           :
                        </Box>
                     )}
                     {phasesList && phasesList.length > 0 && (
                        <Box>
                           {selectedPhase !== 'All'
                              ? `चरण ${selectedPhase} के अंतर्गत निर्वाचन क्षेत्र`
                              : `सभी चरणों के अंतर्गत निर्वाचन क्षेत्र`}
                           :
                        </Box>
                     )}
                  </Box>
                  {!fetchingConstis && constisList && constisList.length < 1 && (
                     <Box>
                        <Alert
                           variant="outlined"
                           severity="warning"
                           sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
                        >
                           <AlertTitle sx={{ fontSize: 12 }}>
                              To be available soon. <br /> शीघ्र उपलब्ध होगा |
                           </AlertTitle>
                        </Alert>
                     </Box>
                  )}

                  {fetchingConstis && (
                     <MyLoaderCircularGradient title={'Fetching constituencies ...'}></MyLoaderCircularGradient>
                  )}

                  {(isSuper || isDataEditor) && phasesList && (
                     <Box>
                        <Button
                           sx={{ py: 0, px: 1, minWidth: 0 }}
                           onClick={(e) => {
                              handleOpenModalAddConstisToPhase(e)
                           }}
                        >
                           Add Constituencies
                        </Button>
                     </Box>
                  )}
                  <Box
                     sx={{
                        backgroundColor: '#d3d3d3',
                        py: 0.5,
                        whiteSpace: 'pre-wrap',
                        lineHeight: 1.2,
                        fontFamily: 'Poppins',
                        display: 'flex',
                        flexDirection: 'row',
                     }}
                  >
                     <>
                        <Box
                           sx={{ backgroundColor: '#000000', color: '#CFFF04', borderRadius: 1, mx: 1, p: 0.5 }}
                        >{`Phase: `}</Box>
                        <Box sx={{ backgroundColor: '#FFFFFF', borderRadius: 1, px: 1, mr: 1, color: '#ba68c8' }}>
                           {selectedPhase}
                        </Box>
                     </>
                     {legis.Strata === 'Central' && (
                        <>
                           <Box
                              sx={{ backgroundColor: '#000000', color: '#CFFF04', borderRadius: 1, mx: 1, p: 0.5 }}
                           >{`State / UT: `}</Box>
                           <Box sx={{ backgroundColor: '#FFFFFF', borderRadius: 1, px: 1, mr: 1, color: '#ba68c8' }}>
                              {selectedStateName}
                           </Box>
                        </>
                     )}
                     <>
                        <Box
                           sx={{ backgroundColor: '#000000', color: '#CFFF04', borderRadius: 1, mx: 1, p: 0.5 }}
                        >{`Sorted by: `}</Box>
                        <Box sx={{ backgroundColor: '#FFFFFF', borderRadius: 1, px: 1, mr: 1, color: '#ba68c8' }}>
                           {sortField}
                        </Box>
                     </>
                     <>
                        <Box
                           sx={{ backgroundColor: '#000000', color: '#CFFF04', borderRadius: 1, mx: 1, p: 0.5 }}
                        >{`Count: `}</Box>
                        <Box sx={{ backgroundColor: '#FFFFFF', borderRadius: 1, px: 1, mr: 1, color: '#ba68c8' }}>
                           {constiCount}
                        </Box>
                     </>
                  </Box>
                  {!fetchingConstis && (
                     <List dense>
                        {constisList &&
                           constisList.length > 0 &&
                           constisList.map((item, index) => (
                              <div key={index}>
                                 <ListItem
                                    key={index}
                                    secondaryAction={
                                       <Box
                                          sx={{
                                             ml: 'auto',
                                             display: 'flex',
                                             flexDirection: 'column',
                                             alignItems: 'center',
                                          }}
                                       >
                                          <Box>
                                             <Button
                                                size="small"
                                                sx={{
                                                   padding: 0,
                                                   minWidth: 0,
                                                   fontSize: 12,
                                                   lineHeight: 1.1,
                                                   whiteSpace: 'pre-line',
                                                }}
                                                endIcon={<PeopleIcon />}
                                                onClick={(e) => {
                                                   handleCandidateLink(e, item.Constituency_id)
                                                }}
                                                target="_blank"
                                                rel="noopener"
                                             >
                                                Candidates {'\n'} प्रत्याशी
                                             </Button>
                                          </Box>
                                          <Box>
                                             {isSuper && (
                                                <IconButton
                                                   edge="end"
                                                   aria-label="delete"
                                                   onClick={(e) => {
                                                      handleDelete(e, item)
                                                   }}
                                                   sx={{ mr: 1, height: '25px', width: '25px' }}
                                                >
                                                   <DeleteIcon fontSize="small" />
                                                </IconButton>
                                             )}
                                          </Box>
                                       </Box>
                                    }
                                 >
                                    <ListItemAvatar>
                                       <Avatar sx={{ height: '30px', width: '30px' }}>
                                          <Badge badgeContent={index + 1} color="secondary" max={999}></Badge>
                                       </Avatar>
                                    </ListItemAvatar>

                                    <ListItemText
                                       secondaryTypographyProps={{
                                          align: 'left',
                                          whiteSpace: 'pre-line',
                                          fontSize: '13px',
                                       }}
                                       primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                                       primary={
                                          <>
                                             <Link
                                                href={`/constituency/${item.Name}/${item.Constituency_id}`}
                                                sx={{ mr: 1, textDecoration: 'none !important' }}
                                                rel="noreferrer"
                                                target="_blank"
                                             >
                                                <strong>{item.Name.toUpperCase()}</strong> ({item.ConstituencyNumber}){' '}
                                                {item.NameLocal && <>{item.NameLocal}</>}
                                                {!item.NameLocal && <>{item.NameHindi}</>}
                                             </Link>
                                          </>
                                       }
                                       component={'span'}
                                       secondary={
                                          <>
                                             <>
                                                {' Phase / चरण: '}
                                                <Typography
                                                   sx={{ display: 'inline' }}
                                                   component="span"
                                                   variant="body2"
                                                   color="text.primary"
                                                >
                                                   {item.PhaseNum}
                                                </Typography>
                                                {(isDataEditor || isSuper) && (
                                                   <IconButton
                                                      edge="end"
                                                      aria-label="edit"
                                                      onClick={(e) => {
                                                         handleOpenModalChangePhaseOfConsti(e, item)
                                                      }}
                                                      sx={{ ml: 1, height: '15px', width: '15px' }}
                                                   >
                                                      <EditIcon sx={{ height: '15px', width: '15px' }} />
                                                   </IconButton>
                                                )}
                                                <br />
                                             </>
                                             <>
                                                {' Seat: '}
                                                <Typography
                                                   sx={{ display: 'inline' }}
                                                   component="span"
                                                   variant="body2"
                                                   color="#000000"
                                                >
                                                   {item.SeatType}
                                                </Typography>

                                                {(isDataEditor || isSuper) && (
                                                   <IconButton
                                                      edge="end"
                                                      aria-label="edit"
                                                      onClick={(e) => {
                                                         handleOpenModalEditSeat(
                                                            e,
                                                            item.Constituency_id,
                                                            item.Name,
                                                            item.ConstituencyNumber,
                                                         )
                                                      }}
                                                      sx={{ ml: 1, height: '15px', width: '15px' }}
                                                   >
                                                      <EditIcon sx={{ height: '15px', width: '15px' }} />
                                                   </IconButton>
                                                )}
                                                <br />
                                             </>
                                             {item.Strata === 'State' && (
                                                <>
                                                   {' Parent PC: '}
                                                   <Link
                                                      href={`/constituency/${item.ParentConstiName}/${item.ParentConsti_id}`}
                                                      sx={{ mr: 1, textDecoration: 'none !important' }}
                                                      rel="noreferrer"
                                                      target="_blank"
                                                   >
                                                      {item.ParentConstiName}
                                                   </Link>
                                                   <br />
                                                </>
                                             )}
                                             {' District: '}
                                             <>
                                                {item.DistrictName.map((itemDis, index) => (
                                                   <Fragment key={itemDis.District_id}>
                                                      {index + 1}.
                                                      <Link
                                                         key={itemDis.District_id}
                                                         // href={`/district/${itemDis.Name}/${itemDis.District_id}`}
                                                         sx={{
                                                            mr: 1,
                                                            ml: 0.5,
                                                            textDecoration: 'none !important',
                                                            color: '#000000',
                                                         }}
                                                         rel="noreferrer"
                                                         target="_blank"
                                                      >
                                                         {itemDis.Name}
                                                      </Link>
                                                   </Fragment>
                                                ))}

                                                {/* <Typography
                                                   sx={{ display: 'inline' }}
                                                   component="span"
                                                   variant="body2"
                                                   color="text.primary"
                                                >
                                                   {item.DistrictName.join(', ')}
                                                </Typography> */}
                                             </>
                                             {item.DivisionName.length > 0 && (
                                                <>
                                                   <br />
                                                   {' Division: '}
                                                   {item.DivisionName.map((itemDiv, index) => (
                                                      <Fragment key={itemDiv.Division_id}>
                                                         {index + 1}.
                                                         <Link
                                                            key={itemDiv.Division_id}
                                                            // href={`/division/${itemDiv.Name}/${itemDiv.Division_id}`}
                                                            sx={{
                                                               mr: 1,
                                                               ml: 0.5,
                                                               textDecoration: 'none !important',
                                                               color: '#000000',
                                                            }}
                                                            rel="noreferrer"
                                                            target="_blank"
                                                         >
                                                            {itemDiv.Name}
                                                         </Link>
                                                      </Fragment>
                                                   ))}
                                                   {/* <Typography
                                                      sx={{ display: 'inline' }}
                                                      component="span"
                                                      variant="body2"
                                                      color="text.primary"
                                                   >
                                                      {item.DivisionName.join(', ')}
                                                   </Typography> */}
                                                </>
                                             )}
                                             {legis.Strata === 'Central' && (
                                                <>
                                                   <br />
                                                   {' State / UT: '}
                                                   <Typography
                                                      sx={{ display: 'inline' }}
                                                      component="span"
                                                      variant="body2"
                                                      color="text.primary"
                                                   >
                                                      {item.StateName}
                                                   </Typography>
                                                </>
                                             )}
                                          </>
                                       }
                                    />
                                 </ListItem>
                                 <Divider />
                              </div>
                           ))}
                     </List>
                  )}
               </Box>
               <Box id="boxModals">
                  <Modal
                     open={openModalAddConstisToPhase}
                     onClose={handleCloseModalAddConstisToPhase}
                     aria-labelledby="modal-modal-title-addConstis"
                     aria-describedby="modal-modal-description-addConstis"
                     disableScrollLock
                  >
                     <Box sx={styleModalBox}>
                        <Typography
                           id="modal-modal-title-addConstis"
                           variant="h6"
                           component="h6"
                           align="center"
                           sx={styleModalTypography}
                        >
                           Add constituencies to phase {selectedPhase}
                        </Typography>
                        <AddConstisToPhase
                           constisListAvailable={constisListAvailable}
                           uploadingConstis={uploadingConstis}
                           uploadedConstis={uploadedConstis}
                           uploadingFailedConstis={uploadingFailedConstis}
                           handleAddConstisToPhase={handleAddConstisToPhase}
                           phasesList={phasesList}
                        ></AddConstisToPhase>
                     </Box>
                  </Modal>
                  <Modal
                     open={openModalEditSeat}
                     onClose={handleCloseModalEditSeat}
                     aria-labelledby="modal-modal-title-editSeat"
                     aria-describedby="modal-modal-description-editSeat"
                     disableScrollLock
                  >
                     <Box sx={styleModalBox}>
                        <Typography
                           id="modal-modal-title-editSeat"
                           variant="h6"
                           component="h6"
                           align="center"
                           sx={styleModalTypography}
                        >
                           Edit seat reservation for {'\n'}
                           {selectedConstiName}
                        </Typography>
                        <EditSeat
                           uploadingSeat={uploadingSeat}
                           uploadedSeat={uploadedSeat}
                           uploadingFailedSeat={uploadingFailedSeat}
                           handleEditSeat={handleEditSeat}
                        ></EditSeat>
                     </Box>
                  </Modal>
                  <Modal
                     open={openModalChangePhaseOfConsti}
                     onClose={handleCloseModalChangePhaseOfConsti}
                     aria-labelledby="modal-modal-title-changePhaseOfConsti"
                     aria-describedby="modal-modal-description-changePhaseOfConsti"
                     disableScrollLock
                  >
                     <Box sx={styleModalBox}>
                        <Typography
                           id="modal-modal-title-changePhaseOfConsti"
                           variant="h6"
                           component="h6"
                           align="center"
                           sx={styleModalTypography}
                        >
                           Change Phase of {'\n'}
                           {selectedConstiObj.Name}
                        </Typography>
                        <ChangePhaseOfConsti
                           selectedConstiObj={selectedConstiObj}
                           phasesList={phasesList}
                           uploadingPhaseChangeOfConsti={uploadingPhaseChangeOfConsti}
                           uploadedPhaseChangeOfConsti={uploadedPhaseChangeOfConsti}
                           uploadingFailedPhaseChangeOfConsti={uploadingFailedPhaseChangeOfConsti}
                           handleChangePhaseOfConsti={handleChangePhaseOfConsti}
                        ></ChangePhaseOfConsti>
                     </Box>
                  </Modal>
               </Box>
            </Box>
         )}
      </Box>
   )
}

ElecConstis.propTypes = {
   elec: PropTypes.object.isRequired,
   legis: PropTypes.object.isRequired,
   constisListFromElec: PropTypes.array.isRequired,
   constisListFromLegis: PropTypes.array.isRequired,

   handleConstituencyCandidatesLink: PropTypes.func.isRequired,
   handleConstisListRootUpdate: PropTypes.func.isRequired,

   candidatesListRoot: PropTypes.array.isRequired,
   handleCandidatesListRootUpdateForConsti: PropTypes.func.isRequired,
}

export default ElecConstis
