import { Box, List, ListItem, ListItemText, Typography } from '@mui/material'
import React from 'react'

function Contact() {
   const addresses = [`2/6A, Natural Green, BD-37, Rabindrapally, Kestopur, Kolkata - 700101`]

   const emails = [`admin@trackdemocracy.com`]
   return (
      <Box sx={{ minHeight: 800, m: 1, pb: 20, backgroundColor: '#FFFFF0' }}>
         <Box
            sx={{
               p: 2,
               fontWeight: 800,
               fontSize: 18,
               textAlign: 'center',
            }}
         >
            CONTACT
         </Box>
         <Box sx={{ p: 2 }}>
            <strong>Addresses:</strong>
            <br />
            <List sx={{ ml: 4 }}>
               {addresses.map((value, index) => (
                  <ListItem key={value} sx={{ p: 0, ml: 4 }}>
                     <ListItemText
                        sx={{ my: 0 }}
                        primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                        secondaryTypographyProps={{
                           whiteSpace: 'pre-line',
                           color: 'text.primary',
                           display: 'inline',
                           textAlign: 'left',
                        }}
                        primary={
                           <>
                              <Typography component="span" sx={{ fontWeight: 600, mr: 2 }}>
                                 {index + 1}.
                              </Typography>
                              <Typography component="span" sx={{ whiteSpace: 'pre-line' }}>
                                 {value}
                              </Typography>
                           </>
                        }
                     />
                  </ListItem>
               ))}
            </List>
         </Box>
         <Box sx={{ p: 2 }}>
            <strong>Emails:</strong>
            <br />
            <List sx={{ ml: 4 }}>
               {emails.map((value, index) => (
                  <ListItem key={value} sx={{ p: 0, ml: 4 }}>
                     <ListItemText
                        sx={{ my: 0 }}
                        primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                        secondaryTypographyProps={{
                           whiteSpace: 'pre-line',
                           color: 'text.primary',
                           display: 'inline',
                           textAlign: 'left',
                        }}
                        primary={
                           <>
                              <Typography component="span" sx={{ fontWeight: 600, mr: 2 }}>
                                 {index + 1}.
                              </Typography>
                              <Typography component="span" sx={{ whiteSpace: 'pre-line' }}>
                                 {value}
                              </Typography>
                           </>
                        }
                     />
                  </ListItem>
               ))}
            </List>
         </Box>
      </Box>
   )
}

export default Contact
