import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Alert, Box, Typography } from '@mui/material'

function ElecCandiWorks({ candi, elec }) {
   return (
      <div>
         <div>
            <Helmet>
               <title>{`Track Democracy - Works of candidate -${candi.Name} for ${elec.Name}`}</title>
            </Helmet>
         </div>
         <Typography
            variant="subtitle1"
            color="#FFFFFF"
            component="div"
            sx={{
               fontWeight: 500,
               backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
               px: 1,
               py: 0.5,
               whiteSpace: 'pre-line',
               lineHeight: 1.2,
            }}
         >
            Works by {candi.Name}: {'\n'} {candi.NameHindi} द्वारा कार्य:
         </Typography>
         {/* <Box>
            <Alert
               variant="outlined"
               severity="success"
               sx={{ mx: 10, mt: 5, justifyContent: 'center', alignItems: 'center' }}
            >
               <strong>Candidates can show their works here.</strong>
               <br />
               <br />
               <strong>प्रत्याशी यहां अपना कार्य दिखा सकते हैं।</strong>
            </Alert>
         </Box> */}
         <Box>
            <Alert
               variant="outlined"
               severity="success"
               sx={{ mx: 10, mt: 5, justifyContent: 'center', alignItems: 'center' }}
            >
               This facility will be available soon.
               <br />
               <br />
               यह सुविधा शीघ्र ही उपलब्ध होगी।
            </Alert>
         </Box>
      </div>
   )
}

ElecCandiWorks.propTypes = {
   candi: PropTypes.object.isRequired,
   elec: PropTypes.object.isRequired,
}

export default ElecCandiWorks
