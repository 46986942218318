import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
   Alert,
   AlertTitle,
   Box,
   Button,
   Divider,
   Link,
   List,
   ListItem,
   ListItemIcon,
   ListItemText,
   Modal,
   Typography,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectUsers } from '../../../redux/reducers/users-slice'
import { styleModalBox, styleModalTypography } from '../../../Utils/MUITheme/MUITheme'
import AddElecManifesto from './AddElecManifesto'
import {
   addDoc,
   arrayUnion,
   collection,
   doc,
   getDoc,
   getDocs,
   query,
   setDoc,
   updateDoc,
   where,
} from 'firebase/firestore'
import { auth, db, dbStorage } from '../../../FirebaseConfig'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import CloudOffIcon from '@mui/icons-material/CloudOff'
import { v4 } from 'uuid'
import moment from 'moment'
import { Helmet } from 'react-helmet'

function ElecManifestoes({ elec, candidatesListRoot }) {
   // console.log('value from props from inside Elec Manifestoes page: ', elec)

   const user = useSelector(selectUsers)
   const ip = user.ip

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   useEffect(() => {
      // console.log('states - first use effect entered')

      if (user.currentUser !== null) {
         // console.log('states - found user state not-null')
         setThisUser(user.currentUser.id)
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
         } else {
            setIsSuper(false)
            setIsDataEditor(false)
         }
      } else {
         // console.log('states - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   // related to manifestoes list
   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')
   const [manifestoesList, setManifestoesList] = useState([])
   const [partiesList, setPartiesList] = useState([])

   const fetchManifestoes = async () => {
      // Note:
      // manifestoes are already here in the elec object
      // however after adding a new manifesto, there was difficulty in displaying the new date under moment
      // hence this fucntion which will be used after adding a new manifesto
      try {
         const docRef = doc(db, 'Elections', elec.id, 'CoreLists', 'Manifestoes-0001')
         const docSnap = await getDoc(docRef)
         if (docSnap.exists()) {
            const listConsolidated = [...docSnap.data().Manifestoes].filter(
               (item) => item && Object.keys(item).length > 0,
            )
            const sortedManifestoes = listConsolidated.sort((a, b) => {
               if (b.DatePublished > a.DatePublished) {
                  return -1
               } else if (b.DatePublished < a.DatePublished) {
                  return 1
               } else {
                  return 0
               }
            })
            setManifestoesList(sortedManifestoes)
         }
      } catch (err) {
         console.log('error', err)
      }
   }

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         fetchManifestoes()
         setFirstLoadStatus('success')
      }
   }, [])

   // related to form filling and submission of manifestoes

   const [openModalAddManifesto, setOpenModalAddManifesto] = useState(false)

   const handleOpenModalAddManifesto = async (e) => {
      e.preventDefault()

      if (partiesList.length > 0) {
         setOpenModalAddManifesto(true)
      } else {
         /// fetch parties list
         // get the list of all parties that are associated with elected members
         const listConsolidated = candidatesListRoot.filter(
            (item) => item && Object.keys(item).length > 0 && item.Name && item.Name.toLowerCase() !== 'zznota',
         )
         // the above filter is used to weed out entries with som undefined data
         const parties = []
         console.log('listConsolidated', listConsolidated)
         listConsolidated.forEach((candi) => {
            if (candi.Name !== 'Zznota' || candi.Party_id !== '') {
               const partyObj = {
                  Party_id: candi.Party_id,
                  PartyName: candi.PartyName,
                  PartyNameHindi: candi.PartyNameHindi,
                  PartyNameLocal: candi.PartyNameLocal,
                  PartyAbbreviation: candi.PartyAbbreviation,
                  PartyFlag: candi.PartyFlag,
               }
               parties.push(partyObj)
            }
         })
         // now create a list of unique items in parties list
         // in the following code, we can have multiple conditions
         if (parties && parties.length > 0) {
            const partiesUnique = parties.filter(
               (ele, ind) => ind === parties.findIndex((elem) => elem.Party_id === ele.Party_id),
            )
            console.log('partiesUnique', partiesUnique)
            const sortedParties = partiesUnique.sort((a, b) => {
               if (b.PartyName.trim() > a.PartyName.trim()) {
                  return -1
               } else if (b.PartyName.trim() < a.PartyName.trim()) {
                  return 1
               } else {
                  return 0
               }
            })

            setPartiesList(sortedParties)
         }
      }
   }

   useEffect(() => {
      if (partiesList && partiesList.length > 0) {
         setOpenModalAddManifesto(true)
      }
   }, [partiesList])

   const handleCloseModalAddManifesto = () => setOpenModalAddManifesto(false)

   const [lastManifesto, setLastManifesto] = useState('')
   const [uploading, setUploading] = useState(false)
   const [uploaded, setUploaded] = useState(false)
   const [uploadingFailed, setUploadingFailed] = useState(false)

   const handleAddManifesto = async (manifestoDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (manifestoDetails.Name.length > 3) {
               manifestoDetails.CrBy = auth.currentUser.uid
               manifestoDetails.CrDt = new Date()
               manifestoDetails.CrIP = ip.data.ip

               manifestoDetails.Legislature_id = elec.Legislature_id
               manifestoDetails.LegislatureName = elec.LegislatureName
               manifestoDetails.LegislatureNameHindi = elec.LegislatureNameHindi
               manifestoDetails.LegislatureNameLocal = elec.LegislatureNameLocal
               manifestoDetails.HouseNumber = elec.HouseNumber

               manifestoDetails.Election_id = elec.id
               manifestoDetails.ElectionName = elec.Name
               manifestoDetails.ElectionNameHindi = elec.NameHindi
               manifestoDetails.ElectionNameLocal = elec.NameLocal
               manifestoDetails.Country_id = elec.Country_id
               manifestoDetails.State_id = elec.State_id
               manifestoDetails.Division_id = elec.Division_id
               manifestoDetails.District_id = elec.District_id
               manifestoDetails.SubDistrict_id = elec.SubDistrict_id
               manifestoDetails.Block_id = elec.Block_id
               manifestoDetails.Village_id = elec.Village_id

               // const partyIdsList = []
               if (manifestoDetails.IssuedByParty_id !== '') {
                  //
                  const queryAlliance = query(
                     collection(db, 'PPAlliances'),
                     where('Party_id_s', 'array-contains', manifestoDetails.IssuedByParty_id),
                  )
                  const querySnapshotAlliance = await getDocs(queryAlliance)
                  querySnapshotAlliance.forEach(async (docSnap) => {
                     manifestoDetails.Alliances.push({
                        Alliance_id: docSnap.id,
                        Name: docSnap.data().Name,
                        NameHindi: docSnap.data().NameHindi,
                        NameLocal: docSnap.data().NameLocal,
                        Abbreviation: docSnap.data().Abbreviation,
                        Parties: docSnap.data().Parties,
                     })

                     const partyIdsList = [...manifestoDetails.SupportingParties, ...docSnap.data().Party_id_s]
                     const uniquePartyIdsList = partyIdsList.filter((val, id, array) => array.indexOf(val) === id)
                     manifestoDetails.SupportingParties = uniquePartyIdsList
                  })
               }

               if (manifestoDetails.IssuedByAlliance_id !== '') {
                  //
                  const docRefAlliance = doc(db, 'PPAlliances', manifestoDetails.IssuedByAlliance_id)
                  const docSnapAlliance = await getDoc(docRefAlliance)

                  manifestoDetails.SupportingParties = docSnapAlliance.data().Party_id_s
               }

               // manifestoDetails.SupportingParties = partyIdsList

               // const uniquePartyIdsList = partyIdsList.filter((val, id, array) => array.indexOf(val) === id)

               console.log('manifestoDetails 1: ', manifestoDetails)

               setLastManifesto(manifestoDetails.Name.toString())
               setUploading(true)

               console.log('manifestoDetails 2: ', manifestoDetails, ' uploading: ', uploading)

               const record = {
                  Manifesto_id: '',
                  Name: manifestoDetails.Name,
                  NameHindi: manifestoDetails.NameHindi,
                  NameLocal: manifestoDetails.NameLocal,
                  IssuedByParty_id: manifestoDetails.IssuedByParty_id,
                  IssuedByPartyName: manifestoDetails.IssuedByPartyName,
                  IssuedByPartyNameHindi: manifestoDetails.IssuedByPartyNameHindi,
                  IssuedByPartyNameLocal: manifestoDetails.IssuedByPartyNameLocal,
                  IssuedByPartyAbbreviation: manifestoDetails.IssuedByPartyAbbreviation,
                  IssuedByPartyFlag: manifestoDetails.IssuedByPartyFlag,
                  IssuedByAlliance_id: manifestoDetails.IssuedByAlliance_id,
                  IssuedByAllianceName: manifestoDetails.IssuedByAllianceName,
                  IssuedByAllianceNameHindi: manifestoDetails.IssuedByAllianceNameHindi,
                  IssuedByAllianceNameLocal: manifestoDetails.IssuedByAllianceNameLocal,
                  IssuedByAllianceAbbreviation: manifestoDetails.IssuedByAllianceAbbreviation,
                  IssuedByAllianceLogo: manifestoDetails.IssuedByAllianceLogo,
                  SupportingParties: manifestoDetails.SupportingParties,
                  Legislature_id: elec.Legislature_id,
                  LegislatureName: elec.LegislatureName,
                  LegislatureNameHindi: elec.LegislatureNameHindi,
                  LegislatureNameLocal: elec.LegislatureNameLocal,
                  HouseNumber: elec.HouseNumber,
                  Election_id: elec.id,
                  ElectionName: elec.Name,
                  ElectionNameHindi: elec.NameHindi,
                  ElectionNameLocal: elec.NameLocal,
                  Country_id: elec.Country_id,
                  State_id: elec.State_id,
                  Division_id: elec.Division_id,
                  District_id: elec.District_id,
                  SubDistrict_id: elec.SubDistrict_id,
                  Block_id: elec.Block_id,
                  Village_id: elec.Village_id,
                  Alliances: manifestoDetails.Alliances,
                  DateReleased: manifestoDetails.DateReleased,
               }

               try {
                  // console.log('add start')
                  let recordNew = {}

                  // sub collection of election
                  // const docRefSubColElec = doc(db, 'Elections', elec.id, 'CoreLists', 'Manifestoes-0001')
                  // const docSnapSubColElec = await getDoc(docRefSubColElec)

                  // sub collection of issuing party

                  // sub collection of issuing alliance

                  if (manifestoDetails.Image !== '') {
                     console.log('record inside image block: ', record)
                     const imageRef = ref(
                        dbStorage,
                        `ElectionManifestoes/${elec.id}/${manifestoDetails.Image.name + v4()}`,
                     )
                     await uploadBytes(imageRef, manifestoDetails.Image).then((snapshot) => {
                        getDownloadURL(snapshot.ref).then(async (url) => {
                           manifestoDetails = {
                              ...manifestoDetails,
                              Image: url,
                              ImagesOld: [
                                 {
                                    Image: url,
                                    CrBy: auth.currentUser.uid,
                                    CrDt: new Date(),
                                    CrIP: ip.data.ip,
                                 },
                              ],
                           }
                           console.log('manifestoDetails inside image block: ', url, manifestoDetails)
                           const docRef = await addDoc(collection(db, 'ElectionManifestoes'), manifestoDetails)
                           manifestoDetails.id = docRef.id

                           recordNew = { ...record, Manifesto_id: docRef.id, Image: url }
                           console.log('record new after iamge upload: ', recordNew)

                           setLastManifesto(manifestoDetails.Name)

                           updateCoreListsWithNewRecord(manifestoDetails, recordNew)
                        })
                     })
                  } else {
                     const docRef = await addDoc(collection(db, 'ElectionManifestoes'), manifestoDetails)
                     manifestoDetails.id = docRef.id

                     recordNew = { ...record, Manifesto_id: docRef.id }
                     console.log('record new without iamge upload: ', recordNew)

                     setLastManifesto(manifestoDetails.Name)

                     updateCoreListsWithNewRecord(manifestoDetails, recordNew)
                  }
               } catch (error) {
                  alert('Error adding election 22: ', error.message)
                  console.log('Error adding electione', error)
                  setUploading(false)
                  setUploaded(false)
                  setUploadingFailed(true)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const updateCoreListsWithNewRecord = async (manifestoDetails, recordNew) => {
      const docRefSubColElec = doc(db, 'Elections', elec.id, 'CoreLists', 'Manifestoes-0001')
      const docSnapSubColElec = await getDoc(docRefSubColElec)
      /// / 1.
      console.log('record new before insert: ', recordNew)
      if (docSnapSubColElec.exists()) {
         await updateDoc(docRefSubColElec, {
            Manifestoes: arrayUnion(recordNew),
         })
      } else {
         setDoc(docRefSubColElec, { Manifestoes: [recordNew] }, { merge: true })
      }
      // 2.
      if (manifestoDetails.IssuedByParty_id !== '') {
         console.log('Part_id')
         const docRefSubColParty = doc(
            db,
            'PoliticalParties',
            manifestoDetails.IssuedByParty_id,
            'CoreLists',
            'Manifestoes-0001',
         )
         const docSnapSubColParty = await getDoc(docRefSubColParty)

         if (docSnapSubColParty.exists()) {
            await updateDoc(docRefSubColParty, {
               Manifestoes: arrayUnion(recordNew),
            })
         } else {
            setDoc(docRefSubColParty, { Manifestoes: [recordNew] }, { merge: true })
         }
      }
      // 3.
      if (manifestoDetails.IssuedByAlliance_id !== '') {
         console.log('Alliance_id')
         const docRefSubColAlliance = doc(
            db,
            'PPAlliances',
            manifestoDetails.IssuedByAlliance_id,
            'CoreLists',
            'Manifestoes-0001',
         )
         const docSnapSubColAlliance = await getDoc(docRefSubColAlliance)
         if (docSnapSubColAlliance.exists()) {
            await updateDoc(docRefSubColAlliance, {
               Manifestoes: arrayUnion(recordNew),
            })
         } else {
            setDoc(docRefSubColAlliance, { Manifestoes: [recordNew] }, { merge: true })
         }
      }

      // 4.
      if (manifestoDetails.SupportingParties.length > 0) {
         console.log('Supporting Parties')
         manifestoDetails.SupportingParties.forEach(async (item) => {
            const docRefSubCol = doc(db, 'PoliticalParties', item, 'CoreLists', 'Manifestoes-0001')
            const docSnapSubCol = await getDoc(docRefSubCol)
            if (docSnapSubCol.exists()) {
               await updateDoc(docRefSubCol, {
                  Manifestoes: arrayUnion(recordNew),
               })
            } else {
               setDoc(docRefSubCol, { Manifestoes: [recordNew] }, { merge: true })
            }
         })
      }

      // 5.
      if (manifestoDetails.Alliances.length > 0) {
         console.log('Alliances')
         manifestoDetails.Alliances.forEach(async (item) => {
            const docRefSubCol = doc(db, 'PPAlliances', item.Alliance_id, 'CoreLists', 'Manifestoes-0001')
            const docSnapSubCol = await getDoc(docRefSubCol)
            if (docSnapSubCol.exists()) {
               await updateDoc(docRefSubCol, {
                  Manifestoes: arrayUnion(recordNew),
               })
            } else {
               setDoc(docRefSubCol, { Manifestoes: [recordNew] }, { merge: true })
            }
         })
      }

      // Note:
      // setManifestoesList([...manifestoesList, manifestoDetails]) was causing problem / error for date adjustment in the list
      // hence, on insert, we fetch the election data afresh, as follows

      fetchManifestoes()

      setUploading(false)
      setUploaded(true)
      setUploadingFailed(false)
   }

   return (
      <Box>
         <div>
            <Helmet>
               <title>{`Track Democracy - Manifestoes of ${elec.Name}`}</title>
            </Helmet>
         </div>
         <Box
            sx={{
               p: 1,
               mt: 0.5,
               backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
               color: '#ffffff',
            }}
         >
            <Box>Manifestoes:</Box>
            <Box>घोषणापत्र:</Box>
         </Box>
         {!navigator.onLine && (
            <div>
               <Alert
                  variant="outlined"
                  severity="error"
                  sx={{ margin: 5, justifyContent: 'center', alignItems: 'center' }}
               >
                  <AlertTitle>
                     You are offline !
                     <CloudOffIcon sx={{ ml: 5 }} />
                  </AlertTitle>
               </Alert>
            </div>
         )}
         {navigator.onLine && (
            <Box>
               <Box>
                  <Box sx={{ display: 'flex', px: 1, mt: 1 }}>
                     {(isSuper || isDataEditor) && (
                        <Button
                           onClick={(e) => {
                              handleOpenModalAddManifesto(e)
                           }}
                           size="small"
                           variant="outlined"
                           sx={{ py: 0, px: 1, minWidth: 0 }}
                        >
                           Add a manifesto
                        </Button>
                     )}
                  </Box>
                  <Box sx={{ m: 1 }}>
                     {manifestoesList && manifestoesList.length > 0 ? (
                        <List dense sx={{ py: 0.5 }}>
                           {manifestoesList.map((item, index) => (
                              <Box key={item.Manifesto_id}>
                                 <ListItem sx={{ p: 0, ml: 0 }} key={item.Manifesto_id}>
                                    <ListItemIcon sx={{ minWidth: '50px !important' }}>
                                       {item.Image && (
                                          <Box
                                             key={item.Manifesto_id}
                                             component="img"
                                             src={item.Image}
                                             alt=""
                                             sx={{ height: '50px' }}
                                          />
                                       )}
                                    </ListItemIcon>
                                    <ListItemText
                                       sx={{ ml: 1, fontSize: 13, p: 0 }}
                                       secondaryTypographyProps={{
                                          fontSize: 12,
                                          color: 'Crimson',
                                          pr: 1,
                                          align: 'left',
                                       }}
                                       primaryTypographyProps={{ fontSize: 12 }}
                                       primary={
                                          <>
                                             <Link
                                                href={`/election/${elec.Name}/manifesto/${item.Manifesto_id}`}
                                                sx={{ textDecoration: 'none !important' }}
                                                target="_blank"
                                                rel="noopener"
                                             >
                                                <strong>{item.Name.toUpperCase()}</strong>
                                             </Link>

                                             {item.NameLocal && (
                                                <>
                                                   <br />
                                                   <Link
                                                      href={`/election/${elec.Name}/manifesto/${item.Manifesto_id}`}
                                                      sx={{ textDecoration: 'none !important' }}
                                                      target="_blank"
                                                      rel="noopener"
                                                   >
                                                      <strong>{item.NameLocal.toUpperCase()}</strong>
                                                   </Link>
                                                </>
                                             )}
                                             {!item.NameLocal && (
                                                <>
                                                   <br />
                                                   <Link
                                                      href={`/election/${elec.Name}/manifesto/${item.Manifesto_id}`}
                                                      sx={{ textDecoration: 'none !important' }}
                                                      target="_blank"
                                                      rel="noopener"
                                                   >
                                                      <strong>{item.NameHindi.toUpperCase()}</strong>
                                                   </Link>
                                                </>
                                             )}
                                          </>
                                       }
                                       secondary={
                                          <>
                                             {item.DateReleased && (
                                                <>{moment(item.DateReleased.toDate()).format('Do MMM YYYY')}</>
                                             )}{' '}
                                             By:{' '}
                                             <strong>
                                                {item.IssuedByParty_id && <>{item.IssuedByPartyName}</>}
                                                {item.IssuedByAlliance_id && <>{item.IssuedByAllianceName}</>}
                                             </strong>
                                          </>
                                       }
                                    />
                                 </ListItem>
                                 <Divider />
                              </Box>
                           ))}
                        </List>
                     ) : (
                        <Box>
                           <Alert
                              variant="outlined"
                              severity="warning"
                              sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
                           >
                              <AlertTitle sx={{ fontSize: 12 }}>
                                 Manifestoes to be available soon. <br /> घोषणा पत्र शीघ्र उपलब्ध होंगे |
                              </AlertTitle>
                           </Alert>
                        </Box>
                     )}
                  </Box>
                  <Box id="BoxModals">
                     <Modal
                        open={openModalAddManifesto}
                        onClose={handleCloseModalAddManifesto}
                        aria-labelledby="modal-modal-title-addmanifesto"
                        aria-describedby="modal-modal-description-addmanifesto"
                        disableScrollLock
                     >
                        <Box sx={styleModalBox}>
                           <Typography
                              id="modal-modal-title-addmanifesto"
                              variant="h6"
                              component="h6"
                              align="center"
                              sx={styleModalTypography}
                           >
                              Add a manifesto
                           </Typography>
                           <Box>
                              <AddElecManifesto
                                 partiesList={partiesList}
                                 lastManifesto={lastManifesto}
                                 uploading={uploading}
                                 uploaded={uploaded}
                                 uploadingFailed={uploadingFailed}
                                 handleAddManifesto={handleAddManifesto}
                              ></AddElecManifesto>
                           </Box>
                        </Box>
                     </Modal>
                  </Box>
               </Box>
            </Box>
         )}
      </Box>
   )
}

ElecManifestoes.propTypes = {
   elec: PropTypes.object.isRequired,
   candidatesListRoot: PropTypes.array.isRequired,
}

export default ElecManifestoes
