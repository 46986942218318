import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
   Avatar,
   Box,
   Divider,
   IconButton,
   Link,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   Typography,
} from '@mui/material'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { selectUsers } from '../../redux/reducers/users-slice'
import { auth } from '../../FirebaseConfig'

import DeleteIcon from '@mui/icons-material/Delete'

import styles from './Constituency.module.css'
import { Helmet } from 'react-helmet'
import moment from 'moment'

function ConstituencyMembers({ constituency, electedMembers, legislature }) {
   //
   //
   const user = useSelector(selectUsers)
   // const ip = user.ip
   // const countries = useSelector(selectCountries).countriesList

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   useEffect(() => {
      console.log('states - first use effect entered')

      if (user.currentUser !== null) {
         console.log('states - found user state not-null')
         setThisUser(user.currentUser.id)
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
         } else {
            setIsSuper(false)
            setIsDataEditor(false)
         }
      } else {
         console.log('states - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')
   const [membersList, setMembersList] = useState('')

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         //
         if (electedMembers && electedMembers.length > 0) {
            const sortedMembers = electedMembers.sort((a, b) => b.HouseNumber - a.HouseNumber)
            setMembersList(sortedMembers)
         } else {
            setMembersList([])
         }
         setFirstLoadStatus('success')
      }
   }, [])

   const handleDelete = async (e, item) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isSuper || isDataEditor) {
            if (confirm('Are you sure you want to remove this member from the list?')) {
               try {
                  //
                  // await deleteDoc(doc(db, 'ElectionCandidates', thisCandidateId))
               } catch (error) {
                  alert('Error deleting district')
                  console.log(error)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   return (
      <Box sx={{ px: 1, minHeight: 600 }}>
         <div>
            <Helmet>
               <title>{`Track Democracy - Elected members of ${constituency.Name}(${constituency.ConstituencyNumber}) - Constituency of ${constituency.LegislatureName}`}</title>
            </Helmet>
         </div>
         <Typography
            variant="subtitle1"
            color="#FFFFFF"
            component="div"
            sx={{
               fontWeight: 500,
               backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
               pl: 1,
            }}
         >
            Elected Members / <strong>निर्वाचित सदस्य</strong>:
         </Typography>
         <Box>
            <List dense>
               {membersList &&
                  membersList.length > 0 &&
                  membersList.map((item, index) => (
                     <div key={item.LegisMem_id}>
                        <ListItem
                           alignItems="flex-start"
                           sx={{ pl: 0, ml: 0 }}
                           key={item.LegisMem_id}
                           secondaryAction={
                              <Box sx={{ ml: 'auto' }}>
                                 {isSuper && (
                                    <IconButton
                                       edge="end"
                                       aria-label="delete"
                                       onClick={(e) => {
                                          handleDelete(e, item)
                                       }}
                                       sx={{ mr: 1, height: '25px', width: '25px' }}
                                    >
                                       <DeleteIcon fontSize="small" />
                                    </IconButton>
                                 )}
                              </Box>
                           }
                        >
                           <ListItemAvatar sx={{ display: 'flex', flexDirection: 'column' }}>
                              <Typography
                                 component="span"
                                 variant="body2"
                                 sx={{ color: 'text.primary', display: 'inline', fontWeight: 600, mb: 1 }}
                              >
                                 {index + 1}.
                              </Typography>
                              <Avatar alt={item.Name} src={item.Image} />
                           </ListItemAvatar>
                           <ListItemText
                              secondaryTypographyProps={{ align: 'left', whiteSpace: 'pre-line' }}
                              primary={
                                 <>
                                    <Link
                                       underline="hover"
                                       href={`/${legislature.Name}/member/${item.Name}/${item.LegisMem_id}`}
                                       sx={{ textDecoration: 'none !important' }}
                                       target="_blank"
                                       rel="noopener"
                                    >
                                       {item.Name.toUpperCase()}
                                    </Link>{' '}
                                    .{' '}
                                    {item.NameLocal && (
                                       <Link
                                          href={`/${legislature.Name}/member/${item.Name}/${item.LegisMem_id}`}
                                          sx={{ textDecoration: 'none !important' }}
                                          target="_blank"
                                          rel="noopener"
                                       >
                                          {item.NameLocal}
                                       </Link>
                                    )}{' '}
                                    .{' '}
                                    <Link
                                       underline="none"
                                       href={`/${legislature.Name}/member/${item.Name}/${item.LegisMem_id}`}
                                       color="warning"
                                       sx={{ textDecoration: 'none !important' }}
                                       target="_blank"
                                       rel="noopener"
                                    >
                                       {item.NameHindi}
                                       {'  '}
                                    </Link>
                                 </>
                              }
                              secondary={
                                 <>
                                    <>
                                       <Typography
                                          component="span"
                                          variant="body2"
                                          sx={{ color: 'text.primary', display: 'inline', mr: 1 }}
                                       >
                                          Seat type:
                                       </Typography>
                                       {` ${item.SeatType}`}
                                    </>
                                    {legislature.Strata === 'Central' && (
                                       <>
                                          <br />
                                          <Typography
                                             component="span"
                                             variant="body2"
                                             sx={{ color: 'text.primary', display: 'inline', mr: 1 }}
                                          >
                                             State / राज्य:
                                          </Typography>
                                          {item.StateName}
                                       </>
                                    )}
                                    <>
                                       <br />
                                       {item.PartyFlag && (
                                          <Link
                                             href={`/political-party/${item.PartyName}/${item.Party_id}`}
                                             target="_blank"
                                             rel="noopener"
                                          >
                                             <img src={item.PartyFlag} className={styles.imgX2} />
                                          </Link>
                                       )}
                                       {item.PartyName !== 'Independent' && (
                                          <Link
                                             href={`/political-party/${item.PartyName}/${item.Party_id}`}
                                             target="_blank"
                                             rel="noopener"
                                             sx={{ textDecoration: 'none !important' }}
                                          >
                                             {item.PartyName}
                                          </Link>
                                       )}
                                       {item.PartyName === 'Independent' && <>{item.PartyName}</>}
                                    </>
                                    <>
                                       <br />
                                       <Typography
                                          component="span"
                                          variant="body2"
                                          sx={{ color: 'text.primary', display: 'inline', mr: 1 }}
                                       >
                                          Tenure:
                                       </Typography>
                                       {moment(item.FromDate.toDate()).format('Do MMMM YYYY')} to
                                       {item.ToDate !== ''
                                          ? ` ${moment(item.ToDate.ToDate.toDate()).format('Do MMMM YYYY')}`
                                          : ' till date'}
                                    </>
                                 </>
                              }
                           />
                        </ListItem>

                        <Divider sx={{ width: '80%' }} />
                     </div>
                  ))}
            </List>
         </Box>
      </Box>
   )
}

ConstituencyMembers.propTypes = {
   legislature: PropTypes.object.isRequired,
   constituency: PropTypes.object.isRequired,
   electedMembers: PropTypes.array.isRequired,
}

export default ConstituencyMembers
