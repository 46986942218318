import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Alert, Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import MyLoaderCircularGradient from '../../../Utils/Loaders/MyLoaderCircularGradient'

function ChangePhaseOfConsti({
   phasesList,
   selectedConstiObj,
   handleChangePhaseOfConsti,
   uploadingPhaseChangeOfConsti,
   uploadedPhaseChangeOfConsti,
   uploadingFailedPhaseChangeOfConsti,
}) {
   const [selectedPhase, setSelectedPhase] = useState('')

   const handleChangePhase = (e) => {
      //
      setSelectedPhase(e.target.value)
   }

   const handleSubmitNewPhase = async (e) => {
      e.preventDefault()

      // console.log('phase before submit: ', selectedPhase)
      if (selectedPhase !== '') {
         handleChangePhaseOfConsti(selectedPhase)
      }
      setSelectedPhase('')
   }

   return (
      <Box sx={{ m: 2 }}>
         <Box>
            <Typography>
               The present phase for this constituency is{' '}
               {selectedConstiObj.PhaseNum ? selectedConstiObj.PhaseNum : `"All"`}
            </Typography>
         </Box>
         <Box sx={{ mt: 2 }}>
            <Typography sx={{ textAlign: 'left' }}>Select a new phase for this constituency:</Typography>
            <FormControl size="small" sx={{ mt: 1 }}>
               <InputLabel>New Phase / नया चरण</InputLabel>
               <Select
                  value={selectedPhase}
                  label="New Phase / नया चरण"
                  onChange={(e) => {
                     handleChangePhase(e)
                  }}
                  sx={{ backgroundColor: '#FFFFFF', width: '30ch' }}
               >
                  <MenuItem value="">
                     <em>All</em>
                  </MenuItem>
                  {phasesList &&
                     phasesList.length > 0 &&
                     phasesList.map((item) => (
                        <MenuItem key={item} value={item}>
                           {item}
                        </MenuItem>
                     ))}
               </Select>
            </FormControl>
         </Box>
         <Box sx={{ mb: 4 }}>
            {uploadingPhaseChangeOfConsti && (
               <MyLoaderCircularGradient title={'Changing phase ...'}></MyLoaderCircularGradient>
            )}
            {uploadedPhaseChangeOfConsti && (
               <Alert variant="outlined" severity="success">
                  <strong>Phase changed to ...</strong>.
               </Alert>
            )}
            {uploadingFailedPhaseChangeOfConsti && (
               <Alert variant="outlined" severity="error">
                  Error occured! <strong>Phase could not be changed</strong>.
               </Alert>
            )}
         </Box>
         <Box sx={{ pl: 2 }}>
            <Button
               type="submit"
               align="center"
               variant="contained"
               color="primary"
               size="small"
               onClick={(e) => {
                  handleSubmitNewPhase(e)
               }}
               disabled={selectedPhase === '' || selectedPhase === selectedConstiObj.PhaseNum}
            >
               Submit
            </Button>
         </Box>
      </Box>
   )
}

ChangePhaseOfConsti.propTypes = {
   phasesList: PropTypes.array.isRequired,
   selectedConstiObj: PropTypes.object.isRequired,
   handleChangePhaseOfConsti: PropTypes.func.isRequired,
   uploadingPhaseChangeOfConsti: PropTypes.bool.isRequired,
   uploadedPhaseChangeOfConsti: PropTypes.bool.isRequired,
   uploadingFailedPhaseChangeOfConsti: PropTypes.bool.isRequired,
}

export default ChangePhaseOfConsti
