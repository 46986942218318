import {
   Alert,
   Box,
   Button,
   FormControl,
   FormControlLabel,
   FormLabel,
   InputLabel,
   MenuItem,
   Radio,
   RadioGroup,
   Select,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { selectPParties } from '../../redux/reducers/pParties-slice'
import MyLoaderCircularGradient from '../../Utils/Loaders/MyLoaderCircularGradient'

function AddParty({ handleAddParty, lastParty, uploadingParty, uploadedParty, uploadingFailedParty }) {
   const pParties = useSelector(selectPParties)

   const [partiesList, setPartiesList] = useState([])
   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         setPartiesList(pParties.pPartiesList)

         setFirstLoadStatus('success')
      }
   }, [])

   const [partyDetails, setPartyDetails] = useState({
      Party_id: '',
      PartyName: '',
      PartyNameHindi: '',
      PartyNameLocal: '',
      PartyAbbreviation: '',
      PartyFlag: '',
      PartySymbol: '',
   })

   const [selectedPoliticalAffiliationType, setSelectedPoliticalAffiliationType] = useState('')
   const [enablePartiesList, setEnablePartiesList] = useState(false)
   const [selectedPartyId, setSelectedPartyId] = useState('')

   function handlePoliticalAffiliationType(e) {
      e.preventDefault()

      setSelectedPoliticalAffiliationType(e.target.value)

      setSelectedPartyId('')
      setEnablePartiesList(false)
      setPartyDetails({
         Party_id: '',
         PartyName: '',
         PartyNameHindi: '',
         PartyNameLocal: '',
         PartyAbbreviation: '',
         PartyFlag: '',
         PartySymbol: '',
      })

      if (e.target.value === 'PoliticalParty') {
         setEnablePartiesList(true)
      } else {
         setEnablePartiesList(false)
         setPartyDetails({
            Party_id: '',
            PartyName: 'Independent',
            PartyNameHindi: 'निर्दलीय',
            PartyNameLocal: '',
            PartyAbbreviation: '',
            PartyFlag: '',
            PartySymbol: '',
         })
      }
   }

   function handleChangeParty(e) {
      e.preventDefault()

      if (e.target.value !== '') {
         setSelectedPartyId(e.target.value)
         setEnablePartiesList(true)

         const party = partiesList.find((item) => item.Party_id === e.target.value)
         console.log('party flag', party.Flag)
         console.log('party name', party.Name)
         setPartyDetails({
            Party_id: party.Party_id,
            PartyName: party.Name,
            PartyNameHindi: party.NameHindi,
            PartyNameLocal: party.NameLocal,
            PartyAbbreviation: party.Abbreviation,
            PartyFlag: party.Flag,
            PartySymbol: party.Symbol,
         })
      } else {
         setSelectedPartyId('')
         setEnablePartiesList(false)
         setPartyDetails({
            Party_id: '',
            PartyName: '',
            PartyNameHindi: '',
            PartyNameLocal: '',
            PartyAbbreviation: '',
            PartyFlag: '',
            PartySymbol: '',
         })
      }
   }

   const handleSubmitParty = async (e) => {
      e.preventDefault()
      if (partyDetails.PartyName !== '') {
         console.log('partyDetails before submit: ', partyDetails)

         handleAddParty(partyDetails)

         setPartyDetails({
            Party_id: '',
            PartyName: '',
            PartyNameHindi: '',
            PartyNameLocal: '',
            PartyAbbreviation: '',
            PartyFlag: '',
            PartySymbol: '',
         })
         setSelectedPoliticalAffiliationType('')
         setSelectedPartyId('')
         setEnablePartiesList(false)
      }
   }
   return (
      <Box sx={{ display: 'flex', flexDirection: 'column', border: 1, borderRadius: 1, mb: 1 }}>
         <FormControl
            sx={{
               py: 0,
               px: 1,
            }}
         >
            <FormLabel id="demo-controlled-radio-buttons-group">Political Affiliation Type:</FormLabel>
            <RadioGroup
               row
               aria-labelledby="demo-controlled-radio-buttons-group"
               name="PoliticalAffiliationType"
               value={selectedPoliticalAffiliationType}
               // style={{ display: 'flex', gap: '2rem' }}
               onChange={(e) => {
                  handlePoliticalAffiliationType(e)
               }}
               sx={{
                  backgroundColor: '#ffffff',
                  borderRadius: 1,
                  py: 0,
                  '& .MuiSvgIcon-root': {
                     fontSize: 15,
                  },
                  '& .MuiButtonBase-root': {
                     py: 0,
                  },
                  '& MuiFormControlLabel-label': {
                     fontSize: 13,
                     lineHeight: 16,
                     py: 0,
                  },
               }}
            >
               <FormControlLabel
                  value="Independent"
                  control={<Radio size="small" />}
                  label="Independent"
                  sx={{ py: 0, fontSize: 15 }}
               />
               <FormControlLabel
                  value="PoliticalParty"
                  control={<Radio size="small" />}
                  label="Political Party"
                  sx={{ py: 0, fontSize: 15 }}
               />
            </RadioGroup>
         </FormControl>
         <FormControl sx={{ m: 1 }} size="small" disabled={!enablePartiesList}>
            <InputLabel>Party</InputLabel>
            <Select
               value={selectedPartyId}
               label="Party"
               onChange={(e) => {
                  handleChangeParty(e)
               }}
               sx={{ backgroundColor: '#ffffff' }}
            >
               <MenuItem value="">
                  <em>None</em>
               </MenuItem>
               {partiesList &&
                  partiesList.map((item) => (
                     <MenuItem key={item.Party_id} value={item.Party_id} sx={{ fontSize: 13 }}>
                        {item.Name} - <strong> {item.Abbreviation}</strong>
                     </MenuItem>
                  ))}
            </Select>
         </FormControl>
         <Box>
            <Button
               type="submit"
               align="center"
               variant="contained"
               color="primary"
               size="small"
               onClick={(e) => {
                  handleSubmitParty(e)
               }}
               disabled={partyDetails.PartyName === ''}
            >
               Submit
            </Button>
         </Box>
         <Box>
            {uploadingParty && <MyLoaderCircularGradient title={'Adding the party ...'}></MyLoaderCircularGradient>}
            {uploadedParty && (
               <Alert variant="outlined" severity="success">
                  <strong>{lastParty}</strong> listed successfully.
               </Alert>
            )}
            {uploadingFailedParty && (
               <Alert variant="outlined" severity="error">
                  Error occured! <strong>{lastParty}</strong> could not be listed.
               </Alert>
            )}
         </Box>
      </Box>
   )
}

AddParty.propTypes = {
   handleAddParty: PropTypes.func.isRequired,
   lastParty: PropTypes.string.isRequired,
   uploadingParty: PropTypes.bool.isRequired,
   uploadedParty: PropTypes.bool.isRequired,
   uploadingFailedParty: PropTypes.bool.isRequired,
}

export default AddParty
