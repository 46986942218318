import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
// import { CardMedia } from '@mui/material'
// import VideoJS from '../../Components/VideoJS'
// import videojs from 'video.js'
import Microlink from '@microlink/react'
import { Box, Typography } from '@mui/material'

function ShortsCarouselHome() {
   const shortsVideos = [
      {
         sources: 'https://www.youtube.com/shorts/pawipPYXJNo',
         // subtitle: 'By Blender Foundation',
         // thumb: 'images/BigBuckBunny.jpg',
         title: 'Delhi Elections 2025 | Kalkaji | Candidates | Online Support',
         // id: '1',
      },
      {
         sources: 'https://www.youtube.com/shorts/FklsMZIOxOU',
         // subtitle: 'By Blender Foundation',
         // thumb: 'images/ElephantsDream.jpg',
         title: 'Delhi Elections 2025 | Karol Bagh | Candidates | Online Support',
         // id: '2',
      },
      {
         sources: 'https://www.youtube.com/shorts/LGrNWuWI6_c',
         // subtitle: 'By Blender Foundation',
         // thumb: 'images/BigBuckBunny.jpg',
         title: 'Delhi Elections 2025 | Uttam Nagar | Candidates | Online Support',
         // id: '3',
      },
      {
         sources: 'https://www.youtube.com/shorts/41jsrYKyNBA',
         // subtitle: 'By Blender Foundation',
         // thumb: 'images/ElephantsDream.jpg',
         title: 'Delhi Election 2025 | दिल्ली छावनी |  Candidates | Online Support',
         // id: '4',
      },
      {
         sources: 'https://www.youtube.com/shorts/XnFyDI_tBLc',
         // subtitle: 'By Blender Foundation',
         // thumb: 'images/BigBuckBunny.jpg',
         title: 'Delhi Election 2025 | Bijwasan |  Candidates | Online Support',
         // id: '5',
      },
      {
         sources: 'https://www.youtube.com/shorts/Gv2kcx0vH9Y',
         // subtitle: 'By Blender Foundation',
         // thumb: 'images/ElephantsDream.jpg',
         title: 'Delhi Election 2025 | Chhatarpur |  Candidates | Online Support',
         // id: '6',
      },
      {
         sources: 'https://www.youtube.com/shorts/HZdYApqyi8Q',
         // subtitle: 'By Blender Foundation',
         // thumb: 'images/BigBuckBunny.jpg',
         title: 'Delhi Election 2025 | Chandni Chowk |  Candidates | Online Support',
         // id: '7',
      },
      {
         sources: 'https://www.youtube.com/shorts/d6SLs5xxloE',
         // subtitle: 'By Blender Foundation',
         // thumb: 'images/ElephantsDream.jpg',
         title: 'Delhi Elections 2025 |  Tughlakabad | Candidates | Online Support',
         // id: '8',
      },
      {
         sources: 'https://www.youtube.com/shorts/5JQEfYPDdyk',
         // subtitle: 'By Blender Foundation',
         // thumb: 'images/BigBuckBunny.jpg',
         title: 'Delhi Elections 2025 | Trilokpuri | Candidates | Online Support',
         // id: '9',
      },
      {
         sources: 'https://www.youtube.com/shorts/7NNXkVrDWWU',
         // subtitle: 'By Blender Foundation',
         // thumb: 'images/ElephantsDream.jpg',
         title: 'Delhi Elections 2025 | Tri Nagar | Candidates | Online Support',
         // id: '10',
      },
      {
         sources: 'https://www.youtube.com/shorts/AEYdCJTWhZQ',
         // subtitle: 'By Blender Foundation',
         // thumb: 'images/BigBuckBunny.jpg',
         title: 'Delhi Elections 2025 | Timarpur  | Candidates | Online Support',
         // id: '11',
      },
      {
         sources: 'https://www.youtube.com/shorts/ITD3u3li8dU',
         // subtitle: 'By Blender Foundation',
         // thumb: 'images/ElephantsDream.jpg',
         title: 'Delhi Elections 2025 | Tilak Nagar | Candidates | Online Support',
         // id: '12',
      },
      {
         sources: 'https://www.youtube.com/shorts/a8PuUL95rZo',
         // subtitle: 'By Blender Foundation',
         // thumb: 'images/BigBuckBunny.jpg',
         title: 'Delhi Elections 2025 |  Sultanpur Majra  | Candidates | Online Support',
         // id: '13',
      },
      {
         sources: 'https://www.youtube.com/shorts/m1N3Uj9u1EY',
         // subtitle: 'By Blender Foundation',
         // thumb: 'images/ElephantsDream.jpg',
         title: 'Delhi Elections 2025 | Shalimar Bagh  | Candidates | Online Support',
         // id: '14',
      },
      {
         sources: 'https://www.youtube.com/shorts/1atb6nmZGJ0',
         // subtitle: 'By Blender Foundation',
         // thumb: 'images/BigBuckBunny.jpg',
         title: 'Delhi Elections 2025 | Shahdara | Candidates | Online Support',
         // id: '15',
      },
      {
         sources: 'https://www.youtube.com/shorts/x25VVje0hnI',
         // subtitle: 'By Blender Foundation',
         // thumb: 'images/ElephantsDream.jpg',
         title: 'Delhi Elections 2025 | Seemapuri | Candidates | Online Support',
         // id: '16',
      },
   ]

   // Carousel settings
   const settings = {
      dots: false,
      speed: 500,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
   }

   // const playerRef = React.useRef(null)

   //  const videoJsOptions = {
   //     autoplay: true,
   //     controls: true,
   //     responsive: true,
   //     fluid: true,
   //     sources: [
   //        {
   //           src: '/path/to/video.mp4',
   //           type: 'video/mp4',
   //        },
   //     ],
   //  }

   // const handlePlayerReady = (player) => {
   //    playerRef.current = player

   //    // You can handle player events here, for example:
   //    player.on('waiting', () => {
   //       videojs.log('player is waiting')
   //    })

   //    player.on('dispose', () => {
   //       videojs.log('player will dispose')
   //    })
   // }

   return (
      <div>
         <Slider {...settings}>
            {shortsVideos.map((video, index) => (
               <Box key={index} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {/* <VideoJS
                     key={index}
                     options={{
                        src: video.sources,
                        autoplay: true,
                        controls: true,
                        responsive: true,
                        fluid: true,
                        aspectRatio: '9:16',
                     }}
                     onReady={handlePlayerReady}
                     className="custom-video-player"
                  /> */}
                  {/* <iframe
                     width="95%"
                     //  height="315"
                     autoPlay
                     src={video.sources}
                     //  title="YouTube video player"
                     //  frameBorder="0"
                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                     allowfullscreen
                  ></iframe> */}
                  {/* <CardMedia
                     component="iframe"
                     src={video.sources}
                     sx={{
                        aspectRatio: '16/9',
                     }}
                  /> */}
                  <Microlink url={video.sources} fetchData lazy={{ threshold: 0.5 }} />
                  <Typography variant="body1" sx={{ lineHeight: 1.2 }}>
                     {video.title}
                  </Typography>
               </Box>
            ))}
         </Slider>
      </div>
   )
}

function ShortsCarouselHome02() {
   const shortsVideos = [
      {
         sources: 'https://www.youtube.com/shorts/td-bgKs2CsI',
         title: 'Delhi Elections 2025 | Seelampur | Candidates | Online Support',
      },
      {
         sources: 'https://www.youtube.com/shorts/65CBbzZB0FU',
         title: 'Delhi Elections 2025 | Sangam Vihar | Candidates | Online Support',
      },
      {
         sources: 'https://www.youtube.com/shorts/FpZAIE2GzcY',
         title: 'Delhi Elections 2025 | Shakur Basti | Candidates | Online Support',
      },
      {
         sources: 'https://www.youtube.com/shorts/bQPeFn1r7Tw',
         title: 'Delhi Elections 2025 | Sadar Bazar | Candidates | Online Support',
      },
      {
         sources: 'https://www.youtube.com/shorts/U22m08XezF4',
         title: 'Delhi Elections 2025 | Rohtas Nagar | Candidates | Online Support',
      },
      {
         sources: 'https://www.youtube.com/shorts/_aEcXh4qpQ0',
         title: 'Delhi Elections 2025 | Rohini | Candidates | Online Support',
      },
      {
         sources: 'https://www.youtube.com/shorts/QCXidbFwtRo',
         title: 'Delhi Elections 2025 | Rithala | Candidates | Online Support',
      },
      {
         sources: 'https://www.youtube.com/shorts/HHBSePT2MO8',
         title: 'Delhi Elections 2025 | Rajouri Garden | Candidates | Online Support',
      },
      {
         sources: 'https://www.youtube.com/shorts/_-eDxVxaf84',
         title: 'Delhi Elections 2025 |  Gandhi Nagar | Candidates | Online Support',
      },
      {
         sources: 'https://www.youtube.com/shorts/uWP5G0q4b0Q',
         title: 'Delhi Elections 2025 | Krishna Nagar | Candidates | Online Support',
      },
      // {
      //    sources: '',
      //    title: '',
      // },
      // {
      //    sources: '',
      //    title: '',
      // },
      // {
      //    sources: '',
      //    title: '',
      // },
      // {
      //    sources: '',
      //    title: '',
      // },
      // {
      //    sources: '',
      //    title: '',
      // },
      // {
      //    sources: '',
      //    title: '',
      // },
      // {
      //    sources: '',
      //    title: '',
      // },
   ]

   // Carousel settings
   const settings = {
      dots: false,
      speed: 500,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2500,
      rtl: false,
   }

   return (
      <div>
         <Slider {...settings}>
            {shortsVideos.map((video, index) => (
               <Box key={index} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Microlink url={video.sources} fetchData lazy={{ threshold: 0.5 }} />
                  <Typography variant="body1" sx={{ lineHeight: 1.2 }}>
                     {video.title}
                  </Typography>
               </Box>
            ))}
         </Slider>
      </div>
   )
}

export { ShortsCarouselHome02 }

export default ShortsCarouselHome
