import { collection, doc, getDoc, getDocs, query } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { db } from '../../FirebaseConfig'
import { Box } from '@mui/system'
import { Card, CardContent, CardMedia, Link, Tabs, Typography, useTheme } from '@mui/material'
import moment from 'moment'
import HowToVoteIcon from '@mui/icons-material/HowToVote'
import { TabContext } from '@mui/lab'
import { StyledTab1, TabPanel2, a11yProps } from '../../Components/TabPanelSystem'
import SwipeableViews from 'react-swipeable-views'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import ElecManifestoBasic from './ElecManifestoBasic'
import ElecManifestoAnalysis from './ElecManifestoAnalysis'
import ElecManifestoComparision from './ElecManifestoComparision'
import ElecTheManifesto from './ElecTheManifesto'
import ElecManifestoNews from './ElecManifestoNews'

function ElecManifesto() {
   const { manifestoId, elecName } = useParams()
   console.log('manifestoid at start: ', manifestoId, 'elecName at start: ', elecName)
   const navigate = useNavigate()

   // const theme = useTheme()
   const [value, setValue] = useState(0)
   const handleChangeNavTab = (event, newValue) => {
      setValue(newValue)
   }
   const handleChangeNavTabIndex = (index) => {
      setValue(index)
   }

   const [fetchStatus, setFetchStatus] = useState('idle')
   const [elec, setElec] = useState('')
   const [manifesto, setManifesto] = useState('')
   const [weblinksListRoot, setWeblinksListRoot] = useState([])
   const [weblinksList, setWeblinksList] = useState([])

   useEffect(() => {
      if (fetchStatus === 'idle') {
         console.log('reached first blank use effect')
         fetchManifesto(manifestoId)
      }
   }, [])

   const fetchManifesto = async (manifestoId) => {
      console.log('reached fetching manifesto')
      try {
         const docRef = doc(db, 'ElectionManifestoes', manifestoId)
         const docSnap = await getDoc(docRef)
         console.log('fetched manifesto')
         if (docSnap.exists()) {
            setManifesto({ ...docSnap.data(), id: docSnap.id })
            fetchWeblinks(manifestoId)
         } else {
            navigate('/', { replace: true })
         }

         setFetchStatus('success')
      } catch (err) {
         console.log('error fetching manifesto: ', err.message)
         setFetchStatus('error')
      }
   }

   const fetchWeblinks = async (ManifestoId) => {
      try {
         const querryWeblinks = query(collection(db, 'ElectionManifestoes', ManifestoId, 'WeblinksLists'))
         const querySnapshot = await getDocs(querryWeblinks)
         if (querySnapshot && querySnapshot.length < 1) {
            setWeblinksList([])
         } else {
            const listFetched = []
            querySnapshot.forEach((docSnap) => {
               listFetched.push(...docSnap.data().Weblinks)
            })
            // const filteredList = listFetched.filter((item) => item.Election_id === elec.id)
            const sortedList = [...listFetched].sort((a, b) => {
               // bigger date first
               if (moment(a.Date.toDate()).isAfter(moment(b.Date.toDate()))) {
                  return -1
               } else if (moment(a.Date.toDate()).isBefore(moment(b.Date.toDate()))) {
                  return 1
               } else {
                  return 0
               }
            })
            setWeblinksListRoot(listFetched)
            setWeblinksList(sortedList)
         }
      } catch (err) {
         console.log('error fetching weblinks', err)
      }
   }

   useEffect(() => {
      console.log('weblinks list: ', weblinksList)
   }, [weblinksList])

   const handleWeblinksUpdate = (obj) => {
      weblinksListRoot.push(obj)
   }

   useEffect(() => {
      console.log('reached use effect of manifesto ..', manifesto)
      if (manifesto !== '') {
         if (elecName !== manifesto.ElectionName) {
            navigate('/', { replace: true })
         } else {
            console.log('election id ..', manifesto.Election_id)
            fetchElec(manifesto.Election_id)
            // const sortedConstisListRoot = [...elec.Constituencies].sort((a, b) => {
            //    if (b.Name > a.Name) {
            //       return -1
            //    } else if (b.Name < a.Name) {
            //       return 1
            //    } else {
            //       return 0
            //    }
            // })
            // setConstisListRoot(sortedConstisListRoot)
         }
      } else {
         console.log('use effect of manifesto is null: ')
      }
   }, [manifesto])

   const fetchElec = async (elecId) => {
      console.log('reached fetching elections 1')
      try {
         const docRef = doc(db, 'Elections', elecId)
         const docSnap = await getDoc(docRef)
         console.log('reached fetching elections 2')
         if (docSnap.exists()) {
            setElec({ ...docSnap.data(), id: docSnap.id })
         } else {
            navigate('/', { replace: true })
         }
      } catch (err) {
         console.log('error', err.message)
         setFetchStatus('error')
      }
   }

   useEffect(() => {
      console.log('reached use effect of elec ..', elec)
      if (elec !== '') {
         //  fetchLegis(elec.Legislature_id)
         //  fetchCadidates()
         //  const sortedConstisListRoot = [...elec.Constituencies].sort((a, b) => {
         //     if (b.Name > a.Name) {
         //        return -1
         //     } else if (b.Name < a.Name) {
         //        return 1
         //     } else {
         //        return 0
         //     }
         //  })
         //  setConstisListRoot(sortedConstisListRoot)
      } else {
         console.log('use effect of elec is null: ')
      }
   }, [elec])

   //    const fetchLegis = async (LegislatureId) => {
   //       try {
   //          const docRef = doc(db, 'Legislatures', LegislatureId)
   //          const docSnap = await getDoc(docRef)
   //          console.log('reached fetching legislature')
   //          if (docSnap.exists()) {
   //             setLegis({ ...docSnap.data(), id: docSnap.id })
   //          } else {
   //             navigate('/', { replace: true })
   //          }
   //       } catch (err) {
   //          console.log('error fetchinf elec', err)
   //       }
   //    }

   const theme = useTheme()

   return (
      <Box sx={{ minHeight: 800, px: 0.5, pb: 20 }}>
         <Card
            sx={{
               my: 1,
               py: 1,
               px: 2,
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               // backgroundColor: '#000000',
               backgroundImage: 'linear-gradient(to right, #545159, #797580, #545159)',
            }}
         >
            <Box sx={{ display: 'flex', flexDirection: 'column', px: 2 }}>
               <CardContent sx={{ flex: '1 0 auto' }}>
                  {manifesto && (
                     <div>
                        {manifesto.NameLocal && (
                           <Typography
                              component="div"
                              variant="h5"
                              sx={{ fontWeight: 700, fontSize: 22, fontFamily: 'Poppins' }}
                              color="#FFFFFF"
                           >
                              {manifesto.NameLocal}
                           </Typography>
                        )}
                        {manifesto.NameHindi && manifesto.NameLocal === '' && (
                           <Typography
                              component="div"
                              variant="h5"
                              sx={{ fontWeight: 700, fontSize: 20, fontFamily: 'Poppins' }}
                              color="#FFFFFF"
                           >
                              {manifesto.NameHindi}
                           </Typography>
                        )}
                        {manifesto.NameHindi && manifesto.NameLocal !== '' && (
                           <Typography component="div" sx={{ fontWeight: 700, fontSize: 16 }} color="#FFFFFF">
                              {manifesto.NameHindi}
                           </Typography>
                        )}
                        {manifesto.NameLocal === '' && (
                           <Typography
                              component="div"
                              variant="h5"
                              sx={{ fontWeight: 700, fontSize: 20, fontFamily: 'Poppins' }}
                              color="#FFFFFF"
                           >
                              {manifesto.Name}
                           </Typography>
                        )}
                        {manifesto.NameLocal !== '' && (
                           <Typography
                              component="div"
                              sx={{ fontWeight: 700, fontSize: 16, fontFamily: 'Poppins' }}
                              color="#FFFFFF"
                           >
                              {manifesto.Name}
                           </Typography>
                        )}
                        <Box>
                           <Box
                              sx={{
                                 display: 'inline',
                                 mx: 0.5,
                                 fontSize: 14,
                                 fontWeight: 400,
                                 color: '#D0D3D5',
                              }}
                           >
                              Released on:
                           </Box>
                           {manifesto.DateReleased !== '' && (
                              <Box
                                 sx={{
                                    display: 'inline',
                                    mx: 0.5,
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: '#ffffff',
                                 }}
                              >
                                 <>{moment(manifesto.DateReleased.toDate()).format('Do MMMM YYYY')}</>
                              </Box>
                           )}
                        </Box>
                        <Box sx={{ display: 'flex', direction: 'row' }}>
                           <Box
                              sx={{
                                 display: 'inline',
                                 mx: 0.5,
                                 fontSize: 14,
                                 fontWeight: 400,
                                 color: '#D0D3D5',
                              }}
                           >
                              <HowToVoteIcon sx={{ ml: 1 }} />{' '}
                           </Box>
                           <Box
                              sx={{
                                 display: 'inline',
                                 mx: 0.5,
                                 fontSize: 14,
                              }}
                           >
                              {manifesto.ElectionNameLocal !== '' && (
                                 <Box>
                                    <Link
                                       sx={{ color: '#ffffff', fontWeight: 700 }}
                                       href={`/election/${manifesto.ElectionName}/${manifesto.Election_id}`}
                                       rel="noreferrer"
                                       target="_blank"
                                    >
                                       {manifesto.ElectionNameLocal}
                                    </Link>
                                 </Box>
                              )}
                              <Box>
                                 <Link
                                    sx={{ color: '#ffffff', fontWeight: 700 }}
                                    href={`/election/${manifesto.ElectionName}/${manifesto.Election_id}`}
                                    rel="noreferrer"
                                    target="_blank"
                                 >
                                    {manifesto.ElectionNameHindi}
                                 </Link>
                              </Box>
                              <Box>
                                 <Link
                                    sx={{ color: '#ffffff', fontWeight: 700 }}
                                    href={`/election/${manifesto.ElectionName}/${manifesto.Election_id}`}
                                    rel="noreferrer"
                                    target="_blank"
                                 >
                                    {manifesto.ElectionName}{' '}
                                 </Link>
                              </Box>
                              {elec && elec.DateStart && (
                                 <Box
                                    sx={{
                                       display: 'inline',
                                       mx: 0.5,
                                       fontSize: 14,
                                       fontWeight: 400,
                                       color: '#ffffff',
                                    }}
                                 >
                                    {moment(elec.DateStart.toDate()).format('Do MMM YYYY')}

                                    {moment(elec.DateStart.toDate()).format('Do MMM YYYY') !==
                                       moment(elec.DateFinish.toDate()).format('Do MMM YYYY') && (
                                       <> - {moment(elec.DateFinish.toDate()).format('Do MMM YYYY')}</>
                                    )}
                                 </Box>
                              )}
                           </Box>
                        </Box>
                        {/* <Box>
                     <Box
                        sx={{
                           display: 'inline',
                           mx: 0.5,
                           fontSize: 14,
                           color: '#b3bdbd',
                        }}
                     >
                        Poll end:
                     </Box>
                     <Box
                        sx={{
                           display: 'inline',
                           mx: 0.5,
                           fontSize: 14,
                           color: '#ffffff',
                        }}
                     >
                        {moment(elec.DateFinish.toDate()).format('Do MMMM YYYY')}
                     </Box>
                  </Box> */}
                     </div>
                  )}
               </CardContent>
            </Box>
            <Link
               href={`/political-party/${manifesto.IssuedByPartyName}/${manifesto.IssuedByParty_id}`}
               sx={{ mr: 1, ml: 0.5, textDecoration: 'none !important' }}
               rel="noreferrer"
               target="_blank"
            >
               <CardMedia
                  component="img"
                  sx={{ width: 151, borderRadius: 1, mr: 1 }}
                  alt={manifesto.Name}
                  src={manifesto.IssuedByPartyFlag ? manifesto.IssuedByPartyFlag : null}
               />
            </Link>
         </Card>

         <TabContext value={value}>
            <div>
               <Tabs
                  value={value}
                  onChange={handleChangeNavTab}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="full width tabs example"
                  centered
                  //   variant="scrollable"
                  //   scrollButtons="auto"
                  sx={{
                     marginBottom: 1,
                     py: 0.5,
                     // pl: 5,
                     minHeight: 25,
                     // ml: 5,
                     backgroundColor: '#e7e9bb',
                  }}
               >
                  <StyledTab1 label={`Basic info \n मूल सूचना`} {...a11yProps(0)} />
                  <StyledTab1 label={`The Manifesto \n घोषणापत्र`} {...a11yProps(1)} />
                  <StyledTab1 label={`Analysis \n विश्लेषण`} {...a11yProps(2)} />
                  <StyledTab1 label={`Comparisions \n तुलना`} {...a11yProps(3)} />
                  <StyledTab1 label={`News / Statements \n समाचार / वक्तव्य`} {...a11yProps(4)} />
               </Tabs>
            </div>
            <Grid2 container sx={{ mt: 2 }} id="vBoxGridContainer">
               <Grid2 item xs={8} id="vboxGrid1">
                  <Box>
                     <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeNavTabIndex}
                     >
                        <TabPanel2 value={value} index={0} dir={theme.direction}>
                           {manifesto && <ElecManifestoBasic manifesto={manifesto} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={1} dir={theme.direction}>
                           {manifesto && <ElecTheManifesto manifesto={manifesto} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={2} dir={theme.direction}>
                           {manifesto && <ElecManifestoAnalysis manifesto={manifesto} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={3} dir={theme.direction}>
                           {manifesto && <ElecManifestoComparision manifesto={manifesto} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={4} dir={theme.direction}>
                           {manifesto && (
                              <ElecManifestoNews
                                 elec={elec}
                                 manifesto={manifesto}
                                 weblinksList={weblinksList}
                                 weblinksListRoot={weblinksListRoot}
                                 handleWeblinksUpdate={handleWeblinksUpdate}
                              />
                           )}
                        </TabPanel2>
                     </SwipeableViews>
                  </Box>
               </Grid2>
               <Grid2 item xs={4}></Grid2>
            </Grid2>
         </TabContext>
      </Box>
   )
}

export default ElecManifesto
