import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useTheme } from '@mui/material/styles'
import { doc, getDoc } from 'firebase/firestore'

import styles from './LegisMem.module.css'
import { Avatar, Badge, Box, Card, CardContent, CardMedia, Grid, Link, Tabs, Typography } from '@mui/material'
import { TabContext } from '@mui/lab'

import SwipeableViews from 'react-swipeable-views'
import { StyledTab1, TabPanel2, a11yProps } from '../../../Components/TabPanelSystem'
import { db } from '../../../FirebaseConfig'

import logoKia from '../../../Images/Adverts/Kia-01.jpeg'
import logoKotak from '../../../Images/Adverts/Kotak-01.jpeg'
import logoMaxLife from '../../../Images/Adverts/MaxLife-01.jpeg'

import LegisMemBasicInfo from '../LegisMemBasicInfo/LegisMemBasicInfo'
import LegisMemPetition from '../LegisMemPetition/LegisMemPetition'
import LegisMemInteract from '../LegisMemInteract/LegisMemInteract'
import LegisMemCred from '../LegisMemCred/LegisMemCred'
import LegisMemStatements from '../LegisMemStatements/LegisMemStatements'
import LegisMemWorks from '../LegisMemWorks/LegisMemWorks'
import { Helmet } from 'react-helmet'
import { countWordsUsingReplace } from '../../../Utils/Conversions/CaseConversion'
import {
   SmallAvatar,
   stringAvatar150,
   stringAvatar150Single,
   stringAvatar50,
   stringAvatar50Single,
} from '../../../Utils/MUITheme/MUITheme'

function LegisMem() {
   const { memberName, memberId } = useParams()
   console.log('parameters from link: ', memberId, '-', memberName)

   const navigate = useNavigate()

   const theme = useTheme()
   const [value, setValue] = useState(0)

   const handleChangeNavTab = (event, newValue) => {
      setValue(newValue)
   }

   const handleChangeNavTabIndex = (index) => {
      setValue(index)
   }

   const [member, setMember] = useState({})
   const [fetchStatus, setFetchStatus] = useState('idle')

   useEffect(() => {
      if (fetchStatus === 'idle') {
         fetchMember(memberId)
      }
   }, [])

   const fetchMember = async (memberId) => {
      try {
         const docRef = doc(db, 'LegisMembers', memberId)
         const docSnap = await getDoc(docRef)

         if (docSnap.exists()) {
            if (docSnap.data().Name !== memberName) {
               navigate('/', { replace: true })
            } else {
               setMember({ ...docSnap.data(), id: docSnap.id })
            }
         } else {
            navigate('/', { replace: true })
         }

         setFetchStatus('success')
      } catch (err) {
         console.log('error', err)
         setFetchStatus('error')
      }
   }

   return (
      <Box sx={{ minHeight: 600, px: 0.5, pb: 20 }}>
         <div>
            <Helmet>
               <title>{`Track Democracy -  {elec.Name} - Member of - {legis.Name}`}</title>
            </Helmet>
         </div>
         <Card
            sx={{
               my: 1,
               py: 1,
               pr: 2,
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               backgroundImage: 'linear-gradient(to right, #403b4a, #d6d6d6)',
            }}
         >
            <Box sx={{ display: 'flex', flexDirection: 'column', px: 2 }}>
               <CardContent sx={{ flex: '1 0 auto', fontWeight: 700 }}>
                  {member && (
                     <div>
                        {member.NameLocal && (
                           <Typography
                              component="div"
                              variant="h5"
                              sx={{ fontWeight: 700, fontSize: 22, fontFamily: 'Poppins' }}
                              color="#FFFFFF"
                           >
                              {member.NameLocal}
                           </Typography>
                        )}
                        {member.NameLocal === '' && (
                           <Typography
                              component="div"
                              variant="h5"
                              sx={{ fontWeight: 700, fontSize: 22, fontFamily: 'Poppins' }}
                              color="#FFFFFF"
                           >
                              {member.NameHindi}
                           </Typography>
                        )}
                        {member.NameLocal !== '' && (
                           <Typography
                              component="div"
                              sx={{ fontWeight: 700, fontSize: 16, fontFamily: 'Poppins' }}
                              color="#FFFFFF"
                           >
                              {member.NameHindi}
                           </Typography>
                        )}
                        {member.NameLocal === '' && (
                           <Typography
                              component="div"
                              variant="h5"
                              sx={{ fontWeight: 700, fontSize: 22, fontFamily: 'Poppins' }}
                              color="#FFFFFF"
                           >
                              {member.Name}
                           </Typography>
                        )}
                        {member.NameLocal !== '' && (
                           <Typography
                              component="div"
                              sx={{ fontWeight: 700, fontSize: 16, fontFamily: 'Poppins' }}
                              color="#FFFFFF"
                           >
                              {member.Name}
                           </Typography>
                        )}
                     </div>
                  )}
               </CardContent>
            </Box>

            <CardMedia>
               {member.Name && (
                  <Badge
                     overlap="circular"
                     anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                     badgeContent={
                        countWordsUsingReplace(member.Name) > 1 ? (
                           <SmallAvatar {...stringAvatar50(member.Name)} src={member.PartyFlag} />
                        ) : (
                           <SmallAvatar {...stringAvatar50Single(member.Name)} src={member.PartyFlag} />
                        )
                     }
                  >
                     {countWordsUsingReplace(member.Name) > 1 ? (
                        <Avatar {...stringAvatar150(member.Name)} alt="Travis Howard" src={member.Image} />
                     ) : (
                        <Avatar {...stringAvatar150Single(member.Name)} alt="Travis Howard" src={member.Image} />
                     )}
                  </Badge>
               )}
            </CardMedia>
         </Card>
         <TabContext value={value}>
            <div>
               <Tabs
                  value={value}
                  onChange={handleChangeNavTab}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="full width tabs example"
                  centered
                  // variant="scrollable"
                  // scrollButtons="auto"
                  sx={{
                     marginBottom: 1,
                     py: 0.5,
                     minHeight: 25,
                     backgroundColor: '#e7e9bb',
                  }}
               >
                  <StyledTab1 label={`Basic info \n मूल सूचना`} {...a11yProps(0)} />
                  <StyledTab1 label={`Send Petitions \n याचिकाएँ भेजें`} {...a11yProps(1)} />
                  <StyledTab1 label={`Individual Request \n व्यक्तिगत अनुरोध`} {...a11yProps(2)} />
                  <StyledTab1 label={`Credentials \n प्रमाणिकताएँ`} {...a11yProps(3)} />
                  <StyledTab1 label={`Statements \n वक्तव्य`} {...a11yProps(4)} />
                  <StyledTab1 label={`Works \n कार्य`} {...a11yProps(5)} />
               </Tabs>
            </div>
            <Grid container spacing={1} sx={{ mt: 1 }} id="vBoxGridContainer">
               <Grid item xs={12} sm={8} id="vboxGrid1">
                  <Box>
                     <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeNavTabIndex}
                        id="swipeableee"
                     >
                        <TabPanel2 value={value} index={0} dir={theme.direction}>
                           {member && <LegisMemBasicInfo member={member} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={1} dir={theme.direction}>
                           {member && <LegisMemPetition member={member} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={2} dir={theme.direction}>
                           {member && <LegisMemInteract member={member} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={3} dir={theme.direction}>
                           {member && <LegisMemCred member={member} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={4} dir={theme.direction}>
                           {member && <LegisMemStatements member={member} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={5} dir={theme.direction}>
                           {member && <LegisMemWorks member={member} />}
                        </TabPanel2>
                     </SwipeableViews>
                  </Box>
               </Grid>
               <Grid item xs={12} sm={4}>
                  <Grid container spacing={1} sx={{ my: 0.5 }} disableequaloverflow="true">
                     <Grid xs={6} sm={12} md={12}>
                        <Box
                           sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              p: 1,
                           }}
                        >
                           <Link
                              target="_blank"
                              rel="noopener"
                              href="https://www.kia.com/in/our-vehicles/seltos/showroom.html?utm_source=msan&utm_medium=cpm&utm_campaign=seltos-sustenance-2&utm_content=banner&dclid=CN7ZnMGm-YcDFY-O2AUd0BIuZA"
                           >
                              <img src={logoKia} className={styles.imgAd} />
                           </Link>
                        </Box>
                     </Grid>
                     <Grid xs={6} sm={12} md={12}>
                        <Box
                           sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              p: 1,
                           }}
                        >
                           <Link
                              target="_blank"
                              rel="noopener"
                              href="https://www.kotak.com/en/personal-banking/insurance/life-insurance/protection-plans/kotak-e-term.html"
                           >
                              <img src={logoKotak} className={styles.imgAd} />
                           </Link>
                        </Box>
                     </Grid>
                     <Grid xs={6} sm={12} md={12}>
                        <Box
                           sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              p: 1,
                           }}
                        >
                           <Link
                              target="_blank"
                              rel="noopener"
                              href="https://www.maxlifeinsurance.com/investment-plans/investment-calculator?utmCode=14230408&utm_source=Impact&utm_medium=Bing_Native_Savings&utm_campaign=May24_MLI_BingNative_Savings_English_Domestic_ROI_Misc_PR&utm_term=1Cr_FatherSon1English_ULIP_15032024&utm_content={{site_source_name}}&utm_theme=ULIP"
                           >
                              <img src={logoMaxLife} className={styles.imgAd} />
                           </Link>
                        </Box>
                     </Grid>
                     <Grid xs={6} sm={12} md={12}>
                        <Box
                           sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              p: 1,
                           }}
                        >
                           <Link
                              target="_blank"
                              rel="noopener"
                              href="https://www.kia.com/in/our-vehicles/seltos/showroom.html?utm_source=msan&utm_medium=cpm&utm_campaign=seltos-sustenance-2&utm_content=banner&dclid=CN7ZnMGm-YcDFY-O2AUd0BIuZA"
                           >
                              <img src={logoKia} className={styles.imgAd} />
                           </Link>
                        </Box>
                     </Grid>
                     <Grid xs={6} sm={12} md={12}>
                        <Box
                           sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              p: 1,
                           }}
                        >
                           <Link
                              target="_blank"
                              rel="noopener"
                              href="https://www.maxlifeinsurance.com/investment-plans/investment-calculator?utmCode=14230408&utm_source=Impact&utm_medium=Bing_Native_Savings&utm_campaign=May24_MLI_BingNative_Savings_English_Domestic_ROI_Misc_PR&utm_term=1Cr_FatherSon1English_ULIP_15032024&utm_content={{site_source_name}}&utm_theme=ULIP"
                           >
                              <img src={logoMaxLife} className={styles.imgAd} />
                           </Link>
                        </Box>
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>
         </TabContext>
      </Box>
   )
}

export default LegisMem
